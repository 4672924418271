import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatMonthYear, formatDate } from 'utils/time.util';
import RecordTableToolbar from './RecordTableToolbar';
import RecordTableHead from './RecordTableHead';

function descendingComparator(rawA, rawB, orderBy) {
  const prop = orderBy.split('.');
  const len = prop.length;
  let a = Object.assign(rawA);
  let b = Object.assign(rawB);
  let i = 0;
  while (i < len) {
    a = a ? a[prop[i]] : null;
    b = b ? b[prop[i]] : null;
    i++;
  }
  if ((!b && a) || b < a) {
    return -1;
  }
  if ((b && !a) || b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}

function sortRecords(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const styles = (theme) => ({
  dateTableCell: {
    textAlign: 'center',
  },
  table: {
    minWidth: '100%',
  },
  TableCellCheckboxStyle: {
    padding: '0px 12px 0 0px',
  },
  tableRowStyles: {
    transition: '0.2s ease-in-out',
    '&$selected, &$selected:hover': {
      backgroundColor: `${theme.palette.primary.light}16`,
    },
  },
  selected: {},
});

function RecordTable(props) {
  const { classes, records, selectedRecords, updateSelectedRecords, recordType, pdfPreview } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    const newSelection = sortRecords(selectedRecords, getComparator(newOrder, property));
    updateSelectedRecords(newSelection);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      updateSelectedRecords(records);
      return;
    }
    updateSelectedRecords([]);
  };

  const handleRecordClick = (event, data) => {
    let newSelection = [...selectedRecords];
    const isRemovingRecord = Boolean(selectedRecords.find((record) => record._id === data._id));

    if (isRemovingRecord) {
      newSelection = newSelection.filter((record) => record._id !== data._id);
    } else {
      newSelection.push(data);
      newSelection = sortRecords(newSelection, getComparator(order, orderBy));
    }

    updateSelectedRecords(newSelection);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selectedRecords.some((record) => record._id === id);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <RecordTableToolbar recordType={recordType} numSelected={selectedRecords.length} pdfPreview={pdfPreview} />
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
          <RecordTableHead
            numSelected={selectedRecords.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={records.length}
          />
          <TableBody>
            {sortRecords(records, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((record, index) => {
                const isRecordSelected = isSelected(record._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    className={classes.tableRowStyles}
                    onClick={(event) => handleRecordClick(event, record)}
                    role="checkbox"
                    aria-checked={isRecordSelected}
                    tabIndex={-1}
                    key={record._id}
                    selected={isRecordSelected}
                    classes={{ selected: classes.selected }}
                  >
                    <TableCell className={classes.TableCellCheckboxStyle}>
                      <Checkbox
                        checked={isRecordSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell className={classes.dateTableCell} component="th" id={labelId} scope="row" padding="none">
                      {formatMonthYear(record.date)}
                    </TableCell>
                    <TableCell align="center">
                      {record.monthlyEntry ? formatDate(record.monthlyEntry.enteredDate) : 'N/A'}
                    </TableCell>

                    <TableCell align="center">
                      {record.review ? formatDate(record?.review?.reviewedDate) : formatDate(record?.reviewedDate)}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

RecordTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  recordType: PropTypes.string.isRequired,
  selectedRecords: PropTypes.arrayOf(PropTypes.any),
  updateSelectedRecords: PropTypes.func.isRequired,
  pdfPreview: PropTypes.element.isRequired,
};

RecordTable.defaultProps = {
  records: [],
  selectedRecords: [],
};

export default withStyles(styles)(RecordTable);
