import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useStepperCtx } from 'context/StepperContext';
import { EntryModes } from 'types/entry';

const useStyles = makeStyles({
  status: {
    display: 'inline-block',
    marginRight: '2rem',
  },
  taskNav: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 2rem',
    marginTop: '2rem',
  },
  submitBtn: {
    marginLeft: '2rem',
  },
});

function TaskNav(props) {
  const { taskName, taskId, onComplete, mode } = props;
  const { completed, toggleComplete, optional, skipped, toggleSkip } = useStepperCtx();
  const classes = useStyles();

  const isCompleted = () => completed.has(taskId);

  const isOptional = () => optional.has(taskId);

  const isSkipped = () => skipped.has(taskId);

  let status = '';
  const statusText = isSkipped() ? `${taskName} Skipped` : `${taskName} Completed`;
  if (isCompleted() || isSkipped()) {
    status = (
      <Typography variant="caption" className={classes.status}>
        {statusText}
      </Typography>
    );
  }
  let submitBtnText = isCompleted() ? 'Edit Task' : 'Complete Task';
  if (mode === EntryModes.EDIT) {
    submitBtnText = 'Submit';
  }
  const skippedBtnText = isSkipped() ? 'Unskip Task' : 'Skip Task';

  const handleComplete = () => {
    if (!isCompleted()) {
      onComplete();
    } else {
      toggleComplete();
    }
  };

  return (
    <Box className={classes.taskNav}>
      {status}
      {isOptional() && (
        <Button variant="outlined" color="primary" onClick={toggleSkip}>
          {skippedBtnText}
        </Button>
      )}
      <Button
        className={classes.submitBtn}
        disabled={isSkipped()}
        variant="outlined"
        color="primary"
        onClick={handleComplete}
      >
        {submitBtnText}
      </Button>
    </Box>
  );
}

TaskNav.propTypes = {
  taskName: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  mode: PropTypes.string,
};

TaskNav.defaultProps = {
  onComplete: () => null,
  mode: EntryModes.ENTER,
};

export default TaskNav;
