import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

function AutoSave({ debounceMs = 100 }) {
  const formik = useFormikContext();
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return null;
}

export default AutoSave;
