import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import QaEntryForm from './QaEntryForm';
import QaEntryDisplay from './QaEntryDisplay';

function QaToggleableEntry(props) {
  const { entryData, mode } = props;
  const { values, setFieldValue } = useFormikContext();
  const [isToggled, setToggled] = useState(false);

  let EntryComponent = QaEntryDisplay;

  const handleToggle = () => {
    const updatedEntries = Object.assign(values.entries);
    if (!isToggled) {
      updatedEntries[entryData._id] = cloneDeep(entryData);
    } else {
      delete updatedEntries[entryData._id];
    }
    setFieldValue('entries', updatedEntries);
    setToggled(!isToggled);
  };

  const canToggle = isToggled || mode === 'edit';
  const toggleHandler = canToggle ? handleToggle : null;

  if (isToggled) {
    EntryComponent = QaEntryForm;
  }

  return <EntryComponent entryData={entryData} click={toggleHandler} />;
}

QaToggleableEntry.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
};

export default QaToggleableEntry;
