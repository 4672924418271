import MuiTab from './MuiTab';
import MuiTypography from './MuiTypography';

export default {
  MuiTab,
  MuiTypography,
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: '#49526f',
    },
  },
  MuiPickersDay: {
    day: {
      color: '#000',
    },
    daySelected: {
      backgroundColor: '#49526f',
    },
    current: {
      color: '#49526f',
    },
    dayDisabled: {
      color: '#808080',
    },
  },
};
