import React from 'react';
import clsx from 'clsx';
import { TableRow, TableCell, FormControl, Input, Button, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import UndoIcon from '@mui/icons-material/Undo';
import PropTypes from 'prop-types';
import { RadTextInput, RadCheckbox } from 'presentational/Inputs';

const styles = (theme) => ({
  entryRow: {
    maxWidth: '100%',
    backgroundColor: `${theme.palette.primary.main}1a`,
  },

  cancelIcon: {
    fontSize: '1.2rem',
    marginRight: 16,
    color: theme.palette.primary.main,
  },
  select: {
    height: '32px',
    fontSize: '12px',
    bottom: '2px',
    'label + &': {
      marginTop: '0rem',
    },
  },
  selectedTableCell: {
    padding: 0,
    textAlign: 'center',
  },
  buttonCell: {
    padding: 0,
    textAlign: 'end',
  },
  radTextInput: {
    textAlign: 'center',
    width: '100%',
  },
  error: {},
  menuItem: {
    fontSize: '12px',
  },
  cellRoots: {
    whiteSpace: 'nowrap',
  },
  remarks: {
    width: '100%',
  },
});

function RwsDailyEntryForm(props) {
  const { entryData, classes, click } = props;

  return (
    <TableRow className={classes.entryRow}>
      <TableCell className={classes.selectedTableCell}>
        {entryData.day}
        <Input type="hidden" name={`entries.${entryData.day}.day`} value={entryData.day} />
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadCheckbox name={`entries.${entryData.day}.viewingCheckPerformed`} />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadCheckbox name={`entries.${entryData.day}.monitorCheckPerformed`} />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl>
          <RadTextInput
            inputProps={{
              style: {
                padding: '0.5rem',
              },
            }}
            name={`entries.${entryData.day}.remarks`}
            type="text"
            classes={{ root: classes.remarks }}
          />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        {entryData.initials}
        <Input type="hidden" name={`entries.${entryData.day}.enteredById`} />
      </TableCell>
      <TableCell className={classes.buttonCell}>
        <Tooltip title="Cancel">
          <Button onClick={click}>
            <UndoIcon className={classes.cancelIcon} />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

RwsDailyEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

RwsDailyEntryForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(RwsDailyEntryForm);
