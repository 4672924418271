import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DevicesOverview from 'views/deviceViews/devicesOverview';
import DeviceDashboard from 'views/deviceViews/deviceDashboard/DeviceDashboard';

function DeviceRoutes() {
  return (
    <Switch>
      <Route path="/devices/overview" exact component={DevicesOverview} />
      <Route path="/devices/:deviceId" component={DeviceDashboard} />
    </Switch>
  );
}

export default DeviceRoutes;
