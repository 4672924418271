import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDateShort } from 'utils/time.util';

const useStyles = makeStyles({
  tableTitles: {
    color: 'black',
    textAlign: 'center',
    width: '30rem',
    fontSize: '0.75rem',
    height: '50px',
    padding: '0 2rem',
    border: '1px solid #0000001f',
  },
});

function MamWeeklyOverviewRow(props) {
  const { entries, years } = props;
  const classes = useStyles();

  return years.map((year) => {
    const cells = [];
    for (let i = 0; i < 12; i++) {
      const monthString = `${i}_${year}`;
      if (entries[0] && entries[0].month === monthString) {
        cells.push(
          <TableCell colSpan={entries[0].monthSpan} className={classes.tableTitles} key={monthString}>
            {entries[0].monthSpan > 1 ? `${formatDateShort(entries[0].date)} by ${entries[0].initials}` : 'X'}
          </TableCell>,
        );
        i += entries[0].monthSpan - 1;
        entries.shift();
      } else {
        cells.push(<TableCell className={classes.tableTitles} key={monthString} />);
      }
    }
    return cells;
  });
}

MamWeeklyOverviewRow.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  years: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MamWeeklyOverviewRow;
