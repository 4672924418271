import React from 'react';
import clsx from 'clsx';
import { Grid, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useModalCtx } from 'context/ModalContext';
import HeConfirmTable from 'views/entry/he/HeConfirmTable';
import QaConfirmTable from 'views/entry/qa/QaConfirmTable';
import QcConfirmTable from 'views/entry/qc/QcConfirmTable';
import VcConfirmTable from 'views/entry/vc/VcConfirmTable';
import MamConfirmTable from 'views/entry/mam/components/MamConfirmTable';

const styles = (theme) => ({
  modalContent: {
    minWidth: '320px',
    overflow: 'auto',
  },
  headingSection: { fontSize: '1rem' },
  divider: {
    borderTop: '1px solid #00000012',
    padding: '16px',
  },
  heading: {
    padding: '16px 0 32px 0',
    fontSize: '1.2rem',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  button: {
    textTransform: 'capitalize',
    fontSize: '0.8rem',
    margin: '10px 0',
  },
  containedButton: {
    marginLeft: '24px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '16px',
  },
  confirmMsgSection: {
    overflow: 'auto',
  },
  emphasis: {
    color: theme.palette.primary.red,
  },
  outFieldsSection: {
    marginBottom: '1rem',
  },
  submit: {
    marginLeft: '1rem',
  },
});

function EntryConfirmModal(props) {
  const { classes, entryType, confirmTitle, confirmEntries, accept, fieldTitles } = props;
  const { hideModal } = useModalCtx();

  let displayType = entryType.toUpperCase();
  // TODO: Convert HE, QA, QC, and VC confirm tables to use MamConfirmTable
  let ConfirmationTable = null;
  switch (entryType) {
    case 'he':
      ConfirmationTable = HeConfirmTable;
      break;
    case 'mam':
      displayType = confirmTitle;
      ConfirmationTable = MamConfirmTable;
      break;
    case 'qa':
      ConfirmationTable = QaConfirmTable;
      break;
    case 'qc':
      ConfirmationTable = QcConfirmTable;
      break;
    case 'vc':
      ConfirmationTable = VcConfirmTable;
      break;
    default:
      throw new Error(`Unrecognized record type: ${entryType}`);
  }

  const handleSubmit = () => {
    accept();
    hideModal();
  };

  return (
    <Grid container className={classes.modalContent}>
      <Grid item xs={12} className={classes.headingSection}>
        <div className={classes.heading}>{`${displayType} Entry Confirmation`}</div>

        <div className={classes.divider} />
      </Grid>
      <Grid item xs={12} className={classes.confirmMsgSection}>
        <div className={classes.outFieldsSection}>
          The following values are about to be entered. Any input(s) marked in red will result in
          <em className={classes.emphasis}> 'out of spec'</em> for this device. Would you still like to proceed with
          these changes?
        </div>
        {confirmEntries ? <ConfirmationTable confirmEntries={confirmEntries} fieldTitles={fieldTitles} /> : null}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} />
          <Grid item xs={6} className={classes.buttons}>
            <Button className={classes.button} classes={{ contained: classes.containedButton }} onClick={hideModal}>
              Cancel
            </Button>
            <Button
              className={clsx(classes.button, classes.submit)}
              variant="outlined"
              color="primary"
              classes={{ contained: classes.containedButton }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

EntryConfirmModal.propTypes = {
  entryType: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string,
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
  accept: PropTypes.func.isRequired,
  fieldTitles: PropTypes.objectOf(PropTypes.any),
};

EntryConfirmModal.defaultProps = {
  confirmTitle: '',
  fieldTitles: {},
};

export default withStyles(styles)(EntryConfirmModal);
