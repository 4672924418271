const generateEmptyHeEntry = (currUser, device) => ({
  _id: 'new',
  deviceId: device._id,
  date: new Date().toISOString(),
  heliumLevel: '',
  pressure: '',
  coldHeadRunning: '',
  enteredBy: `${currUser.firstName} ${currUser.lastName}`,
  enteredById: currUser._id,
  outFields: [],
  heAlertLevel: device.heAlertLevel,
});

export default generateEmptyHeEntry;
