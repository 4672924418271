export const getUserInitials = (user) => (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase();

export const getUserName = (user) => `${user.firstName} ${user.lastName}`;

export const isAdmin = (user) => user.role.includes('admin') || user.role === 'god';

export const isUserCreator = (user) => user.role.includes('admin') || user.role === 'god' || user.role === 'lead_tech';

// TODO: remove the following once converted over to typescript
const UserRoles = Object.freeze({
  GOD: Symbol('god'),
  ADMIN: Symbol('admin'),
  ADMIN_P: Symbol('admin_phys'),
  PHYS: Symbol('physicist'),
  LEAD: Symbol('lead_tech'),
  TECH: Symbol('technologist'),
  M_TECH: Symbol('mam_tech'),
  ENGNR: Symbol('engineer'),
  OBSERV: Symbol('observer'),
});

export const compareRole = (userRole, comparedUserRole) => {
  // Define the hierarchy as an array where the order represents the hierarchy
  const hierarchy = [
    'god', // Highest
    'admin',
    'admin_phys',
    'physicist',
    'lead_tech',
    'technologist',
    'mam_tech',
    'engineer',
    'observer', // Lowest
  ];

  // Get the index of each user in the hierarchy array
  const index1 = hierarchy.indexOf(userRole);
  const index2 = hierarchy.indexOf(comparedUserRole);

  if (index1 === -1 || index2 === -1) {
    throw new Error('Invalid string provided');
  }

  // Compare the indices
  if (index1 < index2) {
    return 1; // user is higher than comparedUser
  }
  if (index1 > index2) {
    return -1; // user is lower than comparedUser
  }
  return 0; // user is equal to comparedUser
};

// No longer used anywhere but keeping for future use
export const formatPhoneNumber = (userPhone) => {
  const cleaned = `${userPhone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return userPhone;
};

// format full string of name into first name, last initial (i.e. John, M)
export const formatIntoFirstLastInitial = (name) => {
  if (!name) return '-';
  const temp = name.split(' ');
  const lastNameInitial = temp[1].charAt(0);
  return `${temp[0]}, ${lastNameInitial}`;
};

export const setAvatarColorByName = (name) => ({
  sx: {
    border: '1px solid #F2F2F2',
    width: 36,
    height: 36,
    fontSize: 14,
    padding: 0,
    textTransform: 'uppercase',
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});
