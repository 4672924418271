import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';

function NoPadTableHeader({ children, ...props }) {
  const styles = makeStyles(() => ({
    tableCellRoot: {
      padding: 0,
      lineHeight: '1rem',
      fontSize: '0.725rem',
      textAlign: 'center',
    },
  }));

  const classes = styles();

  return (
    <TableCell classes={{ root: classes.tableCellRoot }} {...props}>
      {children}
    </TableCell>
  );
}

export default NoPadTableHeader;
