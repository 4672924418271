import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { defaultDatasetConfig } from 'utils/chart.util';
import { formatMonthDate } from 'utils/time.util';

// TODO: switch to allow multiple chart types

function MamEntryChart(props) {
  const { title, entries, field, chartOpts, setChartImage } = props;
  const chartRef = React.createRef();

  const getChartImage = () => {
    if (setChartImage) {
      const chartImage = {
        field,
        image: chartRef.current.toBase64Image(),
      };
      setChartImage(chartImage);
    }
  };

  const datasets = [
    {
      ...defaultDatasetConfig,
      data: entries.map((dataPoint) => dataPoint[field]),
      label: field,
    },
  ];

  const chartData = {
    labels: entries.map((dataPoint) => formatMonthDate(dataPoint.date)),
    datasets,
  };

  // TODO: replace each of the following with: const <the var> = chartOpts?.suggestedMin || 0;
  let suggestedMin = 0;
  let suggestedMax = 0;
  if (chartOpts && chartOpts.suggestedMin) {
    ({ suggestedMin } = chartOpts);
  }
  if (chartOpts && chartOpts.suggestedMax) {
    ({ suggestedMax } = chartOpts);
  }

  const options = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: title,
      fontSize: 20,
    },
    scales: {
      x: {
        ticks: {
          fontSize: 16,
        },
      },
      y: {
        type: 'linear',
        display: true,
        suggestedMin, // minimum will be 0, unless there is a lower value.
        suggestedMax,
        ticks: {
          fontSize: 14,
          // OR //
          // beginAtZero: true   // minimum value will be 0.
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (t, d) => `${d.datasets[t.datasetIndex].label}: ${+t.yLabel.toFixed(2)}`,
      },
    },
    animation: {
      onComplete: getChartImage,
    },
  };
  // const plugins = [
  //   {
  //     beforeRender: (x, options) => {
  //       const c = x.chart;
  //       const dataset = x.data.datasets[0];
  //       const yScale = x.scales['y-axis-0'];
  //       const yPos = 60; // yScale.getPixelForValue(1);
  //       console.log('yPos:', yPos);
  //       console.log('c.height:', c.height);
  //       console.log('stop:', yPos / c.height - 0.01);

  //       const gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
  //       gradientFill.addColorStop(0, 'red');
  //       gradientFill.addColorStop(yPos / c.height - 0.01, 'red');
  //       gradientFill.addColorStop(yPos / c.height + 0.01, 'green');
  //       gradientFill.addColorStop(1, 'green');

  //       const model = x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
  //       model.backgroundColor = gradientFill;
  //     },
  //   },
  // ];

  // console.log('chartData:', chartData);

  return <Line ref={chartRef} data={chartData} options={options} height={65} />;
}

MamEntryChart.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string,
  chartOpts: PropTypes.objectOf(PropTypes.any),
  setChartImage: PropTypes.func,
};

MamEntryChart.defaultProps = {
  title: '',
  chartOpts: null,
  setChartImage: null,
};

export default MamEntryChart;
