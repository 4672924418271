import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { mapValues, uniqBy } from 'lodash';
import { TableCell, Table, TableHead, TableBody, TableRow, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import { getUserName } from 'utils/user.util';
import radqcApi from 'dataExchange/radqcApiClient';
import HeEntryForm from './components/HeEntryForm';
import generateEmptyHeEntry from './components/HeTable.util';
import HeToggleableEntry from './components/HeToggleableEntry';
import HeAdjustedTableCell from './components/HeAdjustedTableCell';

const styles = () => ({
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: {
    height: '98%',
  },
  bottomNavigation: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
  },
  tableCellRoot: {
    padding: 0,
    lineHeight: '1rem',
    fontSize: '0.8rem',
    textAlign: 'center',
    height: '40px',
    borderTop: '0.1px solid #0000004a',
  },
});

function HeTable(props) {
  const { match, classes, heEntries, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyHeEntry(currUser, device) };
    newEntryForm = <HeEntryForm entryData={initialValues.entries.new} />;
  }

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    showModal(EntryConfirmModal, {
      entryType: 'he',
      confirmEntries: newEntries,
      accept: () => submitForm(),
    });
  };

  const handleReview = async () => {
    const reviewPayload = {
      reviewedBy: getUserName(currUser),
      reviewedById: currUser._id,
      reviewedDate: new Date().toISOString(),
    };
    const accessToken = await getAccessTokenSilently();
    const resultMsg = await radqcApi('put', `/entries/he/device/${device._id}/review`, reviewPayload, { accessToken });
    enqueueSnackbar(resultMsg);
    const numEntriesReviewed = resultMsg.substr(0, resultMsg.indexOf(' '));
    if (numEntriesReviewed > 0) {
      updateEntries();
      updateDevices();
      updateUsers();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                coldHeadRunning: Yup.bool().required('Required'),
                date: Yup.string().required('Required'),
                enteredBy: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
                heliumLevel: Yup.number().required('Required'),
                outFields: Yup.array(),
                pressure: Yup.number().required('Required'),
                reviewedBy: Yup.string().nullable(),
                reviewedById: Yup.string().nullable(),
                device: Yup.string(),
                _id: Yup.string(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          delete newEntry._id;
          await radqcApi('post', '/entries/he', newEntry, { accessToken });
          enqueueSnackbar(`Added new Helium entry`, { variant: 'info' });
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await newEntries.forEach((entry) => radqcApi('put', `/entries/he/${entry._id}`, entry, { accessToken }));
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
        updateEntries();
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, submitForm }) => {
        let allHeEntries = heEntries;
        if (mode === 'edit') {
          const savedHeEntries = heEntries || [];
          allHeEntries = [...savedHeEntries, ...Object.values(values.entries)].sort((a, b) => a.date - b.date);
          allHeEntries = uniqBy(allHeEntries, '_id');
        }

        return (
          <Form>
            <Table key={entryKey} stickyHeader aria-label="HeTable">
              <TableHead>
                <TableRow>
                  <HeAdjustedTableCell width="150px">Date</HeAdjustedTableCell>
                  <HeAdjustedTableCell width="150px">Helium Level (%)</HeAdjustedTableCell>
                  <HeAdjustedTableCell width="150px">Pressure (PSI)</HeAdjustedTableCell>
                  <HeAdjustedTableCell width="150px">Cold Head Running</HeAdjustedTableCell>
                  <HeAdjustedTableCell width="200px">Entered By</HeAdjustedTableCell>
                  <HeAdjustedTableCell width="200px">Reviewed By</HeAdjustedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newEntryForm}
                {allHeEntries.map((entry) => (
                  <HeToggleableEntry key={entry.date} entryData={entry} mode={mode} />
                ))}
              </TableBody>
            </Table>

            <Table className={classes.bottomNavigation}>
              <TableHead className={classes.bottomTableHead}>
                <TableRow className={classes.headerTableRow}>
                  <TableCell colSpan={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={Object.keys(values.entries).length < 1 && mode !== 'review'}
                      onClick={() => (mode === 'review' ? handleReview() : handleSubmit(values, submitForm))}
                    >
                      {mode === 'review' ? 'Review' : 'Submit'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

HeTable.propTypes = {
  heEntries: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

HeTable.defaultProps = {
  heEntries: [],
};

export default withRouter(withStyles(styles)(HeTable));
