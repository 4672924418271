import React from 'react';
// import Carousel from 'react-material-ui-carousel';
import { MultilineChart, PictureAsPdf, FindInPage, PeopleOutline, EmojiEmotions } from '@mui/icons-material';
import { Paper, Typography, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import './Carousel.css';

import { Items } from 'utils/landing.util';

const styles = (theme) => ({
  CarouselContainer: {
    maxWidth: '16rem',
    padding: '1.5rem',
    zIndex: '9',
    margin: '1rem',
    minHeight: '24rem',
    backgroundColor: '#252f50', // needs to be added onto theme color
    color: theme.palette.white,
    cursor: 'initial',
    '&:hover': {
      boxShadow: '0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)',
    },

    '@media (max-width: 1131px)': {
      maxWidth: '20rem',
    },
  },
  heading: {
    textAlign: 'center',
    fontWeight: 600,
    margin: '2rem 0',
    '@media (max-width: 1015px)': {
      fontSize: '1rem',
    },
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '2rem 0',
  },
  icons: {
    fontSize: '5rem',
    color: theme.palette.white,
  },
  description: {
    fontSize: '13px',
    lineHeight: 1.6,
    minHeight: '6rem',
  },
  carouselBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  carouselHeading: {
    padding: '2rem',
    width: '100%',
  },
  carouselHeaderSub: {
    fontSize: '2rem',
    color: theme.palette.white,
    fontFamily: 'Domine, serif',
    '@media (max-width: 1015px)': {
      fontSize: '2rem',
      textAlign: 'center',
    },

    '@media (max-width: 650px)': {
      fontSize: '1.5rem',
      textAlign: 'center',
    },

    '@media (max-width: 500px)': {
      fontSize: '1.25rem',
      textAlign: 'center',
    },

    '@media (max-width: 443px)': {
      maxWidth: '320px',
    },
  },
  carouselHeader: {
    fontSize: '3rem',
    color: theme.palette.white,
    fontFamily: 'Domine, serif',
    '@media (max-width: 1015px)': {
      fontSize: '2rem',
      textAlign: 'center',
    },

    '@media (max-width: 650px)': {
      fontSize: '1.5rem',
      textAlign: 'center',
    },

    '@media (max-width: 500px)': {
      fontSize: '1.25rem',
      textAlign: 'center',
    },

    '@media (max-width: 443px)': {
      maxWidth: '320px',
    },
  },
});

function CarouselFeatures(props) {
  const { classes } = props;

  const showIcons = (type) => {
    if (type === 'limits') {
      return <MultilineChart className={classes.icons} style={{ color: '#b5dab5' }} />;
    }
    if (type === 'export') {
      return <PictureAsPdf className={classes.icons} />;
    }
    if (type === 'oversight') {
      return <FindInPage className={classes.icons} style={{ color: '#e8a8a8' }} />;
    }
    if (type === 'roles') {
      return <PeopleOutline className={classes.icons} style={{ color: '#c4c4ff' }} />;
    }
    return <EmojiEmotions className={classes.icons} />;
  };

  return (
    <div className="home-section">
      <div className="container">
        <div
          className="row home__hero-row"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={classes.carouselHeading}>
            <Typography className={classes.carouselHeader}>More features</Typography>
            <Typography className={classes.carouselHeaderSub} style={{ opacity: 0.5 }}>
              For you to handle your QC efficiently
            </Typography>
          </Box>

          <Box className={classes.carouselBox}>
            {Items.map((item) => (
              <Paper key={item.type} elevation={4} className={classes.CarouselContainer}>
                <Box className={classes.iconBox}>{showIcons(item.type)}</Box>
                <Typography className={classes.heading}>{item.name}</Typography>
                <Box className={classes.description}>{item.description}</Box>
              </Paper>
            ))}
          </Box>

          {/* <Carousel animation="slide">
            {Items.map(item => (
              <Paper className={classes.CarouselContainer}>
                <Typography variant="h2">{item.name}</Typography>
                <p>{item.img}</p>
                <p>{item.description}</p>

                <Button className="CheckButton">Check it out!</Button>
              </Paper>
            ))}
          </Carousel> */}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CarouselFeatures);
