import React from 'react';
import PropTypes from 'prop-types';
import MamEntryChart from 'views/entry/mam/components/MamEntryChart';

function PhantomPdfCharts(props) {
  const { recordType, entries, setChartImage } = props;
  const phantomChartOpts = { suggestedMin: 3, suggestedMax: 6 };
  const dim = recordType === 'Phantom 2D' ? 2 : 3;

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
      }}
    >
      <MamEntryChart
        entries={entries}
        field={`phantom${dim}Fibers`}
        title={`Phantom ${dim}D Fibers`}
        chartOpts={phantomChartOpts}
        setChartImage={setChartImage}
      />

      <MamEntryChart
        entries={entries}
        field={`phantom${dim}Specks`}
        title={`Phantom ${dim}D Specks`}
        chartOpts={phantomChartOpts}
        setChartImage={setChartImage}
      />
      <MamEntryChart
        entries={entries}
        field={`phantom${dim}Masses`}
        title={`Phantom ${dim}D Masses`}
        chartOpts={phantomChartOpts}
        setChartImage={setChartImage}
      />
    </div>
  );
}

PhantomPdfCharts.propTypes = {
  recordType: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  setChartImage: PropTypes.func.isRequired,
};

export default PhantomPdfCharts;
