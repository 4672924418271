import { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import Logo from 'components/Layouts/Logo';
import AuthStatus from 'types/authentication';
import NotificationSlideout from 'components/Layouts/Authorized/NotificationSlideout/NotificationsSlideout';
import AccountSettingsSlideout from 'components/Layouts/Authorized/AccountSettingsSlideout';
import DevicesSettingsSlideout from 'components/Layouts/Authorized/DeviceSettingsSlideout';
import FacilityDropdown from 'components/Layouts/Authorized/FacilitiesDropdown';
import LeftNavigationMenu from 'components/Layouts/Authorized/LeftNavigationMenu';
import { Typography } from '@mui/material';

const drawerWidth = 230;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const openedMixin = (MUITheme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: MUITheme.transitions.create('width', {
    easing: MUITheme.transitions.easing.sharp,
    duration: MUITheme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (MUITheme: Theme): CSSObject => ({
  transition: MUITheme.transitions.create('width', {
    easing: MUITheme.transitions.easing.sharp,
    duration: MUITheme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${MUITheme.spacing(6)})`,
  [MUITheme.breakpoints.up('sm')]: {
    width: `calc(${MUITheme.spacing(7)})`,
  },
});

const DrawerHeader = styled('div')(({ theme: MUITheme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: MUITheme.spacing(0, 1),
  ...MUITheme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme: MUITheme, open }) => ({
  zIndex: MUITheme.zIndex.drawer + 1,
  transition: MUITheme.transitions.create(['width', 'margin'], {
    easing: MUITheme.transitions.easing.sharp,
    duration: MUITheme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: MUITheme.transitions.create(['width', 'margin'], {
      easing: MUITheme.transitions.easing.sharp,
      duration: MUITheme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme: MUITheme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(MUITheme),
    '& .MuiDrawer-paper': openedMixin(MUITheme),
  }),
  ...(!open && {
    ...closedMixin(MUITheme),
    '& .MuiDrawer-paper': closedMixin(MUITheme),
  }),
}));

const AuthorizedAppBar = styled(AppBar)(() => ({
  zIndex: 2,
  borderBottom: '1px solid #FAFAFA',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: '#f6f6f6',
  boxShadow: 'none',
  padding: '0 16px',
}));

export default function MiniDrawer({ children }: any) {
  const [openNavbar, setOpenNavbar] = useState(true);
  const theme = useTheme();

  const handleExpandNavbar = () => setOpenNavbar(true);
  const handleCloseNavbar = () => setOpenNavbar(false);

  return (
    <Box component="div" display="flex">
      <AuthorizedAppBar position="fixed" open={openNavbar}>
        <Toolbar sx={{ padding: '0 !important', paddingLeft: '4px !important' }}>
          <IconButton
            color="inherit"
            onClick={handleExpandNavbar}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openNavbar && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: '#000' }} />
          </IconButton>
          <Typography sx={{ color: 'black', fontSize: 14, fontWeight: theme.typography.fontWeightBold }}>
            Facility / Group:
          </Typography>
          <FacilityDropdown />
        </Toolbar>
      </AuthorizedAppBar>
      <Drawer variant="permanent" open={openNavbar} sx={{ zIndex: 1 }}>
        <DrawerHeader>
          <Logo authStatus={AuthStatus.AUTH} />
          <IconButton onClick={handleCloseNavbar}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <LeftNavigationMenu open={openNavbar} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>

      <NotificationSlideout />
      <AccountSettingsSlideout />
      <DevicesSettingsSlideout />
    </Box>
  );
}
