import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import UndoIcon from '@mui/icons-material/Undo';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useModalCtx } from 'context/ModalContext';
import { getNoiseLimitsForCt } from 'utils/device.util';
import QcDailyEntryList from './qcDailyEntryList/QcDailyEntryList';
import QcBaseline from './components/QcBaseline';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';

const styles = (theme) => ({
  entriesList: {
    height: '340px',
  },
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: {
    height: '100%',
  },
  dailyTableContainer: {
    width: '98%',
    height: '95%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  tableTitles: {
    fontSize: 14,
    padding: '0',
    textAlign: 'center',
    minWidth: '60px',
  },
  tableStyle: {
    marginLeft: '20px',
  },
  widthFixed: {
    width: '161px',
  },
  bottomNavigation: {
    display: 'flex',
    margin: '0 1.255rem',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: '#cbd6e32b',

    '@media screen and (max-width: 659px)': {
      height: '60px',
      margin: '0 .255rem',
    },
  },
  cancelIcon: {
    fontSize: '1.2rem',
    color: 'transparent',
  },
  root: {
    fontSize: 14,
    '@media (min-width: 451px) and (max-width: 659px)': {
      fontSize: '0.8rem',
      lineHeight: '0.85rem',
    },

    '@media (max-width: 450px)': {
      fontSize: '0.7rem',
      lineHeight: '0.85rem',
      padding: '0.25rem',
      textAlign: 'center',
    },
  },
  buttonText: {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    '@media (min-width: 451px) and (max-width: 659px)': {
      fontSize: '0.8rem',
      lineHeight: '0.85rem',
    },

    '@media (max-width: 450px)': {
      fontSize: '0.7rem',
      lineHeight: '0.85rem',
      padding: '0.25rem',
      textAlign: 'center',
    },
  },
});

function QcDailyTable(props) {
  const { match, date, classes, qcEntry, mode, updateQcEntry } = props;
  const [dailyEntryKey, setDailyEntryKey] = useState(0);
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const device = devices.find((a) => match.url.includes(a._id));
  const { showModal } = useModalCtx();

  const initialValues = { entries: {} };

  // Calculate axial and helical limits
  const [axialLimit, helicalLimit] = getNoiseLimitsForCt(device);

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    showModal(EntryConfirmModal, {
      entryType: 'qc',
      confirmEntries: newEntries,
      accept: () => submitForm(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                day: Yup.number().required('Required'),
                warmUp: Yup.bool().required('Required'),
                airCals: Yup.bool().required('Required'),
                mode: Yup.string().required('Required'),
                water: Yup.object({
                  value: Yup.string().required('Required'),
                  min: Yup.string(),
                  max: Yup.string(),
                }),
                noise: Yup.object({
                  value: Yup.string().required('Required'),
                  max: Yup.string(),
                }),
                artifacts: Yup.bool().required('Required'),
                outFields: Yup.array(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
                enteredDate: Yup.string(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        await updateQcEntry('dailyEntries', newEntries);
        setDailyEntryKey(dailyEntryKey + 1);
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm({ entries: {} });
      }}
    >
      {({ values, submitForm }) => {
        const qcDailyEntries = qcEntry ? qcEntry.dailyEntries : [];
        let dailyEntries = [...qcDailyEntries, ...Object.values(values.entries)].sort((a, b) => a.day - b.day);
        dailyEntries = uniqBy(dailyEntries, 'day');
        return (
          <Form className={classes.formStyle}>
            <div className={classes.dailyTableContainer}>
              <Table stickyHeader className={classes.tableStyle}>
                <TableHead>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={classes.tableTitles}>Day</TableCell>
                    <TableCell className={classes.tableTitles}>Warm Up</TableCell>
                    <TableCell className={classes.tableTitles}>Air Cals</TableCell>
                    <TableCell className={classes.tableTitles}>Mode</TableCell>
                    <TableCell className={clsx(classes.tableTitles, classes.widthFixed)}>
                      CT<sub>water mean</sub> (HU)
                    </TableCell>
                    <TableCell className={clsx(classes.tableTitles, classes.widthFixed)}>
                      Standard Deviation (HU)
                    </TableCell>
                    <TableCell className={classes.tableTitles}>Artifacts</TableCell>
                    <TableCell className={classes.tableTitles}>Pass/Fail</TableCell>
                    <TableCell className={classes.tableTitles}>Initials</TableCell>
                    <TableCell className={classes.tableTitles}>
                      <UndoIcon className={classes.cancelIcon} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <QcDailyEntryList
                    key={dailyEntryKey}
                    date={date}
                    dailyEntries={dailyEntries}
                    mode={mode}
                    className={classes.entriesList}
                  />
                </TableBody>
              </Table>
            </div>
            <Box className={classes.bottomNavigation}>
              <Box className={classes.root}>
                <Button
                  variant="text"
                  disabled={mode === 'review'}
                  onClick={() => showModal(QcBaseline, { deviceId: device._id })}
                  classes={{ root: classes.buttonText }}
                >
                  Enter Baseline
                </Button>
              </Box>
              <Box className={classes.root}>
                CT<sub>water</sub> = 0 &plusmn; {device.waterRange} HU
              </Box>
              <Box className={classes.root}>
                Noise: A &le; {parseFloat(axialLimit).toPrecision(3)} H &le; {parseFloat(helicalLimit).toPrecision(3)}
              </Box>

              <Box>
                <Button
                  disabled={!Object.keys(values.entries).length > 0}
                  onClick={() => handleSubmit(values, submitForm)}
                  classes={{ root: classes.buttonText }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

QcDailyTable.propTypes = {
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  qcEntry: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateQcEntry: PropTypes.func.isRequired,
};

QcDailyTable.defaultProps = {
  qcEntry: null,
};

export default withRouter(withStyles(styles)(QcDailyTable));
