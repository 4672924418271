import React from 'react';
import { CssBaseline, AppBar, Toolbar, Box, Container } from '@mui/material';
import { styled } from '@mui/styles';
import theme from 'theme';
import UnauthenticatedNavbar from './UnauthenticatedNavbar';
import Footer from 'components/Layouts/Unauthorized/Footer';

const CustomizedContainer = styled(Container)((type: any) => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: type === 'light' ? '#8080801f' : 'initial',
}));

const appBar = {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  backgroundColor: '#1c2237',
  padding: '1rem',
};
const appBarLight = {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  backgroundColor: '#FFF',
};

function UnauthorizedLayout(props: any) {
  const { children, type } = props;

  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', overflow: 'auto' }}>
      <CssBaseline />
      <AppBar sx={type === 'light' ? appBarLight : appBar}>
        <Toolbar>
          <UnauthenticatedNavbar />
        </Toolbar>
      </AppBar>
      <Box component="div" sx={{ backgroundColor: '#1c2237' }}>
        <CustomizedContainer type={type}>
          <div style={theme.mixins.toolbar} />
          {children}
        </CustomizedContainer>
      </Box>

      <Box component="div" mt={8} sx={{ backgroundColor: '#1c2237', margin: 0, width: '100%' }}>
        <Footer />
      </Box>
    </div>
  );
}

export default UnauthorizedLayout;
