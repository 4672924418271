import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import radqcApi from '../dataExchange/radqcApiClient';
import { useCurrUserCtx } from './CurrUserContext';

const OrganizationContext = createContext();

function OrganizationCtxProvider(props) {
  const { children } = props;
  const [currAccount, setCurrAccount] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({
    type: null,
    id: 'all',
    name: 'all',
  });
  const { currUser } = useCurrUserCtx();
  const { getAccessTokenSilently } = useAuth0();

  const modifyCurrAccount = async (modAccountFields) => {
    const accessToken = await getAccessTokenSilently();
    const modCurrAccount = await radqcApi('put', `/accounts/me`, modAccountFields, { accessToken });
    setCurrAccount(modCurrAccount);
  };

  const updateCurrAccount = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const account = await radqcApi('get', '/accounts/me', { accessToken });
    setCurrAccount(account);
  }, [getAccessTokenSilently]);

  const updateSelectedOrg = (organizations) => {
    let organization;
    let isFacility = false;
    if (currUser.selectedFacility) {
      isFacility = true;
      organization = organizations.find((facility) => facility._id === currUser.selectedFacility);
    } else if (currUser.selectedGroup) {
      organization = organizations.find((group) => group._id === currUser.selectedGroup);
    }
    if (organization) {
      setSelectedOrg({
        type: isFacility ? 'facility' : 'group',
        id: organization._id,
        name: organization.name,
      });
    }
  };

  const updateFacilities = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const facilityList = await radqcApi('get', '/facilities', { accessToken });
    setFacilities(facilityList);
    updateSelectedOrg(facilityList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  const updateGroups = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const groupList = await radqcApi('get', '/groups', { accessToken });
    setGroups(groupList);
    updateSelectedOrg(groupList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  // Update with the list of groups from the DB
  useEffect(() => {
    updateCurrAccount();
    updateFacilities();
    updateGroups();
  }, [updateCurrAccount, updateFacilities, updateGroups]);

  return (
    <OrganizationContext.Provider
      value={{
        currAccount,
        facilities,
        groups,
        selectedOrg,
        setSelectedOrg,
        modifyCurrAccount,
        updateFacilities,
        updateGroups,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

const useOrganizationCtx = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useOrganizationCtx must be used within a OrganizationCtxProvider');
  }
  return context;
};

export { OrganizationCtxProvider, useOrganizationCtx };
