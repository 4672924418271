import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import { TableRow, FormControl, Input, MenuItem, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { RadSelect, RadTextInput } from 'presentational/Inputs';
import HeAdjustedTableCell from './HeAdjustedTableCell';

const styles = (theme) => ({
  formControl: {
    textAlign: 'center',
  },
  select: {
    fontSize: '12px',
  },
  radTextInput: {
    '&$error': {
      color: 'blue',
    },
    textAlign: 'center',
  },
  outValue: {
    '&$error': {
      color: theme.palette.primary.red,
    },
    color: theme.palette.primary.red,
    boxShadow: `0 0 2px 1px ${theme.palette.primary.red}`,
    borderRadius: '5px',
    textAlign: 'center',
  },
  error: {},
  menuItem: {
    fontSize: '12px',
  },
  icons: {
    fontSize: 16,
  },
  deleteIcon: {
    color: theme.palette.primary.red,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  inputProps: {
    fontSize: '0.8rem',
    width: '100%',
  },
  radSelects: {},
  textInput: {
    width: '56%',
  },
  inputTableCell: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
  },
});

function QaEntryForm(props) {
  const { entryData, classes, click } = props;
  const { values, setFieldValue } = useFormikContext();
  const [heliumStyle, setHeliumStyle] = useState(classes.radTextInput);

  const entryIndex = entryData._id || 'new';

  const handleHeliumChange = (e) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex].heliumLevel = parseFloat(e.target.value);
    if (entryData.heAlertLevel && e.target.value < entryData.heAlertLevel) {
      setHeliumStyle(classes.outValue);
      updatedEntries[entryIndex].outFields.push('heliumLevel');
    } else {
      setHeliumStyle(classes.radTextInput);
      const outfields = updatedEntries[entryIndex].outFields.filter((field) => field !== 'heliumLevel');
      updatedEntries[entryIndex].outFields = outfields;
    }

    setFieldValue('entries', updatedEntries);
  };

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableRow>
      <HeAdjustedTableCell width="6.7%">
        {formatDate(entryData.date)}
        <Input type="hidden" name={`entries.${entryIndex}.date`} value={entryData.date} />
      </HeAdjustedTableCell>
      <HeAdjustedTableCell width="6.85%">
        <FormControl className={clsx(classes.formControl, classes.inputTableCell)}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.heliumLevel`}
            type="number"
            presicion={1}
            className={clsx(classes.textInput, heliumStyle)}
            onChange={(e) => handleHeliumChange(e)}
            InputProps={{
              classes: { inputMarginDense: classes.inputProps },
            }}
          />
        </FormControl>
      </HeAdjustedTableCell>
      <HeAdjustedTableCell width="6.85%">
        <FormControl className={clsx(classes.formControl, classes.inputTableCell)}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.pressure`}
            type="number"
            className={classes.textInput}
            InputProps={{
              classes: { inputMarginDense: classes.inputProps },
            }}
          />
        </FormControl>
      </HeAdjustedTableCell>
      <HeAdjustedTableCell width="6.75%">
        <FormControl className={classes.formControl}>
          <RadSelect name={`entries.${entryIndex}.coldHeadRunning`} className={classes.select}>
            <MenuItem className={classes.menuItem} value>
              Yes
            </MenuItem>
            <MenuItem className={classes.menuItem} value={false}>
              No
            </MenuItem>
          </RadSelect>
        </FormControl>
      </HeAdjustedTableCell>
      <HeAdjustedTableCell width="13.5%">{entryData.enteredBy}</HeAdjustedTableCell>
      <HeAdjustedTableCell width="13.5%">
        {entryData?.reviewedBy ? `${entryData?.reviewedBy} on ${formatDate(entryData?.reviewedDate)}` : null}
      </HeAdjustedTableCell>
      <HeAdjustedTableCell width="1%">{cancelButton}</HeAdjustedTableCell>
    </TableRow>
  );
}

QaEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QaEntryForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(QaEntryForm);
