import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmDialog from 'components/ConfirmDialog';
import radqcApi from 'dataExchange/radqcApiClient';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useUserCtx } from 'context/UserContext';
import { useModalCtx } from 'context/ModalContext';
import { getUserName, isAdmin, isUserCreator } from 'utils/user.util';

function UserRowAction({ user, setSelectedUser, setEditModal, setOpenEditDevice }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getAccessTokenSilently } = useAuth0();
  const { currUser } = useCurrUserCtx();
  const { updateUsers } = useUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    setSelectedUser(user);
    setAnchorEl(null);
    setEditModal(true);
  };

  const handleModifyUserDevice = () => {
    setSelectedUser(user);
    setAnchorEl(null);
    setOpenEditDevice(true);
  };

  const removeUser = async () => {
    console.log('removing user');
    const accessToken = await getAccessTokenSilently();
    await radqcApi('put', `/users/${user._id}`, { visible: false }, { accessToken });
    enqueueSnackbar(`Removed user ${getUserName(user)}`);
    updateUsers();
  };

  const handleDeleteUser = () => {
    showModal(ConfirmDialog, {
      message: 'Permanently delete this user?',
      actionText: 'Remove user',
      confirmAction: () => removeUser(),
    });
    setAnchorEl(null);
  };

  let menuOptions = null;
  if (isAdmin(currUser)) {
    menuOptions = (
      <>
        <MenuItem sx={{ fontSize: 14 }} onClick={handleEditProfile}>
          Edit User
        </MenuItem>
        <MenuItem sx={{ fontSize: 14 }} onClick={handleModifyUserDevice}>
          Modify User Devices
        </MenuItem>
        <MenuItem onClick={handleDeleteUser} sx={{ color: 'red', fontSize: 14 }}>
          Delete User
        </MenuItem>
      </>
    );
  } else if (isUserCreator(currUser) && !isUserCreator(user)) {
    // If the current user is a creator and the user being modified is not a creator
    menuOptions = (
      <>
        <MenuItem sx={{ fontSize: 14 }} onClick={handleEditProfile}>
          Edit User
        </MenuItem>
        <MenuItem sx={{ fontSize: 14 }} onClick={handleModifyUserDevice}>
          Modify User Devices
        </MenuItem>
      </>
    );
  }

  return (
    <Box component="div">
      <IconButton
        sx={{ padding: 0 }}
        size="small"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!menuOptions}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{ fontSize: 14 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuOptions}
      </Menu>
    </Box>
  );
}
export default UserRowAction;
