import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { IconButton, TableCell, Table, TableBody, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import PropTypes from 'prop-types';
import { weeklyCommonStyles } from 'views/entry/mam/components/commonStyles';
import { useModalCtx } from 'context/ModalContext';
import { MamEntryFields, updateOutFields } from 'views/entry/mam/components/mamEntryOutValues';
import { formatDate } from 'utils/time.util';
import MamNumberInput from 'views/entry/mam/weekly/modify/components/MamNumberInput';
import { entryTypes } from 'views/entry/mam/components/mamEntry.util';
import EntryComment from 'views/entry/components/EntryComment';

const useStyles = makeStyles(weeklyCommonStyles);

function CompEntryForm(props) {
  const { entryData, formDisabled, click } = props;
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { showModal, hideModal } = useModalCtx();
  const [outFieldsLocal, setOutfieldsLocal] = useState(entryData.outFields);

  if (!entryData) return null;

  const entryIndex = entryData._id || 'new';

  const handleUpdateRemarks = (newRemarks) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex].artEvalRemarks = newRemarks;
    setFieldValue('entries', updatedEntries);
    hideModal();
  };

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableCell className={classes.noPadding}>
      <Table className={classes.formTableWidth}>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{entryData.initials}</TableCell>
          </TableRow>
          <MamNumberInput
            entryType={entryTypes.COMP}
            name={`entries.${entryIndex}.${MamEntryFields.compThickness}`}
            isOut={outFieldsLocal.includes(MamEntryFields.compThickness)}
            onChange={(e) => setOutfieldsLocal(updateOutFields(e, values.entries[entryIndex], setFieldValue, true))}
            formDisabled={formDisabled}
          />
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>
              {entryIndex !== 'new' && (
                <IconButton
                  onClick={() =>
                    showModal(EntryComment, {
                      savedComment: values.entries[entryIndex].compRemarks,
                      hideModal,
                      updateComment: handleUpdateRemarks,
                      title: 'Remarks',
                    })
                  }
                >
                  <CommentIcon classes={{ root: classes.icons }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{cancelButton}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableCell>
  );
}

CompEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  formDisabled: PropTypes.bool,
  click: PropTypes.func,
};

CompEntryForm.defaultProps = {
  formDisabled: false,
  click: null,
};

export default CompEntryForm;
