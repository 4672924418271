import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, Checkbox, TableCell, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  tableHeadCheckbox: {
    padding: '0px 12px 0 0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    textAlign: 'center',
  },
});

const columns = [
  { property: 'date', label: 'Month/Year' },
  { property: 'monthlyEntry.enteredDate', label: 'Completed' },
  { property: 'review.reviewedDate', label: 'Reviewed' },
];

function RecordTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const classes = styles();
  const handleSortClick = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHeadCheckbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
            inputProps={{ 'aria-label': 'select all records' }}
          />
        </TableCell>
        {columns.map((column) => (
          <TableCell
            className={classes.tableCell}
            key={column.property}
            sortDirection={orderBy === column.property ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.property}
              direction={orderBy === column.property ? order : 'asc'}
              onClick={handleSortClick(column.property)}
            >
              {column.label}
              {orderBy === column.property ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

RecordTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default RecordTableHead;
