/* eslint-disable jsx-a11y/click-events-have-key-events */
//@ts-nocheck

import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import { formatDate } from 'utils/time.util';
import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from '@tanstack/react-table';

import TablePaginationActions from 'views/components/TablePaginationActions';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useUserCtx } from 'context/UserContext';
import { Snackbar, Typography } from '@mui/material';
import UserDeviceList from './UserDeviceList';
import UserRowAction from './UserRowAction';
import ModifyUserModal from './ModifyUserModal';
import ModifyUserDevicesModal from './ModifyUserDevicesModal';

//TODO update type 'any'
//TODO consolidate styling to styled components
//TODO consolidate devices and users table, make it reusable

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])}
        placeholder="Max"
        className="w-24 border shadow rounded"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  ) : (
    <InputBase
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      sx={{ fontSize: 12, border: '1px solid #f1f0f0', borderRadius: '4px', paddingLeft: '2px ' }}
      className="w-36 border shadow rounded"
      inputProps={{ 'aria-label': 'search' }}
    />
  );
}

function UsersTable({ data, columns }: { data: any[]; columns: ColumnDef<any>[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
  });

  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <Box component="div" width="100%">
      <TableContainer component={Box} sx={{ marginTop: '16px' }}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header) => {
                  return (
                    <TableCell sx={{ fontSize: 14, fontWeight: 600 }} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ArrowDropUpIcon sx={{ fontSize: '16px' }} />,
                            desc: <ArrowDropDownIcon sx={{ fontSize: '16px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <Box component="div" marginTop="4px">
                          <Filter column={header.column} table={table} />
                        </Box>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel()?.rows?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FAFAFA',
                    },
                  }}
                >
                  {row.getVisibleCells()?.map((cell) => {
                    return (
                      <TableCell sx={{ fontSize: 14 }} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
        component="div"
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={(_, page) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
}

function UsersTableContainer({ getDevicesForUser }: any) {
  const { users } = useUserCtx();
  const [selectedUser, setSelectedUser] = useState({});
  const [openEditModal, setEditModal] = useState(false);
  const [openEditDevice, setOpenEditDevice] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        id: 'firstName',
        cell: (info) => <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>,
        header: () => <Typography sx={{ fontSize: 14, fontWeight: 600 }}>First</Typography>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'lastName',
        id: 'lastName',
        cell: (info) => <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>,
        header: () => <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Last</Typography>,
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.role,
        id: 'role',
        cell: (info) => <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>,
        header: () => <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Role</Typography>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'email',
        header: () => 'Email',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'certification',
        header: () => 'Certifications',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'degree',
        header: () => <span>Degree</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'lastActionDate',
        header: () => <span>Last Action</span>,
        accessorFn: (row) => formatDate(row.lastActionDate ? row.lastActionDate : null),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'devices',
        header: 'Associated Devices',
        cell: (info) => {
          return <UserDeviceList devices={getDevicesForUser(info.row.original.devices)} />;
        },
        footer: (props) => props.column.id,
      },
      {
        id: '-',
        header: '',
        cell: (info) => (
          <UserRowAction
            user={info.row.original}
            setSelectedUser={setSelectedUser}
            setEditModal={setEditModal}
            setOpenEditDevice={setOpenEditDevice}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [getDevicesForUser],
  );

  const tableData = useMemo(() => users, [users]);
  const [data, _setData] = useState(tableData);

  useEffect(() => _setData(tableData), [tableData]);

  return (
    <>
      <ModifyUserModal selectedUser={selectedUser} openEditModal={openEditModal} setEditModal={setEditModal} />
      <UsersTable {...{ data, columns }} />
      <ModifyUserDevicesModal
        setOpen={setOpen}
        user={selectedUser}
        openEditDevice={openEditDevice}
        setOpenEditDevice={setOpenEditDevice}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" />
    </>
  );
}

export default UsersTableContainer;
