import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import commonPreviewStyles from './commonStyles';
import { formatDateShort, getCurrentYear } from 'utils/time.util';

// Create styles
const styles = StyleSheet.create({
  ...commonPreviewStyles,
  formTitle: {
    ...commonPreviewStyles.formTitle,
    paddingTop: 40,
  },
});

function RemarksPdfPreview(props) {
  const { records, device, addProps } = props;
  const { recordType } = addProps;

  const showRemarks = (entry) => {
    let remark;
    switch (recordType) {
      case 'Geo Calibration':
        remark = entry.remarks;
        break;
      case 'View Box':
        remark = entry.viewBoxRemarks;
        break;
      case 'Flat Field':
        remark = entry.flatFieldRemarks;
        break;
      default:
        throw new Error(`Unrecognized record type: ${recordType}. Therefore, there's no remarks detected.`);
    }
    return remark;
  };

  const showFullTitleNames = () => {
    let title;
    switch (recordType) {
      case 'Geo Calibration':
        title = 'Geometry Calibration (Tomosynthesis Option)';
        break;
      case 'View Box':
        title = 'View Box';
        break;
      case 'Flat Field':
        title = 'Flat Field Detector Calibration';
        break;
      default:
        throw new Error(`Unrecognized record type: ${recordType}. Therefore, there's no title name detected.`);
    }
    return title;
  };

  return (
    <Document>
      {records &&
        records.map((record) => (
          <Page key={record.entries[0]._id} style={styles.body} orientation="landscape">
            <View fixed style={styles.footer}>
              <Text
                style={styles.header}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              <Text fixed style={styles.header}>
                Provided By Radiology QC Solutions
              </Text>
            </View>
            <Text style={styles.formTitle}>Record For : {showFullTitleNames(recordType)}</Text>

            <View key={record.entries[0]._id} style={styles.scannerInfoView}>
              <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
              <Text style={styles.scannerInfo}>Scanner Name: {`${device.name} (${device.apNumber})`}</Text>
            </View>

            <View style={styles.remarksHeaderView}>
              <Text style={styles.remarksTableShort}>Date</Text>
              <Text style={styles.remarksTableShort}>Initials</Text>
              <Text style={styles.remarks}>Actions</Text>
            </View>
            {record.entries.map((entry) => (
              <View key={entry._id} style={styles.remarksView}>
                <Text style={styles.remarksTableShort}>{formatDateShort(entry.date)}</Text>
                <Text style={styles.remarksTableShort}>{entry.initials}</Text>
                <Text style={styles.remarks}>{showRemarks(entry)}</Text>
              </View>
            ))}

            <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
              <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
            </View>
          </Page>
        ))}
    </Document>
  );
}

RemarksPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
  addProps: PropTypes.objectOf(PropTypes.any),
};

RemarksPdfPreview.defaultProps = {
  records: [],
  device: null,
  addProps: {},
};

export default RemarksPdfPreview;
