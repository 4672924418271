import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';
import { getDaysInMonth } from 'utils/time.util';

const useStyles = makeStyles((theme) => ({
  tableTitlesInput: {
    color: 'black',
    textAlign: 'center',
    width: '76px',
    fontSize: '0.75rem',
    borderBottom: '0.1px solid #00000059',
    lineHeight: 1.3,
    padding: '0.25rem',
  },

  tableTitlesInputOdd: {
    borderRight: '0.1px solid #00000059',
    textAlign: 'center',
    height: '20px',
    fontSize: '0.75rem',
    backgroundColor: '#CBCBCB',
    borderBottom: '0.1px solid #00000059',
    lineHeight: 1.3,
    padding: '0.25rem',
  },
  tableContainer: {
    display: 'flex',
    cursor: 'pointer',
    '@media (max-width: 1024px)': {
      margin: 0,
    },
  },
  stickyHeader: {
    width: '10rem',
  },
  rightTables: {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  entryCells: {
    color: 'black',
    textAlign: 'center',
    width: '30px',
    height: '20px',
    padding: '0.25rem',
    borderBottom: '0.1px solid #00000059',
  },
  tableTitles: {
    color: 'black',
    textAlign: 'left',
    width: '56px',
    height: '20px',
    fontSize: '0.75rem',
    borderBottom: '0.1px solid #00000059',
    lineHeight: 1.3,
    padding: '0.5rem',
    transition: '0.3s ease-in-out',
  },
}));

const generateRowData = (entries, classes) => {
  const dayCells = [];
  const initialCells = [];
  const viewCells = [];
  const monitorCells = [];

  entries.forEach((entry) => {
    const date = new Date(entry.date);
    const daysInMonth = getDaysInMonth(date);
    const headerClass = date.getUTCMonth() % 2 === 0 ? classes.tableTitlesInput : classes.tableTitlesInputOdd;
    const dataEntries = [...entry.dailyEntries];
    for (let i = 1; i <= daysInMonth; i++) {
      dayCells.push(
        <TableCell className={headerClass} key={date + i}>
          {i}
        </TableCell>,
      );
      const hasData = dataEntries[0] && dataEntries[0].day === i;
      initialCells.push(
        <TableCell className={classes.tableTitlesInput} key={date + i}>
          {hasData && dataEntries[0].initials}
        </TableCell>,
      );
      viewCells.push(
        <TableCell className={classes.tableTitlesInput} key={date + i}>
          {hasData && dataEntries[0].viewingCheckPerformed && 'X'}
        </TableCell>,
      );
      monitorCells.push(
        <TableCell className={classes.tableTitlesInput} key={date + i}>
          {hasData && dataEntries[0].monitorCheckPerformed && 'X'}
        </TableCell>,
      );
      if (hasData) {
        dataEntries.shift();
      }
    }
  });

  return {
    dayCells,
    initialCells,
    viewCells,
    monitorCells,
  };
};

function RwsDailyOverviewTable(props) {
  const { device, history, match } = props;
  const [rwsEntries, setRwsEntries] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [entryRefs, setEntryRefs] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  // get existing data
  const getEntries = useCallback(async () => {
    let receivedEntries;
    try {
      const accessToken = await getAccessTokenSilently();
      receivedEntries = await radqcApi('get', `/entries/rws_sc/device/${device._id}`, { accessToken });
    } catch (err) {
      console.log(err);
    }
    const refs = receivedEntries.map(() => React.createRef());
    setEntryRefs(refs);
    setRwsEntries(receivedEntries);
    setRowData(generateRowData(receivedEntries, classes));
  }, [getAccessTokenSilently, device._id, classes]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  useEffect(() => {
    if (entryRefs.length > 0 && entryRefs[0].current) {
      entryRefs[entryRefs.length - 1].current.scrollIntoView({ block: 'nearest' });
    }
  }, [rwsEntries, entryRefs]);

  if (!rwsEntries) {
    return null;
  }

  return (
    <Box className={classes.tableContainer} onClick={() => history.push(`${match.url}/rwsdaily`)}>
      <Table stickyHeader className={classes.stickyHeader}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitles}>Month</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Day</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Initials</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>View Box Checked</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Monitor Checked</TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Box className={classes.rightTables}>
        <Table>
          <TableBody>
            <TableRow>
              {rwsEntries.map((entry, index) => {
                const date = new Date(entry.date);
                return (
                  <TableCell
                    colSpan={getDaysInMonth(date)}
                    className={date.getUTCMonth() % 2 > 0 ? classes.tableTitlesInput : classes.tableTitlesInputOdd}
                    key={entry._id}
                    ref={entryRefs[index]}
                  >
                    {`${date.toLocaleString('default', { timeZone: 'UTC', month: 'long' })} ${date.getUTCFullYear()}`}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>{rowData.dayCells}</TableRow>
            <TableRow>{rowData.initialCells}</TableRow>
            <TableRow>{rowData.viewCells}</TableRow>
            <TableRow>{rowData.monitorCells}</TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

RwsDailyOverviewTable.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(RwsDailyOverviewTable);
