import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';

import { useDeviceCtx } from 'context/DeviceContext';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import GeoCalibTable from './geoCalibTable/GeoCalibTable';

const styles = (theme) => ({
  table: {
    height: '100%',
  },
  notAvailableMsg: {
    textAlign: 'center',
    padding: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  notAvailableIcon: {
    fontSize: '6rem',
    color: theme.palette.primary.darkMain,
    opacity: 0.25,
    marginBottom: '2rem',
  },
  notAvailableText: {
    opacity: 0.8,
  },
});

function GeoCalibDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedEntries, setSavedEntries] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { devices } = useDeviceCtx();

  const getEntries = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedEntries;
    const startDate = date.start.toISOString();
    // Add 1 day to the end date to handle timezones for brand new entries
    let endDate = new Date(date.end);
    const now = new Date();
    endDate.setDate(now.getDate() + 1);
    endDate = endDate.toISOString();

    try {
      const accessToken = await getAccessTokenSilently();
      receivedEntries = await radqcApi('get', `/entries/mam_gc/device/${deviceId}?start=${startDate}&end=${endDate}`, {
        accessToken,
      });
    } catch (err) {
      console.log(err);
    }
    if (!receivedEntries) receivedEntries = [];
    if (isMounted) {
      setSavedEntries(receivedEntries);
    }
  }, [match.url, date.start, date.end, getAccessTokenSilently, isMounted]);

  useEffect(() => {
    setIsMounted(true);
    getEntries();

    return () => setIsMounted(false);
  }, [mode, date, getEntries]);

  const device = devices.find((a) => match.url.includes(a._id));
  if (!device.is3dSet) {
    return (
      <Box className={classes.notAvailableMsg}>
        <SpeakerNotesOffIcon className={classes.notAvailableIcon} />
        <Box className={classes.notAvailableText}>
          Geo Calibration is not available for this scanner because it is not configured for 3D.
        </Box>
      </Box>
    );
  }

  if (mode === 'view') {
    const numEntriesPerRecord = 10;

    const records = splitEntriesIntoRecords(savedEntries, numEntriesPerRecord);

    return <RecordViewer recordType="Geo Calibration" records={records} />;
  }

  return (
    <Box className={classes.table}>
      <GeoCalibTable
        key={mode + savedEntries.length}
        geoCalibEntries={savedEntries}
        mode={mode}
        updateEntries={getEntries}
      />
    </Box>
  );
}

GeoCalibDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(GeoCalibDashboard));
