import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { TableContainer } from '@mui/material';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StepperCtxProvider } from 'context/StepperContext';
import MamWeeklyModify from './modify/MamWeeklyModify';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import MamWeeklyView from './view/MamWeeklyView';

function MamWeeklyDashboard(props) {
  const { mode, match, date } = props;
  const [leadingEntries, setLeadingEntries] = useState([]);
  const [trailingEntries, setTrailingEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [entryKey, setEntryKey] = useState(0);

  // get existing data (currently just adds all existing values to 'trailing' entries)
  const getEntries = useCallback(async () => {
    const requestEntries = async (endpoint) => {
      let receivedEntries;
      try {
        const accessToken = await getAccessTokenSilently();
        receivedEntries = await radqcApi('get', endpoint, { accessToken });
      } catch (err) {
        console.log(err);
      }
      return receivedEntries || [];
    };

    const deviceId = getDeviceIdFromUrl(match.url);
    const startDate = date.start.toISOString();
    let endDate;
    let endpoint;
    let receivedEntries;
    switch (mode) {
      case 'view':
        endDate = new Date(date.end);
        endDate.setDate(new Date().getDate() + 1);
        endDate = endDate.toISOString();
        endpoint = `/entries/mam_wk/device/${deviceId}?start=${startDate}&end=${endDate}`;
        break;
      case 'enter':
      case 'edit':
        endpoint = `/entries/mam_wk/device/${deviceId}/${startDate}/nearby/leading`;
        receivedEntries = await requestEntries(endpoint);
        setLeadingEntries(receivedEntries);
        endpoint = `/entries/mam_wk/device/${deviceId}/${startDate}/nearby/trailing`;
        break;
      default:
        throw Error(`Unrecognized mode: ${mode}`);
    }

    receivedEntries = await requestEntries(endpoint);
    setTrailingEntries(receivedEntries);
  }, [match.url, date.start, date.end, mode, getAccessTokenSilently]);

  useEffect(() => {
    getEntries();
    setEntryKey(date.start);
  }, [getEntries, date.start]);

  const saveEntries = async (entries) => {
    const accessToken = await getAccessTokenSilently();
    const newEntries = Object.keys(entries).map((entry) => entries[entry]);
    if (newEntries[0]._id === 'new') {
      const newEntry = newEntries[0];
      delete newEntry._id;
      await radqcApi('post', '/entries/mam_wk', newEntry, { accessToken });
      setEntryKey(entryKey + 1);
      enqueueSnackbar(`Added new Weekly Mammography entry`, { variant: 'info' });
    } else {
      // TODO: add new back end endpoint to accept multiple modified entries at once
      await newEntries.forEach((entry) => radqcApi('put', `/entries/mam_wk/${entry._id}`, entry, { accessToken }));
      enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, { variant: 'info' });
    }
    await getEntries();
  };

  if (mode === 'view') {
    const numEntriesPerRecord = 10;

    const records = splitEntriesIntoRecords(trailingEntries, numEntriesPerRecord);

    return <MamWeeklyView records={records} />;
  }

  return (
    <TableContainer>
      <StepperCtxProvider key={entryKey}>
        <MamWeeklyModify
          key={mode + date + trailingEntries.length}
          mode={mode}
          date={date}
          saveEntries={saveEntries}
          mamEntries={{ leading: leadingEntries, trailing: trailingEntries }}
        />
      </StepperCtxProvider>
    </TableContainer>
  );
}

MamWeeklyDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(MamWeeklyDashboard);
