import { getUserInitials } from 'utils/user.util';

export const mamQuarterlyLineFields = {
  positioning: 'Positioning',
  patientMotion: 'Patient Motion',
  detUnderexpos: 'Detector Underexposure (excessively noisy images)',
  improperDet: 'Improper Detector Exposure (Saturation)',
  artifacts: 'Artifacts',
  incorPatId: 'Incorrect Patient ID',
  xrayEqptFail: 'X-Ray Equipment Failure',
  softwareFail: 'Software Failure',
  blankImage: 'Blank Image',
  wireLocal: 'Wire Localization',
  abortedAecExp: 'Aborted AEC Exposure',
  other: 'Other',
};

export const exposures = ['leftCc', 'rightCc', 'leftMlo', 'rightMlo', 'leftOther', 'rightOther'];

export const getTotalForMamQuarterlyLine = (line) => {
  return exposures.reduce((acc, exposure) => acc + line[exposure], 0);
};

export const generateInitialDate = () => {
  const date = new Date();
  return {
    date: new Date(date.getFullYear(), date.getMonth(), 1),
    dateTo: new Date(date.getFullYear(), date.getMonth() + 3, 1),
  };
};

export const generateInitialTotals = (entry) => {
  const totals = { overall: 0 };
  Object.keys(mamQuarterlyLineFields).forEach((field) => {
    totals[field] = 0;
  });

  // If there is an entry, add all rejects to each row and overall total
  if (entry) {
    Object.keys(entry).forEach((field) => {
      if (Object.keys(mamQuarterlyLineFields).includes(field)) {
        const totalForLine = getTotalForMamQuarterlyLine(entry[field]);
        totals[field] = totalForLine;
        totals.overall += totalForLine;
      }
    });
  }

  return totals;
};

export const generateEmptyMamReject = (currUser, device) => {
  const date = new Date();
  const newReject = {
    deviceId: device._id,
    date: new Date(date.getFullYear(), date.getMonth(), 1),
    dateTo: new Date(date.getFullYear(), date.getMonth() + 3, 1),
    totalExposures: 0,
    remarks: '',
    correctiveAction: '',
    initials: getUserInitials(currUser),
    outFields: [],
    enteredById: currUser._id,
  };
  Object.keys(mamQuarterlyLineFields).forEach((field) => {
    const mamQuarterlyLine = {};
    exposures.forEach((exposure) => {
      mamQuarterlyLine[exposure] = 0;
    });
    newReject[field] = mamQuarterlyLine;
  });

  return newReject;
};
