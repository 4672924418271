import React from 'react';
import clsx from 'clsx';
import { Table, TableHead, TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  tableHeader: {
    width: 400,
    backgroundColor: '#005ed412',
  },
  headerTableRow: { backgroundColor: 'transparent' },
  tableTitles: {
    color: 'black',
    fontSize: 12,
    padding: '0.25rem 0',
    textAlign: 'center',
    minWidth: '60px',
  },
  subCats: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

function VcTableHeader(props) {
  const { classes } = props;

  return (
    <Table className={classes.tableHeader}>
      <TableHead>
        <TableRow className={classes.headerTableRow}>
          <TableCell colSpan={2} className={classes.tableTitles}>
            Date
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell rowSpan={5} className={classes.tableTitles}>
            Patient Transport & Magnet
          </TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Bed Position / Other Lights</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Light Alignment</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>High Tension Cables / Others</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
            Horizontal Motion Smoothness / Stability
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
            Vertical Motion Smoothness / Stability
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell rowSpan={2} className={classes.tableTitles}>
            Filming / Viewing
          </TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Laser Camera</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Laser Boxes (Improper Function)</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell rowSpan={6} className={classes.tableTitles}>
            RF Integrity / Control Room
          </TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Door Contacts</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Window Screen Integrity</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
            Operator Console Switches / Lights / Meters
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Patient Monitors</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Patient Intercom</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Room Temperature / Humidity</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell rowSpan={5} className={classes.tableTitles}>
            Facility Safety
          </TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Emergency Cart</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Safety Warning Signage</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
            Door Indicator Switch (If Installed)
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Cryogen Level Indicator</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Oxygen Monitor</TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell colSpan={2} className={clsx(classes.tableTitles, classes.initials)}>
            Entered By (Initials)
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerTableRow}>
          <TableCell colSpan={2} className={clsx(classes.tableTitles, classes.initials)}>
            Reviewed By (Initials)
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}

export default withStyles(styles)(VcTableHeader);
