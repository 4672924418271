import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Table, TableBody, TableHead, TableRow, TextField, TableCell, FormControl, Button, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import {
  exposures,
  generateEmptyMamReject,
  generateInitialTotals,
  getTotalForMamQuarterlyLine,
  mamQuarterlyLineFields,
} from './components/mamRejectTable.util';
import { EntryModes } from 'types/entry';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import radqcApi from 'dataExchange/radqcApiClient';
import MamRejectTableLine from './components/MamRejectTableLine';
import { RadTextInput } from 'presentational/Inputs';
import MamRejectTableHeader from './components/MamRejectTableHeader';
import MamRejectTableFooter from './components/MamRejectTableFooter';
import MamRejectTableCell from './components/MamRejectTableCell';
import { roundUpNum } from 'utils/entry.util';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';

const styles = (theme) => ({
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: { height: '600px', overflowY: 'auto' },
  tableContainer: {
    border: '1px solid #005ed426',
    overflowY: 'auto',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 649px)': {
      margin: '0',
      width: '100%',
    },
  },
  totalContainer: {
    width: '30%',
    border: '1px solid #005ed426',
    overflowY: 'auto',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 649px)': {
      margin: '0',
      width: '100%',
    },
  },
  bottomNavigation: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: `${theme.palette.primary.main}0a`,
  },
  initials: {
    textAlign: 'left',
    paddingLeft: '1rem',
  },
  rightTables: { height: '675px' },
  formControl: { margin: '0px 0' },
  radTextInput: { textAlign: 'center' },
  inputProps: {
    padding: '0.2rem',
    fontSize: 12,
    width: '100%',
  },
  datePickerTables: {
    margin: '0 2rem',
    '@media (max-width: 649px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  datePickerRight: {
    margin: '1rem',
    '@media (max-width: 649px)': {
      margin: '1rem 0',
    },
  },
  buttonText: {
    width: '10rem',
  },
});

function MamRejectTable(props) {
  const { match, classes, mamReject, mode, updateRejects } = props;
  const [totals, setTotals] = useState(generateInitialTotals(mamReject));
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));
  const fieldTitles = {
    [MamEntryFields.qtrlyTotalReasons]: 'Total with Reasons',
    [MamEntryFields.qtrlyTotalExps]: 'Total Exposures',
    [MamEntryFields.qtrlyRatio]: 'Ratio (%)',
  };

  const initialValues = mode === EntryModes.ENTER ? generateEmptyMamReject(currUser, device) : mamReject;

  if (initialValues === null) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', fontWeight: 400 }}>
        <h2>No entries to edit</h2>
      </Box>
    );
  }

  if (mode === EntryModes.EDIT) {
    initialValues.date = new Date(initialValues.date);
    initialValues.dateTo = new Date(initialValues.dateTo);
  }

  const posNumValidation = Yup.number().min(0).required('Required');

  const validationObj = {
    deviceId: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    dateTo: Yup.string().required('Required'),
    totalExposures: posNumValidation,
    remarks: Yup.string(),
    correctiveAction: Yup.string(),
    outFields: Yup.array(),
    initials: Yup.string().required('Required'),
    enteredById: Yup.string().required('Required'),
  };

  Object.keys(mamQuarterlyLineFields).forEach((field) => {
    const fieldLine = {};
    exposures.forEach((exposure) => {
      fieldLine[exposure] = posNumValidation;
    });
    validationObj[field] = Yup.object(fieldLine);
  });

  /* For each entry, re-calculate the total for that field (newFieldTotal) then remove the old
  /  field total from the overall total and add the new field total */
  const updateTotals = (newValue, field, values) => {
    if (!newValue || !field.includes('.')) {
      return;
    }
    const lineName = field.split('.')[0];
    const exposureName = field.split('.')[1];
    const newTotals = Object.assign(totals);
    // Remove the line's previous total and add the new line total to overall
    newTotals.overall -= totals[lineName];
    newTotals[lineName] =
      getTotalForMamQuarterlyLine(values[lineName]) - values[lineName][exposureName] + parseInt(newValue, 10);
    newTotals.overall += newTotals[lineName];
    setTotals(newTotals);
  };

  const dateValidator = (isFrom, values) => {
    const validateDate = isFrom ? values.date : values.dateTo;
    if (validateDate.toString() === 'Invalid Date') {
      return 'Invalid date string';
    }
    if (values?.date?.toString() === 'Invalid Date' || values?.dateTo?.toString() === 'Invalid Date') {
      return '';
    }
    if (values?.date?.setUTCHours(12, 0, 0) > values.dateTo.setUTCHours(12, 0, 0)) {
      if (isFrom) {
        return "'From' date must be before 'To' date";
      }
      return "'To' date must be after 'From' date";
    }
    return '';
  };

  const getRatio = (exposureCount) => (exposureCount > 0 ? roundUpNum((totals.overall / exposureCount) * 100) : '-');

  const handleSubmit = (values, submitForm) => {
    const entry = {
      ...values,
      totalWithReasons: totals.overall,
      ratio: getRatio(values.totalExposures),
    };
    showModal(EntryConfirmModal, {
      entryType: 'mam',
      confirmTitle: 'Mammograph Reject Analysis',
      confirmEntries: [entry],
      accept: () => submitForm(),
      fieldTitles,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(validationObj)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newReject = Object.assign(values);
        if (mode === EntryModes.ENTER) {
          await radqcApi('post', '/entries/mam_qu', newReject, { accessToken });
          enqueueSnackbar(`Added new Mammography Reject entry`, { variant: 'info' });
        } else {
          await radqcApi('put', `/entries/mam_qu/${mamReject._id}`, newReject, { accessToken });
          enqueueSnackbar(`Entry updated for Quarter starting on ${formatDate(newReject.date)}`, { variant: 'info' });
        }
        updateRejects();
        updateDevices();
        updateUsers();
        setSubmitting(false);
        if (mode === EntryModes.ENTER) {
          resetForm();
          setTotals(generateInitialTotals());
        }
      }}
    >
      {({ values, handleChange, setFieldValue, submitForm }) => {
        const handleUpdate = (e, field) => {
          handleChange(e);
          updateTotals(e.target.value, field, values);
        };
        return (
          <Form className={classes.formStyle}>
            <Box className={classes.datePickerTables}>
              <LocalizationProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="normal"
                  renderInput={() => <TextField label="From" helperText={dateValidator(true, values)} />}
                  format="MM/dd/yyyy"
                  value={values.date}
                  animateYearScrolling="true"
                  error={dateValidator(true, values) !== ''}
                  onChange={() => undefined}
                  onAccept={(inputDate) => setFieldValue('date', new Date(inputDate))}
                  onBlur={(e) => setFieldValue('date', new Date(e.target.value))}
                  maxDate={values.dateTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change from date',
                  }}
                  rifmFormatter={(str) => str}
                  autoOk
                />

                <DatePicker
                  className={classes.datePickerRight}
                  margin="normal"
                  renderInput={() => <TextField label="To" helperText={dateValidator(false, values)} />}
                  format="MM/dd/yyyy"
                  value={values.dateTo}
                  animateYearScrolling="true"
                  error={dateValidator(false, values) !== ''}
                  onChange={() => undefined}
                  onAccept={(inputDate) => setFieldValue('dateTo', new Date(inputDate))}
                  onBlur={(e) => setFieldValue('dateTo', new Date(e.target.value))}
                  minDate={values.date}
                  KeyboardButtonProps={{
                    'aria-label': 'change from date',
                  }}
                  rifmFormatter={(str) => str}
                  autoOk
                />
              </LocalizationProvider>
            </Box>

            <div className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <MamRejectTableHeader rowSpan={2}>Reason</MamRejectTableHeader>
                    <MamRejectTableHeader colSpan={6}>Number of Exposures</MamRejectTableHeader>
                    <MamRejectTableHeader rowSpan={2}>Subtotals</MamRejectTableHeader>
                    <MamRejectTableHeader rowSpan={2}>% of Total</MamRejectTableHeader>
                  </TableRow>
                  <TableRow>
                    <MamRejectTableHeader>Left CC</MamRejectTableHeader>
                    <MamRejectTableHeader>Right CC</MamRejectTableHeader>
                    <MamRejectTableHeader>Left MLO</MamRejectTableHeader>
                    <MamRejectTableHeader>Right MLO</MamRejectTableHeader>
                    <MamRejectTableHeader>Left Other</MamRejectTableHeader>
                    <MamRejectTableHeader>Right Other</MamRejectTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totals &&
                    Object.keys(mamQuarterlyLineFields).map((field) => (
                      <MamRejectTableLine key={field} field={field} totals={totals} onChange={handleUpdate} />
                    ))}
                  <TableRow>
                    <MamRejectTableFooter colSpan={5} />
                    <MamRejectTableFooter colSpan={2}>Totals:</MamRejectTableFooter>
                    <MamRejectTableFooter>{totals.overall}</MamRejectTableFooter>
                    <MamRejectTableFooter>{totals.overall > 0 ? 100 : '-'} %</MamRejectTableFooter>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.totalContainer}>
              <Table>
                <TableBody>
                  <TableRow>
                    <MamRejectTableCell>Total with Reasons:</MamRejectTableCell>
                    <MamRejectTableCell>{totals.overall}</MamRejectTableCell>
                  </TableRow>
                  <TableRow>
                    <MamRejectTableCell>Total Exposures:</MamRejectTableCell>
                    <MamRejectTableCell>
                      <FormControl className={classes.formControl}>
                        <RadTextInput
                          sx={{ margin: 0 }}
                          margin="dense"
                          name="totalExposures"
                          type="number"
                          className={classes.radTextInput}
                          InputProps={{
                            classes: { inputMarginDense: classes.inputProps },
                          }}
                        />
                      </FormControl>
                    </MamRejectTableCell>
                  </TableRow>
                  <TableRow>
                    <MamRejectTableCell>Ratio (%):</MamRejectTableCell>
                    <MamRejectTableCell>{getRatio(values.totalExposures)} %</MamRejectTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.tableContainer}>
              <Table>
                <TableBody>
                  <TableRow>
                    <MamRejectTableCell>Remarks:</MamRejectTableCell>
                    <MamRejectTableCell>
                      <FormControl className={classes.formControl}>
                        <RadTextInput
                          sx={{ margin: 0 }}
                          margin="dense"
                          name="remarks"
                          type="text"
                          className={classes.radTextInput}
                          InputProps={{
                            classes: { inputMarginDense: classes.inputProps },
                          }}
                        />
                      </FormControl>
                    </MamRejectTableCell>
                  </TableRow>
                  <TableRow>
                    <MamRejectTableCell>Corrective Action:</MamRejectTableCell>
                    <MamRejectTableCell>
                      <FormControl className={classes.formControl}>
                        <RadTextInput
                          sx={{ margin: 0 }}
                          margin="dense"
                          name="correctiveAction"
                          type="text"
                          className={classes.radTextInput}
                          InputProps={{
                            classes: { inputMarginDense: classes.inputProps },
                          }}
                        />
                      </FormControl>
                    </MamRejectTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <Table className={classes.bottomNavigation}>
              <TableHead>
                <TableRow className={classes.headerTableRow}>
                  <TableCell sx={{ border: 'none' }}>
                    <Button classes={{ text: classes.buttonText }} onClick={() => handleSubmit(values, submitForm)}>
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

MamRejectTable.propTypes = {
  mamReject: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateRejects: PropTypes.func.isRequired,
};

MamRejectTable.defaultProps = {
  mamReject: null,
};

export default withRouter(withStyles(styles)(MamRejectTable));
