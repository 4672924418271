import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';
import { mamLongTermEntryTypes } from 'views/entry/mam/components/mamEntry.util';
import MamLongTermOverviewRow from './MamLongTermOverviewRow';

const useStyles = makeStyles((theme) => ({
  tableTitles: {
    color: 'black',
    fontWeight: 600,
    textAlign: 'left',
    width: '76px',
    height: '50px',
    fontSize: 12,
    borderBottom: '0.1px solid #0000000a',
    lineHeight: 1.3,
    padding: '0.25rem',
    transition: '0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tableTitlesInput: {
    color: 'black',
    textAlign: 'center',
    height: '50px',
    fontSize: 12,
    borderBottom: '0.1px solid #0000000a',
    lineHeight: 1.3,
    padding: '0.25rem',
  },

  tableTitlesInputOdd: {
    textAlign: 'center',
    height: '44px',
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: '#dbdbdb',
    borderBottom: '0.1px solid #0000004a',
    lineHeight: 1.3,
    padding: '0.25rem',
  },
  tableContainer: {
    marginTop: '16px',
    display: 'flex',
    '@media (max-width: 1024px)': {
      margin: 0,
    },
  },
  stickyHeader: {
    width: '10rem',
  },
  rightTables: {
    overflowX: 'auto',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  hoverRow: {
    transition: '0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

function MamLongTermOverviewTable(props) {
  const { device, history, match } = props;
  const [mamLongTermSummary, setMamLongTermSummary] = useState(null);
  const [yearRefs, setYearRefs] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  // get existing data
  const getEntries = useCallback(async () => {
    let receivedSummary;
    try {
      const accessToken = await getAccessTokenSilently();
      receivedSummary = await radqcApi('get', `/entries/device/${device._id}/mamlongtermsummary`, { accessToken });
    } catch (err) {
      console.log(err);
    }
    if (receivedSummary) {
      const refs = receivedSummary.years.map(() => React.createRef());
      setYearRefs(refs);
      setMamLongTermSummary(receivedSummary);
    }
  }, [device._id, getAccessTokenSilently]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  useEffect(() => {
    if (yearRefs.length > 0) {
      yearRefs[yearRefs.length - 1].current?.scrollIntoView({ block: 'nearest' });
    }
  }, [mamLongTermSummary, yearRefs]);

  if (!mamLongTermSummary) {
    return null;
  }

  const goToTaskView = (task) => {
    history.push(`${match.url}/${task.toLowerCase()}/view`);
  };

  const getMonthCells = (year) => {
    const monthDate = new Date();
    monthDate.setUTCMonth(0);
    monthDate.setUTCDate(1);

    const months = [];
    for (let i = 0; i < 12; i++) {
      months.push(
        <TableCell className={year % 2 === 0 ? classes.tableTitlesInput : classes.tableTitlesInputOdd} key={monthDate}>
          {monthDate.toUTCString().split(' ')[2]}
        </TableCell>,
      );
      monthDate.setUTCMonth(monthDate.getUTCMonth() + 1);
    }
    return months;
  };

  return (
    <Box className={classes.tableContainer}>
      <Table stickyHeader className={classes.stickyHeader}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitles}>Year</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Month</TableCell>
          </TableRow>
          <TableRow onClick={() => goToTaskView(mamLongTermEntryTypes.MAM_MT)}>
            <TableCell className={classes.tableTitles}>Visual Checklist (monthly)</TableCell>
          </TableRow>
          <TableRow onClick={() => goToTaskView(mamLongTermEntryTypes.MAM_QU)}>
            <TableCell className={classes.tableTitles}>Repeat/Reject Analysis (±2% change) (quarterly)</TableCell>
          </TableRow>
          <TableRow onClick={() => goToTaskView(mamLongTermEntryTypes.MAM_CF)}>
            <TableCell className={classes.tableTitles}>Compression (25-45lb) (semi-annually)</TableCell>
          </TableRow>
          <TableRow onClick={() => goToTaskView(mamLongTermEntryTypes.MAM_GC)}>
            <TableCell className={classes.tableTitles}>Geometry Calib. for Tomo (25-45lb) (semi-annually)</TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Box className={classes.rightTables}>
        <Table stickyHeader className={classes.stickyHeader}>
          <TableBody>
            <TableRow>
              {mamLongTermSummary.years.map((year, index) => (
                <TableCell
                  colSpan={12}
                  className={year % 2 === 0 ? classes.tableTitlesInput : classes.tableTitlesInputOdd}
                  key={year}
                  ref={yearRefs[index]}
                >
                  {year}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>{mamLongTermSummary.years.map((year) => getMonthCells(year))}</TableRow>
            {Object.values(mamLongTermEntryTypes).map((task) => (
              <TableRow classes={{ root: classes.hoverRow }} key={task} onClick={() => goToTaskView(task)}>
                <MamLongTermOverviewRow entries={mamLongTermSummary[task]} years={mamLongTermSummary.years} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

MamLongTermOverviewTable.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(MamLongTermOverviewTable);
