/* eslint-disable react/jsx-boolean-value */

import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Box, Button } from '@mui/material';
import NotifLine from './NotifLine';
import { useNotifCtx } from 'context/NotifContext';
import radqcApi from 'dataExchange/radqcApiClient';

export default function TemporaryDrawer() {
  const { getAccessTokenSilently } = useAuth0();
  const { notifications, updateNotifs, setOpenNotifSlideout, openNotifSlideout } = useNotifCtx();

  const handleMarkAllClick = async () => {
    const accessToken = await getAccessTokenSilently();
    notifications.forEach(async (notif: any) => {
      const newNotif = Object.assign(notif);
      newNotif.read = true;
      await radqcApi('put', `/notifications/${notif?._id}`, newNotif, { accessToken });
    });
    updateNotifs();
    setOpenNotifSlideout(false);
  };

  return (
    <Drawer anchor="right" open={openNotifSlideout} onClose={() => setOpenNotifSlideout(false)}>
      <Box component="div" width="400px" padding="32px">
        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontSize="20px">Notifications</Typography>
          <Button sx={{ textTransform: 'capitalize' }} onClick={handleMarkAllClick}>
            <Typography fontSize="14px">Mark all as read</Typography>
          </Button>
        </Box>
        <Box component="div" sx={{ marginTop: '32px' }}>
          {notifications.map((notif: any) => (
            <Box
              component="div"
              key={notif?._id}
              sx={{
                marginY: '4px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <NotifLine notification={notif} closeNotifs={() => setOpenNotifSlideout(false)} />
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}
