import React from 'react';
import { TableRow, TableCell, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { RadTextInput } from 'presentational/Inputs';
import { exposures, mamQuarterlyLineFields } from './mamRejectTable.util';
import { roundUpNum } from 'utils/entry.util';

const useStyles = makeStyles({
  formControl: {
    margin: '0px 0',
    padding: '0.25rem',
    width: '4rem',
  },
  selectedTableCell: {
    padding: 0,
    textAlign: 'center',
  },
  radTextInput: {
    textAlign: 'center',
  },
  inputProps: {
    padding: '0.2rem',
    fontSize: '0.725rem',
    width: '100%',
  },
  RtLtCells: {
    textAlign: 'center',
    padding: 0,
  },
  cellHeader: {
    padding: '0 1rem',
    fontSize: 12,
    fontWeight: 600,
    width: '12rem',
  },
});

function MamRejectTableLine(props) {
  const { field, totals, onChange } = props;
  const classes = useStyles();

  const inputProps = { min: 0 };

  return (
    <TableRow>
      <TableCell size="small" className={classes.cellHeader}>
        {mamQuarterlyLineFields[field]}
      </TableCell>
      {exposures.map((exposure) => {
        const fieldName = `${field}.${exposure}`;
        return (
          <TableCell size="small" key={exposure} className={classes.selectedTableCell}>
            <FormControl className={classes.formControl}>
              <RadTextInput
                sx={{ margin: 0 }}
                margin="dense"
                name={fieldName}
                type="number"
                onChange={(e) => onChange(e, fieldName)}
                className={classes.radTextInput}
                InputProps={{
                  inputProps,
                  classes: { inputMarginDense: classes.inputProps },
                }}
              />
            </FormControl>
          </TableCell>
        );
      })}
      <TableCell size="small" className={classes.selectedTableCell}>
        {totals[field]}
      </TableCell>
      <TableCell size="small" className={classes.selectedTableCell}>
        {totals.overall > 0 ? roundUpNum((totals[field] / totals.overall) * 100) : 0} %
      </TableCell>
    </TableRow>
  );
}

MamRejectTableLine.propTypes = {
  field: PropTypes.string.isRequired,
  totals: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MamRejectTableLine;
