import React, { useState } from 'react';
import PropTypes from 'prop-types';

function RecordCharts(props) {
  const { numberOfCharts, setChartImages, records, PdfCharts, recordType } = props;
  const [localChartImages, setLocalChartImages] = useState({});

  const handleAddImage = (chartImage) => {
    const newChartImages = Object.assign(localChartImages);
    newChartImages[chartImage.field] = chartImage.image;
    setLocalChartImages(newChartImages);
    if (Object.keys(newChartImages).length >= numberOfCharts) {
      setChartImages(newChartImages);
    }
  };

  if (numberOfCharts < 1 || records.length < 1 || Object.keys(localChartImages).length > numberOfCharts - 1) {
    return null;
  }

  return records.map((record) => (
    <PdfCharts
      key={record.entries[0]._id}
      recordType={recordType}
      entries={record.entries}
      setChartImage={handleAddImage}
    />
  ));
}

RecordCharts.propTypes = {
  numberOfCharts: PropTypes.number,
  setChartImages: PropTypes.func.isRequired,
  records: PropTypes.arrayOf(PropTypes.any),
  PdfCharts: PropTypes.func,
  recordType: PropTypes.string.isRequired,
};

RecordCharts.defaultProps = {
  numberOfCharts: 0,
  records: [],
  PdfCharts: null,
};

export default RecordCharts;
