import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import RwsDailyTable from './dailyTable/RwsDailyTable';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';

const styles = () => ({
  dashboard: { minHeight: '33rem' },
  dailyTable: {
    height: '31.5rem',
    marginBottom: '0.5rem',
  },
});

function RwsDailyDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedRwsEntries, setSavedRwsEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const getEntries = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedRwsEntries;
    if (mode === 'view') {
      const startDate = date.start.toISOString();
      const endDate = date.end.toISOString();

      try {
        receivedRwsEntries = await radqcApi(
          'get',
          `/entries/rws_sc/device/${deviceId}?start=${startDate}&end=${endDate}`,
          { accessToken },
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      const entryDate = date.start.toISOString();

      try {
        receivedRwsEntries = await radqcApi('get', `/entries/rws_sc/device/${deviceId}/date/${entryDate}`, {
          accessToken,
        });
      } catch (err) {
        receivedRwsEntries = [];
      }
    }
    if (!receivedRwsEntries) receivedRwsEntries = [];
    setSavedRwsEntries(receivedRwsEntries);
  }, [getAccessTokenSilently, mode, match.url, date.start, date.end]);

  useEffect(() => {
    getEntries();
  }, [mode, date, getEntries]);

  const handleRwsChangeSubmitted = async (submission, data) => {
    let dailyEntries = [];
    let comments = '';
    let putExtension = '';
    let putData = data;
    switch (submission) {
      case 'dailyEntries':
        dailyEntries = data;
        putExtension = '/daily';
        break;
      case 'comments':
        comments = data;
        if (savedRwsEntries[0]) {
          putData = Object.assign(savedRwsEntries[0]);
          putData.comments = data;
        }
        break;
      case 'review':
        putExtension = '/review';
        if (savedRwsEntries[0]) {
          putData = Object.assign(savedRwsEntries[0]);
          putData = { ...data };
        }
        break;
      default:
        throw Error(`Unknown submission type passed to RwsDashboard: ${submission}`);
    }

    // If RWS Entry exists, update DB with new data
    // else post new RWS Entry
    let updatedRwsEntry;
    const accessToken = await getAccessTokenSilently();
    if (savedRwsEntries[0]) {
      try {
        updatedRwsEntry = await radqcApi('put', `/entries/rws_sc/${savedRwsEntries[0]._id}${putExtension}`, putData, {
          accessToken,
        });
        enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
          variant: 'info',
        });
      } catch (err) {
        console.log('put err:', err);
      }
    } else {
      const deviceId = getDeviceIdFromUrl(match.url);
      const newRwsEntry = {
        deviceId,
        date: date.start,
        dailyEntries,
        comments,
        outFields: [],
        review: null,
      };
      try {
        updatedRwsEntry = await radqcApi('post', '/entries/rws_sc', newRwsEntry, { accessToken });
        enqueueSnackbar(`Added new RWS Sectra entry`, { variant: 'info' });
      } catch (err) {
        console.log('post err:', err);
      }
    }

    setSavedRwsEntries([updatedRwsEntry]);
  };

  if (mode === 'view') {
    return <RecordViewer recordType="rws" records={savedRwsEntries} />;
  }

  return (
    <Box className={classes.dashboard} justify="space-between">
      <Box className={classes.dailyTable}>
        <RwsDailyTable
          date={date}
          rwsEntry={savedRwsEntries.length > 0 ? savedRwsEntries[0] : null}
          mode={mode}
          updateRwsEntry={handleRwsChangeSubmitted}
        />
      </Box>
    </Box>
  );
}

RwsDailyDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(RwsDailyDashboard));
