import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  deviceListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    maxWidth: '49rem',
    '@media (max-width: 550px)': {
      maxWidth: '9rem',
    },

    '@media (min-width: 551px) and (max-width: 800px)': {
      maxWidth: '28rem',
    },
  },
  scnrAvatar: {
    width: '22px',
    height: '22px',
    fontSize: '0.7rem',
    textTransform: 'capitalize',
    '@media (max-width: 550px)': {
      width: '10px',
      height: '10px',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '@media (max-width: 550px)': {
      display: 'none',
    },
  },
});

const abbreviateDevice = (name: string) => name.substring(0, 2);

function UserDeviceList(props: any) {
  const { devices } = props;
  const classes = useStyles();

  const toolTip = (device: any) => (
    <>
      <span>{device.name}</span> <br />
      <span style={{ textTransform: 'uppercase' }}>({device.type}) </span>
    </>
  );

  return (
    <div className={classes.deviceListContainer}>
      {devices.map((device: any) => (
        <div key={device._id} style={{ width: '25px' }}>
          <Tooltip id={device._id} title={toolTip(device)} placement="bottom">
            <Link to={`/devices/${device._id}`} className={classes.link}>
              <Avatar
                variant="square"
                className={classes.scnrAvatar}
                style={{
                  textTransform: 'uppercase',
                  borderRadius: 4,
                  color: device.outFields.length === 0 ? '#1C9C1C' : '#F44336',
                  backgroundColor: 'white',
                  border: `1px solid ${device.outFields.length === 0 ? '#1C9C1C' : '#F44336'}`,
                }}
              >
                {abbreviateDevice(device.name)}
              </Avatar>
            </Link>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default UserDeviceList;
