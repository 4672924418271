import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import Check from '@mui/icons-material/Check';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';

const useStyles = makeStyles({
  error: {
    '&:hover': {
      color: '#dd3333',
    },
  },
  warning: {
    '&:hover': {
      color: '#999900',
    },
  },
  pastDue: {
    color: '#beb306',
  },
  container: {
    position: 'relative',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  optionIcon: {
    backgroundColor: '#FFF',
    position: 'absolute',
    right: '20px',
    '&:hover': {
      backgroundColor: '#DDD',
    },
  },
  link: {
    color: '#333',
    textDecoration: 'none',
  },
  text: {
    textAlign: 'left',
    fontSize: '14px',
    marginLeft: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '16rem',
    height: '40px',
    display: '-webkit-box',
  },
  indicator: {
    fontSize: 8,
  },
  menuListToggleRead: {
    width: '14rem',
  },
  listItemText: {
    fontSize: '0.8rem',
  },
  listItemIcon: {
    minWidth: '1.5rem',
  },
  toggleReadIcons: {
    fontSize: 16,
  },
});

function NotifLine(props) {
  const { notification, closeNotifs } = props;
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [notifMenuAnchor, setNotifMenuAnchor] = useState(null);
  const [moreIconClasses, setMoreIconClasses] = useState(classes.displayNone);

  const handleMouseOver = () => {
    if (moreIconClasses === classes.displayNone) setMoreIconClasses(classes.displayBlock);
  };

  const handleMouseOut = () => {
    if (!notifMenuAnchor) setMoreIconClasses(classes.displayNone);
  };

  const handleClose = () => {
    setNotifMenuAnchor(null);
  };

  const handleToggleRead = async () => {
    const newNotif = Object.assign(notification);
    newNotif.read = !newNotif.read;
    const accessToken = await getAccessTokenSilently();
    await radqcApi('put', `/notifications/${notification._id}`, newNotif, { accessToken });
    handleClose();
  };

  const handleRemove = async () => {
    const accessToken = await getAccessTokenSilently();
    await radqcApi('delete', `/notifications/${notification._id}`, { accessToken });
  };

  const handleLinkClick = async () => {
    if (!notification.read) {
      const newNotif = Object.assign(notification);
      newNotif.read = true;
      const accessToken = await getAccessTokenSilently();
      await radqcApi('put', `/notifications/${notification._id}`, newNotif, { accessToken });
    }
    closeNotifs();
  };

  // TODO: Replace the following with actual icons
  let avatar;
  switch (notification.type) {
    case 'warnQa':
    case 'pastDueQa':
      avatar = <AlarmOnIcon sx={{ fontSize: '16px' }} className={classes.pastDue} />;
      break;
    case 'warnReview':
    case 'pastDueReview':
      avatar = <AssignmentOutlinedIcon sx={{ fontSize: '16px' }} className={classes.pastDue} />;
      break;
    case 'outOfSpec':
      avatar = <BuildOutlinedIcon sx={{ fontSize: '16px' }} color="secondary" />;
      break;
    default:
      avatar = <InfoOutlinedIcon sx={{ fontSize: '16px' }} />;
  }

  const indicator = notification.read ? null : <Brightness1RoundedIcon color="primary" className={classes.indicator} />;

  return (
    <Box
      className={classes.container}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
    >
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...props}
      >
        <MenuItem onClick={handleToggleRead}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <Check className={classes.toggleReadIcons} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            classes={{ root: classes.listItemText }}
            primary={`Mark as ${notification.read ? 'unread' : 'read'}`}
          />
        </MenuItem>
        <MenuItem onClick={handleRemove}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <ClearIcon className={classes.toggleReadIcons} />
          </ListItemIcon>
          <ListItemText disableTypography classes={{ root: classes.listItemText }} primary="Remove this notification" />
        </MenuItem>
      </Menu>
      <Link to={notification.link} className={classes.link} onClick={handleLinkClick}>
        <Box sx={{ height: '40px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <Box sx={{ marginRight: '16px', display: 'flex' }}>{avatar}</Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              width: '348px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {notification.text}
          </Box>
          <Box>{indicator}</Box>
        </Box>
      </Link>
    </Box>
  );
}

NotifLine.prototypes = {
  notification: PropTypes.objectOf(PropTypes.any),
  closeNotifs: PropTypes.func,
};

export default NotifLine;
