import React from 'react';
import { Grid, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useModalCtx } from 'context/ModalContext';

//testing
const styles = {
  button: {
    fontSize: '0.8rem',
    width: '150px',
  },
  message: {
    marginBottom: 25,
    fontWeight: 200,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '20px 0px',
  },
  removeButton: {
    textTransform: 'capitalize',
  },
};

function ConfirmDialog(props) {
  const { message, actionText, confirmAction, hasCancel, classes } = props;
  const { hideModal } = useModalCtx();

  const handleConfirmClick = () => {
    hideModal();
    confirmAction();
  };

  let cancelButton = null;
  let buttonGrid = 12;
  if (hasCancel) {
    cancelButton = (
      <Grid item xs={6} className={classes.buttonContainer}>
        <Button className={classes.button} onClick={hideModal}>
          Cancel
        </Button>
      </Grid>
    );
    buttonGrid = 6;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h3 className={classes.message}>{message}</h3>
      </Grid>
      {cancelButton}
      <Grid item xs={buttonGrid} className={classes.buttonContainer}>
        <Button
          className={clsx(classes.button, classes.removeButton)}
          color="secondary"
          variant="outlined"
          onClick={handleConfirmClick}
        >
          {actionText}
        </Button>
      </Grid>
    </Grid>
  );
}

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  hasCancel: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  actionText: 'OK',
  hasCancel: true,
};

export default withStyles(styles)(ConfirmDialog);
