import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import { useModalCtx } from 'context/ModalContext';
import { formatMonthDate } from 'utils/time.util';
import EntryComment from 'views/entry/components/EntryComment';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  outValue: {
    color: theme.palette.primary.red,
  },
});

function ForceCompDisplay(props) {
  const { entryData, click, classes } = props;

  const { showModal, hideModal } = useModalCtx();

  const getStyleForField = (field) => (entryData.outFields.includes(field) ? classes.outValue : null);

  let editIcon = null;
  if (click) {
    editIcon = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableRow key={entryData._id} onClick={click}>
      <TableCell size="small">{formatMonthDate(entryData.date)}</TableCell>
      <TableCell size="small" className={getStyleForField('autoCompression')}>
        {entryData.autoCompression}
      </TableCell>
      <TableCell size="small" className={getStyleForField('manualCompression')}>
        {entryData.manualCompression}
      </TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            showModal(EntryComment, {
              savedComment: entryData.remarks,
              hideModal,
              title: 'Remarks',
            });
          }}
        >
          <CommentIcon className={classes.icons} />
        </IconButton>
      </TableCell>
      <TableCell size="small">{editIcon}</TableCell>
    </TableRow>
  );
}

ForceCompDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

ForceCompDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(ForceCompDisplay);
