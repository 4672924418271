import React from 'react';
import clsx from 'clsx';
import { TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { trueFalseEmpty } from 'utils/entry.util';
import HeAdjustedTableCell from './HeAdjustedTableCell';

const styles = (theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}1a`,
      cursor: 'pointer',
    },
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '60px',
  },
  outFieldValues: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
  },
});

function QcDailyEntryDisplay(props) {
  const { entryData, click, classes } = props;

  return (
    <TableRow onClick={click} className={classes.tableRow}>
      <HeAdjustedTableCell className={classes.tableCellStyle}>{formatDate(entryData.date)}</HeAdjustedTableCell>
      <HeAdjustedTableCell
        className={clsx(
          classes.tableCellStyle,
          entryData.outFields && entryData.outFields.includes('heliumLevel') ? classes.outFieldValues : null,
        )}
      >
        {`${entryData.heliumLevel} %`}
      </HeAdjustedTableCell>
      <HeAdjustedTableCell className={classes.tableCellStyle}>{`${entryData.pressure} PSI`}</HeAdjustedTableCell>
      <HeAdjustedTableCell className={classes.tableCellStyle}>
        {trueFalseEmpty(entryData.coldHeadRunning, 'Yes', 'No')}
      </HeAdjustedTableCell>
      <HeAdjustedTableCell className={classes.tableCellStyle}>{entryData.enteredBy}</HeAdjustedTableCell>
      <HeAdjustedTableCell width="10px" className={classes.tableCellStyle}>
        {entryData?.reviewedBy ? `${entryData?.reviewedBy} on ${formatDate(entryData?.reviewedDate)}` : null}
      </HeAdjustedTableCell>
    </TableRow>
  );
}

QcDailyEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QcDailyEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(QcDailyEntryDisplay);
