import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { mamWeeklyTasks } from 'views/entry/mam/components/mamEntry.util';

const useStyles = makeStyles({
  tableTitles: {
    color: 'black',
    textAlign: 'center',
    width: '30px',
    height: '50px',
    padding: '0.25rem',
    borderBottom: '0.1px solid #0000000a',
  },
});

function MamWeeklyOverviewRow(props) {
  const { entries, task } = props;
  const classes = useStyles();

  let dataField;
  switch (task) {
    case mamWeeklyTasks.VIEW_BOX:
    case mamWeeklyTasks.FLAT_FIELD:
      dataField = 'checkPerformed';
      break;
    case mamWeeklyTasks.ART_EVAL:
      dataField = 'artEvalPerformed';
      break;
    case mamWeeklyTasks.SNR_CNR:
      dataField = 'snrCnrPerformed';
      break;
    case mamWeeklyTasks.PHANTOM2:
      dataField = 'phantom2Performed';
      break;
    case mamWeeklyTasks.PHANTOM3:
      dataField = 'phantom3Performed';
      break;
    case mamWeeklyTasks.COMPRESSION:
      dataField = 'compThickPerformed';
      break;
    default:
      throw new Error(`Unrecognized task type: ${task}`);
  }

  return entries.map((entry) => (
    <TableCell className={classes.tableTitles} key={entry._id}>
      {entry[dataField] && 'X'}
    </TableCell>
  ));
}

MamWeeklyOverviewRow.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  task: PropTypes.string.isRequired,
};

export default MamWeeklyOverviewRow;
