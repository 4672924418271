import { getUserInitials } from 'utils/user.util';

export const generateEmptyQaEntry = (currUser, scanner) => ({
  _id: 'new',
  deviceId: scanner._id,
  date: new Date().toISOString(),
  setupAccuracy: '',
  setupConsole: '',
  centerFreq: '',
  txGainAtten: '',
  geoAccuracyHf: '',
  geoAccuracyAp: '',
  geoAccuracyRl: '',
  spatialResUpper: '',
  spatialResLower: '',
  spokesNum: '',
  artifactEval: '',
  notes: '',
  initials: getUserInitials(currUser),
  enteredById: currUser._id,
  fieldStrength: scanner.fieldStrength,
  cfMin: scanner.cfMin,
  cfMax: scanner.cfMax,
  tgMin: scanner.tgMin,
  tgMax: scanner.tgMax,
  phantomSize: scanner.phantomSize,
  outFields: [],
});

const checkCfBounds = (value, entryData) => {
  const { cfMax, cfMin } = entryData;
  if (!(cfMax && cfMin)) return false;

  const cfAvg = (cfMax + cfMin) / 2;
  const cfAltVar = 42.58 * 1.5 * entryData.fieldStrength;
  const cfAltMax = cfAvg + cfAltVar;
  const cfAltMin = cfAvg - cfAltVar;
  return (value > cfMax || value < cfMin) && (value > cfAltMax || value < cfAltMin);
};

const checkTgBounds = (value, entryData) => {
  const { tgMax, tgMin } = entryData;
  if (!(tgMax && tgMin)) return false;

  const tgAvg = (tgMax + tgMin) / 2;
  const tgPercentMax = tgAvg + 0.1 * tgAvg;
  const tgPercentMin = tgAvg - 0.1 * tgAvg;
  return (value > tgMax || value < tgMin) && (value > tgPercentMax || value < tgPercentMin);
};

export const checkForOutField = (field, value, entryData) => {
  let outOfBounds = false;

  switch (field) {
    case 'setupAccuracy':
      if (value > 5.0 || value < -5.0) outOfBounds = true;
      break;
    case 'setupConsole':
      outOfBounds = !value;
      break;
    case 'centerFreq':
      outOfBounds = checkCfBounds(value, entryData);
      break;
    case 'txGainAtten':
      outOfBounds = checkTgBounds(value, entryData);
      break;
    case 'geoAccuracyHf': {
      const limit = QaPhantomLimits[entryData.phantomSize].geoHf;
      if (value > limit + 2 || value < limit - 2) outOfBounds = true;
      break;
    }
    case 'geoAccuracyAp':
    case 'geoAccuracyRl': {
      const limit = QaPhantomLimits[entryData.phantomSize].geoApRl;
      if (value > limit + 2 || value < limit - 2) outOfBounds = true;
      break;
    }
    case 'spatialResUpper':
    case 'spatialResLower': {
      const limit = QaPhantomLimits[entryData.phantomSize].spatialRes;
      if (value > limit) outOfBounds = true;
      break;
    }
    case 'artifactEval':
      outOfBounds = value;
      break;
    default:
      throw new Error(`Unrecognized field: ${field}`);
  }
  return outOfBounds;
};

export const getQaLimits = (limitSource) => {
  let cfLimit = null;
  let tgLimit = null;
  if (limitSource.cfMax) {
    const max = Math.round(limitSource.cfMax);
    const min = Math.round(limitSource.cfMin);
    cfLimit = `${max} to ${min} Hz`;
  }
  if (limitSource.tgMax) {
    const max = limitSource.tgMax.toFixed(2);
    const min = limitSource.tgMin.toFixed(2);
    tgLimit = `${max} to ${min} dB`;
  }
  return [cfLimit, tgLimit];
};

export const getSliceNum = (fieldStrength) => {
  let sliceNumber = '8';
  if (fieldStrength < 0.5) {
    sliceNumber = '11';
  } else if (fieldStrength < 0.6) {
    sliceNumber = '10';
  } else if (fieldStrength < 1.0) {
    sliceNumber = '9';
  }
  return sliceNumber;
};

export const QaPhantomLimits = {
  Large: {
    geoHf: 148,
    geoApRl: 190,
    spatialRes: 1.0,
  },
  Medium: {
    geoHf: 134,
    geoApRl: 165,
    spatialRes: 1.0,
  },
  Small: {
    geoHf: 100,
    geoApRl: 100,
    spatialRes: 0.8,
  },
};
