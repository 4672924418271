import React from 'react';
import clsx from 'clsx';
import { HashLink as Link } from 'react-router-hash-link';
import { withStyles } from '@mui/styles';

const styles = {
  middleButtonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    color: 'white',
    cursor: 'pointer',
    fontSize: '16px',

    '@media (max-width: 800px)': {
      display: 'none',
    },
  },
  mainNavList: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    listStyle: 'none',
    width: '85%',
    textAlign: 'center',
    '@media (max-width: 450px)': {
      display: 'none',
    },
  },

  mainNavItem: {
    display: 'table-cell',
    position: 'relative',
  },

  mainNavLink: {
    color: '#fff',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    display: 'inline-block',
    padding: '15px 20px',
    position: 'relative',
    '&::after': {
      background: 'none repeat scroll 0 0 transparent',
      bottom: 0,
      content: "''",
      display: 'block',
      height: '2px',
      left: '50%',
      position: 'absolute',
      backgroundColor: '#fff',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: 0,
    },
    '&:hover:after': {
      width: '100%',
      left: 0,
    },
  },
};

function NavbarMiddle(props) {
  const { classes } = props;

  return (
    <div className={classes.middleButtonContainer}>
      <ul className={classes.mainNavList}>
        <li className={classes.mainNavItem}>
          <Link className={clsx(classes.mainNavLink)} key="features" to="/#features">
            Features
          </Link>
        </li>
        <li className={classes.mainNavItem}>
          <Link className={clsx(classes.mainNavLink)} key="contact" to="/#contact">
            Contact
          </Link>
        </li>
        <li className={classes.mainNavItem}>
          <Link className={clsx(classes.mainNavLink)} key="terms" to="/terms">
            Terms
          </Link>
        </li>
        <li className={classes.mainNavItem}>
          <Link className={clsx(classes.mainNavLink)} key="policies" to="/policies">
            Policies
          </Link>
        </li>
      </ul>
    </div>
  );
}

NavbarMiddle.propTypes = {};

export default withStyles(styles)(NavbarMiddle);
