import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UsersOverview from 'components/Users';

//TODO consolidate this route
function UserRoutes() {
  return (
    <Switch>
      <Route path="/users/overview" component={UsersOverview} />
    </Switch>
  );
}

export default UserRoutes;
