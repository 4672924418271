import React from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { mamWeeklyTasks, mamWeeklyTaskTitles } from 'views/entry/mam/components/mamEntry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import palette from 'theme/palette';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  buttons: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'auto',
    width: '100%',
    '@media (max-width: 679px)': {
      justifyContent: 'end',
    },
    '&:focus': {
      color: palette.primary.main,
      fontWeight: 600,
    },
  },
  taskBtn: {
    color: palette.primary.main,
    backgroundColor: `${palette.primary.main}2a`,
  },
});

function MamWeeklyView(props) {
  const { match, history, records } = props;
  const classes = useStyles();

  let activeTask = history.location.pathname.split('/').pop();
  // Get the active task from the URL, if it isn't an actual task, default to viewbox
  activeTask = Object.values(mamWeeklyTasks).includes(activeTask) ? activeTask : 'viewbox';

  return (
    <>
      <Box className={classes.buttons}>
        {Object.keys(mamWeeklyTasks).map((task) => (
          <Link key={task} to={`${match.url}/${mamWeeklyTasks[task]}`} className={classes.link}>
            <Button className={mamWeeklyTasks[task] === activeTask ? classes.taskBtn : null}>
              {mamWeeklyTaskTitles[task]}
            </Button>
          </Link>
        ))}
      </Box>
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={() => <RecordViewer recordType={Object.values(mamWeeklyTaskTitles)[0]} records={records} />}
        />
        {Object.keys(mamWeeklyTasks).map((task) => (
          <Route
            key={task}
            path={`${match.url}/${mamWeeklyTasks[task]}`}
            render={() => <RecordViewer recordType={mamWeeklyTaskTitles[task]} records={records} />}
          />
        ))}
      </Switch>
    </>
  );
}

MamWeeklyView.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withRouter(MamWeeklyView);
