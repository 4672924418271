import React from 'react';
import PropTypes from 'prop-types';
import { TailSpin } from 'react-loader-spinner';
import { Typography, Box } from '@mui/material';

export const SpinnerPosition = {
  HIGH: 'high',
  CENTER: 'center',
};

const generateStyleForPosition = (position) => {
  // Default height
  let minHeight = '100vh';
  switch (position) {
    case SpinnerPosition.HIGH:
      minHeight = '50vh';
      break;
    case SpinnerPosition.CENTER:
    default:
      minHeight = '100vh';
  }
  return {
    minHeight,
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

function InitialLoadingIndicator(props) {
  const { position } = props;
  return (
    <Box style={generateStyleForPosition(position)}>
      <TailSpin color="#1C2237" height={80} width={80} />
      <Typography fontSize={30} marginTop="14px">
        Loading...
      </Typography>
    </Box>
  );
}

InitialLoadingIndicator.propTypes = {
  position: PropTypes.string,
};

InitialLoadingIndicator.defaultProps = {
  position: SpinnerPosition.CENTER,
};

export default InitialLoadingIndicator;
