import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import * as Yup from 'yup';
import { Table, TableBody, TableHead, TableRow, TableCell, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useModalCtx } from 'context/ModalContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { enumNaPassFailValidation } from 'utils/entry.util';
import radqcApi from 'dataExchange/radqcApiClient';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import MamChecklistForm from './components/MamChecklistForm';
import { generateEmptyMamChecklist } from './components/mamChecklistTable.util';
import MamChecklistDisplay from './components/MamChecklistDisplay';
import ConfirmDialog from 'components/ConfirmDialog';

const styles = (theme) => ({
  tableHeader: {
    width: 400,
    backgroundColor: '#005ed412',
  },
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: {
    height: '600px',
  },
  tableContainer: {
    width: '100%',
    height: '80%',
    marginBottom: '20px',
    border: '1px solid #005ed426',
    overflowY: 'auto',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 649px)': {
      margin: '0',
      width: '100%',
    },
  },
  tableTitles: {
    color: 'black',
    fontSize: 12,
    padding: '0.25rem 0',
    textAlign: 'center',
    minWidth: '60px',
  },
  bottomNavigation: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: `${theme.palette.primary.main}2a`,
  },
  subCats: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rightTables: {
    height: '675px',
  },
});

function MamChecklistTable(props) {
  const { match, classes, mamChecklists, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { showModal, hideModal } = useModalCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyMamChecklist(currUser, device) };
    newEntryForm = <MamChecklistForm entryData={initialValues.entries.new} mode={mode} />;
  }

  const handleSubmit = (submitForm) => {
    showModal(ConfirmDialog, {
      message: 'Submit entered values for Mammography?',
      actionText: 'Submit',
      confirmAction: () => {
        hideModal();
        submitForm();
      },
      cancelAction: () => hideModal(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                deviceId: Yup.string().required('Required'),
                date: Yup.string().required('Required'),
                sidIndicator: enumNaPassFailValidation,
                angulationIndicator: enumNaPassFailValidation,
                detentsLocks: enumNaPassFailValidation,
                collimatorLight: enumNaPassFailValidation,
                smoothnessMotion: enumNaPassFailValidation,
                gridFunction: enumNaPassFailValidation,
                compDevFunc: enumNaPassFailValidation,
                compThickDisp: enumNaPassFailValidation,
                compForceDisp: enumNaPassFailValidation,
                glassShield: enumNaPassFailValidation,
                expSwitches: enumNaPassFailValidation,
                powerControls: enumNaPassFailValidation,
                monitors: enumNaPassFailValidation,
                techCharts: enumNaPassFailValidation,
                footPedals: enumNaPassFailValidation,
                compPaddles: enumNaPassFailValidation,
                faceshields: enumNaPassFailValidation,
                disinfection: enumNaPassFailValidation,
                outFields: Yup.array(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          delete newEntry._id;
          await radqcApi('post', '/entries/mam_mt', newEntry, { accessToken });
          enqueueSnackbar(`Added new Mammography Checklist entry`, { variant: 'info' });
          updateDevices();
          updateUsers();
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await newEntries.forEach((entry) => radqcApi('put', `/entries/mam_mt/${entry._id}`, entry, { accessToken }));
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
        updateEntries();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, submitForm }) => {
        let allMamChecklists = mamChecklists;
        if (mode === 'edit') {
          const savedMamChecklists = mamChecklists || [];
          allMamChecklists = [...savedMamChecklists, ...Object.values(values.entries)].sort((a, b) => a.date - b.date);
          allMamChecklists = uniqBy(allMamChecklists, '_id');
        }
        return (
          <Form className={classes.formStyle}>
            <div className={classes.tableContainer}>
              <Table className={classes.tableHeader}>
                <TableHead>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell colSpan={2} className={classes.tableTitles}>
                      Date
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell colSpan={2} className={classes.tableTitles}>
                      Tech Initials
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell rowSpan={9} className={classes.tableTitles}>
                      C-arm/Gantry
                    </TableCell>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>SID Indicator or Marks</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Angulation Indicator</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Detents/Locks (all)</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Collimator Light</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Smoothness of Motion</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Grid Function</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Compression Device Function
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Compression Thickness Display
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Compression Force Display
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell rowSpan={5} className={classes.tableTitles}>
                      Acquisition Workstation
                    </TableCell>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Glass Shield</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Exposure Switches</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Power Controls</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Monitors</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Technique Charts</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell rowSpan={4} className={classes.tableTitles}>
                      Accessories
                    </TableCell>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>Foot Pedals</TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Compression Paddles Clean and Not Cracked
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Faceshields Clean and Not Cracked
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={clsx(classes.tableTitles, classes.subCats)}>
                      Disinfection Materials Available
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              <div className={classes.rightTables}>
                <Table>
                  <TableBody>
                    <TableRow>
                      {newEntryForm}
                      {allMamChecklists.map((entry) => (
                        <ToggleableEntry
                          key={entryKey + entry._id}
                          entryData={entry}
                          mode={mode}
                          entryDisplay={MamChecklistDisplay}
                          entryForm={MamChecklistForm}
                        />
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
            <Table className={classes.bottomNavigation}>
              <TableHead>
                <TableRow className={classes.headerTableRow}>
                  <TableCell sx={{ border: 'none' }}>N/A = Not Applicable</TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Button disabled={!Object.keys(values.entries).length > 0} onClick={() => handleSubmit(submitForm)}>
                      {mode === 'enter' ? 'Submit' : 'Submit Change'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

MamChecklistTable.propTypes = {
  mamChecklists: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

MamChecklistTable.defaultProps = {
  mamChecklists: [],
};

export default withRouter(withStyles(styles)(MamChecklistTable));
