import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useDeviceCtx } from 'context/DeviceContext';
import { useModalCtx } from 'context/ModalContext';
import { getUserInitials } from 'utils/user.util';
import { enumNaPassFailValidation } from 'utils/entry.util';
import QcMonthlyTable from './QcMonthlyTable';

const styles = {
  form: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
};

function QcDashboard(props) {
  const { qcEntry, disableMonthlyQc, updateQcEntry, classes } = props;
  const { currUser } = useCurrUserCtx();
  const { updateDevices } = useDeviceCtx();
  const { hideModal } = useModalCtx();
  const initials = getUserInitials(currUser);
  const enteredDate = new Date();

  let initialValues = {
    tableHeight: 'pass',
    tablePosition: 'pass',
    angulation: 'pass',
    laserLocLight: 'pass',
    cables: 'pass',
    smoothness: 'pass',
    gantryXray: 'pass',
    exposureSwitch: 'pass',
    displayWindow: 'pass',
    panelSwitches: 'pass',
    consoleXray: 'pass',
    doorInterlocks: 'pass',
    warningLabels: 'pass',
    intercom: 'pass',
    postings: 'pass',
    records: 'pass',
    contrast0: 'pass',
    contrast95: 'pass',
    graySteps: 'pass',
    alphanumerics: 'pass',
    highPatterns: 'pass',
    lowPatterns: 'pass',
    noArtifacts: 'pass',
    window: '',
    level: '',
    artifacts: '',
    outFields: [],
    initials,
    enteredById: currUser._id,
    enteredDate,
  };

  if (qcEntry && qcEntry.monthlyEntry) {
    initialValues = qcEntry.monthlyEntry;
    initialValues.outFields = qcEntry.outFields;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        tableHeight: enumNaPassFailValidation,
        tablePosition: enumNaPassFailValidation,
        angulation: enumNaPassFailValidation,
        laserLocLight: enumNaPassFailValidation,
        cables: enumNaPassFailValidation,
        smoothness: enumNaPassFailValidation,
        gantryXray: enumNaPassFailValidation,
        exposureSwitch: enumNaPassFailValidation,
        displayWindow: enumNaPassFailValidation,
        panelSwitches: enumNaPassFailValidation,
        consoleXray: enumNaPassFailValidation,
        doorInterlocks: enumNaPassFailValidation,
        warningLabels: enumNaPassFailValidation,
        intercom: enumNaPassFailValidation,
        postings: enumNaPassFailValidation,
        records: enumNaPassFailValidation,
        contrast0: enumNaPassFailValidation,
        contrast95: enumNaPassFailValidation,
        graySteps: enumNaPassFailValidation,
        alphanumerics: enumNaPassFailValidation,
        highPatterns: enumNaPassFailValidation,
        lowPatterns: enumNaPassFailValidation,
        noArtifacts: enumNaPassFailValidation,
        window: Yup.number().required('Required'),
        level: Yup.number().required('Required'),
        artifacts: Yup.bool().required('Required'),
        initials: Yup.string().required('Required'),
        enteredById: Yup.string().required('Required'),
        enteredDate: Yup.date().required('Required'),
        outFields: Yup.array(), // Not part of submitted monthly entry
      })}
      onSubmit={async (values) => {
        const { outFields, enteredById } = values;
        const monthlyEntry = Object.assign(values);
        delete monthlyEntry.outFields;
        const entryData = {
          monthlyEntry,
          outFields,
          initials: values.initials,
          enteredById,
        };
        updateQcEntry('monthlyEntry', entryData);
        hideModal();
        updateDevices();
      }}
    >
      <Form className={classes.form}>
        <QcMonthlyTable disableMonthlyQc={disableMonthlyQc} />
      </Form>
    </Formik>
  );
}

QcDashboard.propTypes = {
  qcEntry: PropTypes.objectOf(PropTypes.any),
  disableMonthlyQc: PropTypes.bool,
  updateQcEntry: PropTypes.func.isRequired,
};

QcDashboard.defaultProps = {
  qcEntry: null,
  disableMonthlyQc: true,
};

export default withStyles(styles)(QcDashboard);
