import React from 'react';
import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import { TableCell, Table, TableBody, TableRow, Input, FormControl, MenuItem, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import UndoIcon from '@mui/icons-material/Undo';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { mamChecklistFields } from './mamChecklistTable.util';
import { RadSelect } from 'presentational/Inputs';

const styles = (theme) => ({
  formControl: {
    margin: 0,
  },
  select: {
    fontSize: '14px',
  },
  entryTableCell: {
    padding: 0,
  },
  entryFormTable: {
    width: '90px',
  },
  menuItem: {
    fontSize: '12px',
  },
  buttonCell: {
    height: '32px',
  },
  cancelIcon: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
  },
  tableRoot: {
    padding: 0,
    border: 'none',
  },
  tableRowRoot: {
    height: '32px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: '0.1rem solid #0d1a2908',
    fontSize: '0.75rem',
  },
  initialTableCell: {
    paddingLeft: '1rem',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
  },
  dateCell: {
    border: 0,
    padding: 0,
    fontSize: '0.75rem',
  },
  MuiSelect: {
    fontSize: '0.75rem',
  },
  initialCellRoot: {
    borderBottom: 'none',
  },
  buttonCellRoot: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: '0.1px solid #6767670f',
  },
  noPadding: {
    padding: 0,
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '32px',
    fontSize: '0.75rem',
  },
  table: {
    width: '90px',
  },
});

function VcEntryForm(props) {
  const { classes, entryData, click } = props;

  const entryIndex = entryData._id || 'new';

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <Button onClick={click}>
        <UndoIcon className={classes.cancelIcon} />
      </Button>
    );
  }

  return (
    <TableCell classes={{ root: classes.noPadding }}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>
              {formatDate(entryData.date)}
              <Input type="hidden" name={`entries.${entryIndex}.date`} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>{entryData.initials}</TableCell>
          </TableRow>
          {mamChecklistFields.map((field) => (
            <TableRow key={field}>
              <TableCell className={classes.tableCellStyle}>
                <FormControl className={classes.formControl}>
                  <RadSelect
                    name={`entries.${entryIndex}.${field}`}
                    className={classes.select}
                    classes={{ select: classes.MuiSelect }}
                  >
                    <MenuItem className={classes.menuItem} value="n/a">
                      N/A
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value="pass">
                      P
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value="fail">
                      F
                    </MenuItem>
                  </RadSelect>
                  <ErrorMessage name={field} />
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
          {click && (
            <TableRow>
              <TableCell className={classes.tableCellStyle}>{cancelButton}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableCell>
  );
}

VcEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

VcEntryForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(VcEntryForm);
