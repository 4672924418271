export const enumUserRoles = {
  god: 'God',
  admin: 'Administrator',
  admin_phys: 'Administrative Phys.',
  physicist: 'Physicist',
  lead_tech: 'Lead Technologist',
  technologist: 'Technologist',
  mam_tech: 'Weekly MAM Tech',
  engineer: 'Service Engineer',
  observer: 'Observer',
};

export const enumDegrees = ['N/A', 'BS', 'MS', 'PhD', 'MD', 'MBA', 'MHA'];

export const enumCertifications = ['N/A', 'DABR', 'AART'];
