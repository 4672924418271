import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import commonPreviewStyles from './commonStyles';
import { formatDateShort, getCurrentYear } from 'utils/time.util';

// Create styles
const styles = StyleSheet.create({
  ...commonPreviewStyles,
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  formTitle: {
    ...commonPreviewStyles.formTitle,
    paddingLeft: 50,
    paddingRightt: 50,
  },
});

function SnrCnrPdfPreview(props) {
  const { records, device, addProps } = props;
  const { chartImages } = addProps;

  if (!chartImages) {
    return <Document />;
  }

  return (
    <Document>
      {records &&
        records.map((record) => (
          <Page key={record.entries[0]._id} style={styles.body} orientation="portrait">
            <View fixed style={styles.footer}>
              <Text
                style={styles.header}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              <Text fixed style={styles.header}>
                Provided By Radiology QC Solutions
              </Text>
            </View>

            <Text style={styles.formTitle}>
              Signal-To-Noise Ratio (SNR) & Contrast-To-Noise Ratio (CNR) Control Chart
            </Text>

            <View key={record.entries[0]._id} style={styles.scannerInfoView}>
              <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
              <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
            </View>

            <View style={styles.entryTable}>
              <View style={styles.subContainerRight}>
                <Text style={styles.entryHeader}>Date</Text>
                <Text style={styles.entryHeader}>Initials</Text>
                <Text style={styles.entryHeader}>kVp</Text>
                <Text style={styles.entryHeader}>mAs</Text>
                <Text style={styles.entryHeader}>El</Text>
                <Text style={styles.entryHeader}>SNR</Text>
                <Text style={styles.entryHeader}>CNR</Text>
                <Text style={styles.entryHeader}>CNR Base</Text>
              </View>
              {record.entries.map((entry) => (
                <View key={entry._id} style={styles.subContainerRight}>
                  <Text style={styles.entry}>{formatDateShort(entry.date)}</Text>
                  <Text style={styles.entry}>{entry.initials}</Text>
                  <Text style={styles.entry}>{entry.cnrKvp}</Text>
                  <Text style={styles.entry}>{entry.cnrMas}</Text>
                  <Text style={styles.entry}>{entry.cnrEl}</Text>
                  <Text style={styles.entry}>{entry.snr}</Text>
                  <Text style={styles.entry}>{entry.cnr}</Text>
                  <Text style={styles.entry}>{entry.cnrBaseline}</Text>
                </View>
              ))}
            </View>

            <Text style={styles.chartTitle}>Summary Charts (See Below).</Text>
            <View style={styles.chartSections}>
              {chartImages.snr && <Image style={styles.charts} src={chartImages.snr} />}
            </View>
            <View style={styles.chartSections}>
              {chartImages.cnrPercentDiff && <Image style={styles.charts} src={chartImages.cnrPercentDiff} />}
            </View>

            <View style={styles.remarksHeaderView}>
              <Text style={styles.remarksTableShort}>Date</Text>
              <Text style={styles.remarksTableShort}>Initials</Text>
              <Text style={styles.remarks}>Actions</Text>
            </View>

            {record.entries.map(
              (entry) =>
                entry.snrCnrRemarks.length > 0 && (
                  <View key={entry._id} style={styles.remarksView}>
                    <Text style={styles.remarksTableShort}>{formatDateShort(entry.date)}</Text>
                    <Text style={styles.remarksTableShort}>{entry.initials}</Text>
                    <Text style={styles.remarks}>{entry.snrCnrRemarks}</Text>
                  </View>
                ),
            )}

            <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
              <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
            </View>
          </Page>
        ))}
    </Document>
  );
}

SnrCnrPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
  addProps: PropTypes.objectOf(PropTypes.any),
};

SnrCnrPdfPreview.defaultProps = {
  records: [],
  device: null,
  addProps: {},
};

export default SnrCnrPdfPreview;
