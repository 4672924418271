import React from 'react';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { getLatestActionForType } from 'utils/device.util';
import { deviceTypes } from 'types/device';

const styles = (theme) => ({
  rowHeader: {
    color: '#5e5d5d',
    fontSize: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '250px',
  },
});

function deviceStatus(props) {
  const { classes, device } = props;

  const lastAction = getLatestActionForType(device);
  const lastReview = getLatestActionForType(device, 'review');

  return (
    <Box sx={{ marginRight: '16px' }}>
      <Box className={classes.row}>
        <Box className={classes.rowHeader}>{`Last ${device.type === deviceTypes.MRI ? 'QA' : 'QC'} on:`}</Box>
        <Typography fontSize={12}>{formatDate(lastAction ? lastAction.date : null)}</Typography>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Performed by:</Box>
        <Typography fontSize={12}>{lastAction ? lastAction.entityName : null}</Typography>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Last reviewed on:</Box>
        <Typography fontSize={12}>{formatDate(lastReview ? lastReview.date : null)}</Typography>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Reviewer:</Box>
        <Typography fontSize={12}>{lastReview ? lastReview.entityName : null}</Typography>
      </Box>
    </Box>
  );
}

deviceStatus.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(deviceStatus);
