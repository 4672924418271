import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import VcEntryForm from './VcEntryForm';
import VcEntryDisplay from './VcEntryDisplay';

function VcToggleableEntry(props) {
  const { entryData, mode } = props;
  const { values, setFieldValue } = useFormikContext();
  const [isToggled, setToggled] = useState(false);

  let EntryComponent = VcEntryDisplay;

  const handleToggle = () => {
    const updatedEntries = Object.assign(values.entries);
    if (!isToggled) {
      updatedEntries[entryData._id] = cloneDeep(entryData);
    } else {
      delete updatedEntries[entryData._id];
    }
    setFieldValue('entries', updatedEntries);
    setToggled(!isToggled);
  };

  const canToggle = isToggled || mode === 'edit';
  const toggleHandler = canToggle ? handleToggle : null;

  if (isToggled) {
    EntryComponent = VcEntryForm;
  }

  return <EntryComponent entryData={entryData} click={toggleHandler} />;
}

VcToggleableEntry.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
};

export default VcToggleableEntry;
