import React from 'react';
import { CurrUserCtxProvider } from './CurrUserContext';
import { DeviceCtxProvider } from './DeviceContext';
import { NotifCtxProvider } from './NotifContext';
import { OrganizationCtxProvider } from './OrganizationContext';
import { UserCtxProvider } from './UserContext';

function AuthedAppProviders({ children }) {
  return (
    <CurrUserCtxProvider>
      <OrganizationCtxProvider>
        <UserCtxProvider>
          <DeviceCtxProvider>
            <NotifCtxProvider>{children}</NotifCtxProvider>
          </DeviceCtxProvider>
        </UserCtxProvider>
      </OrganizationCtxProvider>
    </CurrUserCtxProvider>
  );
}

export default AuthedAppProviders;
