import React from 'react';
import clsx from 'clsx';
import { Checkbox, TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { trueFalseEmpty } from 'utils/entry.util';

const styles = (theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}1a`,
      cursor: 'pointer',
      maxWidth: '100%',
    },
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '50px',
  },
  checkboxCol: {
    width: '40px',
  },
  extendedCol: {
    width: '80px',
  },
  outFieldValues: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
  },
});

function QcDailyEntryDisplay(props) {
  const { entryData, click, classes } = props;

  // TODO: Findout why the checkboxes below need the '!!'

  return (
    <TableRow onClick={click} className={classes.tableRow}>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>{entryData.day}</TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>
        <Checkbox checked={!!entryData.warmUp} disabled />
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>
        <Checkbox checked={!!entryData.airCals} disabled />
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>{entryData.mode}</TableCell>
      <TableCell
        className={clsx(
          classes.tableCellStyle,
          classes.extendedCol,
          entryData.outFields && entryData.outFields.includes('water') ? classes.outFieldValues : null,
        )}
      >
        {entryData.water ? entryData.water.value : null}
      </TableCell>
      <TableCell
        className={clsx(
          classes.tableCellStyle,
          classes.extendedCol,
          entryData.outFields && entryData.outFields.includes('noise') ? classes.outFieldValues : null,
        )}
      >
        {entryData.noise ? entryData.noise.value : null}
      </TableCell>
      <TableCell
        className={clsx(
          classes.tableCellStyle,
          classes.extendedCol,
          entryData.outFields && entryData.outFields.includes('artifacts') ? classes.outFieldValues : null,
        )}
      >
        {trueFalseEmpty(entryData.artifacts, 'Yes', 'No')}
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)}>
        {trueFalseEmpty(entryData.outFields ? entryData.outFields.length === 0 : null, 'Pass', 'Fail')}
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)}>{entryData.initials}</TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)} />
    </TableRow>
  );
}

QcDailyEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QcDailyEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(QcDailyEntryDisplay);
