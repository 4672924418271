import React from 'react';
import { Typography, Link } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  footerWords: {
    margin: '16px 0',
    color: 'white',
    '@media (max-width: 450px)': {
      fontSize: '12px',
    },
  },
});

const footer = (props) => {
  const { classes } = props;
  return (
    <Typography variant="body2" color="textSecondary" align="center" className={classes.footerWords}>
      {'Copyright © '}
      <Link color="inherit" href={process.env.REACT_APP_BASE_URL}>
        Radiology QC Solutions
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

export default withStyles(styles)(footer);
