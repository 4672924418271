// landing - home section
export const homeOne = {
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  headline: 'Manage your equipment QC from one central source',
  description: 'Set up, store, and maintain your instruments from anywhere with just a few clicks.',
  buttonLabel: 'Get Started',
  imgStart: 'start',
  buttonIs: true,
  alt: 'Credit Card',
};

// landing - feature sections

export const featureOne = {
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Features',
  headline: 'Dashboards for quick status at a glance',
  description:
    'In just minutes, you can see the records and status of the most recent QA/QC entries for your instruments. ' +
    'You can then quickly decide what actions are needed to help your team.',
  imgStart: 'start',
  img: 'images/svg-7.svg',
  alt: 'Vault',
};

export const featureTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Features',
  headline: 'Instant Notifications',
  description:
    'Having up-to-date records is the foundation for any quality assurance. Catching any abnormalities in your ' +
    'system right away helps you maintain healthy instruments and prevent major issues.',
  imgStart: '',
  img: 'images/svg-1.svg',
  alt: 'Credit Card',
};

export const featureThree = {
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Features',
  headline: 'Data Visualization for Long Term Trends',
  description:
    'You can see long-term trends of your current instruments via data visualization. These charts will help you ' +
    'determine overall performance of your instruments and make data-driven decisions.',
  imgStart: 'start',
  img: 'images/svg-7.svg',
  alt: 'Vault',
};

// landing - carousel items
export const Items = [
  {
    name: 'Customize and Export',
    type: 'export',
    description:
      'Generate PDF records for your own records or inspections. Your entire history of QA is at your fingertips.',
  },
  {
    name: 'Remote Oversight',
    type: 'oversight',
    description:
      'No more waiting an entire year to have physicists look at your QA/QC program. You can easily implement a ' +
      'quarterly QA cycle for your medical physicists.',
  },
  {
    name: 'Role Permissions',
    type: 'roles',
    description: 'Have staff access only pertinent devices and functions for easy accessilbility.',
  },
  {
    name: 'Dynamic Action Limits',
    type: 'limits',
    description: 'Our system will automatically adjust the lower and top end limits as new data is entered.',
  },
];
