import { deviceTypes, idFields, idTitles } from 'types/device';

export const getDevicesForType = (devices, type) => devices.filter((device) => device.type === type);

export const getIdFieldAndTitleForType = (type) => {
  const idField = idFields[type?.toUpperCase()];
  const idTitle = idTitles[type?.toUpperCase()];

  return [idField, idTitle];
};

export const getDeviceStatus = (device, programmatic) => {
  let status = programmatic ? 'ok' : 'OK';

  if (device.outFields.length > 0) {
    status = programmatic ? 'outOfSpec' : 'Out of Spec';
  } else if (device.pastDueEntryTypes.length === 1 && device.pastDueEntryTypes[0] === 'review') {
    status = programmatic ? 'pastDueReview' : 'Past Due Review';
  } else if (device.pastDueEntryTypes.length > 0) {
    status = programmatic ? 'pastDueQa' : 'Past Due QA';
  }

  return status;
};

export const getDeviceIdFromUrl = (url) => {
  const urlMatches = url.match(/devices\/(.*)/);
  let deviceId = urlMatches.length > 0 ? urlMatches[1] : null;
  [deviceId] = deviceId.split('/');
  return deviceId;
};

export const getNoiseLimitsForCt = (device) => {
  if (device.type !== deviceTypes.CT) return null;

  // Calculate axial and helical limits
  const axialLimit = device.baseline.axialAvg * (1 + device.noiseRange / 100);
  const helicalLimit = device.baseline.helicalAvg * (1 + device.noiseRange / 100);

  return [axialLimit, helicalLimit];
};

export const deviceCompare = (deviceA, deviceB) => {
  const facilityA = deviceA.facilityName ? deviceA?.facilityName?.toUpperCase() : '';
  const facilityB = deviceB.facilityName ? deviceB?.facilityName?.toUpperCase() : '';
  const nameA = deviceA?.name?.toUpperCase();
  const nameB = deviceB?.name?.toUpperCase();
  if (facilityA < facilityB) {
    return -1;
  }
  if (facilityA > facilityB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const getLatestActionForType = (device, type = 'any') => {
  if (device.actions.length === 0) {
    return {
      date: null,
      entityId: null,
      entityName: null,
    };
  }
  if (type === 'review') {
    const reviews = device.actions.filter((action) => action.type === 'REVIEW');
    return reviews[0];
  }
  const actions = device.actions.filter((action) => action.type !== 'REVIEW');
  return actions[0];
};
