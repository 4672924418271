import React from 'react';
import {
  Tooltip,
  Badge,
  Box,
  styled,
  Divider,
  ListItemButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useModalCtx } from 'context/ModalContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useNotifCtx } from 'context/NotifContext';
import { useOrganizationCtx } from 'context/OrganizationContext';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useCurrUserCtx } from 'context/CurrUserContext';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ScannerIcon from '@mui/icons-material/Scanner';
import { Link } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ModifyOrganizations from '../../Organizations/ModifyOrganizations';

const RightNavbarContainer = styled(Box)(() => ({}));

function RightNavbar({ open }: any) {
  const { showModal } = useModalCtx();
  const { logout } = useAuth0();
  const { unreadNotifCount, setOpenNotifSlideout } = useNotifCtx() as any;
  const { setOpenAccountSetting, setOpenDeviceSetting } = useCurrUserCtx() as any;
  const { selectedOrg, setSelectedOrg } = useOrganizationCtx();

  const setAllFacilities = () => {
    if (selectedOrg.type) {
      setSelectedOrg({ id: 'all', name: 'all', type: null });
    }
  };

  const menuActions = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      link: '/devices/overview',
      onClick: () => setAllFacilities(),
    },
    {
      label: 'Devices',
      icon: <ScannerIcon />,
      link: '/devices/overview',
    },
    {
      label: 'Users',
      icon: <PeopleIcon />,
      link: '/users/overview',
    },
  ];

  const actionItems = [
    {
      label: 'Notifications',
      icon: (
        <Badge badgeContent={unreadNotifCount()} color="secondary">
          <NotificationsActiveIcon />
        </Badge>
      ),
      onClick: () => setOpenNotifSlideout(true),
    },
    {
      label: 'Account Setting',
      disabled: true,
      icon: <ManageAccountsIcon />,
      onClick: () => setOpenAccountSetting(true),
    },
    {
      label: 'Add Group',
      icon: <GroupAddIcon />,
      onClick: () => showModal(ModifyOrganizations, { isUpdate: false, isGroupType: true }),
    },
    {
      label: 'Add Facility',
      icon: <DomainAddIcon />,
      onClick: () => showModal(ModifyOrganizations, { isUpdate: false, isGroupType: false }),
    },
    {
      label: 'Settings',
      icon: <SettingsIcon />,
      onClick: () => setOpenDeviceSetting(true),
    },
  ];

  const footerMenuItems = [
    {
      label: 'Get Support',
      disabled: true,
      icon: <HelpOutlineOutlinedIcon />,
      onClick: () => logout({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } }),
    },
    {
      label: 'Log Out',
      icon: <LogoutIcon />,
      onClick: () => logout({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } }),
    },
  ];

  return (
    <RightNavbarContainer>
      <List dense>
        {menuActions.map((action) => (
          <ListItem dense key={action?.label} disablePadding sx={{ display: 'block' }}>
            <Link key="policies" to={action?.link} style={{ textDecoration: 'none', color: '#000' }}>
              <Tooltip title={action?.label} disableHoverListener={open} placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={action.onClick}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: '14px',
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {action?.icon}
                  </ListItemIcon>
                  <ListItemText primary={action.label} sx={{ opacity: open ? 1 : 0, fontSize: 14 }} disableTypography />
                </ListItemButton>
              </Tooltip>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {actionItems.map((text) => (
          <ListItem dense key={text?.label} disablePadding sx={{ display: 'block' }}>
            <Tooltip title={text?.label} disableHoverListener={open} placement="right">
              <ListItemButton
                disabled={text?.disabled}
                onClick={text?.onClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text?.icon}
                </ListItemIcon>
                <ListItemText primary={text?.label} disableTypography sx={{ opacity: open ? 1 : 0, fontSize: 14 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {footerMenuItems.map((text) => (
          <ListItem dense key={text?.label} disablePadding sx={{ display: 'block' }}>
            <Tooltip title={text?.label} disableHoverListener={open} placement="right">
              <ListItemButton
                disabled={text?.disabled}
                onClick={text?.onClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text?.icon}
                </ListItemIcon>
                <ListItemText disableTypography primary={text?.label} sx={{ opacity: open ? 1 : 0, fontSize: 14 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </RightNavbarContainer>
  );
}

export default RightNavbar;
