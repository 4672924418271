//@ts-ignore
//@ts-nocheck

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Typography, FormControl, Button, MenuItem, Paper, FormLabel } from '@mui/material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import radqcApi from 'dataExchange/radqcApiClient';
import { RadSelect, RadTextInput } from 'presentational/Inputs';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { enumUserRoles, enumDegrees, enumCertifications } from 'types/user';
import { useUserCtx } from 'context/UserContext';
import { getUserName } from 'utils/user.util';

function ModifyUserModal({ selectedUser, setEditModal, openEditModal }: any) {
  const { getAccessTokenSilently } = useAuth0();
  const { currUser } = useCurrUserCtx();
  const { users, updateUsers } = useUserCtx();
  const { enqueueSnackbar } = useSnackbar();

  // If selectedUser exists, a user is being updated, otherwise a new user is being created
  const isUpdate = !!selectedUser;
  // Only limit emails when adding a new user
  const takenEmails = isUpdate ? [] : users.map((user) => user.email);
  const action = isUpdate ? 'Update' : 'Add';
  const initialValues = selectedUser || {
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    degree: 'N/A',
    certification: 'N/A',
  };

  return (
    <Dialog onClose={() => setEditModal(false)} open={openEditModal}>
      <Paper sx={{ padding: 4, width: 400 }}>
        <Box component="div">
          <Typography sx={{ fontSize: 20 }}>{action} User</Typography>
          <Typography sx={{ color: 'grey', fontSize: 12 }}>Note that some fields are required</Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            role: Yup.string().oneOf(Object.keys(enumUserRoles), 'Invalid user role').required('Required'),
            firstName: Yup.string()
              .min(2, 'Must be at least 2 characters')
              .max(50, 'Must be 50 characters or less')
              .required('Required'),
            lastName: Yup.string()
              .min(2, 'Must be at least 2 characters')
              .max(50, 'Must be 50 characters or less')
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .min(5, 'Must be at least 5 characters')
              .max(255, 'Must be 255 characters or less')
              .notOneOf(takenEmails, 'This email is already in use')
              .required('Required'),
            degree: Yup.string().oneOf(enumDegrees, 'Invalid degree option'),
            certification: Yup.string().oneOf(enumCertifications, 'Invalid certification option'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            let newUser = {
              role: values?.role,
              firstName: values?.firstName,
              lastName: values?.lastName,
              email: values?.email.toLowerCase(),
              degree: values?.degree,
              certification: values?.certification,
            };

            //Submit user data to DB
            const accessToken = await getAccessTokenSilently();
            if (action.toLowerCase() === 'update') {
              newUser = await radqcApi('put', `/users/${selectedUser._id}`, newUser, { accessToken });
              enqueueSnackbar(`Updated user ${getUserName(selectedUser)}`);
            } else {
              newUser.accounts = [currUser.selectedAccount];
              newUser.selectedAccount = currUser.selectedAccount;
              newUser = await radqcApi('post', '/users', newUser, { accessToken });
              enqueueSnackbar(`Created new user: ${getUserName(newUser)}`);
            }
            updateUsers();
            setSubmitting(false);
            setEditModal(false);
          }}
        >
          <Form>
            <Box component="div" sx={{ display: 'flex', marginTop: 2 }}>
              <FormControl>
                <FormLabel sx={{ fontSize: 12 }}>First Name*</FormLabel>
                <RadTextInput placeholder="i.e. John" name="firstName" type="text" />
              </FormControl>
              <FormControl sx={{ marginLeft: '16px' }}>
                <FormLabel sx={{ fontSize: 12 }}>Last Name*</FormLabel>
                <RadTextInput placeholder="i.e. Doe" name="lastName" type="text" />
              </FormControl>
            </Box>

            <Box component="div">
              <FormControl
                sx={{ width: 240, marginTop: '12px' }}
                disabled={selectedUser && selectedUser._id === currUser._id}
              >
                <FormLabel sx={{ fontSize: 12 }}>User Role</FormLabel>
                <RadSelect name="role">
                  {Object.keys(enumUserRoles).map((role: any) => (
                    <MenuItem key={role} value={role}>
                      {enumUserRoles[role]}
                    </MenuItem>
                  ))}
                </RadSelect>
              </FormControl>
            </Box>

            <FormControl sx={{ display: 'flex', marginTop: 2 }}>
              <FormLabel sx={{ fontSize: 12 }}>Email</FormLabel>
              <RadTextInput
                placeholder="i.e. support@radqcsolutions.com"
                name="email"
                type="email"
                disabled={isUpdate}
              />
            </FormControl>

            <Box component="div" sx={{ marginTop: 2 }}>
              <FormControl>
                <FormLabel sx={{ fontSize: 12 }}>Degree</FormLabel>
                <RadSelect name="degree">
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="BS">BS</MenuItem>
                  <MenuItem value="MS">MS</MenuItem>
                  <MenuItem value="PhD">PhD</MenuItem>
                  <MenuItem value="MD">MD</MenuItem>
                  <MenuItem value="MBA">MBA</MenuItem>
                  <MenuItem value="MHA">MHA</MenuItem>
                </RadSelect>
              </FormControl>
              <FormControl sx={{ marginLeft: '16px' }}>
                <FormLabel sx={{ fontSize: 12 }}>Certification</FormLabel>
                <RadSelect name="certification">
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="DABR">DABR</MenuItem>
                  <MenuItem value="AART">AART</MenuItem>
                </RadSelect>
              </FormControl>
            </Box>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
              <FormControl>
                <Button color="secondary" sx={{ marginRight: '8px' }} onClick={() => setEditModal(false)}>
                  Cancel
                </Button>
              </FormControl>
              <FormControl>
                <Button variant="outlined" color="primary" type="submit">
                  {action}
                </Button>
              </FormControl>
            </Box>
          </Form>
        </Formik>
      </Paper>
    </Dialog>
  );
}

export default ModifyUserModal;
