import React, { useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import RouteWithLayout from 'components/RouteWithLayout';
import AuthorizedLayout from 'components/Layouts/Authorized/AuthorizedLayout';
import DeviceRoutes from './DeviceRoutes';
import UserRoutes from './UserRoutes';
import OpenApiUi from 'openApi/OpenApiUi';

function AuthenticatedRoutes() {
  const initialUrls = {
    devices: '/devices/overview',
    users: '/users/overview',
  };
  const [currentUrls] = useState(initialUrls);
  const location = useLocation();

  /* The following saves the current pathname as the last pathname for devices or users if it is
     is not the base url and does not include 'addnew' */
  if (
    location.pathname !== '/devices' &&
    location.pathname.includes('/devices') &&
    !location.pathname.endsWith('/addnew')
  ) {
    currentUrls.devices = location.pathname;
  } else if (
    location.pathname !== '/users' &&
    location.pathname.includes('/users') &&
    !location.pathname.endsWith('/addnew')
  ) {
    currentUrls.users = location.pathname;
  }

  return (
    <Switch>
      <Route path="/devices" exact render={() => <Redirect exact from="/devices" to={currentUrls.devices} />} />
      <Route
        path="/devices"
        render={(props) => (
          <RouteWithLayout path={props.location.pathname} layout={AuthorizedLayout} component={DeviceRoutes} />
        )}
      />
      <Route path="/users" exact render={() => <Redirect exact from="/users" to={currentUrls.users} />} />
      <Route
        path="/users"
        render={(props) => (
          <RouteWithLayout path={props.location.pathname} layout={AuthorizedLayout} component={UserRoutes} />
        )}
      />

      <Route path="/openapi" component={OpenApiUi} />
      <Redirect from="/" to={currentUrls.devices} />
    </Switch>
  );
}

export default AuthenticatedRoutes;
