import * as Yup from 'yup';
import { getUserInitials } from 'utils/user.util';

export const generateEmptyMamWeeklyEntry = (device, date, currUser) => ({
  deviceId: device._id,
  date: date.start.toISOString(),
  checkPerformed: false,
  artEvalPerformed: false,
  snrCnrPerformed: false,
  phantom2Performed: false,
  phantom3Performed: false,
  compThickPerformed: false,
  viewBoxRemarks: '',
  flatFieldRemarks: '',
  rhKvp: '',
  agKvp: '',
  alKvp: '',
  rhMas: '',
  agMas: '',
  alMas: '',
  rhArtifacts: '',
  agArtifacts: '',
  alArtifacts: '',
  artEvalRemarks: '',
  cnrKvp: '',
  cnrMas: '',
  cnrEl: '',
  snr: 40,
  cnr: '',
  cnrBaseline: device.cnrBaseline,
  snrCnrRemarks: '',
  phantom2Kvp: '',
  phantom2Mas: '',
  phantom2El: '',
  phantom2Fibers: 6.0,
  phantom2Specks: 6.0,
  phantom2Masses: 6.0,
  phantom2Remarks: '',
  phantom3Kvp: '',
  phantom3Mas: '',
  phantom3El: '',
  phantom3Fibers: 6.0,
  phantom3Specks: 6.0,
  phantom3Masses: 6.0,
  phantom3Remarks: '',
  phantomSerialNumber: device.phantomSerialNumber,
  compThickness: '',
  compRemarks: '',
  outFields: [],
  initials: getUserInitials(currUser),
  enteredById: currUser._id,
  _id: 'new',
});

export const kvpValidation = Yup.number().min(24).max(40).required('Required');
export const masValidation = Yup.number().min(20).max(150).required('Required');
export const mas3dValidation = Yup.number().min(20).max(150).required('Required');
export const posNumValidation = Yup.number().min(0).required('Required');

export const artifactEvalFields = [
  'rhKvp',
  'rhMas',
  'rhArtifacts',
  'agKvp',
  'agMas',
  'agArtifacts',
  'alKvp',
  'alMas',
  'alArtifacts',
  'artEvalRemarks',
  'outFields',
];

export const snrCnrFields = ['cnrKvp', 'cnrMas', 'cnrEl', 'snr', 'cnr', 'outFields'];

export const phantomFields = (is3D) => {
  const dim = is3D ? '3' : '2';
  return [
    `phantom${dim}Kvp`,
    `phantom${dim}Mas`,
    `phantom${dim}El`,
    `phantom${dim}Fibers`,
    `phantom${dim}Specks`,
    `phantom${dim}Masses`,
    `phantom${dim}Remarks`,
    'outFields',
  ];
};

export const entryTypes = {
  KVP: 'kvp',
  MAS: 'mas',
  MAS3: 'mas3d',
  ARTIFACTS: 'artifacts',
  POS_NUM: 'pos_num',
  SNR: 'snr',
  COMP: 'comp',
};

export const phantomScores = [3, 3.5, 4, 4.5, 5, 5.5, 6];

export const getChartEntries = (mamEntries, date, activeEntries) => {
  if (activeEntries.new) {
    return [...mamEntries.leading, { date, ...activeEntries.new }, ...mamEntries.trailing];
  }
  return [...mamEntries.leading, ...mamEntries.trailing];
};

// This performs some operation on each data point based on a passed in modify function
export const getModifiedEntries = (entries, field, newField, modifyFunc) => {
  const moddedEntries = entries.reduce((result, entry) => {
    if (entry[field]) {
      const moddedField = modifyFunc(entry);
      const newEntry = {
        ...entry,
        [newField]: moddedField,
      };
      result.push(newEntry);
    }
    return result;
  }, []);
  return moddedEntries;
};

export const mamWeeklyTasks = {
  VIEW_BOX: 'viewbox',
  FLAT_FIELD: 'flatfield',
  ART_EVAL: 'arteval',
  PHANTOM2: 'phantom2',
  PHANTOM3: 'phantom3',
  SNR_CNR: 'snrcnr',
  COMPRESSION: 'compression',
};

export const mamWeeklyTaskTitles = {
  VIEW_BOX: 'View Box',
  ART_EVAL: 'Artifact Eval',
  SNR_CNR: 'SNR CNR',
  PHANTOM2: 'Phantom 2D',
  PHANTOM3: 'Phantom 3D',
  FLAT_FIELD: 'Flat Field',
  COMPRESSION: 'Compression',
};

export const mamLongTermEntryTypes = {
  MAM_MT: 'MAM_MT',
  MAM_QU: 'MAM_QU',
  MAM_CF: 'MAM_CF',
  MAM_GC: 'MAM_GC',
};

export const phantomLimits = (is3D) => {
  const dim = is3D ? '3' : '2';
  return {
    [`phantom${dim}Fibers`]: is3D ? 4 : 5,
    [`phantom${dim}Specks`]: is3D ? 3 : 4,
    [`phantom${dim}Masses`]: is3D ? 3 : 4,
  };
};

export const displayValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
};
