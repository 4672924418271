import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { formatDate } from 'utils/time.util';
import { getUserName } from 'utils/user.util';

const styles = (theme) => ({
  summaryContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: 0,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  entryButton: {
    margin: '10px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
  },
  outlined: {
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  subSummary: {
    color: '#A9A6A6',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  summaryReviewButton: {
    display: 'flex',
  },
});

function QcReview(props) {
  const { qcEntry, updateQcEntry, mode, classes } = props;
  const { currUser } = useCurrUserCtx();

  let reviewSummary = <div className={classes.subSummary}>Not reviewed</div>;
  let reviewButton = null;

  if (qcEntry && qcEntry?.reviewedById) {
    reviewSummary = (
      <div className={classes.subSummary}>
        <div>Date: {formatDate(qcEntry?.reviewedDate)}</div>
        <div>Entered By: {qcEntry?.reviewedBy}</div>
      </div>
    );
  }
  if (mode === 'review' && qcEntry?._id && !qcEntry?.reviewedById) {
    const review = {
      entryId: qcEntry._id,
      reviewedBy: getUserName(currUser),
      reviewedById: currUser._id,
      reviewedDate: new Date().toISOString(),
    };
    reviewButton = (
      <Button
        variant="outlined"
        onClick={() => updateQcEntry('review', review)}
        className={classes.entryButton}
        classes={{ outlined: classes.outlined }}
      >
        Review
      </Button>
    );
  }

  return (
    <div className={classes.summaryContainer}>
      <Typography sx={{ fontSize: 14 }}>Review</Typography>
      <Box className={classes.summaryReviewButton}>
        {reviewSummary}
        {reviewButton}
      </Box>
    </div>
  );
}

QcReview.propTypes = {
  mode: PropTypes.string.isRequired,
  qcEntry: PropTypes.objectOf(PropTypes.any),
  updateQcEntry: PropTypes.func.isRequired,
};

QcReview.defaultProps = {
  qcEntry: null,
};
export default withStyles(styles)(QcReview);
