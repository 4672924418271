import React from 'react';
import { IconButton, TableCell, TableRow, Table, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import PropTypes from 'prop-types';
import { weeklyCommonStyles, getStyleForField } from 'views/entry/mam/components/commonStyles';
import { phantomFields } from 'views/entry/mam/components/mamEntry.util';
import { useModalCtx } from 'context/ModalContext';
import { formatDate } from 'utils/time.util';
import EntryComment from 'views/entry/components/EntryComment';

const useStyles = makeStyles(weeklyCommonStyles);

function PhantomDisplay(props) {
  const { entryData, click, is3D } = props;
  const classes = useStyles();
  const { showModal, hideModal } = useModalCtx();
  const dim = is3D ? '3' : '2';
  let fields = phantomFields(is3D);
  fields = fields.filter((field) => !field.includes('Remarks') && !field.includes('outFields'));

  let editButton = null;
  if (click) {
    editButton = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableCell classes={{ root: classes.noPadding }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{entryData.initials}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{is3D ? 'Al' : 'Rh'}</TableCell>
          </TableRow>
          {fields.map((field) => (
            <TableRow key={field}>
              <TableCell classes={{ root: getStyleForField(field, entryData, classes) }}>{entryData[field]}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  showModal(EntryComment, {
                    savedComment: entryData[`phantom${dim}Remarks`],
                    hideModal,
                    title: 'Remarks',
                  });
                }}
              >
                <CommentIcon className={classes.icons} />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{editButton}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableCell>
  );
}

PhantomDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
  is3D: PropTypes.bool,
};

PhantomDisplay.defaultProps = {
  click: null,
  is3D: false,
};

export default PhantomDisplay;
