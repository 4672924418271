import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import ChartEditor from './ChartEditor';

const styles = (theme) => ({
  positionIface: {
    textAlign: 'center',
  },
  positionButton: {
    color: theme.palette.primary.main,
    marginLeft: '2rem',
    padding: '0px',
    '&:disabled': {
      color: `${theme.palette.black}1a`,
    },
    '&:hover': {
      color: theme.palette.primary.darkMain,
    },
  },
  deleteButton: {
    color: theme.palette.primary.lightsky,
    '&:hover': {
      color: theme.palette.primary.darkMain,
    },
  },
  buttonRoot: {
    fontSize: 20,
  },
  settingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

function chartEditorList(props) {
  const { savedConfigs, moveChartPosition, removeChartConfig, updateChartConfig, deviceType, baseClasses, classes } =
    props;

  const handleMoveUpClick = (index) => {
    if (index === 0) {
      return;
    }
    const newIndex = index - 1;
    moveChartPosition(index, newIndex);
  };

  const handleMoveDownClick = (index) => {
    if (index === savedConfigs.length - 1) {
      return;
    }
    const newIndex = index + 1;
    moveChartPosition(index, newIndex);
  };

  return savedConfigs.map((config, index) => (
    <Box key={index} className={baseClasses}>
      <Box className={classes.settingBox}>
        <Box className={classes.positionIface}>
          Position:
          <IconButton
            disabled={index === 0}
            onClick={() => handleMoveUpClick(index)}
            className={classes.positionButton}
          >
            <ExpandLessIcon classes={{ root: classes.buttonRoot }} />
          </IconButton>
          <IconButton
            disabled={index === savedConfigs.length - 1}
            onClick={() => handleMoveDownClick(index)}
            className={classes.positionButton}
          >
            <ExpandMoreIcon classes={{ root: classes.buttonRoot }} />
          </IconButton>
        </Box>

        <Tooltip title="Delete Chart">
          <IconButton onClick={() => removeChartConfig(index)} className={classes.deleteButton}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <ChartEditor
        key={Object.values(config).toString()}
        deviceType={deviceType}
        config={config}
        index={index}
        updateChartConfig={updateChartConfig}
      />
    </Box>
  ));
}

chartEditorList.propTypes = {
  savedConfigs: PropTypes.arrayOf(PropTypes.any).isRequired,
  moveChartPosition: PropTypes.func.isRequired,
  updateChartConfig: PropTypes.func.isRequired,
  removeChartConfig: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  baseClasses: PropTypes.string.isRequired,
};

export default withStyles(styles)(chartEditorList);
