import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import commonPreviewStyles from './commonStyles';
import { formatDateShort, getCurrentYear } from 'utils/time.util';

// Create styles
const styles = StyleSheet.create({
  ...commonPreviewStyles,
  dateInitials: {
    width: 100,
    border: 0,
  },
  subLabels: {
    display: 'flex',
    flexDirection: 'row',
  },
  viewSection: {
    width: 50,
    margin: 0,
    border: 0.25,
    borderLeft: 'none',
  },
  entryHeader2Col: {
    ...commonPreviewStyles.entryHeader,
    textAlign: 'center',
    border: 0,
    borderLeft: 0.5,
    borderTop: 0,
    borderBottom: 0.5,
    width: '100%',
  },
  entryHeaderCenter: {
    ...commonPreviewStyles.entryHeader,
    textAlign: 'center',
    border: 0,
    borderLeft: 0.25,
  },
  entryHeaderLeft: {
    ...commonPreviewStyles.entryHeader,
    border: 0,
    borderTop: 0.25,
  },
  entry: {
    ...commonPreviewStyles.entry,
    border: 0,
    borderTop: 0.25,
  },
  scannerInfoView: {
    ...commonPreviewStyles.scannerInfoView,
    marginTop: 16,
  },
  entryTable: {
    ...commonPreviewStyles.entryTable,
    marginTop: 0,
  },
});

function ArtifactEvalPdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records &&
        records.map((record) => (
          <Page key={record.entries[0]._id} style={styles.body} orientation="landscape">
            <View fixed style={styles.footer}>
              <Text
                style={styles.header}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              <Text fixed style={styles.header}>
                Provided By Radiology QC Solutions
              </Text>
            </View>

            <Text style={styles.formTitle}>Artifact Evaluation</Text>

            <View key={record.entries[0]._id} style={styles.scannerInfoView}>
              <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
              <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
            </View>

            <View style={styles.entryTable}>
              <View>
                <View style={styles.dateInitials}>
                  <Text style={styles.entryHeader2Col}>Date</Text>
                  <Text style={styles.entryHeader2Col}>Initials</Text>
                </View>

                <View style={styles.subLabels}>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter}>Rh</Text>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                  </View>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderLeft}>kVp</Text>
                    <Text style={styles.entryHeaderLeft}>mAs</Text>
                    <Text style={styles.entryHeaderLeft}>Focal Spot</Text>
                    <Text style={styles.entryHeaderLeft}>Artifacts</Text>
                    <Text style={styles.entryHeaderLeft}>Acceptable</Text>
                  </View>
                </View>

                <View style={styles.subLabels}>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter}>Ag</Text>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                  </View>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderLeft}>kVp</Text>
                    <Text style={styles.entryHeaderLeft}>mAs</Text>
                    <Text style={styles.entryHeaderLeft}>Focal Spot</Text>
                    <Text style={styles.entryHeaderLeft}>Artifacts</Text>
                    <Text style={styles.entryHeaderLeft}>Acceptable</Text>
                  </View>
                </View>

                <View style={styles.subLabels}>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter}>Al</Text>
                    <Text style={styles.entryHeaderCenter} />
                    <Text style={styles.entryHeaderCenter} />
                  </View>
                  <View style={styles.viewSection}>
                    <Text style={styles.entryHeaderLeft}>kVp</Text>
                    <Text style={styles.entryHeaderLeft}>mAs</Text>
                    <Text style={styles.entryHeaderLeft}>Focal Spot</Text>
                    <Text style={styles.entryHeaderLeft}>Artifacts</Text>
                    <Text style={styles.entryHeaderLeft}>Acceptable</Text>
                  </View>
                </View>
              </View>

              {record.entries.map((entry) => (
                <View key={entry._id} style={styles.viewSection}>
                  <Text style={styles.entry}>{formatDateShort(entry.date)}</Text>
                  <Text style={styles.entry}>{entry.initials}</Text>
                  <Text style={styles.entry}>{entry.rhKvp}</Text>
                  <Text style={styles.entry}>{entry.rhMas}</Text>
                  <Text style={styles.entry}>LFS</Text>
                  <Text style={styles.entry}>{entry.rhArtifacts ? 'Yes' : 'No'}</Text>
                  <Text style={styles.entry}>{!entry.rhArtifacts ? 'Yes' : 'No'}</Text>
                  <Text style={styles.entry}>{entry.agKvp}</Text>
                  <Text style={styles.entry}>{entry.agMas}</Text>
                  <Text style={styles.entry}>LFS</Text>
                  <Text style={styles.entry}>{entry.agArtifacts ? 'Yes' : 'No'}</Text>
                  <Text style={styles.entry}>{!entry.agArtifacts ? 'Yes' : 'No'}</Text>
                  <Text style={styles.entry}>{entry.alKvp}</Text>
                  <Text style={styles.entry}>{entry.alMas}</Text>
                  <Text style={styles.entry}>LFS</Text>
                  <Text style={styles.entry}>{entry.alArtifacts ? 'Yes' : 'No'}</Text>
                  <Text style={styles.entry}>{!entry.alArtifacts ? 'Yes' : 'No'}</Text>
                </View>
              ))}
            </View>

            <View style={styles.remarksHeaderView}>
              <Text style={styles.remarksTableShort}>Date</Text>
              <Text style={styles.remarksTableShort}>Initials</Text>
              <Text style={styles.remarks}>Actions</Text>
            </View>

            {record.entries.map(
              (entry) =>
                entry.artEvalRemarks.length > 0 && (
                  <View key={entry._id} style={styles.remarksView}>
                    <Text style={styles.remarksTableShort}>{formatDateShort(entry.date)}</Text>
                    <Text style={styles.remarksTableShort}>{entry.initials}</Text>
                    <Text style={styles.remarks}>{entry.artEvalRemarks}</Text>
                  </View>
                ),
            )}

            <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
              <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
            </View>
          </Page>
        ))}
    </Document>
  );
}

ArtifactEvalPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

ArtifactEvalPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default ArtifactEvalPdfPreview;
