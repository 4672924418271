import React from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { Box, Tab, AppBar, Tabs, Typography, Divider, Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDeviceCtx } from 'context/DeviceContext';
import DeviceOverview from './overview/DeviceOverview';
import EntryDashboard from 'views/entry/EntryDashboard';
import MetricsDashboard from 'views/metrics/dashboard/MetricsDashboard';
import DeviceUsers from './DeviceUsers';
import getTabList from './tabLists';
import { getDeviceStatus } from 'utils/device.util';
import DeviceStatus from './overview/components/DeviceStatus';
import DeviceDetails from './overview/components/DeviceDetails';

const styles = (theme) => ({
  flexContainer: {
    justifyContent: 'flex-start',
    display: 'flex',
  },
  iconInTab: {
    marginBottom: '0',
    marginRight: '10px',
  },
  link: {
    textDecoration: 'none',
  },
  tab: {
    textTransform: 'capitalize',
    padding: 0,
    color: '#000',
    '&.Mui-selected': {
      fontWeight: 600,
      color: '#000',
    },
  },
  indicator: {
    color: '#000',
    backgroundColor: '#000',
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function DeviceDashboard(props) {
  const { match, history, classes } = props;
  const { devices } = useDeviceCtx();
  const device = devices.find((a) => match.url.includes(a._id));

  if (!device) {
    return null;
  }

  const tabs = getTabList(device);

  const handleChangeTab = () => {
    history.push(history.location.pathname);
  };

  const getSanitizedHistoryPath = () => {
    const parts = history.location.pathname.split(`${match.url}/`);
    if (parts.length > 1) {
      return `${match.url}/${parts[1].split('/')[0]}`;
    }
    return history.location.pathname;
  };

  // eslint-disable-next-line no-shadow
  const setStatus = (device) => getDeviceStatus(device);
  const setColorByStatus = (status) => {
    switch (status) {
      case 'Out of Spec':
        return '#ff0000';
      case 'Past Due Review':
        return '#c6823f';
      case 'Past Due QA':
        return '#d9a937';
      case 'OK':
        return '#28b0aa';
      default:
        return '#000';
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="default"
        sx={{
          backgroundColor: '#eeebeb',
          boxShadow: 'none',
          top: 65, // Stick to the top
          zIndex: 2,
        }}
      >
        <Box component="div" padding="16px" display="flex" flexDirection="column" justifyContent="space-between">
          <Box component="div" display="flex" alignItems="center">
            <Typography textTransform="capitalize" fontSize={16} fontWeight="bold">{`${device.name}`}</Typography>
            <Box sx={{ paddingX: '8px' }}>
              <Typography display="inline" fontSize={14} textTransform="uppercase">
                <Chip
                  size="small"
                  sx={{
                    fontSize: 12,
                    borderRadius: '4px',
                    borderColor: setColorByStatus(setStatus(device)),
                    color: setColorByStatus(setStatus(device)),
                  }}
                  label={setStatus(device)}
                  variant="outlined"
                />
              </Typography>
            </Box>
            <Typography sx={{ paddingRight: '12px' }}>-</Typography>
            <Box>
              <Typography display="inline" fontSize={14} fontWeight={600}>
                Type:{' '}
              </Typography>
              <Typography display="inline" fontSize={14} textTransform="uppercase">
                {device.type}
              </Typography>
            </Box>
            <Divider orientation="vertical" sx={{ paddingX: '4px' }} />
            <Box sx={{ paddingX: '8px' }}>
              <Typography display="inline" fontSize={14} fontWeight={600}>
                Facility:{' '}
              </Typography>
              <Typography display="inline" fontSize={14} textTransform="capitalize">
                {`${device.facilityName}`}
              </Typography>
            </Box>
          </Box>

          <Box component="div" display="flex" sx={{ marginTop: '16px' }}>
            <DeviceStatus device={device} />
            <DeviceDetails device={device} />
          </Box>
        </Box>
        <Box sx={{ padding: '0 16px' }}>
          <Tabs
            value={getSanitizedHistoryPath()}
            variant="scrollable"
            scrollButtons="on"
            onChange={handleChangeTab}
            aria-label="scrollable force tabs example"
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                label={tab.label}
                value={`${match.url}${tab.linkExt}`}
                component={Link}
                to={`${match.url}${tab.linkExt}`}
                className={classes.tab}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
      </AppBar>
      <Box component="div" overflowY="auto" maxHeight="90rem">
        <Switch>
          <Route exact path={`${match.url}`} component={DeviceOverview} />
          <Route exact path={`${match.url}/qc`} render={() => <EntryDashboard entryType="qc" />} />
          <Route exact path={`${match.url}/qa`} render={() => <EntryDashboard entryType="qa" />} />
          <Route exact path={`${match.url}/visual`} render={() => <EntryDashboard entryType="vc" />} />
          <Route exact path={`${match.url}/helium`} render={() => <EntryDashboard entryType="he" />} />
          <Route exact path={`${match.url}/metrics`} component={MetricsDashboard} />
          <Route exact path={`${match.url}/users`} component={DeviceUsers} />
          <Route exact path={`${match.url}/weekly`} render={() => <EntryDashboard entryType="mam_wk" />} />
          <Route path={`${match.url}/weekly`} render={() => <EntryDashboard entryType="mam_wk" />} />
          <Route
            path={[`${match.url}/checklist/:mode`, `${match.url}/checklist`]}
            render={() => <EntryDashboard entryType="mam_mth" />}
          />
          <Route path={`${match.url}/reject`} render={() => <EntryDashboard entryType="mam_qrt" />} />
          <Route path={`${match.url}/compression`} render={() => <EntryDashboard entryType="mam_fc" />} />
          <Route path={`${match.url}/geocalib`} render={() => <EntryDashboard entryType="mam_gc" />} />
          <Route exact path={`${match.url}/rwsdaily`} render={() => <EntryDashboard entryType="rws_daily" />} />
          <Route exact path={`${match.url}/rwsweekly`} render={() => <EntryDashboard entryType="rws_weekly" />} />
        </Switch>
      </Box>
    </>
  );
}

export default withRouter(withStyles(styles)(DeviceDashboard));
