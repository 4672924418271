import React from 'react';
import PropTypes from 'prop-types';
import MamEntryChart from 'views/entry/mam/components/MamEntryChart';
import { getModifiedEntries } from 'views/entry/mam/components/mamEntry.util';

function SnrCnrPdfCharts(props) {
  const { entries, setChartImage } = props;
  const snrChartOpts = { suggestedMin: 30, suggestedMax: 70 };
  const cnrChartOpts = { suggestedMin: -20, suggestedMax: 20 };
  const newField = 'cnrPercentDiff';
  const modifyCnr = (entry) => ((entry.cnr - entry.cnrBaseline) / entry.cnrBaseline) * 100;

  const modifiedEntries = getModifiedEntries(entries, 'cnr', newField, modifyCnr);
  return (
    <div
      style={{
        width: '1000px',
      }}
    >
      <MamEntryChart entries={entries} field="snr" title="SNR" chartOpts={snrChartOpts} setChartImage={setChartImage} />

      <MamEntryChart
        entries={modifiedEntries}
        field={newField}
        title="CNR % Difference from Baseline"
        chartOpts={cnrChartOpts}
        setChartImage={setChartImage}
      />
    </div>
  );
}

SnrCnrPdfCharts.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  setChartImage: PropTypes.func.isRequired,
};

export default SnrCnrPdfCharts;
