export default {
  root: {
    minWidth: '100px',
    height: '40px',
  },
  wrapper: {
    flexDirection: 'row',
    margin: '1px',
  },
};
