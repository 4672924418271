import React, { useState } from 'react';
import { mapValues, pick, uniqBy } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import { useModalCtx } from 'context/ModalContext';
import { EntryModes } from 'types/entry';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import CompEntryDisplay from './CompEntryDisplay';
import CompEntryForm from './CompEntryForm';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import MamEntryChart from 'views/entry/mam/components/MamEntryChart';
import { mamWeeklyTaskTitles, getChartEntries, getModifiedEntries } from 'views/entry/mam/components/mamEntry.util';
import { RadTextInput } from 'presentational/Inputs';

import TaskNav from 'views/entry/mam/weekly/modify/components/TaskNav';

const useStyles = makeStyles(() => ({
  tableTitles: {
    color: 'black',
    padding: '0',
    textAlign: 'center',
    width: '30px',
    height: '50px',
  },
  remarks: {
    width: '100%',
    overflow: 'auto',
    border: '1px solid #80808026',
  },
  tableContainer: {
    display: 'flex',
    margin: '0 2rem',
    '@media (max-width: 1024px)': {
      margin: '0',
    },
  },
  stickyHeader: {
    width: '5rem',
  },
  rightTables: {
    maxWidth: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  remarkSection: {
    margin: '2rem 2rem 0 2rem',
  },
  remarkTitle: {
    marginBottom: '1rem',
  },
  chartBoxes: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
  },
  chartSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formTableWidth: {
    width: '90px',
  },
}));

const taskName = 'Compression Thickness';
const compressChartOpts = { suggestedMin: -2.0, suggestedMax: 2.0 };
const DEFAULT_COMP_THICKNESS = 4;
const newField = 'compthickDiff';

const modifyThickness = (entry) => entry.compThickness - DEFAULT_COMP_THICKNESS;

function CompressionDashboard(props) {
  const { activeEntry, mode, saveEntries, mamEntries, taskId, formDisabled } = props;
  const classes = useStyles();
  const [entryKey, setEntryKey] = useState(0);
  const { showModal } = useModalCtx();

  const initialValues = { entries: {} };
  let enterModeColumns = null;
  if (mode === EntryModes.ENTER) {
    initialValues.entries = {
      new: pick(activeEntry, [MamEntryFields.compThickness, MamEntryFields.compRemarks, 'outFields']),
    };
    enterModeColumns = (
      <>
        {mamEntries.leading.map((entry) => (
          <CompEntryDisplay key={entry._id} entryData={entry} />
        ))}
        <CompEntryForm entryData={activeEntry} formDisabled={formDisabled} />
        {mamEntries.trailing.map((entry) => (
          <CompEntryDisplay key={entry._id} entryData={entry} />
        ))}
      </>
    );
  }

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    if (mode === EntryModes.ENTER) {
      newEntries[0].date = activeEntry.date;
      newEntries[0].initials = activeEntry.initials;
    }
    showModal(EntryConfirmModal, {
      entryType: 'mam',
      confirmTitle: `Mammograph ${mamWeeklyTaskTitles.COMPRESSION}`,
      confirmEntries: newEntries,
      accept: () => submitForm(),
      fieldTitles: { [MamEntryFields.compThickness]: 'Indicated Thickness' },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                compThickness: Yup.number().min(2.0).max(6.0).required('Required'),
                compRemarks: Yup.string(),
                outFields: Yup.array(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (mode === EntryModes.ENTER) {
          newEntries[0].compThickPerformed = true;
        }
        saveEntries(newEntries);
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
      }}
    >
      {({ values, submitForm }) => {
        let allEntries = getChartEntries(mamEntries, activeEntry.date, values.entries);
        if (mode === 'edit') {
          allEntries = [
            ...mamEntries.leading,
            ...Object.values(values.entries),
            ...mamEntries.trailing,
            /* eslint-disable-next-line no-nested-ternary */
          ].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
          allEntries = uniqBy(allEntries, '_id');
        }
        return (
          <Form>
            <Box className={classes.tableContainer}>
              <Table stickyHeader className={classes.stickyHeader}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Date</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Initials</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Thickness</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Remarks</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableTitles} />
                  </TableRow>
                </TableHead>
              </Table>

              <Box className={classes.rightTables}>
                <Table>
                  <TableBody>
                    <TableRow>
                      {enterModeColumns ||
                        allEntries.map((entry) => (
                          <ToggleableEntry
                            key={entryKey + entry.date}
                            entryData={entry}
                            mode={mode}
                            entryDisplay={CompEntryDisplay}
                            entryForm={CompEntryForm}
                          />
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>

            <Box className={classes.chartSection}>
              <Box className={classes.chartBoxes}>
                <MamEntryChart
                  entries={getModifiedEntries(allEntries, 'compThickness', newField, modifyThickness)}
                  field={newField}
                  title="Indicated Thickness"
                  chartOpts={compressChartOpts}
                />
              </Box>
            </Box>

            {mode === EntryModes.ENTER && (
              <Box className={classes.remarkSection}>
                <Typography className={classes.remarkTitle} fontSize={16}>
                  Remarks
                </Typography>
                <RadTextInput
                  multiline
                  disabled={formDisabled}
                  name="entries.new.compRemarks"
                  type="text"
                  classes={{ root: classes.remarks }}
                />
              </Box>
            )}

            <TaskNav
              taskName={taskName}
              taskId={taskId}
              onComplete={() => handleSubmit(values, submitForm)}
              mode={mode}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

CompressionDashboard.propTypes = {
  activeEntry: PropTypes.objectOf(PropTypes.any).isRequired,
  saveEntries: PropTypes.func.isRequired,
  mamEntries: PropTypes.objectOf(PropTypes.any),
  taskId: PropTypes.number.isRequired,
  formDisabled: PropTypes.bool,
};

CompressionDashboard.defaultProps = {
  mamEntries: { leading: [], trailing: [] },
  formDisabled: false,
};

export default CompressionDashboard;
