import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { IconButton, TableCell, Table, TableBody, TableRow, FormControl, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import PropTypes from 'prop-types';
import { weeklyCommonStyles } from 'views/entry/mam/components/commonStyles';
import { useModalCtx } from 'context/ModalContext';
import { RadSelect } from 'presentational/Inputs';
import { updateOutFields, MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import { formatDate } from 'utils/time.util';
import MamNumberInput from 'views/entry/mam/weekly/modify/components/MamNumberInput';
import { entryTypes } from 'views/entry/mam/components/mamEntry.util';
import EntryComment from 'views/entry/components/EntryComment';
// import RemarksDisplay from 'components/RemarksDisplay';

const useStyles = makeStyles(weeklyCommonStyles);

function ArtifactEvalEntryForm(props) {
  const { entryData, formDisabled, click } = props;
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { showModal, hideModal } = useModalCtx();
  const [outFieldsLocal, setOutfieldsLocal] = useState(entryData.outFields);

  if (!entryData) return null;

  const entryIndex = entryData._id || 'new';

  const handleUpdateRemarks = (newRemarks) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex].artEvalRemarks = newRemarks;
    setFieldValue('entries', updatedEntries);
    hideModal();
  };

  const getStyleForField = (field) =>
    outFieldsLocal.includes(field.substring(field.lastIndexOf('.') + 1)) ? classes.outValueSelect : classes.select;

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  function ArtifactsInput(field) {
    return (
      <TableRow>
        <TableCell className={classes.selectCell}>
          <FormControl className={classes.formControl}>
            <RadSelect
              name={field}
              className={getStyleForField(field)}
              onChange={(e) => setOutfieldsLocal(updateOutFields(e, values.entries[entryIndex], setFieldValue, true))}
              disabled={formDisabled}
            >
              <MenuItem className={classes.menuItem} value>
                Yes
              </MenuItem>
              <MenuItem className={classes.menuItem} value={false}>
                No
              </MenuItem>
            </RadSelect>
          </FormControl>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableCell className={classes.noPadding}>
      <Table className={classes.formTableWidth}>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{entryData.initials}</TableCell>
          </TableRow>
          <MamNumberInput
            entryType={entryTypes.KVP}
            name={`entries.${entryIndex}.${MamEntryFields.rhKvp}`}
            formDisabled={formDisabled}
          />
          <MamNumberInput
            entryType={entryTypes.MAS}
            name={`entries.${entryIndex}.${MamEntryFields.rhMas}`}
            formDisabled={formDisabled}
          />
          {ArtifactsInput(`entries.${entryIndex}.${MamEntryFields.rhArtifacts}`)}
          <MamNumberInput
            entryType={entryTypes.KVP}
            name={`entries.${entryIndex}.${MamEntryFields.agKvp}`}
            formDisabled={formDisabled}
          />
          <MamNumberInput
            entryType={entryTypes.MAS}
            name={`entries.${entryIndex}.${MamEntryFields.agMas}`}
            formDisabled={formDisabled}
          />
          {ArtifactsInput(`entries.${entryIndex}.${MamEntryFields.agArtifacts}`)}
          <MamNumberInput
            entryType={entryTypes.KVP}
            name={`entries.${entryIndex}.${MamEntryFields.alKvp}`}
            formDisabled={formDisabled}
          />
          <MamNumberInput
            entryType={entryTypes.MAS}
            name={`entries.${entryIndex}.${MamEntryFields.alMas}`}
            formDisabled={formDisabled}
          />
          {ArtifactsInput(`entries.${entryIndex}.${MamEntryFields.alArtifacts}`)}
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>
              {entryIndex !== 'new' && (
                <IconButton
                  onClick={() =>
                    showModal(EntryComment, {
                      savedComment: values.entries[entryIndex].artEvalRemarks,
                      hideModal,
                      updateComment: handleUpdateRemarks,
                      title: 'Remarks',
                    })
                  }
                >
                  <CommentIcon classes={{ root: classes.icons }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{cancelButton}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableCell>
  );
}

ArtifactEvalEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  formDisabled: PropTypes.bool,
  click: PropTypes.func,
};

ArtifactEvalEntryForm.defaultProps = {
  formDisabled: false,
  click: null,
};

export default ArtifactEvalEntryForm;
