import React from 'react';
import clsx from 'clsx';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import VcTableHeader from './components/VcTableHeader';
import { formatDate } from 'utils/time.util';
import { visualChecklistFields } from './components/VcTable.util';
import { convertNaPassFail } from 'utils/entry.util';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  tableStyle: { overflow: 'auto', minWidth: '537px' },
  headerTableRow: { backgroundColor: 'transparent' },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    padding: 0,
    border: 'none',
  },
  header: {
    overflowX: 'hidden',
  },
  tableBody: {
    border: '1px solid green',
  },
  tableContainer: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    height: '30rem',
  },
  rightTables: {
    height: '675px',
  },
  vcEntryFormTable: {
    width: '90px',
  },
  vcEntryTableCell: {
    padding: 0,
  },
  dateCell: {
    border: 0,
    padding: 0,
    fontSize: '0.75rem',
  },
  tableRoot: {
    padding: 0,
    border: 'none',
  },
  initialTableCell: {
    paddingLeft: '1rem',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
  },
  tableRowRoot: {
    height: '32px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderBottom: '1px solid #0d1a2908',
    fontSize: '0.75rem',
  },
  initialCellRoot: {
    borderBottom: 'none',
  },
});

function VcConfirmTable(props) {
  const { classes, confirmEntries } = props;

  return (
    <div className={classes.tableContainer}>
      <VcTableHeader />
      <div className={classes.rightTables}>
        <Table>
          <TableBody>
            <TableRow>
              {confirmEntries.map((entry) => {
                const getStyleForField = (field) => (entry.outFields.includes(field) ? classes.outFieldValue : null);

                return (
                  <TableCell key={entry.date} className={classes.vcEntryTableCell}>
                    <Table className={classes.vcEntryFormTable}>
                      <TableBody>
                        <TableRow classes={{ root: classes.tableRowRoot }}>
                          <TableCell className={classes.selectedTableCell} classes={{ root: classes.dateCell }}>
                            {formatDate(entry.date)}
                          </TableCell>
                        </TableRow>
                        {visualChecklistFields.map((field) => (
                          <TableRow key={field} classes={{ root: classes.tableRowRoot }}>
                            <TableCell sx={{ borderBottom: 'none' }} size="small" className={getStyleForField(field)}>
                              {convertNaPassFail(entry[field])}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow classes={{ root: clsx(classes.tableRowRoot, classes.initialCellRoot) }}>
                          <TableCell className={classes.initialTableCell}>{entry.initials}</TableCell>
                        </TableRow>
                        <TableRow classes={{ root: clsx(classes.tableRowRoot, classes.initialCellRoot) }}>
                          <TableCell className={classes.initialTableCell}>
                            {entry?.reviewedBy ? `${entry?.reviewedBy} on ${formatDate(entry?.reviewedDate)}` : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

VcConfirmTable.propTypes = {
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(VcConfirmTable);
