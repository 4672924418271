import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import QaTable from './qaTable/QaTable';

const styles = {
  tableContainer: {
    height: '600px',
    overflowY: 'auto',
  },
};

function QaDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedQaEntries, setSavedQaEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  // get existing data
  const getEntries = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    const startDate = date.start.toISOString();
    let endDate = new Date(date.end);
    endDate.setDate(endDate.getDate() + 1);
    endDate = endDate.toISOString();

    let receivedQaEntries;

    try {
      const accessToken = await getAccessTokenSilently();
      receivedQaEntries = await radqcApi(
        'get',
        `/entries/qa/device/${deviceId}?start=${startDate}&end=${endDate}&order=descending`,
        {
          accessToken,
        },
      );
    } catch (err) {
      console.log(err);
    }

    if (!receivedQaEntries) receivedQaEntries = [];
    setSavedQaEntries(receivedQaEntries);
  }, [match.url, date, getAccessTokenSilently]);

  useEffect(() => {
    getEntries();
  }, [date, getEntries]);

  // view mode should only view PDF
  if (mode === 'view') {
    const numEntriesPerRecord = 20;

    const records = splitEntriesIntoRecords(savedQaEntries, numEntriesPerRecord);

    return <RecordViewer recordType="qa" records={records} />;
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <QaTable key={mode} mode={mode} updateEntries={getEntries} qaEntries={savedQaEntries} />
    </TableContainer>
  );
}

QaDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(QaDashboard));
