import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AuthStatus from 'types/authentication';
import RadQaLogoWhite from 'assets/images/RADQC_LogoLightTM.svg';
import RadQaLogoBlack from 'assets/images/RADQC_LogoDark.svg';

const styles = {
  logo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    height: '1.5rem',
    margin: '0 1rem',
    '@media (max-width: 450px)': {
      display: 'none',
      maxWidth: '12rem',
    },
  },
  landing: {
    height: '1.4rem',
    marginLeft: '1rem',
    '@media (max-width: 450px)': {
      maxWidth: '12rem',
    },
  },
  mobileLogo: {},
};

function Logo(props: any) {
  const { classes, authStatus } = props;
  const logoSrc = authStatus === AuthStatus.AUTH ? RadQaLogoBlack : RadQaLogoWhite;

  return (
    <div className={classes.logo}>
      <img src={logoSrc} alt="Rad QC Logo" className={authStatus === AuthStatus.AUTH ? classes.img : classes.landing} />
    </div>
  );
}

Logo.propTypes = {
  authStatus: PropTypes.string,
};

Logo.defaultProps = {
  authStatus: AuthStatus.UNAUTH,
};

export default withStyles(styles)(Logo);
