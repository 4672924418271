import { colors } from '@mui/material';

const white = '#FFF';
const black = '#000';

export default {
  white,
  black,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: '#004fb3',
    darkMain: '#1c2237',
    mediumMain: '#45578a',
    light: '#d6e4f7',
    medium: '#abc5e6',
    mediumDark: '#49526f',
    vanilla: '#F7F7F7',
    red: '#F44336',
    green: '#1C9C1C',
    yellow: '#ffca36',
    lightgrey: '#ececec',
    orange: '#ef9126',
  },
  secondary: {
    contrastText: white,
    dark: '#922',
    main: '#dd3333',
    light: '#f66',
  },
};
