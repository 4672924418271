import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { roundUpNum } from 'utils/entry.util';
import { formatDateShort, getCurrentYear } from 'utils/time.util';
import {
  exposures,
  generateInitialTotals,
  getTotalForMamQuarterlyLine,
  mamQuarterlyLineFields,
} from 'views/entry/mam/quarterly/mamRejectTable/components/mamRejectTable.util';

// Create styles
const styles = StyleSheet.create({
  remarksCorrectiveValue: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    paddingLeft: 10,
    marginBottom: 0,
    width: '395',
    border: 0.5,
  },
  totalsAndRatioTable: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.4,
  },
  totalsAndRatioHeader: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
    paddingLeft: 10,
    color: palette.primary.main,
    width: 125,
    border: 0.5,
  },
  totalsAndRatioValue: {
    fontSize: 10,
    opacity: 0.8,
    paddingLeft: 10,
    textAlign: 'left',
    marginBottom: 0,
    width: 125,
    border: 0.5,
  },
  subRow: {
    wdisplay: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8.5,
  },
  rowTitle: {
    border: 0.3,
    width: 128,
    textAlign: 'left',
  },
  rowContent: {
    border: 0.5,
    width: 45.15,
    textAlign: 'center',
  },
  totalContent: {
    border: 0.5,
    width: 60.25,
    textAlign: 'center',
  },
  reasonTitle: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'center',
    marginBottom: 0,
    color: palette.primary.main,
    width: 125,
  },
  totals: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    width: 60,
    paddingTop: 5,
  },
  total: {
    fontSize: 10,
    opacity: 0.8,
    marginBottom: 0,
    marginLeft: '2',
    color: palette.primary.main,
    textAlign: 'center',
  },
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  exposureLists: {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
  },
  exposure: {
    fontSize: 8.15,
    opacity: 0.8,
    textAlign: 'center',
    paddingLeft: 1,
    paddingRight: 2,
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    width: 45,
    borderBottom: 'none',
    borderLeft: 'none',
  },
  title: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'center',
    color: palette.primary.main,
    width: 270,
  },
  numberExposure: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
  },
  entryViews: {
    marginBottom: 24,
    marginTop: 16,
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    flexDirection: 'column',
  },
  entryContentView: {
    display: 'flex',
    flexDirection: 'row',
  },
  reason: {
    width: '25%',
    paddingTop: 5,
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
  },
  numberExposures: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    display: 'flex',
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  helperText: {
    marginLeft: 30,
  },
  copyRight: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 0.5,
    marginTop: '16px',
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function MamQuarterlyPdfPreview(props) {
  const { records, device } = props;

  const generateTotals = (record) => {
    const totals = generateInitialTotals();
    Object.keys(mamQuarterlyLineFields).forEach((field) => {
      const totalForLine = getTotalForMamQuarterlyLine(record[field]);
      totals[field] = totalForLine;
      totals.overall += totalForLine;
    });
    return totals;
  };

  const dataLists = (record, totals) =>
    Object.keys(mamQuarterlyLineFields).map((field) => {
      const sumByPercent = roundUpNum((totals[field] / totals.overall) * 100);

      return (
        <View key={field} style={styles.row}>
          <Text style={styles.rowTitle}>{mamQuarterlyLineFields[field]}</Text>
          {exposures.map((exposure) => (
            <Text key={exposure} style={styles.rowContent}>
              {record[field][exposure]}
            </Text>
          ))}
          <Text style={styles.totalContent}> {totals[field]} </Text>
          <Text style={styles.totalContent}> {sumByPercent} % </Text>
        </View>
      );
    });

  return (
    <Document>
      {records
        ? records.map((record) => {
            const totals = generateTotals(record);
            const totalSum = totals.overall;
            return (
              <Page key={record._id} style={styles.body}>
                <View fixed style={styles.footer}>
                  <Text
                    style={styles.header}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                  />
                  <Text fixed style={styles.header}>
                    Provided By Radiology QC Solutions
                  </Text>
                </View>
                <Text style={styles.formTitle}>Mammography Reject Analysis</Text>

                <View style={{ marginBottom: 16, marginTop: 32, marginLeft: 32 }}>
                  <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
                  <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
                  <Text style={styles.scannerInfo}>
                    Date Period: {formatDateShort(record.date)} - {formatDateShort(record.dateTo)}
                  </Text>
                  <Text style={styles.scannerInfo}>Entered by (initials): {record.initials}</Text>
                </View>

                <View style={styles.entryViews}>
                  <View style={styles.entryContentView}>
                    <View style={styles.reason}>
                      <Text style={styles.reasonTitle}>Reason</Text>
                    </View>
                    <View style={styles.numberExposures}>
                      <Text style={styles.title}>Number of Exposure</Text>
                      <View style={styles.exposureLists}>
                        <Text style={styles.exposure}>Left CC</Text>
                        <Text style={styles.exposure}>Right CC</Text>
                        <Text style={styles.exposure}>Left MLO</Text>
                        <Text style={styles.exposure}>Right MLO</Text>
                        <Text style={styles.exposure}>Left Other</Text>
                        <Text style={styles.exposure}>Right Other</Text>
                      </View>
                    </View>
                    <View style={styles.totals}>
                      <Text style={styles.total}>Subtotal</Text>
                    </View>
                    <View style={styles.totals}>
                      <Text style={styles.total}>% of Total</Text>
                    </View>
                  </View>

                  {dataLists(record, totals)}

                  <View style={styles.totalsAndRatioTable}>
                    <View style={styles.subRow}>
                      <Text style={styles.totalsAndRatioHeader}>Total with Reason</Text>
                      <Text style={styles.totalsAndRatioValue}>{totalSum}</Text>
                    </View>
                    <View style={styles.subRow}>
                      <Text style={styles.totalsAndRatioHeader}>Total Exposures</Text>
                      <Text style={styles.totalsAndRatioValue}>{record.totalExposures}</Text>
                    </View>
                    <View style={styles.subRow}>
                      <Text style={styles.totalsAndRatioHeader}>Ratio (%)</Text>
                      <Text style={styles.totalsAndRatioValue}>
                        {totalSum / record.totalExposures ? totalSum / record.totalExposures : 0}%
                      </Text>
                    </View>
                  </View>

                  <View style={styles.totalsAndRatioTable}>
                    <View style={styles.subRow}>
                      <Text style={styles.totalsAndRatioHeader}>Remarks</Text>
                      <Text style={styles.remarksCorrectiveValue}>{record.remarks}</Text>
                    </View>
                    <View style={styles.subRow}>
                      <Text style={styles.totalsAndRatioHeader}>Corrective Action</Text>
                      <Text style={styles.remarksCorrectiveValue}>{record.correctiveAction}</Text>
                    </View>
                  </View>
                </View>

                <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
                  <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
                </View>
              </Page>
            );
          })
        : null}
    </Document>
  );
}

MamQuarterlyPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

MamQuarterlyPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default MamQuarterlyPdfPreview;
