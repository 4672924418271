import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import MamChecklistTable from './mamChecklistTable/MamChecklistTable';

const styles = {
  table: {
    height: '100%',
  },
};

function MamChecklistDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedMamChecklists, setSavedMamChecklists] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getChecklists = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedMamChecklists;
    const startDate = date.start.toISOString();
    // Add 1 day to the end date to handle timezones for brand new entries
    let endDate = new Date(date.end);
    const now = new Date();
    endDate.setDate(now.getDate() + 1);
    endDate = endDate.toISOString();

    try {
      const accessToken = await getAccessTokenSilently();
      receivedMamChecklists = await radqcApi(
        'get',
        `/entries/mam_mt/device/${deviceId}?start=${startDate}&end=${endDate}`,
        { accessToken },
      );
    } catch (err) {
      console.log(err);
    }
    if (!receivedMamChecklists) receivedMamChecklists = [];
    if (isMounted) {
      setSavedMamChecklists(receivedMamChecklists);
    }
  }, [match.url, date.start, date.end, getAccessTokenSilently, isMounted]);

  useEffect(() => {
    setIsMounted(true);
    getChecklists();

    return () => setIsMounted(false);
  }, [mode, date, getChecklists]);

  if (mode === 'view') {
    const numEntriesPerRecord = 7;

    const records = splitEntriesIntoRecords(savedMamChecklists, numEntriesPerRecord);

    return <RecordViewer recordType="MAM Checklist" records={records} />;
  }

  return (
    <Grid item xs={12} className={classes.table}>
      <MamChecklistTable
        key={mode + savedMamChecklists.length}
        mamChecklists={savedMamChecklists}
        mode={mode}
        updateEntries={getChecklists}
      />
    </Grid>
  );
}

MamChecklistDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(MamChecklistDashboard));
