import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { getIdFieldAndTitleForType } from 'utils/device.util';

const styles = (theme) => ({
  rowHeader: {
    color: '#5e5d5d',
    fontSize: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '250px',
    fontSize: 12,
  },
});

function DeviceDetails(props) {
  const { classes, device } = props;

  const [idField, idTitle] = getIdFieldAndTitleForType(device.type);

  let apLine = null;
  if (idField === 'apNumber') {
    apLine = (
      <Box className={classes.row}>
        <Box className={classes.rowHeader}>{idTitle}:</Box>
        <Box>{device.apNumber}</Box>
      </Box>
    );
  }

  return (
    <Box>
      {apLine}

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Model #:</Box>
        <Box>{device.modelNumber}</Box>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Serial #:</Box>
        <Box>{device.serialNumber}</Box>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.rowHeader}>Manufactured by:</Box>
        <Box>{device.manufacturer}</Box>
      </Box>
    </Box>
  );
}

DeviceDetails.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(DeviceDetails);
