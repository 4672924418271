import React from 'react';
import { TableRow, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import { useModalCtx } from 'context/ModalContext';
import { formatDateShort } from 'utils/time.util';
import EntryComment from 'views/entry/components/EntryComment';
import NoPadTableCell from './NoPadTableCell';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  outValue: {
    color: theme.palette.primary.red,
  },
  reviewCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    ' -webkit-line-clamp': 2,
    width: '89px',
    '-webkit-box-orient': 'vertical',
    lineHeight: '1rem',
    fontSize: '0.725rem',
    textAlign: 'center',
  },
});

function QaEntryDisplay(props) {
  const { entryData, click, classes } = props;

  const { showModal, hideModal } = useModalCtx();

  const displayBool = (bool) => {
    if (bool) {
      return 'Y';
    }
    return 'N';
  };

  const getStyleForField = (field) => (entryData?.outFields.includes(field) ? classes.outValue : null);

  let editIcon = null;
  if (click) {
    editIcon = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableRow key={entryData._id} onClick={click}>
      <NoPadTableCell width="6.70%">{formatDateShort(entryData.date)}</NoPadTableCell>
      <NoPadTableCell width="6.85%" className={getStyleForField('setupAccuracy')}>
        {entryData.setupAccuracy}
      </NoPadTableCell>
      <NoPadTableCell width="6.75%" className={getStyleForField('setupConsole')}>
        {displayBool(entryData.setupConsole)}
      </NoPadTableCell>
      <NoPadTableCell width="8%" className={getStyleForField('centerFreq')}>
        {entryData.centerFreq}
      </NoPadTableCell>
      <NoPadTableCell width="7.4%" className={getStyleForField('txGainAtten')}>
        {entryData.txGainAtten}
      </NoPadTableCell>
      <NoPadTableCell width="7.5%" className={getStyleForField('geoAccuracyHf')}>
        {entryData.geoAccuracyHf}
      </NoPadTableCell>
      <NoPadTableCell width="7.65%" className={getStyleForField('geoAccuracyAp')}>
        {entryData.geoAccuracyAp}
      </NoPadTableCell>
      <NoPadTableCell width="7.65%" className={getStyleForField('geoAccuracyRl')}>
        {entryData.geoAccuracyRl}
      </NoPadTableCell>
      <NoPadTableCell width="7.8%" className={getStyleForField('spatialResUpper')}>
        {entryData.spatialResUpper}
      </NoPadTableCell>
      <NoPadTableCell width="7.95%" className={getStyleForField('spatialResLower')}>
        {entryData.spatialResLower}
      </NoPadTableCell>
      <NoPadTableCell width="9%">{entryData?.spokesNum}</NoPadTableCell>
      <NoPadTableCell width="8.25%" className={getStyleForField('artifactEval')}>
        {displayBool(entryData.artifactEval)}
      </NoPadTableCell>
      <NoPadTableCell width="13.5%">{entryData.initials}</NoPadTableCell>
      <NoPadTableCell width="7%">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            showModal(EntryComment, { savedComment: entryData.notes, hideModal, title: 'Notes' });
          }}
        >
          <CommentIcon className={classes.icons} />
        </IconButton>
      </NoPadTableCell>
      <NoPadTableCell width="13.5%">
        <Tooltip
          title={entryData?.reviewedBy ? `${entryData?.reviewedBy} on ${formatDateShort(entryData?.reviewedDate)}` : ''}
        >
          <div className={classes.reviewCell}>
            {entryData?.reviewedBy ? `${entryData?.reviewedBy} on ${formatDateShort(entryData?.reviewedDate)}` : null}
          </div>
        </Tooltip>
      </NoPadTableCell>
      <NoPadTableCell width="4%">{editIcon}</NoPadTableCell>
    </TableRow>
  );
}

QaEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QaEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(QaEntryDisplay);
