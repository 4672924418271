import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormikContext, ErrorMessage } from 'formik';
import { TableRow, TableCell, FormControl, Input, MenuItem, Tooltip, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { RadSelect, RadTextInput, RadCheckbox } from 'presentational/Inputs';
import { trueFalseEmpty } from 'utils/entry.util';
import UndoIcon from '@mui/icons-material/Undo';

const styles = (theme) => ({
  entryRow: {
    maxWidth: '100%',
    backgroundColor: `#FAFAFA`,
  },
  formControl: {
    margin: '0px 0',
  },
  undoIcon: {
    fontSize: '20px',
  },
  select: {
    height: '32px',
    fontSize: '12px',
    bottom: '2px',
    'label + &': {
      marginTop: '0rem',
    },
  },
  selectedTableCell: {
    padding: 0,
    height: '50px',
    textAlign: 'center',
  },
  buttonCell: {
    width: '8%',
    padding: 0,
    height: '43px',
  },
  radTextInputCont: {
    textAlign: 'center',
    maxWidth: '50%',
    width: '112px',
  },
  radTextInput: {
    '&$error': {
      color: 'blue',
    },
    textAlign: 'center',
    height: 'auto',
  },
  outValue: {
    '&$error': {
      color: theme.palette.primary.red,
      fontSize: '10px',
    },
    color: theme.palette.primary.red,
    boxShadow: `0 0 2px 1px ${theme.palette.primary.red}`,
    borderRadius: '5px',
    textAlign: 'center',
  },
  error: {},
  menuItem: {
    fontSize: '12px',
  },
});

function QcDailyEntryForm(props) {
  const { entryData, classes, click } = props;
  const { values, setFieldValue } = useFormikContext();
  const [waterStyle, setWaterStyle] = useState(classes.radTextInput);
  const [noiseStyle, setNoiseStyle] = useState(classes.radTextInput);
  const [artifactStyle, setArtifactStyle] = useState(classes.select);

  const handleWaterChange = (e) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryData.day].water.value = e.target.value;
    if (e.target.value > entryData.water.max || e.target.value < entryData.water.min) {
      setWaterStyle(classes.outValue);
      updatedEntries[entryData.day].outFields.push('water');
    } else {
      setWaterStyle(classes.radTextInput);
      const outfields = updatedEntries[entryData.day].outFields.filter((field) => field !== 'water');
      updatedEntries[entryData.day].outFields = outfields;
    }

    setFieldValue('entries', updatedEntries);
  };

  const handleNoiseChange = (e) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryData.day].noise.value = e.target.value;
    if (
      Math.sign(e.target.value) !== Math.sign(entryData.noise.max) ||
      Math.abs(e.target.value) > Math.abs(entryData.noise.max)
    ) {
      setNoiseStyle(classes.outValue);
      updatedEntries[entryData.day].outFields.push('noise');
    } else {
      setNoiseStyle(classes.radTextInput);
      const outfields = updatedEntries[entryData.day].outFields.filter((field) => field !== 'noise');
      updatedEntries[entryData.day].outFields = outfields;
    }

    setFieldValue('entries', updatedEntries);
  };

  const handleArtifactsChange = (e) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryData.day].artifacts = e.target.value;
    if (updatedEntries[entryData.day].artifacts) {
      setArtifactStyle(classes.outValue);
      updatedEntries[entryData.day].outFields.push('artifacts');
    } else {
      setArtifactStyle(classes.select);
      const outfields = updatedEntries[entryData.day].outFields.filter((field) => field !== 'artifacts');
      updatedEntries[entryData.day].outFields = outfields;
    }

    setFieldValue('entries', updatedEntries);
  };

  return (
    <TableRow className={classes.entryRow}>
      <TableCell className={classes.selectedTableCell}>
        {entryData.day}
        <Input type="hidden" name={`entries.${entryData.day}.day`} value={entryData.day} />
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadCheckbox name={`entries.${entryData.day}.warmUp`} />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadCheckbox name={`entries.${entryData.day}.airCals`} />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        {entryData.mode}
        <Input type="hidden" name={`entries.${entryData.day}.mode`} value={entryData.mode} />
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={clsx(classes.formControl, classes.radTextInputCont)}>
          <RadTextInput
            sx={{ height: 'initial' }}
            margin="dense"
            name={`entries.${entryData.day}.water.value`}
            type="number"
            onChange={(e) => handleWaterChange(e)}
            className={waterStyle}
          />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={clsx(classes.formControl, classes.radTextInputCont)}>
          <RadTextInput
            sx={{ height: 'initial' }}
            margin="dense"
            name={`entries.${entryData.day}.noise.value`}
            type="number"
            onChange={(e) => handleNoiseChange(e)}
            className={noiseStyle}
          />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadSelect
            name={`entries.${entryData.day}.artifacts`}
            onChange={(e) => handleArtifactsChange(e)}
            className={artifactStyle}
          >
            <MenuItem className={classes.menuItem} value>
              Yes
            </MenuItem>
            <MenuItem className={classes.menuItem} value={false}>
              No
            </MenuItem>
          </RadSelect>
          <ErrorMessage name="artifacts" />
        </FormControl>
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        {trueFalseEmpty(entryData.outFields ? entryData.outFields.length === 0 : null, 'Pass', 'Fail')}
      </TableCell>
      <TableCell className={classes.selectedTableCell}>
        {entryData.initials}
        <Input type="hidden" name={`entries.${entryData.day}.enteredById`} />
      </TableCell>
      <TableCell className={classes.buttonCell}>
        <Tooltip title="Undo">
          <IconButton onClick={click} disableRipple disableFocusRipple>
            <UndoIcon className={classes.cancelIcon} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

QcDailyEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QcDailyEntryForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(QcDailyEntryForm);
