import React from 'react';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Chart from './components/Chart';

const styles = (theme) => ({
  metricContainer: {
    transition: '0.5s ease-in-out',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  chartBoxes: {
    margin: '1rem 0',
    width: '80%',
    '@media (max-width: 649px)': {
      width: '100%',
    },
  },
  typoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  typography: {
    color: theme.palette.primary.darkMain,
    fontSize: '16px',
  },
  chartContainer: {
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'auto',
  },
  helperText: {
    fontWeight: 600,
    color: theme.palette.black,
  },
});

function MetricsOverview(props) {
  const { metricsConfig, classes } = props;
  let chartIndex = 0;

  return (
    <Box className={classes.metricContainer}>
      <Box className={classes.typoBox}>
        <Typography variant="body2" className={classes.helperText}>
          Click here to adjust metrics settings.
        </Typography>
      </Box>
      <Box className={classes.chartContainer}>
        {metricsConfig.map(
          (config) =>
            config.display && (
              <Box key={chartIndex++} className={classes.chartBoxes}>
                <Chart config={config} />
              </Box>
            ),
        )}
      </Box>
    </Box>
  );
}
export default withStyles(styles)(MetricsOverview);
