import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Button } from '@mui/material';
import './Section.css';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
  buttonIs,
  buttonContent,
}) {
  return (
    <div className="home__hero-section darkBg trigger">
      <div className="container">
        <div
          className={!lightBg ? 'rowLight home__hero-row' : 'row home__hero-row'}
          style={{
            display: 'flex',
            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row',
          }}
        >
          <div className={lightBg ? 'rightCol' : 'leftCol'}>
            <div className="home__hero-text-wrapper">
              <div className="top-line">{topLine}</div>
              <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
              <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>{description}</p>
              {buttonIs && buttonContent ? (
                <Link to="/#contact" style={{ textDecoration: 'none' }}>
                  <Button variant="outlined" color="primary" size="large">
                    {buttonContent}
                  </Button>
                </Link>
              ) : null}
            </div>
          </div>
          <div className={lightBg ? 'leftCol' : 'rightCol'}>
            <div className={lightBg ? 'home__hero-img-wrapper' : 'home__hero-img-wrapper-dark'} elevation={9}>
              <img src={img} alt={alt} className="home__hero-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
