const baseFieldStyles = {
  padding: '0',
  textAlign: 'center',
  width: '6rem',
  height: '50px',
  border: '0.1rem solid #0d1a2908',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const baseSelectStyles = {
  height: '32px',
  fontSize: '12px',
  bottom: '2px',
  'label + &': {
    marginTop: '0rem',
  },
};

export const weeklyCommonStyles = (theme) => ({
  tableTitles: {
    color: 'black',
    ...baseFieldStyles,
  },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    ...baseFieldStyles,
  },
  select: {
    ...baseSelectStyles,
  },
  outValueSelect: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    ...baseSelectStyles,
  },
  noPadding: {
    padding: 0,
  },
  formTableWidth: {
    width: '90px',
  },
  selectCell: {
    padding: 0,
    height: '50px',
    textAlign: 'center',
    borderBottom: 'none',
  },
  formControl: {
    margin: '0px 0',
  },
  menuItem: {
    fontSize: '12px',
  },
});

export const getStyleForField = (field, entry, classes) =>
  entry?.outFields?.includes(field) ? classes.outFieldValue : classes.tableTitles;
