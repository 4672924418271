import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';
import { formatDateShort } from 'utils/time.util';

const useStyles = makeStyles((theme) => ({
  tableTitles: {
    color: 'black',
    textAlign: 'left',
    width: '76px',
    fontSize: '0.75rem',
    borderBottom: '0.1px solid #0000000a',
    lineHeight: 1.3,
    padding: '0.5rem',
  },
  tableTitlesInput: {
    color: 'black',
    textAlign: 'center',
    width: '76px',
    fontSize: '0.75rem',
    borderBottom: '0.1px solid #0000000a',
    lineHeight: 1.3,
    padding: '0.25rem',
  },
  tableContainer: {
    display: 'flex',
    cursor: 'pointer',
    '@media (max-width: 1024px)': {
      margin: 0,
    },
  },
  stickyHeader: {
    width: '10rem',
  },
  rightTables: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  hoverRow: {
    transition: '0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

function RwsWeeklyOverviewTable(props) {
  const { device, history, match } = props;
  const [entries, setEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  // get existing data (currently just adds all existing values to 'trailing' entries)
  const getEntries = useCallback(async () => {
    let receivedEntries = [];
    try {
      const accessToken = await getAccessTokenSilently();
      receivedEntries = await radqcApi('get', `/entries/rws/device/${device._id}`, { accessToken });
    } catch (err) {
      console.log(err);
    }
    setEntries(receivedEntries);
  }, [getAccessTokenSilently, device._id]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  return (
    <Box className={classes.tableContainer} onClick={() => history.push(`${match.url}/rwsweekly`)}>
      <Table stickyHeader className={classes.stickyHeader}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitles}>Date</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Initials</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableTitles}>Monitor (weekly)</TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Box className={classes.rightTables}>
        <Table>
          <TableBody>
            <TableRow>
              {entries.map((entry) => (
                <TableCell className={classes.tableTitlesInput} key={entry._id}>
                  {formatDateShort(entry.date)}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {entries.map((entry) => (
                <TableCell className={classes.tableTitlesInput} key={entry._id}>
                  {entry.initials}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {entries.map((entry) => (
                <TableCell className={classes.tableTitlesInput} key={entry._id}>
                  X
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

RwsWeeklyOverviewTable.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(RwsWeeklyOverviewTable);
