import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import NoPadTableHeader from './NoPadTableHeader';
import { getQaLimits, getSliceNum, QaPhantomLimits } from './QaEntry.util';

const styles = (theme) => ({
  actionLimit: {
    color: theme.palette.primary.main,
  },
  specialPadding: {
    padding: '1rem',
    lineHeight: '1rem',
    fontSize: '0.725rem',
    textAlign: 'center',
  },
});

function QaTableHeader(props) {
  const { confirmation, device, classes } = props;
  const fieldNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  if (!device) {
    return null;
  }

  const [cfLimits, tgLimits] = getQaLimits(device);
  const sliceNumber = getSliceNum(device.fieldStrength);
  const phantomLimits = QaPhantomLimits[device.phantomSize];

  let reviewSection = (
    <>
      <NoPadTableHeader rowSpan={2} width="8%" classes={{ root: classes.specialPadding }}>
        Review History
      </NoPadTableHeader>
      <NoPadTableHeader rowSpan={2} width="8%" classes={{ root: classes.specialPadding }} />
    </>
  );
  let endingColSpan = 4;
  if (confirmation) {
    reviewSection = null;
    endingColSpan = 2;
  }

  return (
    <TableHead>
      <TableRow>
        {fieldNumbers.map((num) => (
          <NoPadTableHeader key={num}>{num}</NoPadTableHeader>
        ))}
        <NoPadTableHeader colSpan={4} />
      </TableRow>
      <TableRow>
        <NoPadTableHeader rowSpan={2} width="6.75%">
          Date
        </NoPadTableHeader>
        <NoPadTableHeader colSpan={2} width="6%">
          Table Setup/
          <br /> Position Accuracy
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={2} width="8%" classes={{ root: classes.specialPadding }}>
          Ctr Freq. (Hz)
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={2} width="8%">
          TX Gain
          <br />
          /Attn.(dB)
        </NoPadTableHeader>
        <NoPadTableHeader colSpan={3} width="8%">
          Geo. Accuracy
          <br /> Measurement (mm)
        </NoPadTableHeader>
        <NoPadTableHeader colSpan={2} width="8%">
          High Contrast.
          <br /> Spatial Resol. (mm)
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={1} width="8%">
          Low Contrast Detectability <br /> Slice #: {sliceNumber}
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={2} width="8%">
          Any Present of Artifact Eval.?
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={2} width="8%" classes={{ root: classes.specialPadding }}>
          Tested By
        </NoPadTableHeader>
        <NoPadTableHeader rowSpan={2} width="8%">
          Notes
        </NoPadTableHeader>
        {reviewSection}
      </TableRow>
      <TableRow>
        <NoPadTableHeader width="6%">Accuracy OK?</NoPadTableHeader>
        <NoPadTableHeader width="6%">Console OK?</NoPadTableHeader>
        <NoPadTableHeader width="8%">HF</NoPadTableHeader>
        <NoPadTableHeader width="8%">A/P</NoPadTableHeader>
        <NoPadTableHeader width="8%">R/L</NoPadTableHeader>
        <NoPadTableHeader width="8%">Upper Left</NoPadTableHeader>
        <NoPadTableHeader width="8%">Lower Right</NoPadTableHeader>
        <NoPadTableHeader width="8%"># of Spoke</NoPadTableHeader>
      </TableRow>
      <TableRow>
        <NoPadTableHeader className={classes.actionLimit} width="6.75%">
          Limit
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="6%">
          ± 5mm
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="6%">
          Y/N
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} style={{ fontSize: '0.6rem' }} width="6%">
          {cfLimits}
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="6%">
          {tgLimits}
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          {phantomLimits.geoHf} ± 2
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          {phantomLimits.geoApRl} ± 2
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          {phantomLimits.geoApRl} ± 2
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          ≤ {phantomLimits.spatialRes.toFixed(1)}
        </NoPadTableHeader>
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          ≤ {phantomLimits.spatialRes.toFixed(1)}
        </NoPadTableHeader>
        <NoPadTableHeader />
        <NoPadTableHeader className={classes.actionLimit} width="8%">
          Y/N
        </NoPadTableHeader>
        <NoPadTableHeader colSpan={endingColSpan} />
      </TableRow>
    </TableHead>
  );
}

QaTableHeader.propTypes = {
  confirmation: PropTypes.bool,
  device: PropTypes.objectOf(PropTypes.any),
};

QaTableHeader.defaultProps = {
  confirmation: false,
  device: null,
};

export default withStyles(styles)(QaTableHeader);
