import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Toolbar, Typography, Tooltip, IconButton, lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatDateShort } from 'utils/time.util';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.darkMain,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.darkMain,
        },
  title: {
    flex: '1 1 100%',
  },
  initialTitle: {
    color: `${theme.palette.primary.darkMain}`,
    paddingLeft: '0',
  },
  printIcon: {
    color: theme.palette.primary.darkMain,
  },
}));

function RecordTableToolbar(props) {
  const { recordType, numSelected, pdfPreview } = props;
  const classes = useToolbarStyles();

  let isDownloadLinkActive = numSelected > 0;
  if (pdfPreview.props.addProps) {
    isDownloadLinkActive = numSelected > 0 && pdfPreview.props.addProps.chartImages !== null;
  }

  return (
    <Toolbar
      classes={{ root: classes.rootToolbar }}
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} record{numSelected > 1 ? 's' : null} selected
        </Typography>
      ) : (
        <Typography className={clsx(classes.title, classes.initialTitle)} id="tableTitle" component="div">
          {recordType.toUpperCase()} Records
        </Typography>
      )}

      {isDownloadLinkActive && (
        <Tooltip title="Print">
          <IconButton aria-label="print">
            <PDFDownloadLink
              document={pdfPreview}
              fileName={`RADQA ${recordType.toUpperCase()} records ${formatDateShort(new Date())}.pdf`}
            >
              {({ loading }) =>
                loading ? <PrintIcon className={classes.printIcon} /> : <PrintIcon className={classes.printIcon} />
              }
            </PDFDownloadLink>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

RecordTableToolbar.propTypes = {
  recordType: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  pdfPreview: PropTypes.element.isRequired,
};

export default RecordTableToolbar;
