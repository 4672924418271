import * as Yup from 'yup';
import { getDaysInMonth } from './time.util';

const enumNaPassFail = ['n/a', 'pass', 'fail'];

export const enumNaPassFailValidation = Yup.string()
  .oneOf(enumNaPassFail, 'Invalid pass/fail selection')
  .required('Required');

export const convertNaPassFail = (value) => {
  switch (value.toLowerCase()) {
    case 'n/a':
      return 'N/A';
    case 'pass':
      return 'P';
    case 'fail':
      return 'F';
    default:
      throw Error(`Unrecognized value: ${value}`);
  }
};

// Return null if condition is undefined or null, otherwise return true or false value
export const trueFalseEmpty = (condition, trueVal, falseVal) => {
  if (condition == null) {
    return null;
  }
  return condition ? trueVal : falseVal;
};

export const splitEntriesIntoRecords = (entries, numPerRecord) => {
  const records = [];
  for (let i = 0; i < entries.length; i += numPerRecord) {
    const currRecord = {
      _id: entries[i]._id,
      date: entries[i].date,
      reviewedDate: entries[i]?.reviewedDate,
      entries: entries.slice(i, i + numPerRecord),
    };
    currRecord.entries.reverse();
    records.push(currRecord);
  }
  return records;
};

export const getEntryModesForRole = (role, isWeekly) => {
  let entryModes = ['view'];
  switch (role) {
    case 'god':
    case 'admin_phys':
      entryModes = ['enter', 'review', 'view', 'edit'];
      break;
    case 'admin':
      entryModes = ['enter', 'view', 'edit'];
      break;
    case 'physicist':
      entryModes = ['review', 'view'];
      break;
    case 'lead_tech':
      entryModes = ['enter', 'view'];
      break;
    case 'technologist':
      entryModes = ['enter', 'view'];
      break;
    case 'mam_tech':
      entryModes = isWeekly ? ['enter', 'view'] : ['view'];
      break;
    default:
      throw new Error(`Unrecognized role: ${role}`);
  }
  return entryModes;
};

export const roundUpNum = (num) => {
  const rounded = num.toFixed(1);
  return parseFloat(rounded);
};

export const generateDailyEntries = (savedEntries, date, generateEmptyDailyEntry) => {
  // Get all entries for the given month
  const dataEntries = [...savedEntries];

  // Get number of days in the month
  const daysInMonth = getDaysInMonth(date);

  // Loop through days of month entering saved data where it exists
  const dailyEntries = [];
  for (let i = 1; i < daysInMonth + 1; i++) {
    if (dataEntries[0] && dataEntries[0].day === i) {
      dailyEntries.push(dataEntries[0]);
      dataEntries.shift();
    } else {
      dailyEntries.push(generateEmptyDailyEntry(i));
    }
  }
  return dailyEntries;
};

export const isByMonthEntry = (entryType) => entryType === 'qc' || entryType === 'rws_daily';

export const getDefaultDates = (entryType, mode) => {
  const startDate = new Date();
  const endDate = new Date();
  if (mode === 'view' || (!isByMonthEntry(entryType) && !entryType.includes('mam_wk'))) {
    startDate.setMonth(endDate.getMonth() - 3);
  }
  if (isByMonthEntry(entryType)) {
    startDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(-1);
  }
  return { start: startDate, end: endDate };
};
