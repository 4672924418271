import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { getNoiseLimitsForCt } from 'utils/device.util';
import { formatDate, formatMonth, formatYear } from 'utils/time.util';

const baseBorderStyle = {
  borderStyle: 'solid',
  borderColor: '#bfbfbf',
  borderWidth: 0.3,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    margin: 16,
    marginTop: 0,
  },
  subContainer: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.3,
    margin: 8,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  table: {
    ...baseBorderStyle,
    display: 'table',
    width: '46.5%',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginRight: 0,
  },
  autoMarginRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  dailyHeader: {
    ...baseBorderStyle,
    borderBottomColor: '#000',
  },
  baseBorderRight: {
    ...baseBorderStyle,
    borderRightWidth: 0.2,
  },
  dailyHeaderTxt: {
    fontSize: 6,
  },
  dailyCellTxt: {
    margin: 4,
    fontSize: 6,
  },
  monthlyChecklistLblView: {
    ...baseBorderStyle,
    width: '100%',
    borderRightWidth: 0.2,
  },
  monthlyChecklistLblText: {
    fontSize: 7,
    height: 8,
    margin: 1,
    textAlign: 'left',
  },
  monthlyChecklistValView: {
    ...baseBorderStyle,
    width: '30%',
    borderRightWidth: 0.2,
  },
  monthlyChecklistValText: {
    fontSize: 7,
    textAlign: 'right',
  },
  actionLimitColFirst: {
    ...baseBorderStyle,
    width: '150%',
    borderRightWidth: 0.2,
  },
  actionLimitText: {
    fontSize: 7,
    marginRight: 24,
    textAlign: 'right',
  },
  actionLimitTableCol: {
    ...baseBorderStyle,
    width: '65%',
    fontSize: 7,
    borderRightWidth: 0.2,
  },
  comments: {
    fontSize: 7,
    marginRight: 24,
    textAlign: 'justify',
  },
  accentText: {
    fontSize: 7,
    color: palette.primary.main,
    opacity: 0.8,
    marginTop: 8,
  },
  checklistSectionTitle: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 0.8,
    marginTop: 16,
    marginBottom: 16,
    textAlign: 'right',
  },
  checklistSubsectionTitle: {
    fontSize: 7,
    color: palette.primary.main,
    opacity: 0.7,
    marginBottom: 8,
    marginTop: 8,
  },
  copyRight: {
    fontSize: 8,
    color: palette.primary.main,
    opacity: 0.5,
    marginLeft: 24,
    marginBottom: 32,
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function DailyEntriesHeader(props) {
  const { first, autoMargin, children } = props;
  const widthStyle = first ? { width: '15%' } : { width: '25%' };
  const marginStyle = autoMargin ? { margin: 'auto' } : { margin: 4 };

  return (
    <View style={[styles.dailyHeader, widthStyle]}>
      <Text style={[styles.dailyHeaderTxt, marginStyle]}>{children}</Text>
    </View>
  );
}

function DailyEntriesCell(props) {
  const { first, children } = props;
  const widthStyle = first ? { width: '15%' } : { width: '25%' };

  return (
    <View style={[styles.baseBorderRight, widthStyle]}>
      <Text style={styles.dailyCellTxt}>{children}</Text>
    </View>
  );
}

function MonthlyChecklistRow(props) {
  const { text, field, entry } = props;

  let value = null;
  if (entry.monthlyEntry) {
    switch (entry.monthlyEntry[field]) {
      case 'n/a':
        value = 'N/A';
        break;
      case 'pass':
        value = 'P';
        break;
      case 'fail':
        value = 'F';
        break;
      default:
        if (field === 'enteredDate') {
          value = formatDate(entry.monthlyEntry[field]);
        } else {
          value = entry.monthlyEntry[field];
        }
    }
  }

  return (
    <View key={field} style={styles.autoMarginRow}>
      <View style={styles.monthlyChecklistLblView}>
        <Text style={styles.monthlyChecklistLblText}>{text}</Text>
      </View>
      <View style={styles.monthlyChecklistValView}>
        <Text style={styles.monthlyChecklistValText}>{value}</Text>
      </View>
    </View>
  );
}

// Create Document Component
function QcPdfPreview(props) {
  const { records, device } = props;
  const [axialLimit, helicalLimit] = getNoiseLimitsForCt(device);

  return (
    <Document>
      {records.map((qcEntry) => (
        <Page key={qcEntry._id} style={styles.body} orientation="portrait">
          <View fixed style={styles.footer}>
            <Text
              style={styles.header}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
            <Text fixed style={styles.header}>
              Provided By Radiology QC Solutions
            </Text>
          </View>
          <Text style={styles.formTitle}>CT Equipment Quality Control Data Form</Text>

          <View key={qcEntry._id} style={{ marginBottom: 5, marginTop: 32, marginLeft: 32 }}>
            <Text style={styles.scannerInfo}>Month : {formatMonth(qcEntry.date)}</Text>
            <Text style={styles.scannerInfo}>Year : {formatYear(qcEntry.date)}</Text>
            <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
            <Text style={styles.scannerInfo}>Scanner : {`${device.name} (${device.apNumber})`}</Text>
          </View>
          <View style={styles.container}>
            <View style={styles.subContainer}>
              <View style={styles.table}>
                <View style={styles.autoMarginRow}>
                  <DailyEntriesHeader first>Day</DailyEntriesHeader>
                  <DailyEntriesHeader>WarmUp</DailyEntriesHeader>
                  <DailyEntriesHeader>Air Cals</DailyEntriesHeader>
                  <DailyEntriesHeader>Mode</DailyEntriesHeader>
                  <DailyEntriesHeader>CT(HU)</DailyEntriesHeader>
                  <DailyEntriesHeader autoMargin>Noise (SD)</DailyEntriesHeader>
                  <DailyEntriesHeader autoMargin>Atrifacts</DailyEntriesHeader>
                  <DailyEntriesHeader>P/F</DailyEntriesHeader>
                  <DailyEntriesHeader>Initials</DailyEntriesHeader>
                </View>

                {qcEntry.dailyEntries.map((data) => (
                  <View key={data._id} style={styles.autoMarginRow}>
                    <DailyEntriesCell first>{data.day}</DailyEntriesCell>
                    <DailyEntriesCell>{data.warmUp ? 'X' : ''}</DailyEntriesCell>
                    <DailyEntriesCell>{data.airCals ? 'X' : ''}</DailyEntriesCell>
                    <DailyEntriesCell>{data.mode}</DailyEntriesCell>
                    <DailyEntriesCell>{data.water.value}</DailyEntriesCell>
                    <DailyEntriesCell>{data.noise.value}</DailyEntriesCell>
                    <DailyEntriesCell>{data.atrifacts ? 'Yes' : 'No'}</DailyEntriesCell>
                    <DailyEntriesCell>{data.outFields.length > 0 ? 'F' : 'P'}</DailyEntriesCell>
                    <DailyEntriesCell>{data.initials}</DailyEntriesCell>
                  </View>
                ))}

                <Text style={styles.accentText}>Action Limits:</Text>
                <View style={styles.autoMarginRow}>
                  <View style={styles.actionLimitColFirst}>
                    <Text style={styles.actionLimitText}>CT (Water):</Text>
                  </View>
                  <View style={styles.actionLimitTableCol}>
                    <Text style={styles.actionLimitText}>0 +/- {device.waterRange} HU</Text>
                  </View>
                </View>
                <View style={styles.autoMarginRow}>
                  <View style={styles.actionLimitColFirst}>
                    <Text style={[styles.actionLimitText, { marginTop: 4 }]}>Noise:</Text>
                  </View>
                  <View style={styles.actionLimitTableCol}>
                    <Text style={[styles.actionLimitText, { marginTop: 4 }]}>
                      {`A: ${Number(axialLimit.toPrecision(4))}, H: ${Number(helicalLimit.toPrecision(4))}`}
                    </Text>
                  </View>
                </View>
              </View>

              <Text style={styles.accentText}>Comments / Corrective Actions:</Text>
              <View style={{ width: '100%', marginTop: 16 }}>
                <Text style={styles.comments}>{qcEntry.comments}</Text>
              </View>
            </View>
            <View style={styles.subContainer}>
              <View style={styles.table}>
                <Text style={styles.checklistSectionTitle}>Monthly Visual Checklist</Text>
                <Text style={styles.checklistSubsectionTitle}>Gantry</Text>
                <MonthlyChecklistRow text="Table Height Indicator" field="tableHeight" entry={qcEntry} />
                <MonthlyChecklistRow text="Table Position Indicator" field="tablePosition" entry={qcEntry} />
                <MonthlyChecklistRow text="Angulation Indicator" field="angulation" entry={qcEntry} />
                <MonthlyChecklistRow text="Laser Localization Light" field="laserLocLight" entry={qcEntry} />
                <MonthlyChecklistRow text="High Voltage Cable/other Cables" field="cables" entry={qcEntry} />
                <MonthlyChecklistRow text="Smoothness of Table Motion" field="smoothness" entry={qcEntry} />
                <MonthlyChecklistRow text="X-Ray on Indicator" field="gantryXray" entry={qcEntry} />

                <Text style={styles.checklistSubsectionTitle}>Control Console</Text>
                <MonthlyChecklistRow text="Exposure Switch" field="exposureSwitch" entry={qcEntry} />
                <MonthlyChecklistRow text="Display Window Width/Level" field="displayWindow" entry={qcEntry} />
                <MonthlyChecklistRow text="Panel Switches / Lights / Meters" field="panelSwitches" entry={qcEntry} />
                <MonthlyChecklistRow text="X-Ray on Indicator" field="consoleXray" entry={qcEntry} />
                <MonthlyChecklistRow text="Door Interlocks" field="doorInterlocks" entry={qcEntry} />
                <MonthlyChecklistRow text="Warning Labels" field="warningLabels" entry={qcEntry} />
                <MonthlyChecklistRow text="Intercom System" field="intercom" entry={qcEntry} />

                <Text style={styles.checklistSubsectionTitle}>Other</Text>
                <MonthlyChecklistRow text="Postings" field="postings" entry={qcEntry} />
                <MonthlyChecklistRow text="Service Records" field="records" entry={qcEntry} />
              </View>

              <View style={styles.table}>
                <Text style={styles.checklistSectionTitle}>Monthly Display Monitor</Text>
                <Text style={styles.checklistSubsectionTitle}>SMPTE Pattern</Text>
                <MonthlyChecklistRow text="0% - 5% Contrast is Discernible" field="contrast0" entry={qcEntry} />
                <MonthlyChecklistRow text="95% - 100% Contrast is Discernible" field="contrast95" entry={qcEntry} />
                <MonthlyChecklistRow text="Distinct Gray Level Steps" field="graySteps" entry={qcEntry} />
                <MonthlyChecklistRow text="Alphanumerics Discernible" field="alphanumerics" entry={qcEntry} />
                <MonthlyChecklistRow text="High Contrast Patterns Visible" field="highPatterns" entry={qcEntry} />
                <MonthlyChecklistRow text="Low Contrast Patterns Visible" field="lowPatterns" entry={qcEntry} />
                <MonthlyChecklistRow text="No Artifacts Detected" field="noArtifacts" entry={qcEntry} />

                <Text style={{ fontSize: 7, marginTop: 8 }}>
                  {`Window: 
                  ${qcEntry.monthly ? qcEntry.monthly.window : ''} Level : 
                  ${qcEntry.monthly ? qcEntry.monthly.level : ''}`}
                </Text>
                <Text style={styles.accentText}>If Available, Scan Manufacturer&apos;s Large Phantom</Text>
                <MonthlyChecklistRow text="Artifacts" field="artifacts" entry={qcEntry} />

                <MonthlyChecklistRow text="Date of Monthly QA" field="enteredDate" entry={qcEntry} />
                <MonthlyChecklistRow text="Initials" field="initials" entry={qcEntry} />

                <View style={{ marginTop: 32 }}>
                  <View style={styles.monthlyChecklistLblView}>
                    <Text style={styles.monthlyChecklistLblText}>Qualified Medical Physicist Reviewer:</Text>
                    <Text style={styles.monthlyChecklistLblText}>
                      {qcEntry?.review?.reviewedBy ? qcEntry?.review?.reviewedBy : null}
                    </Text>
                  </View>
                </View>
                <View style={{ marginTop: 32 }}>
                  <View style={styles.monthlyChecklistLblView}>
                    <Text style={styles.monthlyChecklistLblText}>Date of Review:</Text>
                    <Text style={styles.monthlyChecklistLblText}>
                      {qcEntry.review?.reviewedDate ? qcEntry.review?.reviewedDate : null}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <Text style={styles.copyRight}>© 2020 Radiology QC Solutions LLC</Text>
        </Page>
      ))}
    </Document>
  );
}

QcPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

QcPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default QcPdfPreview;
