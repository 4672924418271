import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { formatMonthDate } from 'utils/time.util';

const styles = {
  icons: {
    fontSize: 16,
  },
  iconButtonRoot: {
    padding: '1rem',
  },
  cellRoots: {
    height: '68px',
    whiteSpace: 'nowrap',
  },
  remarks: {
    width: '100%',
  },
};

function GeoCalibDisplay(props) {
  const { entryData, click, classes } = props;

  let editIcon = null;
  if (click) {
    editIcon = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableRow key={entryData._id} onClick={click}>
      <TableCell size="small">{formatMonthDate(entryData.date)}</TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {entryData.remarks}
      </TableCell>
      <TableCell size="small">{editIcon}</TableCell>
    </TableRow>
  );
}

GeoCalibDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

GeoCalibDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(GeoCalibDisplay);
