import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import radqcApi from 'dataExchange/radqcApiClient';
import { isCtProperty, transformData, defaultDatasetConfig } from 'utils/chart.util';

// TODO: switch to allow multiple chart types
function Chart(props) {
  const { config } = props;
  const { trackingProp, timePeriod, datasetConfigs, filter } = config;
  const [rawData, setRawData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getChartData = async () => {
      let receivedEntries;

      try {
        const entryType = isCtProperty(trackingProp) ? 'qc' : 'qa';
        const { unit, value } = config.timePeriod;
        const subtractValue = unit === 'day' ? value - 1 : value;
        const startDate = moment().subtract(subtractValue, unit).subtract(1, 'month').toISOString();
        const endDate = moment().toISOString();
        const accessToken = await getAccessTokenSilently();
        receivedEntries = await radqcApi(
          'get',
          `/entries/${entryType}/device/${config.deviceId}?start=${startDate}&end=${endDate}&order=ascending`,
          { accessToken },
        );
      } catch (err) {
        console.log('Error:', err);
      }

      if (!receivedEntries) receivedEntries = [];

      setRawData(receivedEntries);
    };

    getChartData();
  }, [config.deviceId, config.timePeriod, trackingProp, getAccessTokenSilently]);

  const [dataLabels, data] = transformData(trackingProp, rawData, timePeriod, Object.keys(datasetConfigs), filter);

  const datasets = Object.keys(datasetConfigs).map((tProp) => ({
    ...defaultDatasetConfig,
    ...datasetConfigs[tProp],
    data: data[tProp],
  }));

  const chartData = {
    labels: dataLabels,
    datasets,
  };

  const options = {
    plugins: {
      legend: {
        align: 'start',
        position: 'right',
      },
      title: {
        display: true,
        text: Object.values(datasetConfigs)[Object.values(datasetConfigs).length - 1].label,
        font: {
          size: 12,
        },
      },
    },
    scales: {
      y: {
        display: true,
        ticks: {
          // suggestedMin: 0,
          maxTicksLimit: 11,
          // minimum will be 0, unless there is a lower value.
          // OR //
          // beginAtZero: true   // minimum value will be 0.
        },
      },
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
        },
      },
    },

    tooltips: {
      callbacks: {
        label: (t, d) => {
          if (d.datasets.find((a) => a.label.includes('over') || a.label.includes('under'))) {
            const overIndex = d.datasets.findIndex((a) => a.label.includes('over'));
            const belowIndex = d.datasets.findIndex((a) => a.label.includes('below'));
            const valueIndex = d.datasets.length - 1;
            const over = d.datasets[overIndex].data[t.index];
            const below = d.datasets[belowIndex].data[t.index];
            if ((over || below) && t.datasetIndex === valueIndex) return null;
          }
          return `${d.datasets[t.datasetIndex].label}: ${+t.yLabel.toFixed(2)}`;
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
}

Chart.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Chart;
