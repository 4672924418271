import React from 'react';
import theme from 'theme';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Header = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
}));

const SubHeader = styled(Typography)((fontSize = '16px') => ({
  fontSize,
  fontWeight: 600,
}));

const TermsDescription = styled(Box)(() => ({
  margin: '16px 0',
  opacity: 0.7,
  fontSize: '14px',
}));

function TermsOfServices() {
  return (
    <Box sx={{ padding: '100px 40px', color: theme.palette.white }}>
      <Header>Terms of Services</Header>
      <Typography>Services Provided by RadQC Solutions</Typography>
      <TermsDescription>
        Rad QC Solutions will provide access to the Rad QC Solutions Service, a web-based service that allows you to
        store, retrieve, organize and share data (the "Service"). Subject to these Terms of Service (including all
        policies linked to it), Rad QC Solutions hereby grants to you a limited, non-exclusive, non-transferable,
        revocable license to access and use the Service solely for your own internal business purposes. All rights not
        expressly granted to you herein are reserved by Rad QC Solutions and its licensors. You are authorized to access
        and use the Service and to otherwise freely use all tools, features, and capabilities of the Service in
        accordance with these Terms of Service. In order to use the Service, you are responsible at your own expense to
        acquire access to the World Wide Web, either directly or through devices that access web-based content, and to
        pay any service fees associated with such access. In addition, you agree to provide all equipment necessary to
        make such connection to the World Wide Web, including a computer and modem or other access device. You shall not
        attempt to access any other of Rad QC Solution’s systems, programs or data that are not made available for
        public use by Rad QC Solutions.
      </TermsDescription>

      <SubHeader fontSize="20px">Service Fees</SubHeader>
      <SubHeader>2.1 Fees</SubHeader>
      <TermsDescription>
        You agree to pay any applicable fees for the Service plan described on your account page. If (a) the number of
        users granted access exceeds the number of users purchased, or (b) you exceed the number of applications,
        attachments, file space or other plan or package entitlements during the subscription term, Rad QC Solutions
        reserves the right to invoice you for such overage. Rad QC Solutions reserves the right to change fees for the
        Service at any time, at its discretion, with notice to you, which notice may be provided to your email address
        on file with Rad QC Solutions or through your Rad QC Solutions account; provided, that any increase in fees
        shall take effect on the renewal of your then-current subscription term.
      </TermsDescription>

      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>2.2 Payment Terms</Typography>
      <TermsDescription>
        Users may have the option to subscribe to a Service plan that provides for (a) monthly or (b) annual payments.
        For monthly subscriptions, fees shall be due and payable to Rad QC Solutions by credit card monthly in advance
        on the billing date as displayed in the Service. For annual or multi-year subscriptions, fees shall be due and
        payable to Rad QC Solutions by wire/ACH transfer within thirty (30) days from the date of Rad QC Solutions
        invoice and you will be invoiced on an annual basis or about the beginning of your subscription start date
        agreed to by you in the Rad QC Solutions order form for the Service. Rad QC Solutions reserves the right to
        suspend the accounts of customers who fail to make payments on their plan(s). All fees are non-refundable, in
        whole or in part, even if the Service is suspended, cancelled or transferred prior to the end of your
        subscription term.
      </TermsDescription>

      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>3.1 Restrictions</Typography>
      <TermsDescription>
        You agree not to copy, sell, rent or sublicense (including offering the Service to third parties on an
        application service provider or time-sharing basis), lease, loan, redistribute, or create a derivative work of
        any portion of the Service (except solely to the extent necessary for you to create and permit your users to use
        your Applications as permitted under Section 7 of these Terms of Service). Such restrictions do not apply to the
        Content and Applications you place on the Service. For the avoidance of doubt, the right to use the Service (a)
        may be exercised by your agents, representatives, contractors and/or customers that are not competitors of Rad
        QC Solutions; provided, that (i) you require such third parties to execute a written agreement with you that is
        at least as protective of the Service as this Agreement and which does not grant any greater rights than those
        granted to you herein and includes all restrictions set forth herein and (ii) you shall be responsible for any
        breach of this Terms of Service by any such third party. You agree not to access the Service by any means other
        than through the interface that is provided by Rad QC Solutions for use in accessing the Service. You will not,
        and will not allow third parties, to otherwise directly or indirectly access the Service in a manner intended to
        avoid incurring licensing fees or other charges including providing access to the Service through a separate
        system, portal or other interface unless specified in an order form.
      </TermsDescription>
      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>3.2 Users: Concurrent Usage Prohibited.</Typography>
      <TermsDescription>
        Your right to access and use the Service is limited solely to use by the number of users for which you have
        purchased a subscription and paid the applicable subscription fees. You may increase the number of users at any
        time by adding additional users through your account page or via an additional order form. The subscription term
        for any additional users added will run concurrently with the then current subscription term and the applicable
        additional subscription fees will be pro-rated based on the remaining then-current subscription term. You are
        responsible for payment of all subscription fees for users ordered during the subscription term, whether or not
        such user is active. Concurrent usage is prohibited. You may only grant access to the Service to those users who
        have been assigned unique access credentials. A single username or password may not be used by more than one (1)
        user. Sharing of access credentials by more than one (1) user is prohibited. Only the identified individual
        associated with unique access credentials can access the Service using such access credentials. You may not
        provide access and revoke access to user accounts on a daily or other regular basis in order to circumvent
        subscription fees.
      </TermsDescription>
    </Box>
  );
}

export default TermsOfServices;
