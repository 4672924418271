/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Typography, Box, Button, MenuItem, InputLabel, Divider } from '@mui/material';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useOrganizationCtx } from 'context/OrganizationContext';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { RadTextInput, RadSelect } from 'presentational/Inputs';
import { DailySchedOpts } from 'types/scheduling';
import palette from 'theme/palette';

export default function DeviceSettingSlideout() {
  const { setOpenDeviceSetting, openDeviceSetting } = useCurrUserCtx();
  const { currAccount, modifyCurrAccount } = useOrganizationCtx();
  const { enqueueSnackbar } = useSnackbar();

  const convertDateStrToTimeStr = (dateStr: any) => {
    const date = new Date(dateStr);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;
    return time;
  };

  const convertTimeStrToDateStr = (timeStr: any) => {
    const date = new Date();
    const [hours, minutes] = timeStr.split(':');
    date.setHours(hours, minutes);
    return date.toISOString();
  };

  if (!currAccount) {
    return null;
  }

  const initialValues = {
    pastDueCheckTime: convertDateStrToTimeStr(currAccount.pastDueCheckTime),
    dailySchedule: currAccount.dailySchedule,
    weeklyWarnDay: currAccount.weeklyWarnDay,
    monthlyWarnBufferDays: currAccount.monthlyWarnBufferDays,
    quarterlyWarnBufferDays: currAccount.quarterlyWarnBufferDays,
    semiannualWarnBufferDays: currAccount.semiannualWarnBufferDays,
    reviewWarnBufferDays: currAccount.reviewWarnBufferDays,
  };

  return (
    <Drawer anchor="right" open={openDeviceSetting} onClose={() => setOpenDeviceSetting(false)}>
      <Box component="div" width="400px" padding="32px">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography fontWeight={600} fontSize="20px">
            Settings
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '6px', fontSize: 14, color: '#494747' }}>
            The following QC schedule account settings are configurable. Be sure to click the 'Update' button to save
            your changes.
          </Typography>

          <Box component="div" sx={{ marginTop: '16px' }}>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                pastDueCheckTime: Yup.string()
                  .required()
                  .min(5, 'Time must be of format HH:MM')
                  .max(5, 'Time must be of format HH:MM'),
                dailySchedule: Yup.string()
                  .oneOf(Object.values(DailySchedOpts), 'Invalid schedule option')
                  .required('Required'),
                weeklyWarnDay: Yup.number()
                  .required('Required')
                  .min(0, 'Must be 0 or greater')
                  .max(6, 'Must be 6 or less'),
                monthlyWarnBufferDays: Yup.number()
                  .required('Required')
                  .min(1, 'Must be 1 or greater')
                  .max(30, 'Must be 30 or less'),
                quarterlyWarnBufferDays: Yup.number()
                  .required('Required')
                  .min(1, 'Must be 1 or greater')
                  .max(90, 'Must be 90 or less'),
                semiannualWarnBufferDays: Yup.number()
                  .required('Required')
                  .min(1, 'Must be 1 or greater')
                  .max(180, 'Must be 180 or less'),
                reviewWarnBufferDays: Yup.number()
                  .required('Required')
                  .min(1, 'Must be 1 or greater')
                  .max(200, 'Must be 200 or less'),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const payload = {
                  pastDueCheckTime: convertTimeStrToDateStr(values.pastDueCheckTime),
                  dailySchedule: values.dailySchedule,
                  weeklyWarnDay: values.weeklyWarnDay,
                  monthlyWarnBufferDays: values.monthlyWarnBufferDays,
                  quarterlyWarnBufferDays: values.quarterlyWarnBufferDays,
                  semiannualWarnBufferDays: values.semiannualWarnBufferDays,
                  reviewWarnBufferDays: values.reviewWarnBufferDays,
                };
                await modifyCurrAccount(payload);
                enqueueSnackbar(`Updated QC Settings`);
                setSubmitting(false);
                setOpenDeviceSetting(false);
              }}
            >
              <Form>
                <Box component="div" sx={{ marginBottom: '16px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Past Due Check
                  </Typography>
                  <InputLabel
                    id="pastDueCheckTime"
                    aria-label="pastDueCheckTime"
                    aria-labelledby="pastDueCheckTime"
                    sx={{ fontSize: '14px', color: palette.secondary, display: 'flex', marginBottom: '4px' }}
                  >
                    All devices will be checked for past due QA each day at
                  </InputLabel>
                  <RadTextInput
                    sx={{ margin: 0, marginTop: '4px', width: '200px' }}
                    name="pastDueCheckTime"
                    type="time"
                    margin="dense"
                    InputProps={{ shrink: 'true' }}
                  />
                </Box>

                <Box component="div" sx={{ marginBottom: '16px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Daily
                  </Typography>
                  <InputLabel
                    id="dailySchedule"
                    aria-label="dailySchedule"
                    aria-labelledby="dailySchedule"
                    sx={{ fontSize: '14px', color: '#000', display: 'flex' }}
                  >
                    On which days will daily checks be performed?
                  </InputLabel>
                  <RadSelect
                    displayEmpty={true}
                    value={initialValues.dailySchedule}
                    name="dailySchedule"
                    defaultValue={initialValues.dailySchedule}
                  >
                    {Object.values(DailySchedOpts).map((schedOption) => (
                      <MenuItem sx={{ textTransform: 'capitalize' }} key={schedOption} value={schedOption}>
                        {schedOption}
                      </MenuItem>
                    ))}
                  </RadSelect>
                </Box>
                <Divider sx={{ border: '1px solid #F2F2F2', marginY: '16px' }} />
                <Box component="div">
                  <Typography fontSize="16px" fontWeight={600}>
                    Weekly
                  </Typography>
                  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel
                      id="weeklyWarnDay"
                      aria-label="weeklyWarnDay"
                      aria-labelledby="weeklyWarnDay"
                      sx={{ fontSize: '14px', color: '#000', display: 'flex', marginRight: '8px' }}
                    >
                      Send past due warning on every
                    </InputLabel>
                    <RadSelect
                      displayEmpty={true}
                      value={initialValues.weeklyWarnDay}
                      name="weeklyWarnDay"
                      defaultValue={initialValues.weeklyWarnDay}
                    >
                      <MenuItem key="3" value={3}>
                        Wednesday
                      </MenuItem>
                      <MenuItem key="4" value={4}>
                        Thursday
                      </MenuItem>
                      <MenuItem key="5" value={5}>
                        Friday
                      </MenuItem>
                      <MenuItem key="6" value={6}>
                        Saturday
                      </MenuItem>
                      <MenuItem key="0" value={0}>
                        Sunday
                      </MenuItem>
                      <MenuItem key="1" value={1}>
                        Monday
                      </MenuItem>
                      <MenuItem key="2" value={2}>
                        Tuesday
                      </MenuItem>
                    </RadSelect>
                  </Box>
                </Box>

                <Divider sx={{ border: '1px solid #F2F2F2', marginY: '16px' }} />

                <Box component="div" sx={{ marginTop: '16px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Monthly
                  </Typography>
                  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel
                      id="monthlyWarnBufferDays"
                      aria-label="monthlyWarnBufferDays"
                      aria-labelledby="monthlyWarnBufferDays"
                      sx={{ fontSize: '14px', color: '#000', display: 'flex', marginRight: '8px' }}
                    >
                      Send warnings
                    </InputLabel>
                    <RadTextInput
                      sx={{ margin: 0, width: '70px' }}
                      name="monthlyWarnBufferDays"
                      type="number"
                      margin="dense"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                    <Typography fontSize="14px" sx={{ marginLeft: '4px' }}>
                      days before{' '}
                      <Typography fontSize="14px" sx={{ marginLeft: '4px', display: 'inline', fontWeight: 600 }}>
                        past due.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>

                <Box component="div" sx={{ marginTop: '4px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Quarterly
                  </Typography>
                  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel
                      id="quarterlyWarnBufferDays"
                      aria-label="quarterlyWarnBufferDays"
                      aria-labelledby="quarterlyWarnBufferDays"
                      sx={{ fontSize: '14px', color: '#000', display: 'flex', marginRight: '8px' }}
                    >
                      Send warnings
                    </InputLabel>
                    <RadTextInput
                      sx={{ margin: 0, width: '70px' }}
                      name="quarterlyWarnBufferDays"
                      type="number"
                      margin="dense"
                      InputProps={{ inputProps: { min: 1, max: 90 } }}
                    />
                    <Typography fontSize="14px" sx={{ marginLeft: '4px' }}>
                      days before{' '}
                      <Typography fontSize="14px" sx={{ marginLeft: '4px', display: 'inline', fontWeight: 600 }}>
                        past due.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>

                <Box component="div" sx={{ marginTop: '4px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Semiannual
                  </Typography>
                  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel
                      id="semiannualWarnBufferDays"
                      aria-label="semiannualWarnBufferDays"
                      aria-labelledby="semiannualWarnBufferDays"
                      sx={{ fontSize: '14px', color: '#000', display: 'flex', marginRight: '8px' }}
                    >
                      Send warnings
                    </InputLabel>
                    <RadTextInput
                      sx={{ margin: 0, width: '70px' }}
                      name="semiannualWarnBufferDays"
                      type="number"
                      margin="dense"
                      InputProps={{ inputProps: { min: 1, max: 180 } }}
                    />
                    <Typography fontSize="14px" sx={{ marginLeft: '4px' }}>
                      days before{' '}
                      <Typography fontSize="14px" sx={{ marginLeft: '4px', display: 'inline', fontWeight: 600 }}>
                        past due.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ border: '1px solid #F2F2F2', marginY: '16px' }} />

                <Box component="div" sx={{ marginTop: '4px' }}>
                  <Typography fontSize="16px" fontWeight={600}>
                    Review
                  </Typography>
                  <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel
                      id="reviewWarnBufferDays"
                      aria-label="reviewWarnBufferDays"
                      aria-labelledby="reviewWarnBufferDays"
                      sx={{ fontSize: '14px', color: '#000', display: 'flex', marginRight: '8px' }}
                    >
                      Send warnings
                    </InputLabel>
                    <RadTextInput
                      sx={{ margin: 0, width: '70px' }}
                      name="reviewWarnBufferDays"
                      type="number"
                      margin="dense"
                      InputProps={{ inputProps: { min: 1, max: 200 } }}
                    />
                    <Typography fontSize="14px" sx={{ marginLeft: '4px' }}>
                      days before{' '}
                      <Typography fontSize="14px" sx={{ marginLeft: '4px', display: 'inline', fontWeight: 600 }}>
                        past due.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ border: '1px solid #F2F2F2', marginY: '16px' }} />

                {/* button container */}
                <Box component="div" sx={{ marginTop: '32px', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    sx={{ width: '180px' }}
                    color="secondary"
                    type="reset"
                    onClick={() => setOpenDeviceSetting(false)}
                  >
                    Cancel
                  </Button>
                  <Button sx={{ width: '180px' }} variant="outlined" color="primary" type="submit">
                    Update
                  </Button>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
