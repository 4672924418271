import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Stepper, Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useStepperCtx } from 'context/StepperContext';
import { generateEmptyMamWeeklyEntry } from 'views/entry/mam/components/mamEntry.util';
import { EntryModes } from 'types/entry';
import CheckDashboard from './check/CheckDashboard';
import ArtifactEvalDashboard from './artifactEval/ArtifactEvalDashboard';
import SnrCnrDashboard from './snrCnr/SnrCnrDashboard';
import PhantomDashboard from './phantom/PhantomDashboard';
import CompressionDashboard from './compression/CompressionDashboard';

const useStyles = makeStyles(() => ({
  // root: {
  //   width: '100%',
  //   border: '1px solid red',
  // },
  button: {
    marginLeft: '1rem',
    '@media (max-width: 600px)': {
      marginLeft: '0',
      margin: '1rem 0',
    },
  },
  completed: {
    display: 'inline-block',
  },
  notShownInSmall: {
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  buttonBox: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
}));

const Tasks = {
  Check: 'View Box /Flat Field',
  ArtEval: 'Artifact Evaluation',
  Phantom2: 'Phantom 2D',
  Phantom3: 'Phantom 3D',
  SnrCnr: 'SNR & CNR',
  Compress: 'Compression',
};

function MamWeeklyTaskView(props) {
  const { match, mamEntries, mode, date, saveEntries } = props;
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const {
    numOfSteps,
    activeStep,
    completed,
    optional,
    skipped,
    setNumOfSteps,
    setOptional,
    setActiveStep,
    toggleComplete,
  } = useStepperCtx();
  // const { enqueueSnackbar } = useSnackbar();
  const scanner = devices.find((a) => match.url.includes(a._id));
  let initEntry = {};
  if (mode === 'enter') {
    initEntry = generateEmptyMamWeeklyEntry(scanner, date, currUser);
  }
  const [activeEntry, setActiveEntry] = useState(initEntry);

  const classes = useStyles();
  let tasks = Object.values(Tasks);

  if (!scanner.is3dSet) {
    tasks = tasks.filter((task) => task !== Tasks.Phantom3);
  }

  // Make the last step optional
  const optionalSteps = new Set([tasks.length - 1]);

  useEffect(() => {
    if (numOfSteps === 0) {
      setNumOfSteps(tasks.length);
      setOptional(optionalSteps);
    }
  });

  if (!activeEntry) {
    return null;
  }

  const totalTasks = () => tasks.length;

  const isTaskOptional = (task) => optional.has(task);

  const skippedTasks = () => skipped.size;

  const completedTasks = () => completed.size;

  const allTasksCompleted = () => completedTasks() === totalTasks() - skippedTasks();

  const isLastTask = () => activeStep === totalTasks() - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (task) => () => {
    setActiveStep(task);
  };

  const handleUpdate = async (entries) => {
    const newEntries = Object.assign(entries);
    if (mode === EntryModes.ENTER) {
      delete newEntries[0]._id;
      const modifiedEntry = {
        ...activeEntry,
        ...newEntries[0],
      };
      setActiveEntry(modifiedEntry);
      toggleComplete();
    } else {
      saveEntries(entries);
      await updateDevices();
    }
    // console.log('modifiedFields:', modifiedFields);
  };

  const handleSubmit = async () => {
    await saveEntries([activeEntry]);
    await updateDevices();
    await updateUsers();
  };

  // const handleReset = () => {
  //   setActiveTask(0);
  //   setCompleted(new Set());
  //   setSkipped(new Set());
  // };

  const isTaskSkipped = (task) => skipped.has(task);

  function isTaskComplete(task) {
    return completed.has(task);
  }

  const getTaskView = (stepNumber) => {
    const task = tasks[stepNumber];
    // console.log('nearbyEntries:', mamEntries);
    switch (task) {
      case Tasks.Check:
        return (
          <CheckDashboard
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep)}
          />
        );
      case Tasks.ArtEval:
        return (
          <ArtifactEvalDashboard
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep)}
          />
        );
      case Tasks.Phantom2:
        return (
          <PhantomDashboard
            device={scanner}
            key={2}
            match={match}
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep)}
          />
        );
      case Tasks.Phantom3: {
        return (
          <PhantomDashboard
            device={scanner}
            key={3}
            match={match}
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep)}
            is3D
          />
        );
      }
      case Tasks.SnrCnr:
        return (
          <SnrCnrDashboard
            device={scanner}
            match={match}
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep)}
          />
        );
      case Tasks.Compress:
        return (
          <CompressionDashboard
            activeEntry={activeEntry}
            mode={mode}
            saveEntries={handleUpdate}
            mamEntries={mamEntries}
            taskId={stepNumber}
            formDisabled={completed.has(activeStep) || skipped.has(activeStep)}
          />
        );
      default:
        throw new Error(`Unrecognized task id: ${task}`);
    }
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {tasks.map((label, index) => {
          const taskProps = {};
          const buttonProps = {};
          if (isTaskOptional(index)) {
            buttonProps.optional = (
              <Typography className={classes.notShownInSmall} variant="caption">
                Optional
              </Typography>
            );
          }
          if (isTaskSkipped(index)) {
            taskProps.completed = false;
          }
          return (
            <Step key={label} {...taskProps}>
              <StepButton onClick={handleStep(index)} completed={isTaskComplete(index)} {...buttonProps}>
                <Box className={classes.notShownInSmall}>{label}</Box>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      {getTaskView(activeStep)}
      <Box className={classes.buttonBox}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={isLastTask()}
          onClick={handleNext}
          className={classes.button}
        >
          Next
        </Button>
        {allTasksCompleted() && (
          <Button className={classes.button} variant="outlined" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Box>
    </div>
  );
}

MamWeeklyTaskView.propTypes = {
  mamEntries: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  saveEntries: PropTypes.func.isRequired,
};

MamWeeklyTaskView.defaultProps = {
  mamEntries: [],
};

export default withRouter(MamWeeklyTaskView);
