import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';

export default function QAEntryDatePicker({ values, setFieldValue, entryIndex }: any) {
  const { date } = values.entries[entryIndex];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        value={dayjs(date)}
        format="MM/DD/YY"
        sx={{
          '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: 1,
            fontSize: 14,
          },
        }}
        onChange={(inputDate: any) => setFieldValue(`entries.${entryIndex}.date`, new Date(inputDate))}
        onError={(_e: any) => values.entries[entryIndex].date?.toString() === 'Invalid Date'}
      />
    </LocalizationProvider>
  );
}
