import React, { useRef, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import gsap from 'gsap';
import { Button } from '@mui/material';
import RadImageOne from 'assets/images/homeImageRADQC.png';
import { homeOne } from 'utils/landing.util';

import './Home.css';

function Home() {
  const boxRef = useRef();
  const node1 = useRef();
  const node2 = useRef();
  useEffect(() => {
    gsap.from([boxRef.current], {
      x: '400px',
      duration: 0.5,
    });

    gsap.from([node1.current, node2.current], {
      delay: 0.3,
      y: 50,
      skewX: 2,
      duration: 0.3,
      opacity: 0,
      stagger: {
        amount: 0.2,
      },
      ease: 'power3.InOut',
    });
  });

  return (
    <div className="home__hero-section darkBg">
      <div className="home__rowContainer">
        <div className="home__topContent">
          <div ref={node1} className="home__mainTitle__top">
            {homeOne.headline}
          </div>
          <p ref={node2} className="home__mainTitle__bottom">
            {homeOne.description}
          </p>

          <Link to="/#contact" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="primary" size="large">
              Let&apos;s get started
            </Button>
          </Link>

          <div className="home__imageContainer">
            <img ref={boxRef} src={RadImageOne} alt="RADQCScreen" className="home__radqc-desktop" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
