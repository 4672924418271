import React from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  buttonLabel: {},
  listItemDense: {},
  primaryStyle: {
    fontSize: '14px',
  },
  secondaryStyle: {
    fontSize: '0.75rem',
  },
  selected: {
    color: 'white',
    backgroundColor: 'green',
  },
  nameButton: {
    '&$selected': {
      backgroundColor: '#808080',
    },
  },
  listPadding: {
    height: '200px',
    overflow: 'auto',
  },
  search: {
    boxShadow: 'none',
    marginLeft: 0,
  },
});

function FacilitiesList(props: { facilities: any; classes: any; selectedIds: any; setSelectedIds: any }) {
  const {
    selectedIds,
    setSelectedIds,
    facilities,

    classes,
  } = props;

  const handleToggleItem = (item: any) => () => {
    let newSelectedIds = [...selectedIds] as any;
    // If the item's id is already in selected remove it, otherwise add it
    if (selectedIds.includes(item._id)) {
      newSelectedIds = newSelectedIds.filter((id: any) => id !== item._id);
    } else {
      newSelectedIds.push(item._id);
    }
    setSelectedIds(newSelectedIds);
  };

  return (
    <>
      <Typography fontSize="14px" color="#808080" sx={{ marginTop: '14px' }}>
        Facilities
      </Typography>
      <List
        sx={{ border: '1px solid #F2F2F2', height: 200, overflowY: 'auto' }}
        classes={{
          padding: classes.listPadding,
        }}
      >
        {facilities.map((item: any) => (
          <ListItemButton
            key={item._id}
            role={undefined}
            dense
            selected={selectedIds.includes(item._id)}
            onClick={handleToggleItem(item)}
            classes={{
              dense: classes.listItemDense,
              root: classes.nameButton,
              selected: classes.selected,
            }}
          >
            <ListItemText
              key={item._id}
              id={`checkbox-list-label-${item}`}
              primary={item.name}
              classes={{
                root: classes.listItemText,
                primary: classes.primaryStyle,
                secondary: classes.secondaryStyle,
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
}
export default withStyles(styles as any)(FacilitiesList);
