import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import InitialLoadingIndicator from 'components/InitialLoadingIndicator';

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently]);

  return accessToken;
};

function OpenApiUi() {
  const accessToken = useAccessToken();
  console.log('Access Token:', accessToken);

  if (!accessToken) {
    // Access token is not available yet
    return (
      <div>
        <InitialLoadingIndicator />
      </div>
    );
  }

  const swaggerUiConfig = {
    url: `${process.env.REACT_APP_API_SERVER_URL}/openapi`,
    requestInterceptor: (request) => {
      // Modify the request to use the accessToken as Bearer Authorization
      request.headers.Authorization = `Bearer ${accessToken}`;
      return request;
    },
  };
  return (
    <div>
      <h2>API Documentation</h2>
      <SwaggerUI {...swaggerUiConfig} />
    </div>
  );
}

export default OpenApiUi;
