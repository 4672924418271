import React from 'react';
import { useField } from 'formik';
import { InputLabel, Select, Checkbox, TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export function RadTextInput({ label = null, hideError = false, sx = {}, ...props }) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  const styles = makeStyles((theme) => ({
    error: {
      color: theme.palette.secondary.light,
      fontSize: '10px',
      marginTop: 0,
    },
  }));
  const classes = styles();

  //TODO disable inputlabel for now, there's overlapping UI issue
  return (
    <>
      {/* <InputLabel
        id={props?.id}
        aria-label={props?.id}
        aria-labelledby={props?.id}
        sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
      >
        {label}
      </InputLabel> */}
      <TextField
        sx={{
          height: '40px',
          borderBottom: '1px solid var(--neutral-100)',
          fontSize: 14,
          input: {
            fontSize: 14,
            margin: '4px 0 0',
            padding: '6px',
            backgroundColor: 'none',
            textOverflow: 'ellipsis',
          },
          backgroundColor: '#FFF',
          borderRadius: '0px',
          '&.Mui-error': {
            color: 'red',
            borderBottom: 'red',
          },
          '&:hover:not(.Mui-disabled):before': {
            border: 'none',
          },
          ...sx,
        }}
        variant="outlined"
        label={label}
        {...field}
        {...props}
      />
      {!hideError && meta.touched && meta.error ? <div className={classes.error}>{meta.error}</div> : null}
    </>
  );
}

export function RadSelect({ label = null, hideError = false, sx = {}, ...props }) {
  const [field, meta] = useField(props);

  const styles = makeStyles((theme) => ({
    error: {
      color: theme.palette.secondary.light,
      fontSize: '10px',
    },
  }));
  const classes = styles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 'auto',
        borderRadius: 0,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: 'menu',
    sx: {
      maxHeight: '16rem',
      fontSize: 14,
      '&& .Mui-selected': {
        backgroundColor: '#f2f2f2',
      },
    },
    MenuListProps: {
      style: {
        boxShadow: 'none',
      },
      sx: {
        '&.MuiList-root.MuiMenu-list': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  };

  return (
    <Box display="flex" flexDirection="column">
      {label && <InputLabel id="type-select-label">{label}</InputLabel>}
      <Select
        disableUnderline
        sx={{
          height: '30px',
          borderRadius: '0px',
          fontSize: 14,
          backgroundColor: '#FFF',
          '& .MuiInputBase-input': {
            padding: '10px 16px',
            fontSize: 14,
            width: '100%',
            textTransform: 'capitalize',
            '&:hover': {
              color: '#000',
            },
            '&:active': {
              color: '#000',
              '.MuiSvgIcon-root': {
                color: '#000',
              },
            },
          },
          '&:hover:not(.Mui-disabled):before': {
            border: 'none',
          },
          '&:hover .MuiSvgIcon-root': {
            color: '#000',
          },
          '&:valid .MuiSvgIcon-root': {
            color: '#000',
          },
          '&.Mui-focused': {
            '& .MuiInputBase-input': {
              color: '#000',
            },
            '& .MuiSvgIcon-root': {
              color: '#000',
            },
          },
          '& .MuiSvgIcon-root': {
            right: 0,
            marginRight: '12px',
            '&:active': {
              color: '#000',
            },
          },
          ...sx,
        }}
        MenuProps={MenuProps}
        displayEmpty
        variant="filled"
        labelId="type-select-label"
        id="type-select"
        {...field}
        {...props}
      />

      {!hideError && meta.touched && meta.error ? <div className={classes.error}>{meta.error}</div> : null}
    </Box>
  );
}

export function RadCheckbox({ children, ...props }) {
  const { name } = props;
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  const styles = makeStyles((theme) => ({
    error: {
      color: theme.palette.secondary.light,
      fontSize: '10px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
  }));
  const classes = styles();

  return (
    <>
      <label htmlFor={`type-checkbox${name}`} className={classes.label}>
        <Checkbox id={`type-checkbox${name}`} {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <div className={classes.error}>{meta.error}</div> : null}
    </>
  );
}
