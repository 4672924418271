import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { formatMonth, formatYear } from 'utils/time.util';

const baseBorderStyle = {
  borderStyle: 'solid',
  borderColor: '#bfbfbf',
  borderWidth: 0.3,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    margin: 16,
    marginTop: 0,
  },
  subContainer: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.3,
    margin: 8,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  deviceInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  table: {
    ...baseBorderStyle,
    display: 'table',
    width: '46.5%',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginRight: 0,
  },
  autoMarginRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  dailyHeader: {
    ...baseBorderStyle,
    borderBottomColor: '#000',
  },
  baseBorderRight: {
    ...baseBorderStyle,
    borderRightWidth: 0.2,
  },
  dailyHeaderTxt: {
    fontSize: 6,
    fontWeight: 500,
  },
  dailyCellTxt: {
    margin: 4,
    fontSize: 6,
  },
  copyRight: {
    fontSize: 8,
    color: palette.primary.main,
    opacity: 0.5,
    marginLeft: 24,
    marginBottom: 32,
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function DailyEntriesHeader(props) {
  const { first, autoMargin, children } = props;
  const widthStyle = first ? { width: '15%' } : { width: '25%' };
  const marginStyle = autoMargin ? { margin: 'auto' } : { margin: 4 };

  return (
    <View style={[styles.dailyHeader, widthStyle]}>
      <Text style={[styles.dailyHeaderTxt, marginStyle]}>{children}</Text>
    </View>
  );
}

function DailyEntriesCell(props) {
  const { first, children } = props;
  const widthStyle = first ? { width: '15%' } : { width: '25%' };

  return (
    <View style={[styles.baseBorderRight, widthStyle]}>
      <Text style={styles.dailyCellTxt}>{children}</Text>
    </View>
  );
}

// Create Document Component
function RwsPdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records?.map((rwsEntry) => (
        <Page key={rwsEntry._id} style={styles.body} orientation="portrait">
          <View fixed style={styles.footer}>
            <Text
              style={styles.header}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
            <Text fixed style={styles.header}>
              Provided By Radiology QC Solutions
            </Text>
          </View>
          <Text style={styles.formTitle}>CT Equipment Quality Control Data Form</Text>

          <View key={rwsEntry._id} style={{ marginBottom: 5, marginTop: 32, marginLeft: 32 }}>
            <Text style={styles.deviceInfo}>Month : {formatMonth(rwsEntry.date)}</Text>
            <Text style={styles.deviceInfo}>Year : {formatYear(rwsEntry.date)}</Text>
            <Text style={styles.deviceInfo}>Facility : {device.facilityName}</Text>
            <Text style={styles.deviceInfo}>Device : {`${device.name} (${device.serialNumber})`}</Text>
          </View>
          <View style={styles.container}>
            <View style={styles.subContainer}>
              <View style={styles.table}>
                <View style={styles.autoMarginRow}>
                  <DailyEntriesHeader first>Day</DailyEntriesHeader>
                  <DailyEntriesHeader>View Box Checked</DailyEntriesHeader>
                  <DailyEntriesHeader>Monitor Checked</DailyEntriesHeader>
                  <DailyEntriesHeader>Remarks</DailyEntriesHeader>
                  <DailyEntriesHeader>Initials</DailyEntriesHeader>
                </View>

                {rwsEntry?.dailyEntries?.map((data) => (
                  <View key={data._id} style={styles.autoMarginRow}>
                    <DailyEntriesCell first>{data.day}</DailyEntriesCell>
                    <DailyEntriesCell>{data.viewingCheckPerformed ? 'X' : ''}</DailyEntriesCell>
                    <DailyEntriesCell>{data.monitorCheckPerformed ? 'X' : ''}</DailyEntriesCell>
                    <DailyEntriesCell>{data.remarks}</DailyEntriesCell>
                    <DailyEntriesCell>{data.initials}</DailyEntriesCell>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <Text style={styles.copyRight}>© 2020 Radiology QC Solutions LLC</Text>
        </Page>
      ))}
    </Document>
  );
}

RwsPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

RwsPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default RwsPdfPreview;
