import React from 'react';
import { TableCell, Table, TableBody, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { visualChecklistFields } from 'views/entry/vc/components/VcTable.util';
import { convertNaPassFail } from 'utils/entry.util';

const styles = (theme) => ({
  noPadding: {
    padding: 0,
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '32px',
    fontSize: '0.75rem',
  },
  table: {
    width: '90px',
  },
  outValue: {
    padding: 0,
    textAlign: 'center',
    height: '32px',
    fontSize: '0.75rem',
    color: theme.palette.primary.red,
  },
});

function VcEntryDisplay(props) {
  const { entryData, click, classes } = props;

  const getStyleForField = (field) =>
    entryData?.outFields.includes(field) ? classes.outValue : classes.tableCellStyle;

  return (
    <TableCell onClick={click} classes={{ root: classes.noPadding }}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          {visualChecklistFields.map((field) => (
            <TableRow key={field}>
              <TableCell className={getStyleForField(field)}>{convertNaPassFail(entryData[field])}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className={classes.tableCellStyle}>{entryData.initials}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>
              {entryData?.reviewedBy ? `${entryData?.reviewedBy} on ${formatDate(entryData?.reviewedDate)}` : null}
            </TableCell>
          </TableRow>
          {click && (
            <TableRow>
              <TableCell className={classes.tableCellStyle} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableCell>
  );
}

VcEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

VcEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(VcEntryDisplay);
