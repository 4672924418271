import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import commonPreviewStyles from './commonStyles';
import { formatDateShort, getCurrentYear } from 'utils/time.util';

// Create styles
const styles = StyleSheet.create({
  ...commonPreviewStyles,
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  entryTable: {
    ...commonPreviewStyles.entryTable,
    marginTop: 0,
  },
  chartSections: {
    ...commonPreviewStyles.chartSections,
    marginTop: 0,
  },
});

function PhantomPdfPreview(props) {
  const { records, device, addProps } = props;
  const { dim, chartImages } = addProps;

  if (!chartImages) {
    return <Document />;
  }

  return (
    <Document>
      {records &&
        records.map((record) => (
          <Page key={record.entries[0]._id} style={styles.body} orientation="portrait">
            <View fixed style={styles.footer}>
              <Text
                fixed
                style={styles.header}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              />
              <Text fixed style={styles.header}>
                Provided By Radiology QC Solutions
              </Text>
            </View>
            <Text style={styles.formTitle}>Phantom {dim}D Control Chart</Text>
            <View style={styles.scannerInfoView}>
              <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
              <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
              <Text style={styles.scannerInfo}>Phantom Serial #: {record.entries[0].phantomSerialNumber}</Text>
            </View>
            <View style={styles.entryTable}>
              <View style={styles.subContainerRight}>
                <Text style={styles.entryHeader}>Date</Text>
                <Text style={styles.entryHeader}>Initials</Text>
                <Text style={styles.entryHeader}>Filter</Text>
                <Text style={styles.entryHeader}>kVp</Text>
                <Text style={styles.entryHeader}>mAs</Text>
                <Text style={styles.entryHeader}>El</Text>
                <Text style={styles.entryHeader}>Fibers</Text>
                <Text style={styles.entryHeader}>Specks</Text>
                <Text style={styles.entryHeader}>Masses</Text>
              </View>
              {record.entries.map((entry) => (
                <View key={entry._id} style={styles.subContainerRight}>
                  <Text style={styles.entry}>{formatDateShort(entry.date)}</Text>
                  <Text style={styles.entry}>{entry.initials}</Text>
                  <Text style={styles.entry}>{dim === 3 ? 'Al' : 'Rh'}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}Kvp`]}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}Mas`]}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}El`]}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}Fibers`]}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}Specks`]}</Text>
                  <Text style={styles.entry}>{entry[`phantom${dim}Masses`]}</Text>
                </View>
              ))}
            </View>
            <Text style={styles.chartTitle}>Summary Charts (See Below).</Text>
            <View style={styles.chartSections}>
              {chartImages[`phantom${dim}Fibers`] && (
                <Image style={styles.charts} src={chartImages[`phantom${dim}Fibers`]} />
              )}
            </View>
            <View style={styles.chartSections}>
              {chartImages[`phantom${dim}Specks`] && (
                <Image style={styles.charts} src={chartImages[`phantom${dim}Specks`]} />
              )}
            </View>
            <View style={styles.chartSections}>
              {chartImages[`phantom${dim}Masses`] && (
                <Image style={styles.charts} src={chartImages[`phantom${dim}Masses`]} />
              )}
            </View>

            <View style={styles.remarksHeaderView}>
              <Text style={styles.remarksTableShort}>Date</Text>
              <Text style={styles.remarksTableShort}>Initials</Text>
              <Text style={styles.remarks}>Actions</Text>
            </View>
            {record.entries.map(
              (entry) =>
                entry[`phantom${dim}Remarks`].length > 0 && (
                  <View key={entry._id} style={styles.remarksView}>
                    <Text style={styles.remarksTableShort}>{formatDateShort(entry.date)}</Text>
                    <Text style={styles.remarksTableShort}>{entry.initials}</Text>
                    <Text style={styles.remarks}>{entry[`phantom${dim}Remarks`]}</Text>
                  </View>
                ),
            )}

            <View fixed style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
              <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
            </View>
          </Page>
        ))}
    </Document>
  );
}

PhantomPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
  addProps: PropTypes.objectOf(PropTypes.any),
};

PhantomPdfPreview.defaultProps = {
  records: [],
  device: null,
  addProps: null,
};

export default PhantomPdfPreview;
