import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'components/ConfirmDialog';
import radqcApi from 'dataExchange/radqcApiClient';
import { useAuth0 } from '@auth0/auth0-react';
import { useModalCtx } from 'context/ModalContext';
import { useDeviceCtx } from 'context/DeviceContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import ModifyDevice from 'components/Devices/ModifyDevice';
import { isAdmin } from 'utils/user.util';

interface DeviceRowActionProps {
  device: any;
}

function DeviceRowAction({ device }: DeviceRowActionProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getAccessTokenSilently } = useAuth0();
  const { updateDevices } = useDeviceCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDevice = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal(ModifyDevice, { selectedDevice: device });
    setAnchorEl(null);
  };

  const removeDevice = async () => {
    const accessToken = await getAccessTokenSilently();
    await radqcApi('put', `/devices/${device._id}`, { visible: false }, { accessToken });
    enqueueSnackbar(`Deleted device: ${device.name}`);
    updateDevices();
  };

  const handleDeleteDevice = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal(ConfirmDialog, {
      message: 'Permanently delete this device?',
      actionText: 'Remove Device',
      confirmAction: () => removeDevice(),
    });
    setAnchorEl(null);
  };

  return (
    <Box component="div">
      <IconButton
        sx={{ padding: 0 }}
        size="small"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{ fontSize: 14 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ fontSize: 14 }} onClick={(e: any) => handleEditDevice(e)}>
          Edit Device
        </MenuItem>
        <MenuItem
          onClick={(e: any) => handleDeleteDevice(e)}
          sx={{ color: 'red', fontSize: 14 }}
          disabled={!isAdmin(currUser)}
        >
          Delete Device
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default DeviceRowAction;
