import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useModalCtx } from 'context/ModalContext';
import { formatDate } from 'utils/time.util';
import QcMonthlyEntry from './QcMonthlyEntry';

const styles = (theme) => ({
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0 1rem',
    padding: '0 0.5rem 0 1rem',
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    borderRight: `1px solid ${theme.palette.primary.light}`,
    '@media (max-width: 900px)': {
      margin: '1rem 0',
      border: 'none',
      padding: 0,
    },
  },
  entryButton: {
    marginLeft: '14px',
    textTransform: 'capitalize',
    fontSize: '0.8rem',
  },
  subSummary: {
    color: '#A9A6A6',
    display: 'flex',
    fontSize: 14,
    flexDirection: 'column',
    alignItems: 'flexStart',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
  },
});

function QcMonthlySummary(props) {
  const { qcEntry, updateQcEntry, mode, classes } = props;
  const { showModal } = useModalCtx();
  // TODO: select data list or entry list based on mode
  let action = mode.charAt(0).toUpperCase() + mode.slice(1);
  let monthlySummary = <div className={classes.subSummary}>Entry not completed</div>;
  const disableMonthlyBtn = mode === 'review' && !qcEntry?.monthlyEntry;
  const disableMonthlyQc = mode === 'review' || (mode === 'enter' && qcEntry?.monthlyEntry);

  if (qcEntry && qcEntry.monthlyEntry) {
    monthlySummary = (
      <div className={classes.subSummary}>
        <div>Date: {formatDate(qcEntry.monthlyEntry.enteredDate)}</div>
        <div>Entered By: {qcEntry.monthlyEntry.initials}</div>
      </div>
    );
    if (mode === 'enter') {
      action = 'View';
    }
  }
  if (mode === 'review') {
    action = 'View';
  }

  return (
    <div className={classes.summaryContainer}>
      <Typography sx={{ fontSize: 14 }}>Monthly QC</Typography>
      <Box className={classes.content}>
        {monthlySummary}
        <Button
          variant="outlined"
          disableRipple
          onClick={() =>
            showModal(QcMonthlyEntry, { qcEntry, disableMonthlyQc, updateQcEntry, noBackdropCancel: true })
          }
          disabled={disableMonthlyBtn}
          className={classes.entryButton}
        >
          {action}
        </Button>
      </Box>
    </div>
  );
}

QcMonthlySummary.propTypes = {
  mode: PropTypes.string.isRequired,
  qcEntry: PropTypes.objectOf(PropTypes.any),
  updateQcEntry: PropTypes.func.isRequired,
};

QcMonthlySummary.defaultProps = {
  qcEntry: null,
};
export default withStyles(styles)(QcMonthlySummary);
