/* eslint-disable array-callback-return */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { useUserCtx } from 'context/UserContext';
import { useDeviceCtx } from 'context/DeviceContext';
import radqcApi from 'dataExchange/radqcApiClient';
import DevicesUsersContainer from './DevicesUsers';

const styles = () => ({
  mainContainer: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'auto',
    overflowX: 'auto',
    backgroundColor: '#FAFAFA',
    flexWrap: 'wrap',
  },
});

function DeviceUsers(props) {
  const { match, classes } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { users, updateUsers } = useUserCtx();
  const { devices, updateDevices } = useDeviceCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));
  const deviceUsers = users.filter((user) => device.users.includes(user._id));

  const updateDeviceUsers = async (newIds) => {
    const accessToken = await getAccessTokenSilently();
    await radqcApi('put', `/devices/${device._id}/users`, { users: newIds }, { accessToken });
    enqueueSnackbar(`Users updated for ${device.name}`);
    updateDevices();
    updateUsers();
  };

  return (
    <Box className={classes.mainContainer}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: 16 }}>Update User Access</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography color="#595959" fontSize={14} marginBottom={2}>
            This table shows the list of users that have access to this device.
          </Typography>
          <Typography color="#595959" backgroundColor="#e4e4ff" fontSize={14} marginBottom={2} marginLeft={4}>
            *Saved users are highlighted like this
          </Typography>
        </Box>
      </Box>

      <DevicesUsersContainer
        deviceUsers={deviceUsers}
        device={device}
        users={users}
        updateDeviceUsers={updateDeviceUsers}
      />
    </Box>
  );
}

export default withRouter(withStyles(styles)(DeviceUsers));
