import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import ForceCompTable from './forceCompTable/ForceCompTable';

const styles = {
  table: {
    height: '100%',
  },
};

function ForceCompDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedEntries, setSavedEntries] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getEntries = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedEntries;
    const startDate = date.start.toISOString();
    // Add 1 day to the end date to handle timezones for brand new entries
    let endDate = new Date(date.end);
    const now = new Date();
    endDate.setDate(now.getDate() + 1);
    endDate = endDate.toISOString();

    try {
      const accessToken = await getAccessTokenSilently();
      receivedEntries = await radqcApi('get', `/entries/mam_cf/device/${deviceId}?start=${startDate}&end=${endDate}`, {
        accessToken,
      });
    } catch (err) {
      console.log(err);
    }
    if (!receivedEntries) receivedEntries = [];
    if (isMounted) {
      setSavedEntries(receivedEntries);
    }
  }, [match.url, date.start, date.end, getAccessTokenSilently, isMounted]);

  useEffect(() => {
    setIsMounted(true);
    getEntries();

    return () => setIsMounted(false);
  }, [mode, date, getEntries]);

  if (mode === 'view') {
    const numEntriesPerRecord = 10;

    const records = splitEntriesIntoRecords(savedEntries, numEntriesPerRecord);

    return <RecordViewer recordType="Force Compression" records={records} />;
  }

  return (
    <Grid item xs={12} className={classes.table}>
      <ForceCompTable
        key={mode + savedEntries.length}
        forceCompEntries={savedEntries}
        mode={mode}
        updateEntries={getEntries}
      />
    </Grid>
  );
}

ForceCompDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(ForceCompDashboard));
