import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Terms from 'views/unauthenticated/landing/components/Terms';
import Policies from 'views/unauthenticated/landing/components/Policies';
import RouteWithLayout from 'components/RouteWithLayout';
import UnauthorizedLayout from '../components/Layouts/Unauthorized/UnauthorizedLayout';
import LandingPage from 'views/unauthenticated/landing/LandingPage';

function UnauthenticatedRoutes() {
  return (
    <Switch>
      <Route
        path="/terms"
        render={(props) => (
          <RouteWithLayout path={props.location.pathname} component={Terms} layout={UnauthorizedLayout} />
        )}
      />
      <Route
        path="/policies"
        render={(props) => (
          <RouteWithLayout path={props.location.pathname} component={Policies} layout={UnauthorizedLayout} />
        )}
      />
      <Route
        path="/"
        render={(props) => (
          <RouteWithLayout path={props.location.pathname} component={LandingPage} layout={UnauthorizedLayout} />
        )}
      />
      <Redirect from="/" to="/" />
    </Switch>
  );
}

export default UnauthenticatedRoutes;
