/* eslint-disable */

// Fields
// TODO: move this to mamEntry.util once it is a typescript file
export enum MamEntryFields {
  viewBoxRemarks = 'viewBoxRemarks',
  flatFieldRemarks = 'flatFieldRemarks',
  rhKvp = 'rhKvp',
  rhMas = 'rhMas',
  rhArtifacts = 'rhArtifacts',
  agKvp = 'agKvp',
  agMas = 'agMas',
  agArtifacts = 'agArtifacts',
  alKvp = 'alKvp',
  alMas = 'alMas',
  alArtifacts = 'alArtifacts',
  artEvalRemarks = 'artEvalRemarks',
  phantom2Fibers = 'phantom2Fibers',
  phantom2Specks = 'phantom2Specks',
  phantom2Masses = 'phantom2Masses',
  phantom2Remarks = 'phantom2Remarks',
  phantom3Fibers = 'phantom3Fibers',
  phantom3Specks = 'phantom3Specks',
  phantom3Masses = 'phantom3Masses',
  phantom3Remarks = 'phantom3Remarks',
  snr = 'snr',
  cnr = 'cnr',
  cnrKvp = 'cnrKvp',
  cnrMas = 'cnrMas',
  cnrEl = 'cnrEl',
  compThickness = 'compThickness',
  compRemarks = 'compRemarks',
  qtrlyTotalExps = 'totalExposures',
  qtrlyTotalReasons = 'totalWithReasons',
  qtrlyRatio = 'ratio',
  compForceAuto = 'autoCompression',
  compForceManual = 'manualCompression',
}

// Conditions

// Mam Weekly
const weeklyArtifactEvalOut = (value: boolean): boolean => value;

// Mam Phantom
const phantomLimits = {
  [MamEntryFields.phantom2Fibers]: 5,
  [MamEntryFields.phantom2Specks]: 4,
  [MamEntryFields.phantom2Masses]: 4,
  [MamEntryFields.phantom3Fibers]: 4,
  [MamEntryFields.phantom3Specks]: 3,
  [MamEntryFields.phantom3Masses]: 3,
};

const phantomOut = (value: number, field: keyof typeof phantomLimits): boolean => {
  return value < phantomLimits[field];
};

// Mam SNR/CNR
const WEEKLY_SNR_MIN: number = 40;
const WEEKLY_CNR_MAX_PERCENT_DIFF: number = 1.15;

const snrOut = (value: number): boolean => value < WEEKLY_SNR_MIN;

const cnrOut = (value: number, baseline: number): boolean => {
  return Math.abs(value) > Math.abs(baseline * WEEKLY_CNR_MAX_PERCENT_DIFF);
};

// Mam SNR/CNR
const WEEKLY_COMP_MAX: number = 4.5;
const WEEKLY_COMP_MIN: number = 3.5;

const weeklyCompOut = (value: number): boolean => value > WEEKLY_COMP_MAX || value < WEEKLY_COMP_MIN;

// Mam Compression Force
const COMP_FORCE_AUTO_MAX: number = 45.0;
const COMP_FORCE_AUTO_MIN: number = 25.0;

const compForceAutoOut = (value: number): boolean => value > COMP_FORCE_AUTO_MAX || value < COMP_FORCE_AUTO_MIN;
// const compForceManualOut = (value: number): boolean => true;

export const isValueOut = (field: MamEntryFields, value: any, vals: any) => {
  switch (field) {
    case MamEntryFields.rhArtifacts:
    case MamEntryFields.agArtifacts:
    case MamEntryFields.alArtifacts:
      return weeklyArtifactEvalOut(value);
    case MamEntryFields.phantom2Fibers:
    case MamEntryFields.phantom2Specks:
    case MamEntryFields.phantom2Masses:
    case MamEntryFields.phantom3Fibers:
    case MamEntryFields.phantom3Specks:
    case MamEntryFields.phantom3Masses:
      return phantomOut(value, field);
    case MamEntryFields.snr:
      return snrOut(value);
    case MamEntryFields.cnr:
      const { cnrBaseline } = vals;
      return cnrOut(value, cnrBaseline);
    case MamEntryFields.compThickness:
      return weeklyCompOut(value);
    case MamEntryFields.compForceAuto:
      return compForceAutoOut(value);
    default:
      throw Error(`Unrecognized MAM entry field: ${field}`);
  }
};

export const updateOutFields = (e: any, vals: any, setVal: any, fromEntries = false): Array<string> => {
  let field = e.target.name;
  let prefix = '';
  if (fromEntries) {
    prefix = e.target.name.substring(0, e.target.name.lastIndexOf('.')) + '.';
    field = e.target.name.substring(e.target.name.lastIndexOf('.') + 1);
  }
  const { value } = e.target;
  let { outFields } = vals;

  if (isValueOut(field, value, vals)) {
    if (!outFields.includes(field)) {
      outFields.push(field);
    }
  } else {
    outFields = outFields.filter((currfield: any) => currfield !== field);
  }
  setVal(e.target.name, value);
  setVal(`${prefix}outFields`, outFields);
  return outFields;
};
