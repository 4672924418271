import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import VcTable from './vcTable/VcTable';

const styles = {
  dailyTable: {
    height: '100%',
  },
};

function VcDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedVcEntries, setSavedVcEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const getEntries = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedVcEntries;
    const startDate = date.start.toISOString();
    // Add 1 day to the end date to handle timezones for brand new entries
    let endDate = new Date(date.end);
    const now = new Date();
    endDate.setDate(now.getDate() + 1);
    endDate = endDate.toISOString();

    try {
      const accessToken = await getAccessTokenSilently();
      receivedVcEntries = await radqcApi(
        'get',
        `/entries/vc/device/${deviceId}?start=${startDate}&end=${endDate}&order=descending`,
        {
          accessToken,
        },
      );
    } catch (err) {
      console.log(err);
    }
    if (!receivedVcEntries) receivedVcEntries = [];
    setSavedVcEntries(receivedVcEntries);
  }, [match.url, date.start, date.end, getAccessTokenSilently]);

  useEffect(() => {
    getEntries();
  }, [mode, date, getEntries]);

  if (mode === 'view') {
    const numEntriesPerRecord = 7;

    const records = splitEntriesIntoRecords(savedVcEntries, numEntriesPerRecord);

    return <RecordViewer recordType="vc" records={records} />;
  }

  return (
    <Grid item xs={12} className={classes.dailyTable}>
      <VcTable key={mode} vcEntries={savedVcEntries} mode={mode} updateEntries={getEntries} />
    </Grid>
  );
}

VcDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(VcDashboard));
