import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { useModalCtx } from 'context/ModalContext';
import Backdrop from './Backdrop';
import { Box } from '@mui/material';

const styles = () => ({
  modal: {
    position: 'fixed',
    zIndex: 1300,
    overflowY: 'auto',
    backgroundColor: 'white',
    maxWidth: 740,
    minWidth: '300px',
    border: 'none',
    borderRadius: '10px',
    boxShadow: '6px 6px 18px 1px #676767',
    padding: '32px',
    top: '5%',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease-out',
    transform: 'translateY(-100vh)',
    opacity: '0',
  },
  active: {
    transform: 'translateY(0)',
    opacity: '1',
  },
});

function Modal(props) {
  const { classes } = props;
  const { component: Component, modalProps, hideModal } = useModalCtx();
  const backDropFuncion = modalProps && modalProps.noBackdropCancel ? () => {} : hideModal;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Backdrop show={Component !== null} clicked={backDropFuncion} />
      <div className={clsx(classes.modal, Component !== null && classes.active)}>
        {Component ? <Component {...modalProps} /> : <div />}
      </div>
    </Box>
  );
}

export default withStyles(styles)(Modal);
