import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { TableRow, TableCell, Input, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatMonthDate } from 'utils/time.util';
import { RadTextInput } from 'presentational/Inputs';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  deleteIcon: {
    color: theme.palette.primary.red,
  },
  iconButtonRoot: {
    padding: '1rem',
  },
  inputProps: {
    padding: '0.2rem 0',
    fontSize: '0.725rem',
    width: '100%',
  },
});

function GeoCalibForm(props) {
  const { entryData, classes, click } = props;

  const entryIndex = entryData._id || 'new';

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableRow>
      <TableCell size="small">
        {formatMonthDate(entryData.date)}
        <Input type="hidden" name={`entries.${entryIndex}.date`} value={entryData.date} />
      </TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small">
        <RadTextInput
          inputProps={{
            style: {
              padding: '0.5rem',
            },
          }}
          name={`entries.${entryIndex}.remarks`}
          type="text"
          classes={{ root: classes.remarks }}
        />
      </TableCell>
      <TableCell size="small">{cancelButton}</TableCell>
    </TableRow>
  );
}

GeoCalibForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

GeoCalibForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(GeoCalibForm);
