export default {
  h1: { fontSize: 'h1' },
  h2: { fontSize: 'h2' },
  h3: { fontSize: 'h3' },
  h4: { fontSize: 'h4' },
  h5: { fontSize: 'h5' },
  h6: { fontSize: 'h6' },
  caption: { fontSize: '0.75rem' },
  subtitle2: { fontSize: '0.6rem' },
};
