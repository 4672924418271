import React, { useState } from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { isByMonthEntry } from 'utils/entry.util';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const styles = {
  dateInputContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media (max-width: 520px)': {
      flexDirection: 'column',
    },
  },
  datePicker: {
    margin: '1rem',
  },
};

function DateSelection(props) {
  const { mode, date, entryType, dateChange, classes } = props;
  const [workingDate, setWorkingDate] = useState(date);
  let startLabel = 'From';
  let isEndControlSet = true;
  const dateFormat = isByMonthEntry(entryType) ? 'MM/YYYY' : 'MM/DD/YYYY';

  if (isByMonthEntry(entryType) && mode !== 'view') {
    startLabel = 'Month';
    isEndControlSet = false;
  } else if ((entryType.includes('mam_wk') || entryType === 'mam_qrt') && mode !== 'view') {
    startLabel = 'Entry Date';
    isEndControlSet = false;
  } else if (mode === 'enter') {
    isEndControlSet = false;
  }

  const dateValidator = (field, newDate) => {
    const validateDate = newDate || workingDate;
    if (validateDate[field].toString() === 'Invalid Date') {
      return 'Invalid date string';
    }
    if (isEndControlSet && validateDate.start.setUTCHours(12, 0, 0) > validateDate.end.setUTCHours(12, 0, 0)) {
      if (field === 'start') {
        return 'Cannot be later than end date';
      }
      return 'Cannot be earlier than start date';
    }
    return '';
  };

  const handleDateChange = (inputDate, field) => {
    const newDates = { ...workingDate };
    const newDate = new Date(inputDate);
    if (isByMonthEntry(entryType)) {
      if (field === 'start') {
        newDate.setDate(1);
      } else {
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(-1);
      }
    }
    newDates[field] = newDate;
    setWorkingDate(newDates);
    // console.log('newDates:', newDates);

    if (dateValidator(field, newDates) === '') {
      dateChange(newDates);
    }
  };

  let startDateControl = null;

  if (entryType !== 'mam_qrt' || mode === 'view') {
    startDateControl = (
      <MobileDatePicker
        label={startLabel}
        format={dateFormat}
        views={isByMonthEntry(entryType) ? ['month', 'year'] : undefined}
        value={dayjs(workingDate.start)}
        onChange={(inputDate) => handleDateChange(inputDate, 'start')}
        onError={() => dateValidator('start') !== ''}
        minDate={dayjs(new Date('2015-01-01'))}
      />
    );
  }

  let endDateControl = null;

  if (isEndControlSet) {
    endDateControl = (
      <MobileDatePicker
        label="To"
        format={dateFormat}
        views={isByMonthEntry(entryType) ? ['month', 'year'] : undefined}
        value={dayjs(workingDate.end)}
        onChange={(inputDate) => handleDateChange(inputDate, 'end')}
        onError={() => dateValidator('end') !== ''}
        minDate={dayjs(workingDate.start)}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={classes.dateInputContainer}>
        {startDateControl}
        {endDateControl}
      </Box>
    </LocalizationProvider>
  );
}

DateSelection.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  entryType: PropTypes.string.isRequired,
  dateChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(DateSelection);
