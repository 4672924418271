import React from 'react';
import clsx from 'clsx';
import { Table, TableRow, TableCell, TableHead, TableBody, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  tableTitles: {
    color: 'black',
    fontSize: '0.8rem',
    padding: '5px 0',
    textAlign: 'center',
    height: '60px',
    minWidth: '60px',
  },
  tableStyle: { overflow: 'auto', minWidth: '537px' },
  widthFixed: {
    width: '161px',
  },
  headerTableRow: { backgroundColor: 'transparent' },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.red}`,
  },
});

function QcConfirmTable(props) {
  const { classes, confirmEntries } = props;

  return (
    <Table stickyHeader className={classes.tableStyle}>
      <TableHead>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={classes.tableTitles}>Day</TableCell>
          <TableCell className={classes.tableTitles}>Warm Up</TableCell>
          <TableCell className={classes.tableTitles}>Air Cals</TableCell>
          <TableCell className={classes.tableTitles}>Mode</TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.widthFixed)}>
            CT<sub>water</sub> (HU)
          </TableCell>
          <TableCell className={clsx(classes.tableTitles, classes.widthFixed)}>Noise (SD)</TableCell>
          <TableCell className={classes.tableTitles}>Artifacts</TableCell>
          <TableCell className={classes.tableTitles}>Pass/Fail</TableCell>
          <TableCell className={classes.tableTitles}>Initials</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {confirmEntries.map((entry) => {
          if (entry.outFields) {
            return (
              <TableRow key={entry.day} className={classes.headerTableRow}>
                <TableCell className={classes.tableTitles}>{entry.day}</TableCell>
                <TableCell className={classes.tableTitles}>
                  <Checkbox disabled checked={!!entry.warmUp} />
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Checkbox disabled checked={!!entry.airCals} />
                </TableCell>
                <TableCell className={classes.tableTitles}>{entry.mode}</TableCell>
                <TableCell
                  className={clsx(
                    classes.tableTitles,
                    classes.widthFixed,
                    entry.outFields.includes('water') ? classes.outFieldValue : null,
                  )}
                >
                  {entry.water.value}
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableTitles,
                    classes.widthFixed,
                    entry.outFields.includes('noise') ? classes.outFieldValue : null,
                  )}
                >
                  {entry.noise.value}
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableTitles,
                    entry.outFields.includes('artifacts') ? classes.outFieldValue : null,
                  )}
                >
                  {entry.artifacts ? 'Yes' : 'No'}
                </TableCell>
                <TableCell className={classes.tableTitles}>{entry.outFields.length === 0 ? 'Pass' : 'Fail'}</TableCell>
                <TableCell className={classes.tableTitles}>{entry.initials}</TableCell>
              </TableRow>
            );
          }
          return null;
        })}
      </TableBody>
    </Table>
  );
}

QcConfirmTable.propTypes = {
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(QcConfirmTable);
