import React from 'react';
import { Table, TableRow, TableBody } from '@mui/material';
import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import CommentIcon from '@mui/icons-material/Comment';
import { formatMonthDate } from 'utils/time.util';
import QaTableHeader from './components/QaTableHeader';
import NoPadTableCell from './qaTable/components/NoPadTableCell';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  tableStyle: { overflow: 'auto', minWidth: '537px' },
  headerTableRow: { backgroundColor: 'transparent' },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.red}`,
  },
  header: {
    overflowX: 'hidden',
  },
  tableBody: {
    border: '1px solid green',
  },
});

function QaConfirmTable(props) {
  const { classes, confirmEntries } = props;

  const displayBool = (bool) => {
    if (bool) {
      return 'Y';
    }
    return 'N';
  };

  return (
    <Table stickyHeader className={classes.tableStyle}>
      <QaTableHeader confirmation />
      <TableBody className={classes.tableBody}>
        {confirmEntries.map((entry) => {
          const getStyleForField = (field) => (entry.outFields.includes(field) ? classes.outFieldValue : null);

          return (
            <TableRow key={entry._id} className={classes.headerTableRow}>
              <NoPadTableCell width="6.70%">{formatMonthDate(entry.date)}</NoPadTableCell>
              <NoPadTableCell width="6.85%" className={getStyleForField('setupAccuracy')}>
                {entry.setupAccuracy}
              </NoPadTableCell>
              <NoPadTableCell width="6.75%" className={getStyleForField('setupConsole')}>
                {displayBool(entry.setupConsole)}
              </NoPadTableCell>
              <NoPadTableCell width="8%" className={getStyleForField('centerFreq')}>
                {entry.centerFreq}
              </NoPadTableCell>
              <NoPadTableCell width="7.4%" className={getStyleForField('txGainAtten')}>
                {entry.txGainAtten}
              </NoPadTableCell>
              <NoPadTableCell width="7.5%" className={getStyleForField('geoAccuracyHf')}>
                {entry.geoAccuracyHf}
              </NoPadTableCell>
              <NoPadTableCell width="7.65%" className={getStyleForField('geoAccuracyAp')}>
                {entry.geoAccuracyAp}
              </NoPadTableCell>
              <NoPadTableCell width="7.65%" className={getStyleForField('geoAccuracyRl')}>
                {entry.geoAccuracyRl}
              </NoPadTableCell>
              <NoPadTableCell width="7.8%" className={getStyleForField('spatialResUpper')}>
                {entry.spatialResUpper}
              </NoPadTableCell>
              <NoPadTableCell width="7.95%" className={getStyleForField('spatialResLower')}>
                {entry.spatialResLower}
              </NoPadTableCell>
              <NoPadTableCell width="9%">{entry.spokesNum}</NoPadTableCell>
              <NoPadTableCell width="8.25%" className={getStyleForField('artifactEval')}>
                {displayBool(entry.artifactEval)}
              </NoPadTableCell>
              <NoPadTableCell width="13.5%">{entry.initials}</NoPadTableCell>
              <NoPadTableCell width="7%">
                <CommentIcon disabled className={classes.icons} />
              </NoPadTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

QaConfirmTable.propTypes = {
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(QaConfirmTable);
