import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, Box, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { getUserInitials } from 'utils/user.util';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import RwsWeeklyEntryForm from './components/RwsWeeklyEntryForm';
import radqcApi from 'dataExchange/radqcApiClient';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import RwsWeeklyEntryDisplay from './components/RwsWeeklyEntryDisplay';

const styles = (theme) => ({
  headerTableRow: { backgroundColor: 'transparent' },
  tableContainer: {
    height: 460,
    overflowY: 'auto',
    boxShadow: 'none',
  },
  bottomNavigation: {
    width: '96%',
    marginLeft: '20px',
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: `${theme.palette.primary.main}0f`,
  },
});

const generateEmptyEntry = (currUser, device) => ({
  _id: 'new',
  deviceId: device._id,
  date: new Date(),
  remarks: '',
  outFields: [],
  initials: getUserInitials(currUser),
  enteredById: currUser._id,
});

function RwsWeeklyTable(props) {
  const { match, classes, savedEntries, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const device = devices.find((a) => match.url.includes(a._id));
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyEntry(currUser, device) };
    newEntryForm = <RwsWeeklyEntryForm entryData={initialValues.entries.new} mode={mode} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                deviceId: Yup.string().required('Required'),
                date: Yup.string().required('Required'),
                remarks: Yup.string(),
                outFields: Yup.array(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          delete newEntry._id;
          await radqcApi('post', '/entries/rws', newEntry, { accessToken });
          enqueueSnackbar(`Added new RWS weekly entry`, { variant: 'info' });
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await newEntries.forEach((entry) => radqcApi('put', `/entries/rws/${entry._id}`, entry, { accessToken }));
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        setEntryKey(entryKey + 1);
        updateEntries();
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values }) => {
        let allEntries = savedEntries;
        if (mode === 'edit') {
          allEntries = [...savedEntries, ...Object.values(values.entries)].sort((a, b) => a.date - b.date);
          allEntries = uniqBy(allEntries, '_id');
        }
        return (
          <Form>
            <Box component={Paper} className={classes.tableContainer}>
              {['edit', 'enter'].includes(mode) && (
                <Typography fontSize={14} padding="0 16px">
                  To edit, <b>click</b> a row. To save change, press <b>enter</b>.
                </Typography>
              )}
              <Table key={entryKey} stickyHeader className={classes.table} aria-label="RwsWeeklyTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Initials</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newEntryForm}
                  {allEntries.map((entry) => (
                    <ToggleableEntry
                      key={entry.date}
                      className={classes.entriesList}
                      entryData={entry}
                      mode={mode}
                      entryDisplay={RwsWeeklyEntryDisplay}
                      entryForm={RwsWeeklyEntryForm}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Table className={classes.bottomNavigation}>
              <TableHead className={classes.bottomTableHead}>
                <TableRow className={classes.headerTableRow}>
                  <TableCell colSpan={2} sx={{ border: 'none' }}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={Object.keys(values.entries).length < 1}
                    >
                      Save Change
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

RwsWeeklyTable.propTypes = {
  savedEntries: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

RwsWeeklyTable.defaultProps = {
  savedEntries: [],
};

export default withRouter(withStyles(styles)(RwsWeeklyTable));
