import React from 'react';
import { Grid, IconButton, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import clsx from 'clsx';

// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import HomeIcon from '@mui/icons-material/Home';
// import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'components/Layouts/Logo';
import AuthStatus from 'types/authentication';
import Login from 'views/unauthenticated/Login';
import LandingPageNav from './components/LandingPageNav';

const styles = (theme) => ({
  navBar: {
    minWidth: '1020px',
    width: '100%',

    '@media (max-width: 450px)': {
      width: '100%',
      maxWidth: '450px',
    },

    '@media (max-width: 1030px)': {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  logo: {
    width: '90px',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  iconButton: {
    margin: '4px 8px 8px 0',
    display: 'none',
    '@media (max-width: 1084px)': {
      display: 'initial',
    },
  },
  menuIcon: {
    color: 'white',
  },
  menuItems: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}1a`,
    },
  },
  iconContainer: {
    display: 'flex',
  },
  listItemHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px',
  },
  listItemActions: {
    color: theme.palette.primary.darkMain,
  },
  helperText: {
    fontSize: '10px',
    color: 'black',
    opacity: 0.8,
    margin: 0,
    padding: 0,
  },
  linkDecor: {
    textDecoration: 'none',
    color: 'black',
  },
  listItemText: {
    fontSize: '14px',
  },
});

const drawerMenuListTop = [
  { icon: <HomeIcon />, name: 'Home', link: '/#home' },
  { icon: <AssignmentIcon />, name: 'Features', link: '/#features' },
  {
    icon: <TelegramIcon />,
    name: 'Contact Us',
    link: '/#contact',
    helper: 'Do you have questions for us?',
  },
];

const drawerMenuListBottom = [{ icon: <AssignmentIndIcon />, name: 'Log In', link: '/login' }];

function UnauthenticatedNavbar(props) {
  const { classes } = props;
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <List>
        <ListItem className={classes.listItemHeader}>
          RadQA Solutions
          <div style={{ fontSize: '10px', opacity: 0.8 }}>We are always here to help you.</div>
        </ListItem>
        {drawerMenuListTop.map((item) => (
          <HashLink key={item.link} to={item.link} className={classes.linkDecor}>
            <ListItem className={classes.menuItems} button key={item.name}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ListItemText classes={{ primary: classes.listItemText }} primary={item.name} />
                <ListItem className={classes.helperText}>{item.helper}</ListItem>
              </div>
            </ListItem>
          </HashLink>
        ))}
      </List>
      <Divider />
      <List>
        {drawerMenuListBottom.map((item) => (
          <Link key={item.link} to={item.link} className={classes.linkDecor}>
            <ListItem className={clsx(classes.listItemActions, classes.menuItems)} button key={item.name}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ListItemText classes={{ primary: classes.listItemText }} primary={item.name} />
                <ListItem className={classes.helperText}>{item.helper}</ListItem>
              </div>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <div
      className={classes.navBarMain}
      style={{
        width: '100%',
        minWidth: '1020px',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'visible',
      }}
    >
      <div className={classes.iconContainer}>
        <IconButton className={classes.iconButton} onClick={toggleDrawer('left', true)}>
          <MenuIcon classes={{ root: classes.menuIcon }} />
        </IconButton>

        <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)}>
          {list('left')}
        </Drawer>
      </div>
      <Grid container justifyContent="space-evenly" alignItems="center" className={classes.navBar}>
        <Grid item xs={3}>
          <HashLink key="home" to="/#home">
            <Logo className={classes.logo} authStatus={AuthStatus.UNAUTH} />
          </HashLink>
        </Grid>
        <Grid item xs={5}>
          <LandingPageNav />
        </Grid>
        <Grid item xs={3}>
          <Login />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(UnauthenticatedNavbar);
