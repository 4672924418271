import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { useSnackbar } from 'notistack';
import { useCurrUserCtx } from './CurrUserContext';
import radqcApi from '../dataExchange/radqcApiClient';

const NotifContext = createContext({
  notifications: [],
  updateNotifs: () => {},
  openNotifSlideout: false,
  setOpenNotifSlideout: (bool) => {},
});

function NotifCtxProvider(props) {
  const { children } = props;
  const [notifications, setNotifs] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { currUser } = useCurrUserCtx();
  const [openNotifSlideout, setOpenNotifSlideout] = useState(false);

  const unreadNotifCount = () => notifications.reduce((count, notif) => (notif.read ? count : count + 1), 0);

  const updateNotifs = useCallback(async () => {
    if (!currUser) return;
    const accessToken = await getAccessTokenSilently();
    const notifList = await radqcApi('get', `/notifications/user/${currUser._id}`, { accessToken });
    setNotifs(notifList);
  }, [getAccessTokenSilently, currUser]);

  // Update with the list of notifications from the DB
  useEffect(() => {
    updateNotifs();
  }, [updateNotifs]);

  return (
    <NotifContext.Provider
      value={{ notifications, unreadNotifCount, updateNotifs, setOpenNotifSlideout, openNotifSlideout }}
    >
      {children}
    </NotifContext.Provider>
  );
}

const useNotifCtx = () => {
  const context = useContext(NotifContext);
  if (context === undefined) {
    throw new Error('useNotifCtx must be used within a NotifCtxProvider');
  }
  return context;
};

export { NotifCtxProvider, useNotifCtx };
