import palette from 'theme/palette';

const entryBase = {
  fontSize: 10,
  padding: 0,
  height: '13.85px',
  paddingTop: 1,
  border: 0.35,
};

const flexRowMargin32 = {
  marginLeft: 32,
  marginRight: 32,
  display: 'flex',
  flexDirection: 'row',
};

const commonPreviewStyles = {
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 2,
    textAlign: 'center',
    fontSize: 12,
    color: palette.primary.main,
  },
  subContainerRight: {
    width: '50px',
    margin: 0,
    border: 0.5,
    borderLeft: 'none',
  },
  entry: {
    textAlign: 'center',
    ...entryBase,
  },
  entryHeader: {
    textAlign: 'left',
    fontWeight: '600',
    color: palette.primary.main,
    ...entryBase,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  scannerInfoView: {
    marginBottom: 8,
    marginTop: 16,
    marginLeft: 32,
  },
  entryTable: {
    marginTop: 4,
    ...flexRowMargin32,
  },
  remarksHeaderView: {
    marginTop: 16,
    borderBottom: 1,
    ...flexRowMargin32,
  },
  remarksView: {
    border: 0.1,
    ...flexRowMargin32,
  },
  remarksTableShort: {
    fontSize: 9,
    textAlign: 'center',
    padding: 0,
    height: '13.85px',
    paddingTop: 2,
    width: '10%',
    borderRight: 1,
  },
  remarks: {
    fontSize: 9,
    textAlign: 'left',
    paddingLeft: 10,
    height: '13.85px',
    paddingTop: 2,
    width: '80%',
  },
  helperText: {
    marginLeft: 30,
    marginTop: 30,
  },
  reviewInitials: {
    fontSize: 10,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
  },
  copyRight: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 0.5,
    marginTop: '16px',
  },
  charts: {
    height: 125,
    width: 500,
  },
  chartSections: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginTop: 10,
    marginLeft: 30,
    marginRight: 30,
  },
  chartTitle: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 30,
    marginTop: 10,
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
};

export default commonPreviewStyles;
