/* eslint-disable prefer-destructuring */
import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useDeviceCtx } from 'context/DeviceContext';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import QcDailyTable from './qcDailyTable/QcDailyTable';
import QcMonthlySummary from './monthly/QcMonthlySummary';
import QcComment from './components/QcComment';
import QcReview from './components/QcReview';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import { getUserInitials } from 'utils/user.util';
import InitialLoadingIndicator, { SpinnerPosition } from 'components/InitialLoadingIndicator';

const styles = () => ({
  qcDashboard: { minHeight: '33rem' },
  dailyTable: { height: '31.5rem', marginBottom: '0.5rem' },
  bottom: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '0.5rem 1.5rem',
    '@media (max-width: 900px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
});

function QcDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedQcEntries, setSavedQcEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { currUser } = useCurrUserCtx();
  const { updateDevices } = useDeviceCtx();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const getEntries = useCallback(async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedQcEntries;
    if (mode === 'view') {
      const startDate = date.start.toISOString();
      const endDate = date.end.toISOString();

      try {
        receivedQcEntries = await radqcApi('get', `/entries/qc/device/${deviceId}?start=${startDate}&end=${endDate}`, {
          accessToken,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      const entryDate = date.start.toISOString();

      try {
        receivedQcEntries = await radqcApi('get', `/entries/qc/device/${deviceId}/date/${entryDate}`, { accessToken });
      } catch (err) {
        receivedQcEntries = [];
      }
    }
    if (!receivedQcEntries) receivedQcEntries = [];
    setSavedQcEntries(receivedQcEntries);
    setLoading(false);
  }, [getAccessTokenSilently, mode, match.url, date.start, date.end]);

  useEffect(() => {
    getEntries();
  }, [mode, date, getEntries]);

  const handleQcChangeSubmitted = async (submission, data) => {
    let dailyEntries = [];
    const monthlyEntry = null;
    let comments = '';
    let putExtension = '';
    let putData = data;
    let outFields = savedQcEntries[0]?.outFields || [];
    switch (submission) {
      case 'dailyEntries':
        dailyEntries = data;
        outFields = dailyEntries.at(-1).outFields;
        putExtension = '/daily';
        break;
      case 'monthlyEntry':
        // Filter out all fields except water, noise, and artifacts from outFields
        outFields = outFields.filter((field) => field === 'water' || field === 'noise' || field === 'artifacts');
        // Add the new outFields from the monthly entry
        putData.outFields = [...outFields, ...data.outFields];
        putExtension = '/monthly';
        break;
      case 'comments':
        comments = data;
        if (savedQcEntries[0]) {
          putData = { comments: data };
        }
        break;
      case 'review':
        putExtension = '/review';
        putData = { ...data };
        break;
      default:
        throw Error(`Unknown submission type passed to QcDashboard: ${submission}`);
    }

    // If QC Entry exists, update DB with new data
    // else post new QC Entry
    let updatedQcEntry;
    const accessToken = await getAccessTokenSilently();
    const deviceId = getDeviceIdFromUrl(match.url);
    if (savedQcEntries[0]) {
      try {
        if (submission === 'review') {
          updatedQcEntry = await radqcApi('put', `/entries/qc/device/${deviceId}${putExtension}`, putData, {
            accessToken,
          });
          enqueueSnackbar('Reviewed CT QC entry', { variant: 'info' });
        } else {
          updatedQcEntry = await radqcApi('put', `/entries/qc/${savedQcEntries[0]._id}${putExtension}`, putData, {
            accessToken,
          });
          enqueueSnackbar('Updated CT QC entry', { variant: 'info' });
        }
      } catch (err) {
        console.log('put err:', err);
      }
    } else {
      const newQcEntry = {
        deviceId,
        date: date.start,
        dailyEntries,
        monthlyEntry,
        comments,
        outFields,
        initials: getUserInitials(currUser),
        enteredById: currUser._id,
        reviewedBy: null,
        reviewedById: null,
        reviewedDate: null,
      };
      try {
        updatedQcEntry = await radqcApi('post', '/entries/qc', newQcEntry, { accessToken });
        enqueueSnackbar('Added new CT QC Entry', { variant: 'info' });
      } catch (err) {
        console.log('post err:', err);
      }
    }
    await updateDevices();

    if (submission === 'review') {
      getEntries();
    } else {
      setSavedQcEntries([updatedQcEntry]);
    }
  };

  if (mode === 'view') {
    return <RecordViewer recordType="qc" records={savedQcEntries} />;
  }

  return loading ? (
    <InitialLoadingIndicator position={SpinnerPosition.HIGH} />
  ) : (
    <Box className={classes.qcDashboard} justify="space-between">
      <Box className={classes.dailyTable}>
        <QcDailyTable
          date={date}
          qcEntry={savedQcEntries.length > 0 ? savedQcEntries[0] : null}
          mode={mode}
          updateQcEntry={handleQcChangeSubmitted}
        />
      </Box>

      <Box className={classes.bottom}>
        <QcComment
          mode={mode}
          qcEntry={savedQcEntries.length > 0 ? savedQcEntries[0] : null}
          updateQcEntry={handleQcChangeSubmitted}
        />
        <QcMonthlySummary
          mode={mode}
          qcEntry={savedQcEntries.length > 0 ? savedQcEntries[0] : null}
          updateQcEntry={handleQcChangeSubmitted}
        />
        <QcReview
          mode={mode}
          qcEntry={savedQcEntries.length > 0 ? savedQcEntries[0] : null}
          updateQcEntry={handleQcChangeSubmitted}
        />
      </Box>
    </Box>
  );
}

QcDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(QcDashboard));
