import React, { createContext, useContext } from 'react';
import { range } from 'lodash';

const StepperContext = createContext({
  numOfSteps: 0,
  activeStep: 0,
  completed: new Set(),
  optional: new Set(),
  skipped: new Set(),
  setNumOfSteps: () => {},
  setActiveStep: () => {},
  setOptional: () => {},
  toggleComplete: () => {},
  toggleSkip: () => {},
});

function StepperCtxProvider(props) {
  const { children } = props;
  const [numOfSteps, setNumOfSteps] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [optional, setOptional] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => optional.has(step);

  const isLastStep = () => activeStep === numOfSteps - 1;

  const setNextUncompletedStep = () => {
    if (completed.size !== numOfSteps - skipped.size) {
      // It's the last step, but not all steps have been completed
      // find the first step that has not been completed
      const newActiveStep = isLastStep() ? range(numOfSteps).findIndex((step, i) => !completed.has(i)) : activeStep + 1;

      setActiveStep(newActiveStep);
    }
  };

  const toggleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    if (!isLastStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const newSkipped = new Set(skipped);
    if (skipped.has(activeStep)) {
      newSkipped.delete(activeStep);
    } else {
      newSkipped.add(activeStep);
      setNextUncompletedStep();
    }
    setSkipped(newSkipped);
  };

  const toggleComplete = () => {
    const newCompleted = new Set(completed);
    if (completed.has(activeStep)) {
      newCompleted.delete(activeStep);
    } else {
      newCompleted.add(activeStep);
      setNextUncompletedStep();
    }
    setCompleted(newCompleted);
  };

  return (
    <StepperContext.Provider
      value={{
        numOfSteps,
        activeStep,
        completed,
        optional,
        skipped,
        setNumOfSteps,
        setActiveStep,
        setOptional,
        toggleComplete,
        toggleSkip,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
}

const useStepperCtx = () => {
  const context = useContext(StepperContext);
  if (context === undefined) {
    throw new Error('useStepperCtx must be used within a StepperContextProvider');
  }
  return context;
};

export { StepperCtxProvider, useStepperCtx };
