import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Box, FormControl, FormLabel, MenuItem, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import AutoSave from 'views/components/AutoSave';
import { RadSelect, RadCheckbox } from 'presentational/Inputs';
import { timePeriodToStr, enumTimePeriodOptions, generateChartConfig } from 'utils/chart.util';
import { deviceTypes, ctFields, mriFields, ctFieldTitles, mriFieldTitles } from 'types/device';
import Chart from 'views/metrics/components/Chart';

const styles = {
  form: {
    display: 'flex',
    marginTop: '1rem',
    justifyContent: 'space-between',
    '@media (max-width: 765px)': {
      flexDirection: 'column',
    },
  },
  title: {
    marginBottom: '2rem',
  },
  formControl: {
    margin: '5px 0',
  },
  select: {
    width: 150,
  },
  checkboxLabels: {
    fontSize: '0.8rem',
  },
  checkboxSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  radCheckStyle: {
    marginTop: '-15px',
  },
  settingBox: {
    width: '20%',
    '@media (max-width: 765px)': {
      width: '100%',
    },
  },
  subBox: {
    display: 'flex',
    flexDirection: 'column',

    '@media (min-width: 595px) and (max-width: 765px)': {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '@media (max-width: 594px)': {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  chartBox: {
    width: '73%',
    '@media (max-width: 765px)': {
      width: '100%',
    },
  },
};

function ChartEditor(props) {
  const { deviceType, config, updateChartConfig, index, classes } = props;

  // Convert chart config into values for the form
  const initValues = cloneDeep(config);
  initValues.timePeriod = timePeriodToStr(initValues.timePeriod);
  initValues.showRange = !!Object.keys(initValues.datasetConfigs).find((a) => a.includes('max') || a.includes('min'));
  delete initValues.datasetConfigs;

  const fieldList = Object.keys(deviceType === deviceTypes.CT ? ctFields : mriFields);
  const fieldTitles = deviceType === deviceTypes.CT ? ctFieldTitles : mriFieldTitles;

  return (
    <Formik
      reinitialize
      initialValues={initValues}
      validationSchema={Yup.object({
        // label: Yup.string().required(),
        timePeriod: Yup.string().required(),
        // timeScale: Yup.string().required(),
        trackingProp: Yup.string().required(),
        showRange: Yup.boolean().required(),
        display: Yup.boolean().required(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const newValues = Object.assign(values);
        const newChartConfig = generateChartConfig(config, newValues);
        setSubmitting(false);
        updateChartConfig(index, newChartConfig);
      }}
    >
      <Form className={classes.form}>
        <AutoSave />
        <Box className={classes.settingBox}>
          <Box className={classes.title}>
            <Typography sx={{ fontSize: 16 }}>Chart Parameters</Typography>
          </Box>

          <Box className={classes.subBox}>
            <FormControl className={clsx(classes.formControl)}>
              <FormLabel sx={{ margin: 0, padding: 0, fontSize: 12 }}>Property</FormLabel>
              <RadSelect name="trackingProp" className={classes.select}>
                {fieldList.map((field) => (
                  <MenuItem key={field} value={field}>
                    {fieldTitles[field]}
                  </MenuItem>
                ))}
              </RadSelect>
              <ErrorMessage name="trackingProp" />
            </FormControl>
            <FormControl className={clsx(classes.formControl)}>
              <FormLabel sx={{ margin: 0, padding: 0, fontSize: 12 }}>Time Period</FormLabel>
              <RadSelect name="timePeriod" className={classes.select}>
                {Object.keys(enumTimePeriodOptions).map((timePeriodOpt) => (
                  <MenuItem key={timePeriodOpt} value={timePeriodOpt}>
                    {timePeriodOpt}
                  </MenuItem>
                ))}
              </RadSelect>
              <ErrorMessage name="timePeriod" />
            </FormControl>
            <Box className={classes.checkboxSection}>
              <FormControl className={clsx(classes.formControl)}>
                <RadCheckbox size="small" padding={0} id={`showAcceptable${index}`} name="showRange">
                  <div className={classes.checkboxLabels}>Show Acceptable Value Range</div>
                </RadCheckbox>
              </FormControl>
              <FormControl className={clsx(classes.formControl, classes.radCheckStyle)}>
                <RadCheckbox size="small" padding={0} id={`displayOnOverview${index}`} name="display">
                  <div className={classes.checkboxLabels}>Display on Overview</div>
                </RadCheckbox>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box className={classes.chartBox}>
          <Chart config={config} />
        </Box>
      </Form>
    </Formik>
  );
}

ChartEditor.propTypes = {
  deviceType: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  updateChartConfig: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(ChartEditor);
