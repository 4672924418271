import moment from 'moment';

export const formatDate = (date) => {
  if (!date) return '-';
  return moment(date).format('MM/DD/YYYY');
};

export const formatMonthDate = (input) => {
  const date = new Date(input);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}/${day}`;
};

export const formatDateShort = (date) => {
  if (!date) return 'N/A';
  return moment(date).format('MM/DD/YY');
};

export const formatMonth = (date) => {
  if (!date) return 'N/A';
  return moment(date).format('MMMM');
};

export const formatYear = (date) => {
  if (!date) return 'N/A';
  return moment(date).format('YYYY');
};

export const formatMonthYear = (date) => {
  if (!date) return 'N/A';
  return moment(date).format('MM/YYYY');
};

// Check if given date is more than 'daysOld' days old
export const isDaysOld = (date, daysOld) => {
  const givenDate = new Date(date);
  const limitDate = new Date();
  limitDate.setDate(limitDate.getDate() - daysOld);

  return givenDate < limitDate;
};

export const getCurrentYear = () => formatYear(new Date());

export const getDaysInMonth = (date) => {
  const year = date.getYear();
  const month = date.getMonth() + 1;
  return new Date(year, month, 0).getDate();
};
