import React from 'react';
import { Grid, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import clsx from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
// import { useModalCtx } from 'context/ModalContext';

const styles = {
  button: {
    fontSize: '0.8rem',
    width: '150px',
  },
  message: {
    marginBottom: 25,
    fontWeight: 200,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '20px 0px',
  },
  removeButton: {
    textTransform: 'capitalize',
  },
};

function UserNotInSystem(props) {
  const { classes } = props;
  const { logout } = useAuth0();
  //   const { hideModal } = useModalCtx();

  const handleConfirmClick = () => {
    logout();
    // hideModal();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h3 className={classes.message}>
          Your user account can not be located. Please contact your administrator to have them add you to the Radiology
          QC Solutions system.
        </h3>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          className={clsx(classes.button, classes.removeButton)}
          color="secondary"
          variant="outlined"
          onClick={handleConfirmClick}
        >
          OK
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(UserNotInSystem);
