import axios from 'axios';

// const apiRequestString = '/api';

const createConfig = (customConfig) => {
  let headers = {
    'content-type': 'application/json',
  };
  if (customConfig?.accessToken) {
    const { accessToken } = customConfig;
    // console.log('token in createConfig: ', accessToken);
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const config = {
    headers,
  };

  return config;
};

const radqcGet = async (endpoint, customConfig) => {
  const config = createConfig(customConfig);
  let response = null;
  try {
    response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`, config);
    response = response.data;
  } catch (err) {
    err.message = `Error with GET request to ${endpoint}`;
    throw err;
  }
  return response;
};

const radqcPost = async (endpoint, body, customConfig) => {
  const config = createConfig(customConfig);
  let response = null;
  try {
    response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`, body, config);
    response = response.data;
  } catch (err) {
    err.message = `Error with POST request to ${endpoint}`;
    throw err;
  }
  return response;
};

const radqcPut = async (endpoint, body, customConfig) => {
  const config = createConfig(customConfig);
  let response = null;
  try {
    response = await axios.put(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`, body, config);
    response = response.data;
  } catch (err) {
    err.message = `Error with PUT request to ${endpoint}`;
    throw err;
  }
  return response;
};

const radqcDelete = async (endpoint, customConfig) => {
  const config = createConfig(customConfig);
  let response = null;
  try {
    response = await axios.delete(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`, config);
    response = response.data;
  } catch (err) {
    err.message = `Error with PUT-delete request to ${endpoint}`;
    throw err;
  }
  return response;
};

const radqcApi = (userMethod, ...options) => {
  const method = userMethod.toLowerCase();
  // console.log('received request with:', method, options);
  switch (method) {
    case 'get':
      return radqcGet(...options);
    case 'post':
      return radqcPost(...options);
    case 'put':
      return radqcPut(...options);
    case 'delete':
      return radqcDelete(...options);
    default:
      throw Error(`Unrecognized HTTP request method ${userMethod}`);
  }
};

export default radqcApi;
