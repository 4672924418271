import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Typography, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { RadTextInput } from 'presentational/Inputs';

const styles = {
  formStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    textTransform: 'capitalize',
    margin: '10px 0',
    fontSize: '0.8rem',
  },
  radTextInput: {
    marginRight: '8px',
    overflow: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  commentContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function QcComment(props) {
  const { qcEntry, updateQcEntry, mode, classes } = props;
  const initialValues = qcEntry ? { comments: qcEntry.comments } : { comments: '' };

  const commentDisabled = mode === 'view' || mode === 'review';

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        comment: Yup.string().max(1024, 'Must be 1024 characters or less'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await updateQcEntry('comments', values.comments);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form className={classes.formStyle}>
          <Typography sx={{ fontSize: 14 }}>Comments</Typography>
          <Box className={classes.commentContent}>
            <RadTextInput
              sx={{ width: 280 }}
              margin="dense"
              name="comments"
              type="text"
              disabled={commentDisabled}
              classes={{ root: classes.radTextInput }}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                type="submit"
                disabled={commentDisabled}
              >
                Save
              </Button>
            </div>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

QcComment.propTypes = {
  mode: PropTypes.string.isRequired,
  qcEntry: PropTypes.objectOf(PropTypes.any),
  updateQcEntry: PropTypes.func.isRequired,
};

QcComment.defaultProps = {
  qcEntry: null,
};

export default withStyles(styles)(QcComment);
