export const EntryModes = {
  ENTER: 'enter',
  VIEW: 'view',
  EDIT: 'edit',
  REVIEW: 'review',
};

export const EntryTypeNames = {
  HE: 'Helium',
  QA: 'QA',
  QC: 'QC',
  VC: 'Visual Checklist',
  RWS: 'Reading Workstation',
  RWS_SC: 'Reading Workstation Sectra',
  MAM_WK: 'Mammography Weekly',
  MAM_MT: 'Mammography Monthly',
  MAM_QU: 'Mammography Quarterly',
  MAM_CF: 'Mammography Comp Force',
  MAM_GC: 'Mammography Geo Calib',
};
