import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { mapValues, uniqBy } from 'lodash';
import { TableCell, Table, TableHead, TableBody, TableRow, Button, Box, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import { generateEmptyQaEntry } from 'views/entry/qa/components/QaEntry.util';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import { getUserName } from 'utils/user.util';
import radqcApi from 'dataExchange/radqcApiClient';
import QaTableHeader from 'views/entry/qa/components/QaTableHeader';
import QaEntryForm from './components/QaEntryForm';
import QaToggleableEntry from './components/QaToggleableEntry';

const styles = () => ({
  table: {
    minWidth: 700,
  },
  headerTableRow: { backgroundColor: 'transparent' },
  bottomNavigation: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
  },
  qaTableContainer: {
    height: '500px',
    overflowY: 'auto',
    boxShadow: 'none',
  },
});

const spatialResVals = [0.7, 0.8, 0.9, 1.0, 1.1];

function QaTable(props) {
  const { match, classes, qaEntries, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyQaEntry(currUser, device) };
    newEntryForm = <QaEntryForm entryData={initialValues.entries.new} />;
  }

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    showModal(EntryConfirmModal, {
      entryType: 'qa',
      confirmEntries: newEntries,
      accept: () => submitForm(),
    });
  };

  const handleReview = async () => {
    const reviewPayload = {
      reviewedBy: getUserName(currUser),
      reviewedById: currUser._id,
      reviewedDate: new Date().toISOString(),
    };
    const accessToken = await getAccessTokenSilently();
    const resultMsg = await radqcApi('put', `/entries/qa/device/${device._id}/review`, reviewPayload, { accessToken });
    enqueueSnackbar(resultMsg);
    const numEntriesReviewed = resultMsg.substr(0, resultMsg.indexOf(' '));
    if (numEntriesReviewed > 0) {
      updateEntries();
      updateDevices();
      updateUsers();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                artifactEval: Yup.bool().required('Required'),
                centerFreq: Yup.number().required('Required'),
                date: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
                geoAccuracyAp: Yup.number().required('Required'),
                geoAccuracyHf: Yup.number().required('Required'),
                geoAccuracyRl: Yup.number().required('Required'),
                initials: Yup.string().required('Required'),
                notes: Yup.string(),
                outFields: Yup.array(),
                reviewedBy: Yup.string().nullable(),
                reviewedById: Yup.string().nullable(),
                deviceId: Yup.string(),
                setupAccuracy: Yup.number().required('Required'),
                setupConsole: Yup.bool().required('Required'),
                spatialResLower: Yup.number().oneOf(spatialResVals).required('Required'),
                spatialResUpper: Yup.number().oneOf(spatialResVals).required('Required'),
                spokesNum: Yup.number().required('Required'),
                txGainAtten: Yup.number().required('Required'),
                _id: Yup.string(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, actions) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          newEntry.phantomSize = device.phantomSize;
          delete newEntry._id;
          await radqcApi('post', '/entries/qa', newEntry, { accessToken });
          enqueueSnackbar(`Added new MRI QC entry`, { variant: 'info' });
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await Promise.all(
            newEntries.map((entry) => radqcApi('put', `/entries/qa/${entry._id}`, entry, { accessToken })),
          );
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        updateDevices();
        updateUsers();
        updateEntries();
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
        if (mode === 'enter') {
          actions.resetForm({ values: initialValues });
        }
      }}
    >
      {({ values, submitForm }) => {
        let allQaEntries = qaEntries;
        if (mode === 'edit') {
          const savedQaEntries = qaEntries || [];
          allQaEntries = [...savedQaEntries, ...Object.values(values.entries)].sort((a, b) => a.date - b.date);
          allQaEntries = uniqBy(allQaEntries, '_id');
        }

        return (
          <Form>
            <Box component={Paper} className={classes.qaTableContainer}>
              <Table key={entryKey} stickyHeader className={classes.table} aria-label="QaInfoTable">
                <QaTableHeader device={device} />
                <TableBody>
                  {newEntryForm}
                  {allQaEntries.map((entry) => (
                    <QaToggleableEntry key={entry._id} entryData={entry} mode={mode} className={classes.entriesList} />
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Table className={classes.bottomNavigation}>
              <TableHead className={classes.bottomTableHead}>
                <TableRow className={classes.headerTableRow}>
                  <TableCell colSpan={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={Object.keys(values.entries).length < 1 && mode !== 'review'}
                      onClick={() => (mode === 'review' ? handleReview() : handleSubmit(values, submitForm))}
                    >
                      {mode === 'review' ? 'Review' : 'Submit'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

QaTable.propTypes = {
  qaEntries: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

QaTable.defaultProps = {
  qaEntries: [],
};

export default withRouter(withStyles(styles)(QaTable));
