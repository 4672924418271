/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  Backdrop: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 1250,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

function backdrop(props) {
  const { show, classes, clicked } = props;

  return show ? <div className={classes.Backdrop} onClick={clicked} /> : null;
}

backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  clicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(backdrop);
