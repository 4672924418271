import React from 'react';
import theme from 'theme';
import { Box, Typography, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const Header = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
}));

const SubHeader = styled(Typography)((fontSize = '16px') => ({
  fontSize,
  fontWeight: 600,
}));

const PoliciesDescription = styled(Box)(() => ({
  margin: '16px 0',
  opacity: 0.7,
  fontSize: '14px',
}));

const CustomizedListItem = styled(ListItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

function Policies() {
  return (
    <Box sx={{ padding: '100px 40px', color: theme.palette.white }}>
      <Header>Privacy Policy</Header>
      <PoliciesDescription>
        At Rad QC Solutions, accessible from www.radqcsolutions.com, one of our main priorities is the privacy of our
        visitors. This Privacy Policy document contains types of information that is collected and recorded by Rad QC
        Solutions and how we use it. If you have additional questions or require more information about our Privacy
        Policy, do not hesitate to contact us. This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they shared and/or collect in Rad QC Solutions.
        This policy is not applicable to any information collected offline or via channels other than this website. Our
        Privacy Policy was created with the help of the Free Privacy Policy Generator.
      </PoliciesDescription>

      <SubHeader>Consent</SubHeader>
      <PoliciesDescription>
        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
      </PoliciesDescription>
      <SubHeader>Information We Collect</SubHeader>
      <PoliciesDescription>
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be
        made clear to you at the point we ask you to provide your personal information. If you contact us directly, we
        may receive additional information about you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information you may choose to provide. When you
        register for an Account, we may ask for your contact information, including items such as name, company name,
        address, email address, and telephone number.
      </PoliciesDescription>
      <SubHeader>How we use your information</SubHeader>
      <PoliciesDescription>
        We use the information we collect in various ways, including to:
        <List>
          <CustomizedListItem>• Provide, operate, and maintain our website</CustomizedListItem>
          <CustomizedListItem>• Improve, personalize, and expand our website</CustomizedListItem>
          <CustomizedListItem>• Understand and analyze how you use our website</CustomizedListItem>
          <CustomizedListItem>• Develop new products, services, features, and functionality</CustomizedListItem>
          <CustomizedListItem>
            • Communicate with you, either directly or through one of our partners, including for customer service, to
            provide you with updates and other information relating to the website, and for marketing and promotional
            purposes
          </CustomizedListItem>
          <CustomizedListItem>• Send you emails</CustomizedListItem>
          <CustomizedListItem>• Find and prevent fraud</CustomizedListItem>
        </List>
      </PoliciesDescription>

      <SubHeader>Log Files</SubHeader>
      <PoliciesDescription>
        Rad QC Solutions follows a standard procedure of using log files. These files log visitors when they visit
        websites. All hosting companies do this and a part of hosting services' analytics. The information collected by
        log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
        stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is
        personally identifiable. The purpose of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic information.
      </PoliciesDescription>

      <SubHeader>Cookies and Web Beacons</SubHeader>
      <PoliciesDescription>
        Like any other website, Rad QC Solutions uses 'cookies'. These cookies are used to store information including
        visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page content based on visitors' browser type
        and/or other information.
      </PoliciesDescription>
      <SubHeader>Third Party Privacy Policies</SubHeader>
      <PoliciesDescription>
        Rad QC Solutions' Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to
        consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may
        include their practices and instructions about how to opt-out of certain options.
        <br />
        You can choose to disable cookies through your individual browser options. To know more detailed information
        about cookie management with specific web browsers, it can be found at the browsers' respective websites.
      </PoliciesDescription>
      <SubHeader>CCPA Privacy Rights (Do Not Sell My Personal Information)</SubHeader>
      <PoliciesDescription>
        Under the CCPA, among other rights, California consumers have the right to: Request that a business that
        collects a consumer's personal data disclose the categories and specific pieces of personal data that a business
        has collected about consumers. Request that a business delete any personal data about the consumer that a
        business has collected. Request that a business that sells a consumer's personal data, not sell the consumer's
        personal data. If you make a request, we have one month to respond to you. If you would like to exercise any of
        these rights, please contact us at Info@radqcsolutions.com.
      </PoliciesDescription>
      <SubHeader>GDPR Data Protection Rights</SubHeader>
      <PoliciesDescription>
        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to
        the following: The right to access – You have the right to request copies of your personal data. We may charge
        you a small fee for this service. The right to rectification – You have the right to request that we correct any
        information you believe is inaccurate. You also have the right to request that we complete the information you
        believe is incomplete. The right to erasure – You have the right to request that we erase your personal data,
        under certain conditions. The right to restrict processing – You have the right to request that we restrict the
        processing of your personal data, under certain conditions. The right to object to processing – You have the
        right to object to our processing of your personal data, under certain conditions. The right to data portability
        – You have the right to request that we transfer the data that we have collected to another organization, or
        directly to you, under certain conditions. If you make a request, we have one month to respond to you. If you
        would like to exercise any of these rights, please contact us at Info@radqcsolutions.com.
      </PoliciesDescription>
      <SubHeader>Children's Information</SubHeader>
      <PoliciesDescription>
        Another part of our priority is adding protection for children while using the internet. We encourage parents
        and guardians to observe, participate in, and/or monitor and guide their online activity. Rad QC Solutions does
        not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that
        your child provided this kind of information on our website, we strongly encourage you to contact us immediately
        and we will do our best efforts to promptly remove such information from our records.
      </PoliciesDescription>
    </Box>
  );
}

export default Policies;
