import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

const inputFieldBaseStyles = {
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
};

const ContactInputField = withStyles({
  root: {
    margin: '1rem',
    ...inputFieldBaseStyles,
  },
})(TextField);

const InquiryField = withStyles({
  root: {
    width: '100%',
    margin: '1rem 0',
    ...inputFieldBaseStyles,
  },
})(TextField);

const styles = () => ({
  contactHeadContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  contactHeading: {
    '@media (max-width: 636px)': {
      maxWidth: '100%',
    },
  },
  subHeading: {
    maxWidth: '70%',
    marginTop: '2rem',
    '@media (max-width: 636px)': {
      maxWidth: '100%',
    },
  },
  headingTypo: {
    fontSize: '3rem',
    textAlign: 'center',
    lineHeight: 1.4,
    fontFamily: 'Domine, serif;',

    '@media (max-width: 861px)': {
      fontSize: '1.5rem',
    },
  },
  subTypo: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  form: {
    display: 'flex',
    margin: '2rem 0',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inquiryBox: {
    width: '36rem',
    '@media (max-width: 453px)': {
      width: '90%',
    },
  },
  buttonBox: {
    marginTop: '4rem',
  },
  inputs: {
    display: 'flex',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
});

function Home(props) {
  const { classes } = props;

  return (
    <div className="home__hero-section darkBg">
      <div className="container">
        <div className={classes.contactHeadContainer}>
          <Box className={classes.contactHeading}>
            <Typography className={classes.headingTypo} fontSize={16}>
              Ready to start managing your instruments with us?
            </Typography>
          </Box>
          <Box className={classes.subHeading}>
            <Typography className={classes.subTypo}>
              The first step is to contact us regarding your needs. This will guide us to provide you with the right
              customer-oriented web services.
            </Typography>
          </Box>
        </div>

        <Box className={classes.form}>
          <Box className={classes.inputs}>
            <ContactInputField required className={classes.margin} label="First Name" />
            <ContactInputField required className={classes.margin} label="Last Name" />
            <ContactInputField className={classes.margin} label="Email Address" required />
          </Box>

          <Box className={classes.inquiryBox}>
            <InquiryField
              multiline
              minRows={5}
              maxRows={5}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Details of Inquiry"
            />
          </Box>
          <Box className={classes.buttonBox}>
            <Button color="secondary" size="large" className={classes.requestSubmitButton} variant="outlined">
              Send Request
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}
export default withStyles(styles)(Home);
