import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';
import AuthedAppProviders from './context/AuthedAppProviders';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import Modal from './presentational/Modal';
import InitialLoadingIndicator from './components/InitialLoadingIndicator';

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <InitialLoadingIndicator />
      </div>
    );
  }

  return isAuthenticated ? (
    <AuthedAppProviders>
      <Modal />
      <AuthenticatedRoutes />
    </AuthedAppProviders>
  ) : (
    <>
      <Modal />
      <UnauthenticatedRoutes />
    </>
  );
}

export default App;
