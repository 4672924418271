import React from 'react';
import PropTypes from 'prop-types';
import WeeklyEntryDisplay from 'views/entry/mam/weekly/modify/components/weeklyEntryDisplay';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';

function CompEntryDisplay(props) {
  const { entryData, click } = props;

  return (
    <WeeklyEntryDisplay
      key={entryData._id}
      entryData={entryData}
      entryFields={[MamEntryFields.compThickness]}
      remarkFieldName={MamEntryFields.compRemarks}
      click={click}
    />
  );
}

CompEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

CompEntryDisplay.defaultProps = {
  click: null,
};

export default CompEntryDisplay;
