import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { IconButton, TableCell, Table, TableBody, TableRow, FormControl, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import PropTypes from 'prop-types';
import { weeklyCommonStyles } from 'views/entry/mam/components/commonStyles';
import { useModalCtx } from 'context/ModalContext';
import { RadSelect } from 'presentational/Inputs';
import { updateOutFields } from 'views/entry/mam/components/mamEntryOutValues';
import { formatDate } from 'utils/time.util';
import { phantomScores, entryTypes } from 'views/entry/mam/components/mamEntry.util';
import MamNumberInput from 'views/entry/mam/weekly/modify/components/MamNumberInput';
import EntryComment from 'views/entry/components/EntryComment';

const useStyles = makeStyles(weeklyCommonStyles);

function PhantomEntryForm(props) {
  const { entryData, formDisabled, click, is3D } = props;
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { showModal, hideModal } = useModalCtx();
  const dim = is3D ? '3' : '2';
  const [outFieldsLocal, setOutfieldsLocal] = useState(entryData.outFields);

  if (!entryData) return null;

  const entryIndex = entryData._id || 'new';

  const handleUpdateRemarks = (newRemarks) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex][`phantom${dim}Remarks`] = newRemarks;
    setFieldValue('entries', updatedEntries);
    hideModal();
  };

  const getStyleForField = (field) =>
    outFieldsLocal.includes(field.substring(field.lastIndexOf('.') + 1)) ? classes.outValueSelect : classes.select;

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  function ScoresInput(field) {
    return (
      <TableRow>
        <TableCell classes={{ root: classes.tableTitles }}>
          <FormControl className={classes.formControl}>
            <RadSelect
              name={field}
              className={getStyleForField(field, entryData, classes)}
              onChange={(e) => setOutfieldsLocal(updateOutFields(e, values.entries[entryIndex], setFieldValue, true))}
              disabled={formDisabled}
            >
              {phantomScores.map((score) => (
                <MenuItem key={score} value={score}>
                  {score}
                </MenuItem>
              ))}
            </RadSelect>
          </FormControl>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableCell className={classes.noPadding}>
      <Table className={classes.formTableWidth}>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{entryData.initials}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{is3D ? 'Al' : 'Rh'}</TableCell>
          </TableRow>
          <MamNumberInput
            entryType={entryTypes.KVP}
            name={`entries.${entryIndex}.phantom${dim}Kvp`}
            formDisabled={formDisabled}
          />
          <MamNumberInput
            entryType={is3D ? entryTypes.MAS3 : entryTypes.MAS}
            name={`entries.${entryIndex}.phantom${dim}Mas`}
            formDisabled={formDisabled}
          />
          <MamNumberInput
            entryType={entryTypes.POS_NUM}
            name={`entries.${entryIndex}.phantom${dim}El`}
            formDisabled={formDisabled}
          />
          {ScoresInput(`entries.${entryIndex}.phantom${dim}Fibers`)}
          {ScoresInput(`entries.${entryIndex}.phantom${dim}Specks`)}
          {ScoresInput(`entries.${entryIndex}.phantom${dim}Masses`)}
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>
              {entryIndex !== 'new' && (
                <IconButton
                  onClick={() =>
                    showModal(EntryComment, {
                      savedComment: values.entries[entryIndex].artEvalRemarks,
                      hideModal,
                      updateComment: handleUpdateRemarks,
                      title: 'Remarks',
                    })
                  }
                >
                  <CommentIcon classes={{ root: classes.icons }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{cancelButton}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableCell>
  );
}

PhantomEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  formDisabled: PropTypes.bool,
  click: PropTypes.func,
  is3D: PropTypes.bool,
};

PhantomEntryForm.defaultProps = {
  formDisabled: false,
  is3D: false,
  click: null,
};

export default PhantomEntryForm;
