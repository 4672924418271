import React from 'react';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Features from './components/pages/Features/Features';

function LandingPage() {
  return (
    <>
      <div id="home">
        <Home style={{ zIndex: 1 }} />
      </div>
      <div id="features">
        <Features style={{ zIndex: 1 }} />
      </div>
      <div id="contact">
        <Contact style={{ zIndex: 1 }} />
      </div>
    </>
  );
}

export default LandingPage;
