import React, { useState } from 'react';
import { mapValues, pick, uniqBy } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import { useModalCtx } from 'context/ModalContext';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import {
  phantomFields,
  kvpValidation,
  masValidation,
  mas3dValidation,
  posNumValidation,
  phantomScores,
  getChartEntries,
} from 'views/entry/mam/components/mamEntry.util';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import MamEntryChart from 'views/entry/mam/components/MamEntryChart';
import { RadTextInput } from 'presentational/Inputs';
import { EntryModes } from 'types/entry';
import PhantomCNREditModal from 'views/entry/mam/weekly/modify/components/PhantomCNREditModal';

import TaskNav from 'views/entry/mam/weekly/modify/components/TaskNav';
import PhantomEntry3dForm from './PhantomEntry3dForm';
import PhantomEntryForm from './PhantomEntryForm';
import PhantomEntry3dDisplay from './PhantomEntry3dDisplay';
import PhantomEntryDisplay from './PhantomEntryDisplay';

const useStyles = makeStyles({
  tableTitles: {
    color: 'black',
    padding: '0',
    textAlign: 'center',
    width: '30px',
    height: '50px',
  },
  remarks: {
    width: '100%',
    overflow: 'auto',
    border: '1px solid #80808026',
  },
  tableContainer: {
    display: 'flex',
    margin: '0 2rem',
    '@media (max-width: 1024px)': {
      margin: '0',
    },
  },
  stickyHeader: {
    width: '5rem',
  },
  rightTables: {
    maxWidth: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  remarkSection: {
    margin: '2rem 2rem 0 2rem',
  },
  remarkTitle: {
    marginBottom: '1rem',
  },
  chartBoxes: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
  },
  chartSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  editPhantomSection: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  currentVal: {
    padding: '1rem',
  },
});

function PhantomDashboard(props) {
  const { activeEntry, mode, saveEntries, mamEntries, taskId, formDisabled, is3D, match, device } = props;
  const [entryKey, setEntryKey] = useState(0);
  const classes = useStyles();
  const { showModal } = useModalCtx();

  const dim = is3D ? '3' : '2';
  const taskName = `Phantom ${dim}D`;
  const phantomChartOpts = { suggestedMin: 3, suggestedMax: 6 };
  const fieldTitles = {
    [MamEntryFields[`phantom${dim}Fibers`]]: 'Fibers',
    [MamEntryFields[`phantom${dim}Specks`]]: 'Specks',
    [MamEntryFields[`phantom${dim}Masses`]]: 'Masses',
  };

  const initialValues = { entries: {} };
  let enterModeColumns = null;
  if (mode === EntryModes.ENTER) {
    initialValues.entries = { new: pick(activeEntry, phantomFields(is3D)) };
    enterModeColumns = (
      <>
        {mamEntries.leading.map((entry) => (
          <PhantomEntryDisplay key={entry._id} entryData={entry} is3D={is3D} />
        ))}
        <PhantomEntryForm entryData={activeEntry} formDisabled={formDisabled} is3D={is3D} />
        {mamEntries.trailing.map((entry) => (
          <PhantomEntryDisplay key={entry._id} entryData={entry} is3D={is3D} />
        ))}
      </>
    );
  }

  const phantomScoresValidation = Yup.number().oneOf(phantomScores).required('Required');

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    if (mode === EntryModes.ENTER) {
      newEntries[0].date = activeEntry.date;
      newEntries[0].initials = activeEntry.initials;
    }
    showModal(EntryConfirmModal, {
      entryType: 'mam',
      confirmTitle: 'Mammograph Phantom',
      confirmEntries: newEntries,
      accept: () => submitForm(),
      fieldTitles,
    });
  };

  const editPhantomSerialNumber = () => {
    showModal(PhantomCNREditModal, {
      match,
      editType: 'phantomSerialNumber',
      label: 'Phantom Serial Number',
      message: 'Here you can edit phantom serial number.',
      actionText: 'Confirm',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                [`phantom${dim}Kvp`]: kvpValidation,
                [`phantom${dim}Mas`]: is3D ? mas3dValidation : masValidation,
                [`phantom${dim}El`]: posNumValidation,
                [`phantom${dim}Fibers`]: phantomScoresValidation,
                [`phantom${dim}Specks`]: phantomScoresValidation,
                [`phantom${dim}Masses`]: phantomScoresValidation,
                [`phantom${dim}Remarks`]: Yup.string(),
                outFields: Yup.array(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (mode === EntryModes.ENTER) {
          newEntries[0][`phantom${dim}Performed`] = true;
        }
        saveEntries(newEntries);
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
      }}
    >
      {({ values, submitForm }) => {
        let allEntries = getChartEntries(mamEntries, activeEntry.date, values.entries);
        if (mode === 'edit') {
          allEntries = [
            ...mamEntries.leading,
            ...Object.values(values.entries),
            ...mamEntries.trailing,
            /* eslint-disable-next-line no-nested-ternary */
          ].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
          allEntries = uniqBy(allEntries, '_id');
        }
        return (
          <Form>
            <Box className={classes.tableContainer}>
              <Table stickyHeader className={classes.stickyHeader}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Date</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Initials</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Filter</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>kVp</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>mAs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>El</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Fibers</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Specks</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Masses</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Remarks</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles} />
                  </TableRow>
                </TableHead>
              </Table>

              <Box className={classes.rightTables}>
                <Table>
                  <TableBody>
                    <TableRow>
                      {enterModeColumns ||
                        allEntries.map((entry) => (
                          <ToggleableEntry
                            key={entryKey + entry.date}
                            entryData={entry}
                            mode={mode}
                            entryDisplay={is3D ? PhantomEntry3dDisplay : PhantomEntryDisplay}
                            entryForm={is3D ? PhantomEntry3dForm : PhantomEntryForm}
                          />
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>

            <Box className={classes.editPhantomSection}>
              <Box className={classes.currentVal}>
                <b>Current Phantom Serial Number:</b> {device?.phantomSerialNumber}
              </Box>
              <Button variant="outlined" color="primary" onClick={() => editPhantomSerialNumber()}>
                Edit Phantom Serial Number
              </Button>
            </Box>

            <Box className={classes.chartSection}>
              <Box className={classes.chartBoxes}>
                <MamEntryChart
                  key={allEntries}
                  entries={allEntries}
                  field={`phantom${dim}Fibers`}
                  title="Fibers"
                  chartOpts={phantomChartOpts}
                />
              </Box>

              <Box className={classes.chartBoxes}>
                <MamEntryChart
                  entries={allEntries}
                  field={`phantom${dim}Specks`}
                  title="Specks"
                  chartOpts={phantomChartOpts}
                />
              </Box>

              <Box className={classes.chartBoxes}>
                <MamEntryChart
                  entries={allEntries}
                  field={`phantom${dim}Masses`}
                  title="Masses"
                  chartOpts={phantomChartOpts}
                />
              </Box>
            </Box>

            {mode === EntryModes.ENTER && (
              <Box className={classes.remarkSection}>
                <Typography className={classes.remarkTitle} fontSize={16}>
                  Remarks
                </Typography>
                <RadTextInput
                  multiline
                  disabled={formDisabled}
                  name={`entries.new.phantom${dim}Remarks`}
                  type="text"
                  classes={{ root: classes.remarks }}
                />
              </Box>
            )}

            <TaskNav
              taskName={taskName}
              taskId={taskId}
              onComplete={() => handleSubmit(values, submitForm)}
              mode={mode}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

PhantomDashboard.propTypes = {
  activeEntry: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string,
  saveEntries: PropTypes.func.isRequired,
  mamEntries: PropTypes.objectOf(PropTypes.any),
  taskId: PropTypes.number.isRequired,
  formDisabled: PropTypes.bool,
  is3D: PropTypes.bool,
};

PhantomDashboard.defaultProps = {
  mode: EntryModes.ENTER,
  mamEntries: { leading: [], trailing: [] },
  formDisabled: false,
  is3D: false,
};

export default PhantomDashboard;
