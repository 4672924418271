import React from 'react';
import { useFormikContext } from 'formik';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { weeklyCommonStyles } from 'views/entry/mam/components/commonStyles';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import { formatDate } from 'utils/time.util';
import { RadTextInput } from 'presentational/Inputs';

const useStyles = makeStyles(weeklyCommonStyles);

function CheckEntryForm(props) {
  const { entryData, formDisabled, click } = props;
  const classes = useStyles();
  const { values } = useFormikContext();

  if (!entryData) return null;

  const entryIndex = entryData._id || 'new';

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableRow className={classes.entryRow}>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {formatDate(entryData.date)}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {entryData.initials}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        <RadTextInput
          disabled={formDisabled}
          name={`entries.${entryIndex}.${MamEntryFields.viewBoxRemarks}`}
          type="text"
          inputProps={{
            style: {
              padding: '0.5rem',
            },
          }}
          classes={{
            root: classes.remarks,
          }}
        />
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        <RadTextInput
          inputProps={{
            style: {
              padding: '0.5rem',
            },
          }}
          disabled={formDisabled || values.checkRemarksShared}
          name={`entries.${entryIndex}.${MamEntryFields.flatFieldRemarks}`}
          type="text"
          classes={{ root: classes.remarks }}
          value={values.checkRemarksShared ? values.viewBoxRemarks : values.flatFieldRemarks}
        />
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {cancelButton}
      </TableCell>
    </TableRow>
  );
}

CheckEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  formDisabled: PropTypes.bool,
  click: PropTypes.func,
};

CheckEntryForm.defaultProps = {
  formDisabled: false,
  click: null,
};

export default CheckEntryForm;
