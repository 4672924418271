import React from 'react';
import { TableCell, TableRow, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { RadTextInput } from 'presentational/Inputs';
import { entryTypes } from 'views/entry/mam/components/mamEntry.util';

const useStyles = makeStyles((theme) => ({
  selectedTableCell: {
    padding: 0,
    height: '50px',
    textAlign: 'center',
    borderBottom: 'none',
  },
  formControl: {
    margin: '0px 0',
  },
  noPadding: {
    padding: 0,
  },
  textRoot: {
    margin: '0 1.5rem',
  },
  textRootOut: {
    margin: '0 1.5rem',
    color: theme.palette.primary.red,
  },
}));

function MamNumberInput({ entryType, name, formDisabled, isOut, ...props }) {
  const classes = useStyles();

  const rootStyle = isOut ? classes.textRootOut : classes.textRoot;

  let inputProps;
  switch (entryType) {
    case entryTypes.KVP:
      inputProps = { min: 24, max: 40 };
      break;
    case entryTypes.MAS:
      inputProps = { min: 20, max: 150 };
      break;
    case entryTypes.MAS3:
      inputProps = { min: 20, max: 150 };
      break;
    case entryTypes.POS_NUM:
      inputProps = { min: 0 };
      break;
    case entryTypes.SNR:
      inputProps = { min: 30, max: 70 };
      break;
    case entryTypes.COMP:
      inputProps = { min: 3.7, max: 5.7, step: 0.1 };
      break;
    default:
      throw new Error(`Unrecognized entry type: ${entryType}`);
  }

  return (
    <TableRow>
      <TableCell className={classes.selectedTableCell}>
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={name}
            type="number"
            disabled={formDisabled}
            InputProps={{
              inputProps,
              classes: { inputMarginDense: classes.noPadding, root: rootStyle },
            }}
            {...props}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );
}

MamNumberInput.propTypes = {
  entryType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formDisabled: PropTypes.bool,
  isOut: PropTypes.bool,
};

MamNumberInput.defaultProps = {
  formDisabled: false,
  isOut: false,
};

export default MamNumberInput;
