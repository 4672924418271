import React from 'react';
import PropTypes from 'prop-types';
import { generateDailyEntries } from 'utils/entry.util';
import QcDailyToggleableEntry from './components/QcDailyToggleableEntry';

const generateEmptyDailyEntry = (day) => ({
  day,
  mode: day % 2 ? 'axial' : 'helical',
  empty: true,
});

function QcDailyEntryList(props) {
  const { dailyEntries, date, mode } = props;
  const generatedEntries = generateDailyEntries(dailyEntries, date.start, generateEmptyDailyEntry);

  return generatedEntries.map((entry) => <QcDailyToggleableEntry key={entry.day} entryData={entry} mode={mode} />);
}

QcDailyEntryList.propTypes = {
  dailyEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
};

export default QcDailyEntryList;
