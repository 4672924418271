import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import * as serviceWorker from './serviceWorker';
import AppProviders from './context/AppProviders';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProviders>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </AppProviders>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
