import React from 'react';
import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  tableCell: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
  },
};

function MamRejectTableCell({ classes, children, ...props }) {
  return (
    <TableCell className={classes.tableCell} size="small" {...props}>
      {children}
    </TableCell>
  );
}

export default withStyles(styles)(MamRejectTableCell);
