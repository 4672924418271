import React from 'react';
import { TableRow, TableCell, Input, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatMonthDate } from 'utils/time.util';
import { RadTextInput } from 'presentational/Inputs';
import UndoIcon from '@mui/icons-material/Undo';

const styles = (theme) => ({
  formControl: {
    margin: '0px 0',
    textAlign: 'center',
    width: '100%',
  },
  icons: {
    fontSize: 16,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  inputProps: {
    padding: '0.2rem 0',
    fontSize: '0.725rem',
    width: '100%',
  },
  remarks: {
    width: '100%',
  },
});

function ForceCompForm(props) {
  const { entryData, classes, click } = props;

  const entryIndex = entryData._id || 'new';

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <Tooltip title="Undo">
          <UndoIcon classes={{ root: classes.icons }} />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <TableRow>
      <TableCell size="small">
        {formatMonthDate(entryData.date)}
        <Input type="hidden" name={`entries.${entryIndex}.date`} value={entryData.date} />
      </TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        <RadTextInput
          sx={{ height: 'auto' }}
          inputProps={{
            style: {
              padding: '0 4px',
            },
          }}
          name={`entries.${entryIndex}.remarks`}
          type="text"
          classes={{ root: classes.remarks }}
        />
      </TableCell>
      <TableCell size="small">{cancelButton}</TableCell>
    </TableRow>
  );
}

ForceCompForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

ForceCompForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(ForceCompForm);
