import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { isEqual } from 'lodash';
import { Button, Typography, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useDeviceCtx } from 'context/DeviceContext';
import { deviceTypes, ctFields, mriFields } from 'types/device';
import { generateChartConfig } from 'utils/chart.util';
import radqcApi from 'dataExchange/radqcApiClient';
//import WarnBeforeLeaving from 'components/WarnBeforeLeaving';
import ChartEditorList from './components/ChartEditorList';

const styles = (theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  paper: {
    padding: '1rem',
    minHeight: '28rem',
    backgroundColor: theme.white,
    transition: '0.5s ease-in-out',
    borderTop: '1px solid #1c223714',
  },
  addButtonBox: {
    margin: '2rem',
    width: '100%',
  },
  saveButton: {
    margin: '8px',
    textTransform: 'capitalize',
  },
  addChartButton: {
    color: theme.palette.primary.main,
    width: '93%',
    margin: '0 2rem',
  },
  addIcon: {
    marginRight: '20px',
  },
  helperText: {
    opacity: 0.7,
  },
  chartBox: {
    maxHeight: '28rem',
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

const arrayItemMove = (array, fromIndex, toIndex) => {
  const element = array[fromIndex];
  const newArray = [...array];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
};

function MetricsDashboard(props) {
  const { match, classes } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const device = devices.find((a) => match.url.includes(a._id));
  const [chartConfigs, setChartConfigs] = useState(device.metricsConfig);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEqual(device.metricsConfig, chartConfigs)) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [device, chartConfigs]);

  const defaultChartConfig = {
    deviceId: device._id,
    timePeriod: '1 year',
    timeScale: { unit: 'day', value: 1 },
    trackingProp: device.type === deviceTypes.CT ? ctFields.water : mriFields.centerFreq,
    display: true,
    showRange: true,
  };

  const moveChartPosition = (oldChartIndex, newChartIndex) => {
    const newConfigList = arrayItemMove(chartConfigs, oldChartIndex, newChartIndex);
    setChartConfigs(newConfigList);
  };

  const addChartConfig = () => {
    const newConfigList = [...chartConfigs];
    const newConfig = generateChartConfig(defaultChartConfig, { showRange: true });
    newConfigList.push(newConfig);
    setChartConfigs(newConfigList);
  };

  const removeChartConfig = (index) => {
    const newConfigList = [...chartConfigs];
    newConfigList.splice(index, 1);
    setChartConfigs(newConfigList);
  };

  const updateChartConfig = (index, chartConfig) => {
    if (!chartConfig || index < 0 || index > chartConfig.length - 1) {
      return;
    }
    const newConfigList = [...chartConfigs];
    newConfigList[index] = chartConfig;
    setChartConfigs(newConfigList);
  };

  const saveChartConfigs = async () => {
    const newDeviceData = Object.assign(device);
    newDeviceData.metricsConfig = chartConfigs;
    const accessToken = await getAccessTokenSilently();
    await radqcApi('put', `/devices/${device._id}`, newDeviceData, { accessToken });
    updateDevices();
    enqueueSnackbar('Saved configuration changes');
  };

  return (
    <Box className={classes.main}>
      {/* <WarnBeforeLeaving
        key={unsavedChanges}
        warnCondition={unsavedChanges}
        warning="There are unsaved changes to the configuration. Are you sure you want to leave?"
      /> */}

      <Box className={classes.top}>
        <Box className={classes.header}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }} className={classes.helperText}>
            Define metrics for this device.
          </Typography>
        </Box>
        <Button onClick={() => saveChartConfigs()} variant="outlined" color="primary" className={classes.saveButton}>
          Save Change
        </Button>
      </Box>

      <Box className={classes.chartBox}>
        <ChartEditorList
          savedConfigs={chartConfigs}
          moveChartPosition={moveChartPosition}
          updateChartConfig={updateChartConfig}
          removeChartConfig={removeChartConfig}
          deviceType={device.type}
          baseClasses={classes.paper}
        />
      </Box>

      <Box className={classes.addButtonBox}>
        <Button onClick={() => addChartConfig()} className={classes.addChartButton}>
          <AddIcon className={classes.addIcon} />
          Add New Chart
        </Button>
      </Box>
    </Box>
  );
}

export default withRouter(withStyles(styles)(MetricsDashboard));
