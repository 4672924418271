import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useDeviceCtx } from 'context/DeviceContext';
import { getEntryModesForRole } from 'utils/entry.util';

const styles = (theme) => ({
  modeButtons: {
    width: '80px',
    textTransform: 'capitalize',
    border: '1px solid',
    color: theme.palette.primary.darkMain,
    borderColor: theme.palette.primary.darkMain,
    transition: '0.2s ease-in-out',
    marginRight: '1rem',

    '@media (max-width: 520px)': {
      width: 'initial',
      marginRight: '0.5rem',
    },
  },
  selected: {
    color: '#eee',
    backgroundColor: theme.palette.primary.darkMain,
    '&:hover': {
      color: '#eee',
      backgroundColor: theme.palette.primary.darkMain,
    },
  },
});

function ModeSelection(props) {
  const { match, modeChange, mode, classes } = props;
  const { currUser } = useCurrUserCtx();
  const { devices } = useDeviceCtx();
  const device = devices.find((a) => match.url.includes(a._id));

  const isWeekly = match.url.includes('weekly');
  let entryModes = getEntryModesForRole(currUser.role, isWeekly);
  if (device.type === 'mam') {
    entryModes = entryModes.filter((entryMode) => entryMode !== 'review');
  }

  useEffect(() => {
    if (!entryModes.includes(mode)) {
      modeChange(entryModes[0]);
    }
  });

  return (
    <>
      {entryModes.map((entryMode) => (
        <Button
          key={entryMode}
          onClick={() => modeChange(entryMode)}
          className={mode === entryMode ? clsx(classes.selected, classes.modeButtons) : clsx(classes.modeButtons)}
        >
          {entryMode}
        </Button>
      ))}
    </>
  );
}

ModeSelection.propTypes = {
  mode: PropTypes.string.isRequired,
  modeChange: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(ModeSelection));
