import React, { useState } from 'react';
import { mapValues, pick, uniqBy } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { EntryModes } from 'types/entry';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import CheckEntryDisplay from './CheckEntryDisplay';
import CheckEntryForm from './CheckEntryForm';
import { RadCheckbox } from 'presentational/Inputs';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import TaskNav from 'views/entry/mam/weekly/modify/components/TaskNav';

const useStyles = makeStyles((theme) => ({
  tableTitles: {
    color: 'black',
    fontSize: '0.8rem',
    padding: '1rem 0',
    textAlign: 'center',
    minWidth: '60px',
  },
  entryRow: {
    maxWidth: '100%',
    backgroundColor: `${theme.palette.primary.main}1a`,
  },
  remarks: {
    width: '100%',
  },
  inputs: {
    padding: 0,
  },
  checkboxContainer: {
    padding: '0 1.5rem',
  },
  cellRoots: {
    height: '68px',
    whiteSpace: 'nowrap',
  },
}));

const taskName = 'ViewBox & Flat Field Checklist';

function CheckDashboard(props) {
  const { activeEntry, mode, saveEntries, mamEntries, taskId, formDisabled } = props;
  const classes = useStyles();
  const [entryKey, setEntryKey] = useState(0);

  const initialValues = { entries: {} };
  let enterModeColumns = null;
  if (mode === EntryModes.ENTER) {
    initialValues.entries = {
      new: pick(activeEntry, [MamEntryFields.viewBoxRemarks, MamEntryFields.flatFieldRemarks]),
    };
    initialValues.entries.new.checkRemarksShared = false;
    enterModeColumns = (
      <>
        {mamEntries.leading.map((entry) => (
          <CheckEntryDisplay key={entry._id} entryData={entry} />
        ))}
        <CheckEntryForm entryData={activeEntry} formDisabled={formDisabled} />
        {mamEntries.trailing.map((entry) => (
          <CheckEntryDisplay key={entry._id} entryData={entry} />
        ))}
      </>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                checkRemarksShared: Yup.bool(),
                viewBoxRemarks: Yup.string(),
                flatFieldRemarks: Yup.string(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (mode === EntryModes.ENTER) {
          if (newEntries[0].checkRemarksShared) {
            newEntries[0].flatFieldRemarks = newEntries[0].viewBoxRemarks;
          }
          delete newEntries[0].checkRemarksShared;
          newEntries[0].checkPerformed = true;
        }
        saveEntries(newEntries);
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
      }}
    >
      {({ values, submitForm }) => {
        let allEntries = [];
        if (mode === EntryModes.EDIT) {
          allEntries = [
            ...mamEntries.leading,
            ...Object.values(values.entries),
            ...mamEntries.trailing,
            /* eslint-disable-next-line no-nested-ternary */
          ].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
          allEntries = uniqBy(allEntries, '_id');
        }
        return (
          <Form>
            {mode === EntryModes.ENTER && (
              <Box className={classes.checkboxContainer}>
                <RadCheckbox name="entries.new.checkRemarksShared" disabled={formDisabled}>
                  <Typography sx={{ fontSize: 14 }}>Remarks apply to all fields</Typography>
                </RadCheckbox>
              </Box>
            )}

            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.headerTableRow}>
                  <TableCell size="small" className={classes.tableTitles}>
                    Date
                  </TableCell>
                  <TableCell size="small" className={classes.tableTitles}>
                    Initials
                  </TableCell>
                  <TableCell size="small" className={classes.tableTitles}>
                    View Remarks
                  </TableCell>
                  <TableCell size="small" className={classes.tableTitles}>
                    Flat Field Remarks
                  </TableCell>
                  <TableCell size="small" className={classes.tableTitles} />
                </TableRow>
              </TableHead>
              <TableBody>
                {enterModeColumns}
                {allEntries.map((entry) => (
                  <ToggleableEntry
                    key={entryKey + entry.date}
                    entryData={entry}
                    mode={mode}
                    entryDisplay={CheckEntryDisplay}
                    entryForm={CheckEntryForm}
                  />
                ))}
              </TableBody>
            </Table>
            <TaskNav taskName={taskName} taskId={taskId} onComplete={submitForm} />
          </Form>
        );
      }}
    </Formik>
  );
}

CheckDashboard.propTypes = {
  activeEntry: PropTypes.objectOf(PropTypes.any).isRequired,
  saveEntries: PropTypes.func.isRequired,
  mamEntries: PropTypes.objectOf(PropTypes.any),
  taskId: PropTypes.number.isRequired,
  formDisabled: PropTypes.bool,
};

CheckDashboard.defaultProps = {
  mamEntries: { leading: [], trailing: [] },
  formDisabled: false,
};

export default CheckDashboard;
