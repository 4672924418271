import { getUserInitials } from 'utils/user.util';

export const mamChecklistFields = [
  'sidIndicator',
  'angulationIndicator',
  'detentsLocks',
  'collimatorLight',
  'smoothnessMotion',
  'gridFunction',
  'compDevFunc',
  'compThickDisp',
  'compForceDisp',
  'glassShield',
  'expSwitches',
  'powerControls',
  'monitors',
  'techCharts',
  'footPedals',
  'compPaddles',
  'faceshields',
  'disinfection',
];

export const generateEmptyMamChecklist = (currUser, device) => {
  const newEntry = {
    _id: 'new',
    deviceId: device._id,
    date: new Date(),
    outFields: [],
    initials: getUserInitials(currUser),
    enteredById: currUser._id,
  };
  mamChecklistFields.forEach((field) => {
    newEntry[field] = 'pass';
  });

  return newEntry;
};
