import React from 'react';
import { Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { getDeviceStatus } from 'utils/device.util';

type Status = {
  outOfSpec: number;
  pastDueQA: number;
  pastDueReview: number;
  OK: number;
};

const initialStatus: Status = {
  outOfSpec: 0,
  pastDueQA: 0,
  pastDueReview: 0,
  OK: 0,
};

type PieChartProps = {
  type: string;
  devices: any[];
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      align: 'start' as const,
      position: 'left' as const,
    },
  },
} as const;

function PieChart({ type, devices }: PieChartProps) {
  const status: Status = devices.reduce(
    (acc, device) => {
      const deviceStatus = getDeviceStatus(device);
      switch (deviceStatus) {
        case 'Out of Spec':
          acc.outOfSpec++;
          break;
        case 'Past Due Review':
          acc.pastDueReview++;
          break;
        case 'Past Due QA':
          acc.pastDueQA++;
          break;
        default:
          acc.OK++;
      }
      return acc;
    },
    { ...initialStatus },
  );

  const data = {
    labels: ['Out of Spec', 'Past Due QA', 'Past Due Review', 'OK'],
    datasets: [
      {
        label: 'Devices',
        data: [status.outOfSpec, status.pastDueQA, status.pastDueReview, status.OK],
        backgroundColor: ['#f93e3e94', 'rgba(255, 206, 86, 0.2)', 'rgb(239, 145, 38, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: ['#ff0000', 'rgba(255, 206, 86, 1)', 'rgb(239, 145, 38, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 0.5,
      },
    ],
  };

  return (
    <>
      <Typography
        sx={{
          display: 'flex',
          fontWeight: 600,
          marginLeft: '8px',
          textTransform: 'uppercase',
          marginY: '4px',
          fontSize: 14,
        }}
      >
        {type}
      </Typography>
      <Doughnut data={data} options={options} />
    </>
  );
}

export default PieChart;
