import React from 'react';
import Section from 'views/unauthenticated/landing/components/Section';
import Carousel from './Carousel';
import { featureOne, featureTwo, featureThree } from 'utils/landing.util';
import FeatureImageOne from 'assets/images/RADQC_Laptop.png';
import FeatureImageTwo from 'assets/images/main3.png';
import FeatureImageThree from 'assets/images/RADQC_DisplayChart.png';

function Features() {
  return (
    <>
      <Section {...featureThree} img={FeatureImageThree} />
      <Section {...featureTwo} img={FeatureImageTwo} />
      <Section {...featureOne} img={FeatureImageOne} />
      <Carousel />
    </>
  );
}

export default Features;
