import React from 'react';
import { ThemeProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import theme from 'theme';
import { ModalCtxProvider } from './ModalContext';

function AppProviders({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <ModalCtxProvider>{children}</ModalCtxProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default AppProviders;
