import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { weeklyCommonStyles } from 'views/entry/mam/components/commonStyles';
import { formatDate } from 'utils/time.util';

const useStyles = makeStyles(weeklyCommonStyles);

function CheckEntryDisplay(props) {
  const { entryData, click } = props;
  const classes = useStyles();

  let editButton = null;
  if (click) {
    editButton = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableRow key={entryData._id} className={classes.entryRow}>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {formatDate(entryData.date)}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {entryData.initials}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {entryData.viewBoxRemarks}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {entryData.flatFieldRemarks}
      </TableCell>
      <TableCell size="small" classes={{ root: classes.cellRoots }}>
        {editButton}
      </TableCell>
    </TableRow>
  );
}

CheckEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

CheckEntryDisplay.defaultProps = {
  click: null,
};

export default CheckEntryDisplay;
