import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatMonthDate } from 'utils/time.util';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  outValue: {
    color: theme.palette.primary.red,
  },
});

function RwsWeeklyEntryDisplay(props) {
  const { entryData, click } = props;

  return (
    <TableRow key={entryData._id} onClick={click}>
      <TableCell size="small">{formatMonthDate(entryData.date)}</TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small">{entryData.remarks}</TableCell>
      <TableCell size="small" />
    </TableRow>
  );
}

RwsWeeklyEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

RwsWeeklyEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(RwsWeeklyEntryDisplay);
