import React from 'react';
import PropTypes from 'prop-types';
import MamEntryChart from 'views/entry/mam/components/MamEntryChart';
import { getModifiedEntries } from 'views/entry/mam/components/mamEntry.util';

function CompressionPdfCharts(props) {
  const { entries, setChartImage } = props;
  const compressChartOpts = { suggestedMin: -2.0, suggestedMax: 2.0 };
  const newField = 'compthickDiff';
  const DEFAULT_COMP_THICKNESS = 4;
  const modifyThickness = (entry) => entry.compThickness - DEFAULT_COMP_THICKNESS;

  const modifiedEntries = getModifiedEntries(entries, 'compThickness', newField, modifyThickness);

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
      }}
    >
      <MamEntryChart
        entries={modifiedEntries}
        field={newField}
        title="Indicated Thickness"
        chartOpts={compressChartOpts}
        setChartImage={setChartImage}
      />
    </div>
  );
}

CompressionPdfCharts.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.any).isRequired,
  setChartImage: PropTypes.func.isRequired,
};

export default CompressionPdfCharts;
