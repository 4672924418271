import React from 'react';
import { withStyles } from '@mui/styles';
import { Table, TableRow, TableHead, TableCell, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import { formatMonthDate } from 'utils/time.util';
import { displayValue } from './mamEntry.util';

const styles = (theme) => ({
  icons: {
    fontSize: 16,
  },
  tableStyle: { overflow: 'auto', minWidth: '537px' },
  headerTableRow: { backgroundColor: 'transparent' },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.red}`,
  },
  tableBody: {
    border: '1px solid green',
  },
});

function MamConfirmTable(props) {
  const { classes, confirmEntries, fieldTitles } = props;

  const getStyleForField = (entry, field) => (entry.outFields.includes(field) ? classes.outFieldValue : null);

  return (
    <Table stickyHeader className={classes.tableStyle}>
      <TableHead>
        <TableRow className={classes.headerTableRow}>
          <TableCell className={classes.tableTitles}>Date</TableCell>
          {Object.values(fieldTitles).map((fieldTitle) => (
            <TableCell key={fieldTitle} className={classes.tableTitles}>
              {fieldTitle}
            </TableCell>
          ))}
          <TableCell className={classes.tableTitles}>Initials</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        {confirmEntries.map((entry) => (
          <TableRow key={entry.date} className={classes.headerTableRow}>
            <TableCell>{formatMonthDate(entry.date)}</TableCell>
            {Object.keys(fieldTitles).map((field) => (
              <TableCell key={field} className={getStyleForField(entry, field)}>
                {displayValue(entry[field])}
              </TableCell>
            ))}
            <TableCell>{entry.initials}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

MamConfirmTable.propTypes = {
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
  fieldTitles: PropTypes.objectOf(PropTypes.any),
};

MamConfirmTable.defaultProps = {
  fieldTitles: {},
};

export default withStyles(styles)(MamConfirmTable);
