import { createTheme } from '@mui/material/styles';

import palette from './palette';
import overrides from './overrides';

const theme = createTheme({
  palette,
  overrides,
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

export default theme;
