import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer, Paper } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import { splitEntriesIntoRecords } from 'utils/entry.util';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import HeTable from './heTable/HeTable';

const styles = {
  tableContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '600px',
  },
};

function HeDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedHeEntries, setSavedHeEntries] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  // get existing data
  const getEntries = useCallback(async () => {
    const deviceId = getDeviceIdFromUrl(match.url);
    const startDate = date.start.toISOString();
    let endDate = new Date(date.end);
    const now = new Date();
    endDate.setDate(now.getDate() + 1);
    endDate = endDate.toISOString();

    let receivedEntries;

    try {
      const accessToken = await getAccessTokenSilently();
      receivedEntries = await radqcApi('get', `/entries/he/device/${deviceId}?start=${startDate}&end=${endDate}`, {
        accessToken,
      });
    } catch (err) {
      console.log(err);
    }

    if (!receivedEntries) receivedEntries = [];
    setSavedHeEntries(receivedEntries);
  }, [match.url, date, getAccessTokenSilently]);

  useEffect(() => {
    getEntries();
  }, [date, getEntries]);

  // view mode should only view PDF
  if (mode === 'view') {
    const numEntriesPerRecord = 40;

    const records = splitEntriesIntoRecords(savedHeEntries, numEntriesPerRecord);

    return <RecordViewer recordType="he" records={records} />;
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <HeTable
        key={mode + savedHeEntries.length}
        mode={mode}
        updateEntries={getEntries}
        heEntries={savedHeEntries.length > 0 ? savedHeEntries : []}
      />
    </TableContainer>
  );
}

export default withRouter(withStyles(styles)(HeDashboard));
