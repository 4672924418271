import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useOrganizationCtx } from 'context/OrganizationContext';
import { useModalCtx } from 'context/ModalContext';
import theme from 'theme';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ModifyOrganizations from '../../Organizations/ModifyOrganizations';

export default function FacilitiesDropdown() {
  const { facilities, groups, selectedOrg, setSelectedOrg } = useOrganizationCtx();
  const { currUser, modifyCurrUser } = useCurrUserCtx() as any;
  const { showModal } = useModalCtx();

  let filterObject;
  if (currUser.selectedFacility) {
    filterObject = facilities.find((facility: any) => facility._id === currUser.selectedFacility);
  } else if (currUser.selectedGroup) {
    filterObject = groups.find((group: any) => group._id === currUser.selectedGroup) as any;
  }
  const filterName = filterObject ? filterObject?.name : 'All Facilities/Groups';
  const isSelectAll = selectedOrg?.name === 'all';

  const handleItemClick = async (newFacility?: any, newGroup?: any) => {
    if (!newFacility && !newGroup) {
      setSelectedOrg({
        type: null,
        id: 'all',
        name: 'all',
      });
    } else {
      const { _id, name } = newFacility ?? newGroup;

      setSelectedOrg({
        type: newFacility ? 'facility' : 'group',
        id: _id,
        name,
      });
    }

    const newSelections = {
      selectedFacility: newFacility ? newFacility._id : null,
      selectedGroup: newGroup ? newGroup._id : null,
    };
    await modifyCurrUser(newSelections);
  };

  const MenuGroupItem = withStyles({
    root: {
      fontSize: 14,
      paddingLeft: theme.spacing(3),
    },
  })(MenuItem);

  const MenuGroupName = withStyles({
    root: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      opacity: '1 !important',
      backgroundColor: '#1c22371a', //TODO update color hex
    },
  })(MenuItem);

  return (
    <Box component="div" display="flex" alignItems="center">
      <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
        <Select
          sx={{ width: '200px', fontSize: 14 }}
          labelId="all-facilities-groups-select"
          id="all-facilities-groups"
          value={selectedOrg.id}
          defaultValue={filterName}
          label="All Faciities/Groups"
        >
          <MenuGroupItem value="all" onClick={() => handleItemClick()}>
            All Facilities
          </MenuGroupItem>
          <MenuGroupName disabled>Facilities</MenuGroupName>
          {facilities.map((facility: { _id: string; name: string }) => {
            return (
              <MenuGroupItem key={facility._id} value={facility._id} onClick={() => handleItemClick(facility, null)}>
                {facility.name}
              </MenuGroupItem>
            );
          })}
          <MenuGroupName disabled>Groups</MenuGroupName>
          {groups.map((group: { _id: string; name: string }) => {
            return (
              <MenuGroupItem key={group._id} value={group._id} onClick={() => handleItemClick(null, group)}>
                {group.name}
              </MenuGroupItem>
            );
          })}
        </Select>
      </FormControl>

      <Tooltip title="Edit">
        <IconButton
          sx={{ marginRight: '4px' }}
          disabled={isSelectAll}
          onClick={() => showModal(ModifyOrganizations, { isUpdate: true, isGroupType: selectedOrg.type === 'group' })}
        >
          <EditNoteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
