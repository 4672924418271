import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FormControl, Button, Typography, IconButton, Box, InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useOrganizationCtx } from 'context/OrganizationContext';
import radqcApi from 'dataExchange/radqcApiClient';
import { RadTextInput } from 'presentational/Inputs';
import { useModalCtx } from 'context/ModalContext';
import ConfirmDialog from 'components/ConfirmDialog';
import FacilitiesList from './FacilitiesList';
import { useDeviceCtx } from 'context/DeviceContext';

const styles = {};

function ModifyOrganizations({ isUpdate, isGroupType }: any) {
  const { facilities, groups, updateFacilities, updateGroups, selectedOrg, setSelectedOrg } = useOrganizationCtx();
  const [takenNames, setTakenNames] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { currUser } = useCurrUserCtx();
  const { unfilteredDevices, updateDevices } = useDeviceCtx();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal, hideModal } = useModalCtx();
  console.log('currUser: ', currUser);

  const orgType = isGroupType ? 'Group' : 'Facility';
  const orgList = isGroupType ? groups : facilities;
  const selectedOrganization = isUpdate ? orgList.find((a: any) => a._id === selectedOrg.id) : undefined;
  // console.log('selectedOrganization:', selectedOrganization);
  const [selectedFacilityIds, setSelectedFacilityIds] = useState(selectedOrganization?.facilities || []) as any;
  const endpoint = isGroupType ? '/groups' : '/facilities';
  const initialValues = isUpdate ? { name: selectedOrganization.name } : { name: '' };
  const actionText = isUpdate ? 'Update' : 'Add New';

  const deleteItem = async () => {
    setSelectedOrg({
      type: null,
      id: 'all',
      name: 'all',
    });
    const accessToken = await getAccessTokenSilently();
    await radqcApi('delete', `${endpoint}/${selectedOrganization._id}`, { accessToken });
    if (isGroupType) {
      updateGroups();
    } else {
      updateFacilities();
    }
    enqueueSnackbar(`You have permanently deleted ${selectedOrganization.name}.`);
  };

  const handleDelete = () => {
    // Prevent facility deletion if it has associated devices
    const hasDevices = unfilteredDevices.find((device: any) => device.facilityId === selectedOrganization._id);

    if (hasDevices) {
      const confirmDialogProps = {
        message: `This facility has associated devices. Move those devices to another facility before deleting.`,
        actionText: 'OK',
        confirmAction: () => {
          hideModal();
        },
        hasCancel: false,
      };
      showModal(ConfirmDialog, confirmDialogProps);
      return;
    }

    const confirmDialogProps = {
      message: `Would you like to delete this ${orgType} permanently?`,
      actionText: 'DELETE',
      confirmAction: () => {
        hideModal();
        deleteItem();
      },
      cancelAction: () => hideModal(),
    };
    showModal(ConfirmDialog, confirmDialogProps);
  };

  const getCurrentData = useCallback(async () => {
    let otherItems = orgList;
    if (selectedOrganization) {
      otherItems = orgList.filter((org: any) => org._id !== selectedOrganization._id);
    }
    const newTakenNames = otherItems.map((org: any) => org.name);
    if (!isUpdate && selectedOrganization) {
      newTakenNames.push(selectedOrganization.name);
    }
    setTakenNames(newTakenNames);
  }, [orgList, selectedOrganization, isUpdate]);

  useEffect(() => {
    getCurrentData();
  }, [getCurrentData]);

  return (
    <Box
      aria-labelledby="modify-facility-or-group-modal"
      aria-describedby="modify-facility-or-group-modal"
      component="div"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Must be 50 characters or less')
            .notOneOf(takenNames, 'This name is already in use')
            .required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newOrg = Object.assign(values);
          if (isGroupType) {
            newOrg.facilities = selectedFacilityIds;
          }

          // Submit item data to DB
          const accessToken = await getAccessTokenSilently();
          if (isUpdate) {
            await radqcApi('put', `${endpoint}/${selectedOrganization._id}`, newOrg, { accessToken });
            enqueueSnackbar(`Updated ${isGroupType ? 'group' : 'facility'} ${selectedOrganization.name}`);
            updateDevices();
          } else {
            newOrg.account = currUser.selectedAccount;
            await radqcApi('post', endpoint, newOrg, { accessToken });
            enqueueSnackbar(`Created new ${isGroupType ? 'group' : 'facility'}: ${newOrg.name}`);
          }

          isGroupType ? updateGroups() : updateFacilities();
          setSubmitting(false);
          hideModal();
          resetForm();
        }}
      >
        <Form>
          <Box component="div">
            <Box
              component="div"
              sx={{
                display: 'flex',
                fontWeight: 600,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{`${actionText} ${orgType}`}</Typography>
              {isUpdate && (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>

            <Box component="div" style={{ marginTop: '1rem' }}>
              <InputLabel
                id="name"
                aria-label="name"
                aria-labelledby="name"
                sx={{ fontSize: '14px', color: '#808080', display: 'flex', marginRight: '8px' }}
              >
                {orgType} Name*
              </InputLabel>
              <FormControl sx={{ width: '100%' }}>
                <RadTextInput name="name" type="text" />
              </FormControl>
            </Box>

            {isGroupType && (
              <FacilitiesList
                selectedIds={selectedFacilityIds}
                setSelectedIds={setSelectedFacilityIds}
                key="Device"
                facilities={facilities}
              />
            )}

            <Box component="div" sx={{ display: 'flex', marginTop: '24px' }}>
              <Button color="secondary" onClick={hideModal} sx={{ width: '180px' }}>
                Cancel
              </Button>
              <Button variant="outlined" color="primary" type="submit" sx={{ marginLeft: '16px', width: '180px' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
}

export default withStyles(styles)(withRouter(ModifyOrganizations));
