import React from 'react';
import clsx from 'clsx';
import { Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { trueFalseEmpty } from 'utils/entry.util';
import HeAdjustedTableCell from './heTable/components/HeAdjustedTableCell';

const styles = (theme) => ({
  tableStyle: { overflow: 'auto', minWidth: '537px' },
  outFieldValue: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.red}`,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}1a`,
      cursor: 'pointer',
      maxWidth: '100%',
    },
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '60px',
  },
  extendedCol: {
    width: '80px',
  },
  outFieldValues: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
  },
});

function HeConfirmTable(props) {
  const { classes, confirmEntries } = props;

  return (
    <Table stickyHeader className={classes.tableStyle}>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Helium Level (%)</TableCell>
          <TableCell>Pressure (PSI)</TableCell>
          <TableCell>Cold Head Running</TableCell>
          <TableCell>Entered By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {confirmEntries.map((entry) => (
          <TableRow key={entry.date} className={classes.tableRow}>
            <HeAdjustedTableCell className={clsx(classes.tableCellStyle, classes.extendedCol)}>
              {formatDate(entry.date)}
            </HeAdjustedTableCell>
            <HeAdjustedTableCell
              className={clsx(
                classes.tableCellStyle,
                classes.extendedCol,
                entry.outFields && entry.outFields.includes('heliumLevel') ? classes.outFieldValues : null,
              )}
            >
              {`${entry.heliumLevel} %`}
            </HeAdjustedTableCell>
            <HeAdjustedTableCell className={classes.tableCellStyle}>{`${entry.pressure} PSI`}</HeAdjustedTableCell>
            <HeAdjustedTableCell className={classes.tableCellStyle}>
              {trueFalseEmpty(entry.coldHeadRunning, 'Yes', 'No')}
            </HeAdjustedTableCell>
            <HeAdjustedTableCell className={classes.tableCellStyle}>{entry.enteredBy}</HeAdjustedTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

HeConfirmTable.propTypes = {
  confirmEntries: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(HeConfirmTable);
