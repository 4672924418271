import React from 'react';
import clsx from 'clsx';
import { Checkbox, TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}1a`,
      cursor: 'pointer',
      maxWidth: '100%',
    },
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
  },
  checkboxCol: {
    width: '40px',
  },
  extendedCol: {
    width: '80px',
  },
});

function RwsDailyEntryDisplay(props) {
  const { entryData, click, classes } = props;

  // TODO: Findout why the checkboxes below need the '!!'

  return (
    <TableRow onClick={click} className={classes.tableRow}>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>{entryData.day}</TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>
        <Checkbox checked={!!entryData.viewingCheckPerformed} disabled />
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.checkboxCol)}>
        <Checkbox checked={!!entryData.monitorCheckPerformed} disabled />
      </TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)}>{entryData.remarks}</TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)}>{entryData.initials}</TableCell>
      <TableCell className={clsx(classes.tableCellStyle, classes.extendedCol)} />
    </TableRow>
  );
}

RwsDailyEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

RwsDailyEntryDisplay.defaultProps = {
  click: null,
};

export default withStyles(styles)(RwsDailyEntryDisplay);
