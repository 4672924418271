import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { formatDateShort, getCurrentYear } from 'utils/time.util';
import { convertNaPassFail } from 'utils/entry.util';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  helperTexts: {
    fontSize: 10,
    opacity: 0.5,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
  },
  dateHeader: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
    color: palette.primary.main,
  },
  initialFooter: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
    color: palette.primary.main,
  },
  splitHeaders: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'center',
    marginBottom: 0,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    borderColor: 'white',
  },
  entryContentView: {
    marginBottom: 24,
    marginTop: 16,
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    flexDirection: 'row',
  },
  subContainerLeft: {
    width: '47%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0,
    margin: 0,
  },
  subContainerRight: {
    width: '50px',
    margin: 0,
  },
  sectionalHeaders: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 1,
    textAlign: 'left',
    marginBottom: 0,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  leftBox: {
    margin: 'auto',
    flexDirection: 'row',

    maxWidth: '140px',
    width: '140px',
  },
  box: {
    margin: 'auto',
    flexDirection: 'row',
    borderWidth: 0.5,
    borderColor: 'white',
    textAlign: 'left',
    maxWidth: '210px',
    width: '210px',
  },
  result: {
    fontSize: 9,
    textAlign: 'center',
    padding: 0,
    height: '13.85px',
    paddingTop: 2,
  },
  sections: {
    borderColor: '#669EE5',
    borderTopWidth: 0.5,
  },
  helperText: {
    marginLeft: 30,
  },
  copyRight: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 0.5,
    marginTop: '16px',
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function VisualEntriesHeader(props) {
  const { sectionalHeader, subHeaders } = props;

  const genRow = (item, index) => (
    <View key={item} style={styles.splitHeaders}>
      <View style={styles.leftBox}>
        {index === 0 ? (
          <Text style={styles.sectionalHeaders}>{sectionalHeader}</Text>
        ) : (
          <Text style={styles.scannerInfo} />
        )}
      </View>

      <View style={styles.box}>
        <Text style={styles.scannerInfo}>{item}</Text>
      </View>
    </View>
  );

  return <View style={styles.sections}>{subHeaders.map((item, index) => genRow(item, index))}</View>;
}

function MamMonthlyPdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records
        ? records.map((record) => (
            <Page key={record.entries[0]._id} style={styles.body} orientation="landscape">
              <View fixed style={styles.footer}>
                <Text
                  style={styles.header}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
                <Text fixed style={styles.header}>
                  Provided By Radiology QC Solutions
                </Text>
              </View>
              <Text style={styles.formTitle}>
                QC Manual for Selenia Dimensions/3Dimensions Systems (Visual Checklist)
              </Text>

              <View key={record.entries[0]._id} style={{ marginBottom: 16, marginTop: 32, marginLeft: 32 }}>
                <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
                <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
              </View>

              <View style={styles.helperText}>
                <Text style={styles.helperTexts}>Pass = 'P', Fail = 'F', N/A = Not Applicable</Text>
              </View>

              <View style={styles.entryContentView}>
                <View style={styles.subContainerLeft}>
                  <Text style={styles.dateHeader}>Date</Text>
                  <VisualEntriesHeader
                    sectionalHeader="C-arm/Gantry"
                    subHeaders={[
                      'SID Indicator or marks',
                      'Angulation Indicator',
                      'Detents/Look(All)',
                      'Collimator Light',
                      'Smoothness of  Motion',
                      'Grid function',
                      'Compresison device function',
                      'Compressioon thickness display',
                      'Compression force display',
                    ]}
                  />

                  <VisualEntriesHeader
                    sectionalHeader="Acquisition/Workstation"
                    subHeaders={['Glass Shield', 'Exposure Switches', 'Power Controls', 'Monitors', 'Technique Charts']}
                  />

                  <VisualEntriesHeader
                    sectionalHeader="Accessiories"
                    subHeaders={[
                      'Foot Pedals',
                      'Compression Paddles clean and not cracked',
                      'Faceshields clean and not cracked',
                      'Disinfection Materials Available',
                    ]}
                  />

                  <View style={{ borderColor: '#669EE5', borderTopWidth: 0.5 }}>
                    <Text style={styles.initialFooter}>Entered By (Initials)</Text>
                  </View>
                </View>

                {record.entries.map((entry) => (
                  <View key={entry._id} style={styles.subContainerRight}>
                    <Text style={styles.result}>{formatDateShort(entry.date)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.sidIndicator)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.angulationIndicator)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.detentsLocks)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.collimatorLight)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.smoothnessMotion)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.gridFunction)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.compDevFunc)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.compThickDisp)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.compForceDisp)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.glassShield)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.expSwitches)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.powerControls)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.monitors)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.techCharts)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.footPedals)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.compPaddles)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.faceshields)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.disinfection)}</Text>
                    <Text style={styles.result}> {entry.initials}</Text>
                  </View>
                ))}
              </View>

              <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
                <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
              </View>
            </Page>
          ))
        : null}
    </Document>
  );
}

MamMonthlyPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

MamMonthlyPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default MamMonthlyPdfPreview;
