import React, { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form } from 'formik';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Grid, FormControl, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDeviceCtx } from 'context/DeviceContext';
import { useModalCtx } from 'context/ModalContext';
import radqcApi from 'dataExchange/radqcApiClient';
import { RadTextInput } from 'presentational/Inputs';

const styles = (theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  header: {
    color: theme.palette.primary.main,
    margin: '8px',
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: '400',
  },
  formControl: {
    marginBottom: '10px',
  },
  spaceAbove: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  showLastButtons: {
    margin: '16px',
  },
  subHeading: {
    marginTop: '16px',
  },
  listContainer: {
    textAlign: 'center',
    maxHeight: '240px',
    overflowY: 'auto',
    padding: '16px',
    margin: '8px',
    border: `1px solid ${theme.palette.primary.main}3b`,
    borderRadius: '8px',
  },
  avgContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  baselineOverview: {
    margin: '16px 0 24px 0',
  },
  currentValues: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    marginLeft: '16px',
  },
});

const getNums = (mixedArray) => {
  const numsArray = [];
  mixedArray.forEach((item) => {
    const attemptedNum = parseFloat(item);
    if (!Number.isNaN(attemptedNum)) numsArray.push(attemptedNum);
  });
  return numsArray;
};

const averageOfNums = (arrayWithNums) => {
  const numsArray = getNums(arrayWithNums);
  if (numsArray.length < 1) return '';
  const newSum = numsArray.reduce((sum, val) => sum + val);
  return newSum / numsArray.length;
};

function QcBaseline(props) {
  const { deviceId, classes } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { getDeviceFromId, updateDevices } = useDeviceCtx();
  const scanner = getDeviceFromId(deviceId);
  const { hideModal } = useModalCtx();
  const [savedAxHelVals, setSavedAxHelVals] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getLast10Vals = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const receivedAxialHelicalVals = await radqcApi('get', `/entries/qc/device/${deviceId}/axialhelical`, {
      accessToken,
    });
    setSavedAxHelVals(receivedAxialHelicalVals);
  }, [getAccessTokenSilently, deviceId]);

  useEffect(() => {
    getLast10Vals();
  }, [getLast10Vals]);

  if (!scanner) {
    return null;
  }

  const handleLoadLast10Vals = (values, setFieldValue, isAxial) => {
    if (isAxial) {
      const newAxialVals = Object.assign(values.axialVals);
      savedAxHelVals.axialValues.forEach((axialVal, index) => {
        newAxialVals[index] = axialVal;
      });
      const newAxialAvg = newAxialVals.length > 0 ? averageOfNums(newAxialVals) : 5;
      setFieldValue('axialVals', newAxialVals);
      setFieldValue('axialAvg', newAxialAvg);
    } else {
      const newHelicalVals = Object.assign(values.helicalVals);
      savedAxHelVals.helicalValues.forEach((helicalVal, index) => {
        newHelicalVals[index] = helicalVal;
      });
      const newHelicalAvg = newHelicalVals.length > 0 ? averageOfNums(newHelicalVals) : 5;
      setFieldValue('helicalVals', newHelicalVals);
      setFieldValue('helicalAvg', newHelicalAvg);
    }
  };
  return (
    <Formik
      initialValues={cloneDeep(scanner.baseline)}
      validationSchema={Yup.object({
        axialVals: Yup.array().of(Yup.number().nullable()),
        helicalVals: Yup.array().of(Yup.number().nullable()),
        axialAvg: Yup.number().nullable(),
        helicalAvg: Yup.number().nullable(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = { baseline: values };

        const accessToken = await getAccessTokenSilently();
        await radqcApi('put', `/devices/${scanner._id}/baseline`, payload, { accessToken });
        enqueueSnackbar(`Updated baseline for ${scanner.name}`, { variant: 'info' });

        // TODO: only update current scanner
        updateDevices();
        setSubmitting(false);
        hideModal();
      }}
    >
      {({ values, handleChange, setFieldValue }) => {
        const onChangeInput = (e, index, typeOfAvg) => {
          handleChange(e);
          const newArray = JSON.parse(JSON.stringify(typeOfAvg === 'axialAvg' ? values.axialVals : values.helicalVals));
          if (newArray.length > 0) {
            newArray[index] = e.target.value;
            setFieldValue(typeOfAvg, averageOfNums(newArray));
          }
        };

        return (
          <Form className={classes.form}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <div className={classes.header}>Current Baseline :</div>
                  </Grid>
                  <Grid item xs={12} className={classes.baselineOverview}>
                    <Grid container>
                      <Grid item xs={4} className={classes.currentValues}>
                        Noise SD Range: {scanner.noiseRange}
                      </Grid>
                      <Grid item xs={4} className={classes.currentValues}>
                        Axial Average: {scanner.baseline.axialAvg}
                      </Grid>
                      <Grid item xs={4} className={classes.currentValues}>
                        Helical Average: {scanner.baseline.helicalAvg}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.header}>Enter Baseline</div>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction="column">
                      <Grid item xs={12} className={classes.subHeader}>
                        <div className={classes.subHeading}>Axial Noise</div>
                        <Button
                          className={classes.showLastButtons}
                          variant="outlined"
                          color="primary"
                          onClick={() => handleLoadLast10Vals(values, setFieldValue, true)}
                        >
                          Load Last 10 Values
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.listContainer}>
                        {values.axialVals.map((val, index) => (
                          <Grid key={`axialVals[${index}]`} item xs={12}>
                            <FormControl className={classes.formControl}>
                              <RadTextInput
                                label={index + 1}
                                name={`axialVals[${index}]`}
                                type="number"
                                onChange={(e) => onChangeInput(e, index, 'axialAvg')}
                              />
                            </FormControl>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid item xs={12} className={classes.avgContainer}>
                        <FormControl className={classes.formControl}>
                          <RadTextInput label="Axial Average" name="axialAvg" type="number" disabled />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} className={classes.subHeader}>
                        <div className={classes.subHeading}>Helical Noise</div>
                        <Button
                          className={classes.showLastButtons}
                          variant="outlined"
                          color="primary"
                          onClick={() => handleLoadLast10Vals(values, setFieldValue, false)}
                        >
                          Load Last 10 Values
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.listContainer}>
                        {values.helicalVals.map((val, index) => (
                          <Grid key={`helicalVals[${index}]`} item xs={12}>
                            <FormControl className={classes.formControl}>
                              <RadTextInput
                                label={index + 1}
                                name={`helicalVals[${index}]`}
                                type="number"
                                onChange={(e) => onChangeInput(e, index, 'helicalAvg')}
                              />
                            </FormControl>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid item xs={12} className={classes.avgContainer}>
                        <FormControl className={classes.formControl}>
                          <RadTextInput label="Helical Average" name="helicalAvg" type="number" disabled />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.spaceAbove}>
                      {/* <Grid item xs={12} className={classes.buttonContainer}> */}
                      <FormControl className={clsx(classes.formControl, classes.buttons)}>
                        <Button variant="outlined" color="secondary" onClick={() => hideModal()}>
                          Close
                        </Button>
                      </FormControl>
                      <FormControl className={clsx(classes.formControl, classes.buttons)}>
                        <Button variant="outlined" color="primary" type="submit">
                          Save
                        </Button>
                      </FormControl>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

QcBaseline.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default withStyles(styles)(QcBaseline);
