import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

function ToggleableEntry(props) {
  const { entryData, mode, entryDisplay: EntryDisplay, entryForm: EntryForm } = props;
  const { values, setFieldValue } = useFormikContext();
  const [isToggled, setToggled] = useState(false);

  let EntryComponent = EntryDisplay;

  const handleToggle = () => {
    const updatedEntries = Object.assign(values.entries);
    if (!isToggled) {
      updatedEntries[entryData._id] = Object.assign(entryData);
    } else {
      delete updatedEntries[entryData._id];
    }
    setFieldValue('entries', updatedEntries);
    setToggled(!isToggled);
  };

  const canToggle = isToggled || mode === 'edit';
  const toggleHandler = canToggle ? handleToggle : null;

  if (isToggled) {
    EntryComponent = EntryForm;
  }

  return <EntryComponent entryData={entryData} click={toggleHandler} />;
}

ToggleableEntry.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
  entryDisplay: PropTypes.elementType.isRequired,
  entryForm: PropTypes.elementType.isRequired,
};

export default ToggleableEntry;
