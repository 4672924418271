const overviewTab = {
  label: 'Overview',
  linkExt: '',
};

const usersTab = {
  label: 'Users',
  linkExt: '/users',
};

const ctTabs = [
  overviewTab,
  {
    label: 'QC',
    linkExt: '/qc',
  },
  {
    label: 'Metrics',
    linkExt: '/metrics',
  },
  usersTab,
];

const mriTabs = [
  overviewTab,
  {
    label: 'QC',
    linkExt: '/qa',
  },
  {
    label: 'Visual',
    linkExt: '/visual',
  },
  {
    label: 'Helium',
    linkExt: '/helium',
  },
  {
    label: 'Metrics',
    linkExt: '/metrics',
  },
  usersTab,
];

const mamTabs = [
  overviewTab,
  {
    label: 'Weekly',
    linkExt: '/weekly',
  },
  {
    label: 'Monthly',
    linkExt: '/checklist',
  },
  {
    label: 'Quarterly',
    linkExt: '/reject',
  },
  {
    label: 'Comp Force',
    linkExt: '/compression',
  },
  {
    label: 'Geo Calib',
    linkExt: '/geocalib',
  },
  usersTab,
];

const rwsTabs = [
  overviewTab,
  {
    label: 'Daily',
    linkExt: '/rwsdaily',
    requirement: {
      field: 'dailySectra',
      value: true,
    },
  },
  {
    label: 'Weekly',
    linkExt: '/rwsweekly',
  },
  usersTab,
];

const tabLists = {
  ct: ctTabs,
  mri: mriTabs,
  mam: mamTabs,
  rws: rwsTabs,
};

const getTabList = (device) => {
  const tabs = [];
  tabLists[device.type].forEach((tab) => {
    if (tab.requirement && device[tab.requirement.field] !== tab.requirement.value) {
      return;
    }
    tabs.push(tab);
  });

  return tabs;
};

export default getTabList;
