import React from 'react';
import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  footer: {
    backgroundColor: `${theme.palette.primary.light}7a`,
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
  },
});

function MamRejectTableFooter({ classes, children, ...props }) {
  return (
    <TableCell className={classes.footer} size="small" variant="footer" {...props}>
      {children}
    </TableCell>
  );
}

export default withStyles(styles)(MamRejectTableFooter);
