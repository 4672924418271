import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { getDefaultDates, isByMonthEntry } from 'utils/entry.util';
import ModeSelection from './components/ModeSelection';
import DateSelection from './components/DateSelection';
import HeDashboard from './he/HeDashboard';
import MamWeeklyDashboard from './mam/weekly/MamWeeklyDashboard';
import MamChecklistDashboard from './mam/monthly/MamChecklistDashboard';
import MamRejectDashboard from './mam/quarterly/MamRejectDashboard';
import ForceCompDashboard from './mam/forceComp/ForceCompDashboard';
import GeoCalibDashboard from './mam/geoCalib/GeoCalibDashboard';
import RwsDailyDashboard from './rws/RwsDailyDashboard';
import RwsWeeklyDashboard from './rws/RwsWeeklyDashboard';
import QcDashboard from './qc/QcDashboard';
import QaDashboard from './qa/QaDashboard';
import VcDashboard from './vc/VcDashboard';

const styles = {
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 520px)': {
      justifyContent: 'center',
    },
  },
  top: {
    alignItems: 'center',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      margin: 0,
    },
  },
};

function EntryDashboard(props) {
  const { entryType, classes } = props;

  // TODO: Save/load data on users previous selections for mode and date
  let initialMode = 'enter';

  if (window.location.pathname.includes(['/view'])) {
    initialMode = 'view';
  }

  if (window.location.pathname.includes('weekly')) {
    const parts = window.location.pathname.split('weekly/');
    if (parts.length > 1) {
      initialMode = 'view';
    }
  }

  const [mode, setMode] = useState(initialMode);
  const [date, setDate] = useState(getDefaultDates(entryType, initialMode));
  let MainDashboard = null;

  useEffect(() => {
    setDate(getDefaultDates(entryType, mode));
  }, [entryType, mode]);

  switch (entryType) {
    case 'he':
      MainDashboard = HeDashboard;
      break;
    case 'mam_wk':
      MainDashboard = MamWeeklyDashboard;
      break;
    case 'mam_mth':
      MainDashboard = MamChecklistDashboard;
      break;
    case 'mam_qrt':
      MainDashboard = MamRejectDashboard;
      break;
    case 'mam_fc':
      MainDashboard = ForceCompDashboard;
      break;
    case 'mam_gc':
      MainDashboard = GeoCalibDashboard;
      break;
    case 'rws_daily':
      MainDashboard = RwsDailyDashboard;
      break;
    case 'rws_weekly':
      MainDashboard = RwsWeeklyDashboard;
      break;
    case 'qa':
      MainDashboard = QaDashboard;
      break;
    case 'qc':
      MainDashboard = QcDashboard;
      break;
    case 'vc':
      MainDashboard = VcDashboard;
      break;
    default:
      throw new Error(`Unrecognized entry type: ${entryType}`);
  }

  const handleModeChange = (newMode) => {
    if (newMode === 'view' && date.start.getUTCDate() === date.end.getUTCDate()) {
      const newStart = new Date();
      newStart.setMonth(newStart.getMonth() - 3);
      setDate({ start: newStart, end: date.end });
    } else if (newMode !== 'view' && (isByMonthEntry(entryType) || entryType.includes('mam_wk'))) {
      const newStart = new Date();
      setDate({ start: newStart, end: date.end });
    }
    setMode(newMode);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <Box sx={{ margin: '16px' }}>
      <Box className={classes.top}>
        <Box className={classes.buttonSection}>
          <ModeSelection mode={mode} modeChange={handleModeChange} />
        </Box>
        <DateSelection
          key={date.start.toISOString() + date.end.toISOString()}
          mode={mode}
          date={date}
          entryType={entryType}
          dateChange={handleDateChange}
        />
      </Box>
      <MainDashboard mode={mode} date={date} />
    </Box>
  );
}

EntryDashboard.propTypes = {
  entryType: PropTypes.string.isRequired,
};

export default withStyles(styles)(EntryDashboard);
