import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Button, FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { RadTextInput } from 'presentational/Inputs';
import { useModalCtx } from 'context/ModalContext';
import { useDeviceCtx } from 'context/DeviceContext';
import radqcApi from 'dataExchange/radqcApiClient';

const styles = {
  button: {
    fontSize: '0.8rem',
    width: '150px',
  },
  message: {
    marginBottom: 25,
    fontWeight: 200,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '20px 0px',
  },
  removeButton: {
    textTransform: 'capitalize',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function PhantomCNREditModal(props) {
  const { message, actionText, classes, match, editType, label } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { hideModal } = useModalCtx();
  const { devices, updateDevices } = useDeviceCtx();
  const device = devices.find((a) => match?.url.includes(a._id));

  return (
    <Formik
      initialValues={device}
      validationSchema={Yup.object({
        [editType]: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = {
          ...device,
          [editType]: values[editType],
        };

        const accessToken = await getAccessTokenSilently();
        await radqcApi('put', `/devices/${device._id}`, payload, { accessToken })
          .then(() => {
            updateDevices();
            setSubmitting(false);
            hideModal();
          })
          .catch((err) => {
            console.log(err);
            hideModal();
          });
      }}
    >
      {() => (
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <h3 className={classes.message}>{message}</h3>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <RadTextInput label={label} name={editType} type="text" />
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.buttonContainer}>
              <Button className={classes.button} onClick={hideModal}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={6} className={classes.buttonContainer}>
              <FormControl className={classes.buttons}>
                <Button
                  type="submit"
                  className={clsx(classes.button, classes.removeButton)}
                  color="primary"
                  variant="outlined"
                >
                  {actionText}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

PhantomCNREditModal.propTypes = {
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
};

PhantomCNREditModal.defaultProps = {
  actionText: 'OK',
};

export default withStyles(styles)(PhantomCNREditModal);
