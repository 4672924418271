import React, { useState } from 'react';
import { mapValues, pick, uniqBy } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import { useModalCtx } from 'context/ModalContext';
import { artifactEvalFields, kvpValidation, masValidation } from 'views/entry/mam/components/mamEntry.util';
import ArtifactEvalEntryForm from './ArtifactEvalEntryForm';
import ArtifactEvalEntryDisplay from './ArtifactEvalEntryDisplay';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import { EntryModes } from 'types/entry';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import { RadTextInput } from 'presentational/Inputs';
import TaskNav from 'views/entry/mam/weekly/modify/components/TaskNav';

const useStyles = makeStyles({
  tableTitles: {
    color: 'black',
    padding: '0',
    textAlign: 'center',
    width: '30px',
    height: '50px',
  },
  remarks: {
    width: '100%',
    overflow: 'auto',
    border: '1px solid #80808026',
  },
  tableContainer: {
    display: 'flex',
    margin: '0 2rem',
    '@media (max-width: 1024px)': {
      margin: '0',
    },
  },
  stickyHeader: {
    width: '9rem',
  },
  rightTables: {
    maxWidth: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  remarkSection: {
    margin: '2rem 2rem 0 2rem',
  },
  remarkTitle: {
    marginBottom: '1rem',
  },
});

const taskName = 'Artifact Evaluation';
const fieldTitles = {
  [MamEntryFields.rhArtifacts]: 'Rh Artifacts',
  [MamEntryFields.agArtifacts]: 'Ag Artifacts',
  [MamEntryFields.alArtifacts]: 'Al Artifacts',
};

function CheckDashboard(props) {
  const { activeEntry, mode, saveEntries, mamEntries, taskId, formDisabled } = props;
  const [entryKey, setEntryKey] = useState(0);
  const classes = useStyles();
  const { showModal } = useModalCtx();

  const initialValues = { entries: {} };
  let enterModeColumns = null;
  if (mode === EntryModes.ENTER) {
    initialValues.entries = { new: pick(activeEntry, [...artifactEvalFields, 'artEvalRemarks']) };
    enterModeColumns = (
      <>
        {mamEntries.leading.map((entry) => (
          <ArtifactEvalEntryDisplay key={entry._id} entryData={entry} />
        ))}
        <ArtifactEvalEntryForm entryData={activeEntry} formDisabled={formDisabled} />
        {mamEntries.trailing.map((entry) => (
          <ArtifactEvalEntryDisplay key={entry._id} entryData={entry} />
        ))}
      </>
    );
  }

  const boolValidation = Yup.bool().required('Required');

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    if (mode === EntryModes.ENTER) {
      newEntries[0].date = activeEntry.date;
      newEntries[0].initials = activeEntry.initials;
    }
    showModal(EntryConfirmModal, {
      entryType: 'mam',
      confirmTitle: 'Mammograph Artifact Eval',
      confirmEntries: newEntries,
      accept: () => submitForm(),
      fieldTitles,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                rhKvp: kvpValidation,
                agKvp: kvpValidation,
                alKvp: kvpValidation,
                rhMas: masValidation,
                agMas: masValidation,
                alMas: masValidation,
                rhArtifacts: boolValidation,
                agArtifacts: boolValidation,
                alArtifacts: boolValidation,
                artEvalRemarks: Yup.string(),
                outFields: Yup.array(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (mode === EntryModes.ENTER) {
          newEntries[0].artEvalPerformed = true;
        }
        saveEntries(newEntries);
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
      }}
    >
      {({ values, submitForm }) => {
        let allEntries = [];
        if (mode === 'edit') {
          allEntries = [
            ...mamEntries.leading,
            ...Object.values(values.entries),
            ...mamEntries.trailing,
            /* eslint-disable-next-line no-nested-ternary */
          ].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
          allEntries = uniqBy(allEntries, '_id');
        }
        return (
          <Form>
            <Box className={classes.tableContainer}>
              <Table stickyHeader className={classes.stickyHeader}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableTitles}>
                      Date
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableTitles}>
                      Initials
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={3} className={classes.tableTitles}>
                      Rh
                    </TableCell>
                    <TableCell className={classes.tableTitles}>kVp</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>mAs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Artifacts</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={3} className={classes.tableTitles}>
                      Ag
                    </TableCell>
                    <TableCell className={classes.tableTitles}>kVp</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>mAs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Artifacts</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={3} className={classes.tableTitles}>
                      Al
                    </TableCell>
                    <TableCell className={classes.tableTitles}>kVp</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>mAs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableTitles}>Artifacts</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableTitles}>
                      Remarks
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableTitles} />
                  </TableRow>
                </TableHead>
              </Table>

              <Box className={classes.rightTables}>
                <Table>
                  <TableBody>
                    <TableRow>
                      {enterModeColumns}
                      {allEntries.map((entry) => (
                        <ToggleableEntry
                          key={entryKey + entry.date}
                          entryData={entry}
                          mode={mode}
                          entryDisplay={ArtifactEvalEntryDisplay}
                          entryForm={ArtifactEvalEntryForm}
                        />
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>

            {mode === EntryModes.ENTER && (
              <Box className={classes.remarkSection}>
                <Typography className={classes.remarkTitle} fontSize={16}>
                  Remarks
                </Typography>
                <RadTextInput
                  multiline
                  disabled={formDisabled}
                  name="entries.new.artEvalRemarks"
                  type="text"
                  classes={{ root: classes.remarks }}
                />
              </Box>
            )}

            <TaskNav
              taskName={taskName}
              taskId={taskId}
              onComplete={() => handleSubmit(values, submitForm)}
              mode={mode}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

CheckDashboard.propTypes = {
  activeEntry: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string,
  saveEntries: PropTypes.func.isRequired,
  mamEntries: PropTypes.objectOf(PropTypes.any),
  taskId: PropTypes.number.isRequired,
  formDisabled: PropTypes.bool,
};

CheckDashboard.defaultProps = {
  mode: EntryModes.ENTER,
  mamEntries: { leading: [], trailing: [] },
  formDisabled: false,
};

export default CheckDashboard;
