import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import RecordTable from './components/RecordTable';
import HePdfPreview from 'views/entry/he/HePdfPreview';
import QcPdfPreview from 'views/entry/qc/QcPdfPreview';
import QaPdfPreview from 'views/entry/qa/QaPdfPreview';
import VcPdfPreview from 'views/entry/vc/VcPdfPreview';
import RwsPdfPreview from 'views/entry/rws/RwsPdfPreview';
import RemarksPdfPreview from 'views/entry/mam/previews/RemarksPdfPreview';
import ArtifactEvalPdfPreview from 'views/entry/mam/previews/ArtifactEvalPdfPreview';
import SnrCnrPdfPreview from 'views/entry/mam/previews/SnrCnrPdfPreview';
import PhantomPdfPreview from 'views/entry/mam/previews/PhantomPdfPreview';
import CompressionPdfPreview from 'views/entry/mam/previews/CompressionPdfPreview';
import ForceCompPdfPreview from 'views/entry/mam/previews/ForceCompPdfPreview';
import MamMonthlyPdfPreview from 'views/entry/mam/previews/MamMonthlyPdfPreview';
import MamQuarterlyPdfPreview from 'views/entry/mam/previews/MamQuarterlyPdfPreview';
import PlaceholderPdfPreview from 'views/entry/components/PlaceholderPdfPreview';
import RecordCharts from './components/RecordCharts';
import PhantomPdfCharts from 'views/entry/mam/previews/PhantomPdfCharts';
import SnrCnrPdfCharts from 'views/entry/mam/previews/SnrCnrPdfCharts';
import CompressionPdfCharts from 'views/entry/mam/previews/CompressionPdfCharts';

const styles = {
  root: {
    display: 'flex',
    '@media (max-width: 1145px)': {
      flexDirection: 'column',
    },
  },
  pdf: {
    width: '100%',
    '@media (max-width: 1145px)': {
      height: '16rem',
    },
  },
  chartsForPdf: {
    position: 'absolute',
    left: '-5000px',
    top: '-5000px',
  },
};

function RecordViewer(props) {
  const { match, classes, records, recordType } = props;
  const [selectedRecords, setSelectedRecords] = useState([]);
  const { devices } = useDeviceCtx();
  const device = devices.find((a) => match.url.includes(a._id));
  const [chartImages, setChartImages] = useState(null);

  let PdfPreview = null;
  let PdfCharts = null;
  let addProps = null;
  let numberOfCharts = 0;
  switch (recordType) {
    case 'he':
      PdfPreview = HePdfPreview;
      break;
    case 'qa':
      PdfPreview = QaPdfPreview;
      break;
    case 'qc':
      PdfPreview = QcPdfPreview;
      break;
    case 'vc':
      PdfPreview = VcPdfPreview;
      break;
    case 'rws':
      PdfPreview = RwsPdfPreview;
      break;
    case 'MAM Checklist':
      PdfPreview = MamMonthlyPdfPreview;
      break;
    case 'MAM Reject Analysis':
      PdfPreview = MamQuarterlyPdfPreview;
      break;
    case 'Force Compression':
      PdfPreview = ForceCompPdfPreview;
      break;
    case 'Geo Calibration':
    case 'View Box':
    case 'Flat Field':
      PdfPreview = RemarksPdfPreview;
      addProps = { recordType };
      break;
    case 'Artifact Eval':
      PdfPreview = ArtifactEvalPdfPreview;
      break;
    case 'SNR CNR':
      PdfPreview = SnrCnrPdfPreview;
      PdfCharts = SnrCnrPdfCharts;
      addProps = { chartImages };
      numberOfCharts = 2;
      break;
    case 'Phantom 2D':
      PdfPreview = PhantomPdfPreview;
      PdfCharts = PhantomPdfCharts;
      addProps = { dim: 2, chartImages };
      numberOfCharts = 3;
      break;
    case 'Phantom 3D':
      PdfPreview = PhantomPdfPreview;
      PdfCharts = PhantomPdfCharts;
      addProps = { dim: 3, chartImages };
      numberOfCharts = 3;
      break;
    case 'Compression':
      PdfPreview = CompressionPdfPreview;
      PdfCharts = CompressionPdfCharts;
      addProps = { chartImages };
      numberOfCharts = 1;
      break;
    default:
      throw new Error(`Unrecognized record type: ${recordType}`);
  }

  const pdfPreviewDisplay =
    selectedRecords.length > 0 ? (
      <PdfPreview records={selectedRecords} device={device} addProps={addProps} />
    ) : (
      <PlaceholderPdfPreview />
    );

  const handleChangeSelRecords = (newSelection) => {
    setSelectedRecords([]);
    setTimeout(() => setSelectedRecords(newSelection), 1);
  };

  return (
    <div className={classes.root}>
      <RecordTable
        recordType={recordType}
        records={records}
        selectedRecords={selectedRecords}
        updateSelectedRecords={handleChangeSelRecords}
        pdfPreview={pdfPreviewDisplay}
      />
      <Box className={classes.pdf}>
        <PDFViewer key={selectedRecords.reduce((acc, val) => acc + val._id, '')} width="100%" height="100%">
          {pdfPreviewDisplay}
        </PDFViewer>
      </Box>

      <div className={classes.chartsForPdf}>
        <RecordCharts
          numberOfCharts={numberOfCharts}
          setChartImages={setChartImages}
          records={selectedRecords}
          PdfCharts={PdfCharts}
          recordType={recordType}
        />
      </div>
    </div>
  );
}

RecordViewer.propTypes = {
  recordType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any),
};

RecordViewer.defaultProps = {
  records: [],
};

export default withRouter(withStyles(styles)(RecordViewer));
