import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import commonPreviewStyles from './commonStyles';
import { formatDateShort, getCurrentYear } from 'utils/time.util';

// Create styles
const styles = StyleSheet.create({
  ...commonPreviewStyles,
  formTitle: {
    ...commonPreviewStyles.formTitle,
    paddingTop: 40,
  },
  initialsDate: {
    fontSize: 8.5,
    textAlign: 'center',
    padding: 0,
    height: '13.85px',
    paddingTop: 2,
    width: '6%',
    borderRight: 1,
  },
  compressions: {
    fontSize: 8.5,
    textAlign: 'center',
    padding: 0,
    height: '13.85px',
    paddingTop: 2,
    width: '10%',
    borderRight: 1,
    textOverflow: 'wrap',
  },
});

function ForceCompPdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records &&
        records.map((record) => (
          <Page key={record.entries[0]._id} style={styles.body} orientation="landscape">
            <View fixed style={styles.footer}>
              <Text
                style={styles.header}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              <Text fixed style={styles.header}>
                Provided By Radiology QC Solutions
              </Text>
            </View>
            <Text style={styles.formTitle}>Compression Force</Text>

            <View key={record.entries[0]._id} style={styles.scannerInfoView}>
              <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
              <Text style={styles.scannerInfo}>Scanner Name: {`${device.name} (${device.apNumber})`}</Text>
            </View>

            <View style={styles.remarksHeaderView}>
              <Text style={styles.initialsDate}>Initials</Text>
              <Text style={styles.initialsDate}>Date</Text>
              <Text style={styles.compressions}>Auto Comp.(lbs)</Text>
              <Text style={styles.compressions}>Manual Comp.(lbs)</Text>
              <Text style={styles.remarks}>Remarks</Text>
            </View>

            {record.entries.map((entry) => (
              <View key={entry._id} style={styles.remarksView}>
                <Text style={styles.initialsDate}>{entry.initials}</Text>
                <Text style={styles.initialsDate}>{formatDateShort(entry.date)}</Text>
                <Text style={styles.compressions}>{entry.autoCompression}</Text>
                <Text style={styles.compressions}>{entry.manualCompression}</Text>
                <Text style={styles.remarks}>{entry.remarks}</Text>
              </View>
            ))}

            <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
              <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
            </View>
          </Page>
        ))}
    </Document>
  );
}

ForceCompPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

ForceCompPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default ForceCompPdfPreview;
