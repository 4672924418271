import React, { useState } from 'react';
import { Grid, Input, Button, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  inputRoot: {
    width: '100%',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  title: {
    textTransform: 'capitalize',
  },
  cancelText: {
    marginLeft: '1rem',
  },
};

function EntryComment(props) {
  const { savedComment, hideModal, updateComment, classes, title } = props;
  const [comment, setComment] = useState(savedComment);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  let commentUi = savedComment;
  let buttons = <Button onClick={hideModal}>Back</Button>;
  if (updateComment) {
    commentUi = (
      <Input
        classes={{ root: classes.inputRoot }}
        margin="dense"
        type="string"
        value={comment}
        autoFocus
        onChange={handleChange}
      />
    );
    buttons = (
      <>
        <Box>
          <Button onClick={hideModal}>Cancel</Button>
        </Box>
        <Box>
          <Button classes={{ text: classes.cancelText }} color="primary" onClick={() => updateComment(comment)}>
            Accept
          </Button>
        </Box>
      </>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.title}>
        {title}
      </Grid>
      <Grid item xs={12}>
        {commentUi}
      </Grid>
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.buttonContainer}>
          {buttons}
        </Grid>
      </Grid>
    </Grid>
  );
}

EntryComment.propTypes = {
  savedComment: PropTypes.string,
  hideModal: PropTypes.func,
  updateComment: PropTypes.func,
  title: PropTypes.string,
};

EntryComment.defaultProps = {
  savedComment: '',
  hideModal: null,
  updateComment: null,
  title: 'Comments',
};

export default withStyles(styles)(EntryComment);
