import React from 'react';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import { TableRow, TableCell, FormControl, Input, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { MamEntryFields, isValueOut } from 'views/entry/mam/components/mamEntryOutValues';
import EntryComment from 'views/entry/components/EntryComment';
import { useModalCtx } from 'context/ModalContext';
import { formatMonthDate } from 'utils/time.util';
import { RadTextInput } from 'presentational/Inputs';

const styles = (theme) => ({
  formControl: {
    margin: 0,
    textAlign: 'center',
  },
  radTextInput: {
    '&$error': {
      color: 'blue',
    },
    textAlign: 'center',
  },
  outValue: {
    '&$error': {
      color: theme.palette.primary.red,
    },
    '& fieldset': {
      borderColor: theme.palette.primary.red,
      color: theme.palette.primary.red,
    },
    color: theme.palette.primary.red,
    borderRadius: '5px',
    textAlign: 'center',
  },
  error: {},
  icons: {
    fontSize: 16,
  },
  deleteIcon: {
    color: theme.palette.primary.red,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  inputProps: {
    padding: '0.2rem 0',
    fontSize: '0.725rem',
    width: '100%',
  },
});

function ForceCompForm(props) {
  const { entryData, classes, click } = props;
  const { values, setFieldValue } = useFormikContext();
  const { showModal, hideModal } = useModalCtx();

  const entryIndex = entryData._id || 'new';

  const handleUpdateRemarks = (newRemarks) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex].remarks = newRemarks;
    setFieldValue('entries', updatedEntries);
    hideModal();
  };

  const handleChange = (e) => {
    const field = e.target.name.substring(e.target.name.lastIndexOf('.') + 1);
    const entry = values.entries[entryIndex];
    entry[field] = parseFloat(e.target.value);

    if (field === MamEntryFields.compForceAuto && isValueOut(field, entry[field])) {
      if (!entry.outFields.includes(field)) {
        entry.outFields.push(field);
      }
    } else {
      entry.outFields = entry.outFields.filter((currfield) => currfield !== field);
    }
    setFieldValue(`entries.${entryIndex}`, entry);
  };

  const getStyleForField = (field, defaultClass) =>
    values.entries[entryIndex] && values.entries[entryIndex].outFields.includes(field)
      ? clsx(defaultClass, classes.outValue)
      : defaultClass;

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableRow>
      <TableCell size="small">
        {formatMonthDate(entryData.date)}
        <Input type="hidden" name={`entries.${entryIndex}.date`} value={entryData.date} />
      </TableCell>
      <TableCell size="small">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.${MamEntryFields.compForceAuto}`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: {
                inputMarginDense: getStyleForField(MamEntryFields.compForceAuto, classes.inputProps),
              },
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell size="small">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.${MamEntryFields.compForceManual}`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: {
                inputMarginDense: getStyleForField(MamEntryFields.compForceManual, classes.inputProps),
              },
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell size="small">{entryData.initials}</TableCell>
      <TableCell size="small">
        <IconButton
          onClick={() =>
            showModal(EntryComment, {
              savedComment: values.entries[entryIndex].remarks,
              hideModal,
              updateComment: handleUpdateRemarks,
              title: 'Remarks',
            })
          }
        >
          <CommentIcon classes={{ root: classes.icons }} />
        </IconButton>
      </TableCell>
      <TableCell size="small">{cancelButton}</TableCell>
    </TableRow>
  );
}

ForceCompForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

ForceCompForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(ForceCompForm);
