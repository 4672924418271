/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from 'views/components/TablePaginationActions';
import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from '@tanstack/react-table';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Checkbox, Typography } from '@mui/material';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { compareRole, isAdmin } from 'utils/user.util';

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])}
        placeholder="Max"
        className="w-24 border shadow rounded"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  ) : (
    <InputBase
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      sx={{ fontSize: 12, border: '1px solid #f1f0f0', borderRadius: '4px', paddingLeft: '2px ' }}
      className="w-36 border shadow rounded"
      inputProps={{ 'aria-label': 'search' }}
    />
  );
}

function DevicesUserTable({
  data,
  columns,
  savedUserIds,
}: {
  data: any[];
  columns: ColumnDef<any>[];
  savedUserIds: String[];
}) {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'checked',
      desc: true,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
  });
  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <Box component="div" width="100%">
      <TableContainer component={Box} sx={{ marginTop: '16px' }}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header) => {
                  // The following prevents the searchbar for the checked column
                  if (header.column.columnDef.header === '') {
                    return <div key={header.id} />;
                  }
                  return (
                    <TableCell
                      size="small"
                      padding="none"
                      sx={{ fontSize: 14, fontWeight: 600 }}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ArrowDropUpIcon sx={{ fontSize: '16px' }} />,
                            desc: <ArrowDropDownIcon sx={{ fontSize: '16px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <Box component="div" marginY="4px">
                          <Filter column={header.column} table={table} />
                        </Box>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel()?.rows?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: savedUserIds.includes(row?.original._id) ? '#e4e4ff' : '#FAFAFA',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  // onClick={() => history.push(`/users/${row?.original._id}`)}
                >
                  {row.getVisibleCells()?.map((cell) => {
                    return (
                      <TableCell padding="none" size="small" sx={{ fontSize: 14 }} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={(_, page) => {
            table.setPageIndex(page);
          }}
          onRowsPerPageChange={(e) => {
            const size = e.target.value ? Number(e.target.value) : 10;
            table.setPageSize(size);
          }}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </Box>
  );
}

function DevicesUsersContainer({
  deviceUsers,
  users,
  updateDeviceUsers,
}: {
  deviceUsers: any;
  users: any;
  updateDeviceUsers: any;
}) {
  const savedUserIds = useMemo(() => deviceUsers.map((item: any) => item._id), [deviceUsers]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const { currUser } = useCurrUserCtx();

  useEffect(() => setSelectedUsers(savedUserIds), [savedUserIds]);

  const handleSave = () => updateDeviceUsers(selectedUsers);

  const handleCheckboxToggle = (itemId: string) => {
    setSelectedUsers((prevSelectedDevices) => {
      let newSelectedIds = [...prevSelectedDevices];

      if (prevSelectedDevices?.includes(itemId)) {
        newSelectedIds = newSelectedIds.filter((id) => id !== itemId);
      } else {
        newSelectedIds.push(itemId);
      }

      return newSelectedIds;
    });
  };

  const transformedTableData = useMemo(() => {
    const isUserDisabled = (user: any) => {
      return !isAdmin(currUser) && compareRole(currUser.role, user.role) <= 0;
    };

    return users.map((user: any) => {
      const isUserDeviceSelected = selectedUsers?.some((item) => item === user._id);
      return {
        ...user,
        checked: isUserDeviceSelected,
        disabled: isUserDisabled(user),
      };
    });
  }, [users, selectedUsers, currUser.role]);

  const [data, setData] = useState(transformedTableData);
  useEffect(() => setData(transformedTableData), [transformedTableData]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'checked',
        id: 'checked',
        header: '',
        cell: (info) => {
          const row = info.row.original;
          const selected = row.checked;
          return (
            <Checkbox
              sx={{ fontSize: '2px' }}
              checked={Boolean(selected)}
              size="small"
              onClick={() => {
                setIsDirty(true);
                handleCheckboxToggle(row._id);
              }}
              disabled={row?.disabled}
            />
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'firstName',
        id: 'firstName',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>First Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'lastName',
        id: 'lastName',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>Last Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'role',
        id: 'role',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>Role</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'degree',
        id: 'degree',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>Degree</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'certification',
        id: 'ceritfication',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>Certification</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'email',
        id: 'email',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'lowercase', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>email</span>,
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  return (
    <Box component="div" sx={{ width: '100%' }}>
      <DevicesUserTable {...{ data, columns, savedUserIds }} />
      <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
        <Button
          sx={{ textTransform: 'capitalize', marginRight: '16px' }}
          disabled={!isDirty}
          color="secondary"
          onClick={() => {
            setSelectedUsers(savedUserIds);
            setIsDirty(false);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: 'capitalize' }}
          disabled={!isDirty}
          variant="outlined"
          color="primary"
          onClick={handleSave}
        >
          Update Device Users
        </Button>
      </Box>
    </Box>
  );
}

export default DevicesUsersContainer;
