import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import RwsWeeklyOverviewTable from './RwsWeeklyOverviewTable';
import RwsDailyOverviewTable from './RwsDailyOverviewTable';

const useStyles = makeStyles((theme) => ({
  overviewContainer: {
    transition: '0.5s ease-in-out',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  typography: {
    color: theme.palette.primary.darkMain,
    fontWeight: 600,
    textAlign: 'left',
    fontSize: 14,
  },
  tablesContainer: {
    overflowX: 'auto',
    flexDirection: 'column',
    display: 'flex',
    '@media (max-width: 649px)': {
      maxHeight: 'initial',
    },
  },
  tableBoxes: {
    margin: '1rem 0',
  },
}));

function RwsEntryOverview(props) {
  const { device } = props;
  const classes = useStyles();

  let dailyTable = null;
  if (device.dailySectra) {
    dailyTable = (
      <Box className={classes.tablesContainer}>
        <Typography className={classes.typography}>Daily</Typography>
        <Box className={classes.tableBoxes}>
          <RwsDailyOverviewTable device={device} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.overviewContainer}>
      <Box className={classes.tablesContainer}>
        <Typography className={classes.typography}>Weekly</Typography>
        <Box className={classes.tableBoxes}>
          <RwsWeeklyOverviewTable device={device} />
        </Box>
      </Box>
      {dailyTable}
    </Box>
  );
}

RwsEntryOverview.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RwsEntryOverview;
