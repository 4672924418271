import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import { useDeviceCtx } from 'context/DeviceContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import { deviceTypes } from 'types/device';
import { getDevicesForType } from 'utils/device.util';
import DevicesTable from './DevicesTable';
import PieChart from './PieChart';
import { isAdmin } from 'utils/user.util';
import ModifyDevice from 'components/Devices/ModifyDevice';

function DevicesOverview() {
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { devices } = useDeviceCtx();
  const hasDevices = devices.length > 0;

  return (
    <>
      <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Metrics</Typography>
      <Typography sx={{ fontSize: 14, color: '#A2A2A2', marginBottom: '16px' }}>
        Let's check your devices' overall health.
      </Typography>
      <Box
        component="div"
        display="flex"
        width="100%"
        sx={{
          overflowX: 'auto',
        }}
      >
        {Object.values(deviceTypes).map((type, index) => {
          const typeDevices = getDevicesForType(devices, type);
          return typeDevices.length > 0 ? (
            <Box component="div" key={index}>
              <PieChart type={type} devices={typeDevices} />
            </Box>
          ) : null;
        })}
      </Box>
      <>
        <Box component="div" display="flex" alignItems="center">
          <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Devices</Typography>
          <Tooltip title="Add New Device" placement="right">
            <IconButton
              disabled={!isAdmin(currUser)}
              sx={{ marginLeft: '8px' }}
              onClick={() => showModal(ModifyDevice)}
            >
              <AddToQueueOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {hasDevices ? (
          <DevicesTable />
        ) : (
          <>
            <BrowserNotSupportedIcon
              sx={{
                fontSize: '4rem',
                margin: '2rem',
              }}
            />
            <Typography height="50rem">You don't have any devices yet.</Typography>
          </>
        )}
      </>
    </>
  );
}

export default DevicesOverview;
