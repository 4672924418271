import React from 'react';
import { TableCell, Table, TableBody, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/time.util';
import { mamChecklistFields } from './mamChecklistTable.util';
import { convertNaPassFail } from 'utils/entry.util';

const styles = {
  noPadding: {
    padding: 0,
  },
  tableCellStyle: {
    padding: 0,
    textAlign: 'center',
    height: '32px',
    fontSize: '0.75rem',
  },
  table: {
    width: '90px',
  },
};

function MamChecklistDisplay(props) {
  const { entryData, click, classes } = props;

  return (
    <TableCell onClick={click} classes={{ root: classes.noPadding }}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>{entryData.initials}</TableCell>
          </TableRow>
          {mamChecklistFields.map((field) => (
            <TableRow key={field}>
              <TableCell className={classes.tableCellStyle}>{convertNaPassFail(entryData[field])}</TableCell>
            </TableRow>
          ))}
          {click && (
            <TableRow>
              <TableCell className={classes.tableCellStyle} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableCell>
  );
}

MamChecklistDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(MamChecklistDisplay);
