import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import * as Yup from 'yup';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import UndoIcon from '@mui/icons-material/Undo';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { generateDailyEntries } from 'utils/entry.util';
import RwsDailyToggleableEntry from './components/RwsDailyToggleableEntry';

const generateEmptyDailyEntry = (day) => ({
  day,
  empty: true,
});

const styles = (theme) => ({
  entriesList: {
    height: '340px',
  },
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: {
    height: '100%',
  },
  dailyTableContainer: {
    width: '98%',
    height: '95%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  tableTitles: {
    color: 'black',
    fontSize: '0.8rem',
    padding: '0',
    textAlign: 'center',
    height: '32px',
    minWidth: '60px',
  },
  tableStyle: {
    marginLeft: '20px',
  },
  widthFixed: {
    width: '161px',
  },
  bottomNavigation: {
    height: '50px',
    padding: '0 6px',
    display: 'flex',
    margin: '0 1.255rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: '#cbd6e32b',
    '@media screen and (max-width: 659px)': {
      height: '60px',
      margin: '0 .255rem',
    },
  },
  cancelIcon: {
    fontSize: '1.2rem',
    color: 'transparent',
  },
  root: {
    '@media (min-width: 451px) and (max-width: 659px)': {
      fontSize: '0.8rem',
      lineHeight: '0.85rem',
    },

    '@media (max-width: 450px)': {
      fontSize: '0.7rem',
      lineHeight: '0.85rem',
      padding: '0.25rem',
      textAlign: 'center',
    },
  },
  buttonText: {
    borderRadius: '0.25rem',
    backgroundColor: '#49526f2e',
    '@media (min-width: 451px) and (max-width: 659px)': {
      fontSize: '0.8rem',
      lineHeight: '0.85rem',
    },

    '@media (max-width: 450px)': {
      fontSize: '0.7rem',
      lineHeight: '0.85rem',
      padding: '0.25rem',
      textAlign: 'center',
    },
  },
});

function RwsDailyTable(props) {
  const { date, classes, rwsEntry, mode, updateRwsEntry } = props;
  const [dailyEntryKey, setDailyEntryKey] = useState(0);
  const { updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();

  const initialValues = { entries: {} };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                day: Yup.number().required('Required'),
                viewingCheckPerformed: Yup.bool().required('Required'),
                monitorCheckPerformed: Yup.bool().required('Required'),
                remarks: Yup.string(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
                enteredDate: Yup.string(),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        await updateRwsEntry('dailyEntries', newEntries);
        setDailyEntryKey(dailyEntryKey + 1);
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values }) => {
        const rwsDailyEntries = rwsEntry ? rwsEntry.dailyEntries : [];
        let dailyEntries = [...rwsDailyEntries, ...Object.values(values.entries)].sort((a, b) => a.day - b.day);
        dailyEntries = uniqBy(dailyEntries, 'day');
        const generatedEntries = generateDailyEntries(dailyEntries, date.start, generateEmptyDailyEntry);
        return (
          <Form className={classes.formStyle}>
            <div className={classes.dailyTableContainer}>
              <Table stickyHeader className={classes.tableStyle}>
                <TableHead>
                  <TableRow className={classes.headerTableRow}>
                    <TableCell className={classes.tableTitles}>Day</TableCell>
                    <TableCell className={classes.tableTitles}>View Box Checked</TableCell>
                    <TableCell className={classes.tableTitles}>Monitor Checked</TableCell>
                    <TableCell className={classes.tableTitles}>Remarks</TableCell>
                    <TableCell className={classes.tableTitles}>Initials</TableCell>
                    <TableCell className={classes.tableTitles}>
                      <UndoIcon className={classes.cancelIcon} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={dailyEntryKey}>
                  {generatedEntries.map((entry) => (
                    <RwsDailyToggleableEntry key={entry.day} entryData={entry} mode={mode} />
                  ))}
                </TableBody>
              </Table>
            </div>
            <Box className={classes.bottomNavigation}>
              <Box>
                <Button
                  type="submit"
                  disabled={!Object.keys(values.entries).length > 0}
                  classes={{ root: classes.buttonText }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

RwsDailyTable.propTypes = {
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  rwsEntry: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateRwsEntry: PropTypes.func.isRequired,
};

RwsDailyTable.defaultProps = {
  rwsEntry: null,
};

export default withRouter(withStyles(styles)(RwsDailyTable));
