import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import radqcApi from 'dataExchange/radqcApiClient';

const UserContext = createContext({
  users: [],
  updateUsers: () => {},
});

function UserCtxProvider(props) {
  const { children } = props;
  const [users, setUsers] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const updateUsers = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const userList = await radqcApi('get', '/users', { accessToken });
    setUsers(userList);
  }, [getAccessTokenSilently]);

  // Update with the list of users from the DB
  useEffect(() => {
    updateUsers();
  }, [updateUsers]);

  return <UserContext.Provider value={{ users, updateUsers }}>{children}</UserContext.Provider>;
}

const useUserCtx = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserCtx must be used within an UserCtxProvider');
  }
  return context;
};

export { UserCtxProvider, useUserCtx };
