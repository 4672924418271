import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import * as Yup from 'yup';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, Box, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import { getUserInitials } from 'utils/user.util';
import { MamEntryFields } from 'views/entry/mam/components/mamEntryOutValues';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import ToggleableEntry from 'views/entry/components/ToggleableEntry';
import radqcApi from 'dataExchange/radqcApiClient';
import ForceCompForm from './components/ForceCompForm';
import ForceCompDisplay from './components/ForceCompDisplay';

const styles = (theme) => ({
  headerTableRow: { backgroundColor: 'transparent' },
  tableContainer: {
    height: '480px',
    overflowY: 'auto',
    boxShadow: 'none',
  },
  bottomNavigation: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: `${theme.palette.primary.main}0a`,
  },
});

const generateEmptyForceCompEntry = (currUser, device) => ({
  _id: 'new',
  deviceId: device._id,
  date: new Date(),
  autoCompression: '',
  manualCompression: '',
  remarks: '',
  outFields: [],
  initials: getUserInitials(currUser),
  enteredById: currUser._id,
});

function ForceCompTable(props) {
  const { match, classes, forceCompEntries, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));
  const fieldTitles = {
    [MamEntryFields.compForceAuto]: 'Auto Compression',
    [MamEntryFields.compForceManual]: 'Manual Compression',
  };

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyForceCompEntry(currUser, device) };
    newEntryForm = <ForceCompForm entryData={initialValues.entries.new} mode={mode} />;
  }

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    showModal(EntryConfirmModal, {
      entryType: 'mam',
      confirmTitle: 'Mammograph Force Compression',
      confirmEntries: newEntries,
      accept: () => submitForm(),
      fieldTitles,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                deviceId: Yup.string().required('Required'),
                date: Yup.string().required('Required'),
                autoCompression: Yup.number()
                  .min(10.0, 'Must be greater than 10.0')
                  .max(60.0, 'Must be less than 60.0')
                  .required('Required'),
                manualCompression: Yup.number().min(0.0, 'Must be greater than 0.0').required('Required'),
                remarks: Yup.string(),
                outFields: Yup.array(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          delete newEntry._id;
          await radqcApi('post', '/entries/mam_cf', newEntry, { accessToken });
          enqueueSnackbar(`Added new Force Comp entry`, { variant: 'info' });
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await newEntries.forEach((entry) => radqcApi('put', `/entries/mam_cf/${entry._id}`, entry, { accessToken }));
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
        updateEntries();
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, submitForm }) => {
        let allForceCompEntries = forceCompEntries;
        if (mode === 'edit') {
          const savedForceCompEntries = forceCompEntries || [];
          allForceCompEntries = [...savedForceCompEntries, ...Object.values(values.entries)].sort(
            (a, b) => a.date - b.date,
          );
          allForceCompEntries = uniqBy(allForceCompEntries, '_id');
        }
        return (
          <Form>
            <Box component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="ForceCompTable">
                <TableHead>
                  <TableRow>
                    <TableCell size="small">Date</TableCell>
                    <TableCell size="small">Auto Compression (lbs)</TableCell>
                    <TableCell size="small">Manual Compression (lbs)</TableCell>
                    <TableCell size="small">Initials</TableCell>
                    <TableCell size="small">Remarks</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newEntryForm}
                  {allForceCompEntries.map((entry) => (
                    <ToggleableEntry
                      key={entryKey + entry.date}
                      entryData={entry}
                      mode={mode}
                      entryDisplay={ForceCompDisplay}
                      entryForm={ForceCompForm}
                      className={classes.entriesList}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Table className={classes.bottomNavigation}>
              <TableHead className={classes.bottomTableHead}>
                <TableRow className={classes.headerTableRow}>
                  <TableCell colSpan={2} sx={{ border: 'none' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={Object.keys(values.entries).length < 1}
                      onClick={() => handleSubmit(values, submitForm)}
                    >
                      Save Change
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

ForceCompTable.propTypes = {
  forceCompEntries: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

ForceCompTable.defaultProps = {
  forceCompEntries: [],
};

export default withRouter(withStyles(styles)(ForceCompTable));
