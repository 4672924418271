import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { formatDateShort, getCurrentYear } from 'utils/time.util';
import { convertNaPassFail } from 'utils/entry.util';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  helperTexts: {
    fontSize: 10,
    opacity: 0.5,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
  },
  dateHeader: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
    color: palette.primary.main,
  },
  initialFooter: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
    color: palette.primary.main,
  },
  splitHeaders: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'center',
    marginBottom: 0,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    borderColor: 'white',
  },
  entryContentView: {
    marginBottom: 24,
    marginTop: 16,
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    flexDirection: 'row',
  },
  subContainerLeft: {
    width: '47%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0,
    margin: 0,
  },
  subContainerRight: {
    width: '50px',
    margin: 0,
  },
  sectionalHeaders: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 1,
    textAlign: 'left',
    marginBottom: 0,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  leftBox: {
    margin: 'auto',
    flexDirection: 'row',

    maxWidth: '140px',
    width: '140px',
  },
  Box: {
    margin: 'auto',
    flexDirection: 'row',
    borderWidth: 0.5,
    borderColor: 'white',
    textAlign: 'left',
    maxWidth: '210px',
    width: '210px',
  },
  result: {
    fontSize: 9,
    textAlign: 'center',
    padding: 0,
    height: '13.85px',
    paddingTop: 2,
  },
  sections: {
    borderColor: '#669EE5',
    borderTopWidth: 0.5,
  },
  helperText: {
    marginLeft: 30,
  },
  reviewInitials: {
    fontSize: 10,
    textAlign: 'left',
    marginBottom: 0,
    marginLeft: '2',
  },
  copyRight: {
    fontSize: 10,
    color: palette.primary.main,
    opacity: 0.5,
    marginTop: '16px',
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function VisualEntriesHeader(props) {
  const { sectionalHeader, subHeaders } = props;

  const firstRow = (item) => (
    <View key={item} style={styles.splitHeaders}>
      <View style={styles.leftBox}>
        <Text style={styles.sectionalHeaders}>{sectionalHeader}</Text>
      </View>

      <View style={styles.Box}>
        <Text style={styles.scannerInfo}>{subHeaders[0]}</Text>
      </View>
    </View>
  );

  const genRow = (item) => (
    <View key={item} style={styles.splitHeaders}>
      <View style={styles.leftBox}>
        <Text style={styles.scannerInfo} />
      </View>

      <View style={styles.Box}>
        <Text style={styles.scannerInfo}>{item}</Text>
      </View>
    </View>
  );

  return (
    <View style={styles.sections}>
      {subHeaders.map((item) => {
        if (item === subHeaders[0]) return firstRow(item);
        return genRow(item);
      })}
    </View>
  );
}

function VcPdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records
        ? records.map((record) => (
            <Page key={record.entries[0]._id} style={styles.body} orientation="landscape">
              <View fixed style={styles.footer}>
                <Text
                  style={styles.header}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
                <Text fixed style={styles.header}>
                  Provided By Radiology QC Solutions
                </Text>
              </View>
              <Text style={styles.formTitle}>MRI Facility QC Visual Checlikst</Text>

              <View key={record.entries[0]._id} style={{ marginBottom: 16, marginTop: 32, marginLeft: 32 }}>
                <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
                <Text style={styles.scannerInfo}>Scanner: {`${device.name} (${device.apNumber})`}</Text>
              </View>

              <View style={styles.helperText}>
                <Text style={styles.helperTexts}>Pass = 'P', Fail = 'F', N/A = Not Applicable</Text>
              </View>

              <View style={styles.entryContentView}>
                <View style={styles.subContainerLeft}>
                  <Text style={styles.dateHeader}>Date</Text>
                  <VisualEntriesHeader
                    sectionalHeader="Patient Transport & Magnet"
                    subHeaders={[
                      'Bed Position and Other Lights',
                      'Alignment Lights',
                      'High Tension Cable/Others',
                      'Horizontal Smoothness of Motion and Stability',
                      'Vertical Smoothness of Motion and Stability',
                    ]}
                  />

                  <VisualEntriesHeader
                    sectionalHeader="Filming/Viewing"
                    subHeaders={['Laser Camera (Cables, Cassettes, Lights)', 'Light Boxes (Improper Function)']}
                  />

                  <VisualEntriesHeader
                    sectionalHeader="RF Integrity/Control Room"
                    subHeaders={[
                      'Door Contacts',
                      'Window Screen Integrity',
                      'Operator Console Switches/Lights/Meters',
                      'Patient Monitors',
                      'Patient Intercom',
                      'Room Temperature/Humidty',
                    ]}
                  />

                  <VisualEntriesHeader
                    sectionalHeader="Facility Safety"
                    subHeaders={[
                      'Emergency Cart',
                      'Safety Warning Signage',
                      'Door Indicator Switch (If Installed)',
                      'Cryogen Level Indicator',
                      'Oxygen Monitor',
                    ]}
                  />

                  <View style={{ borderColor: '#669EE5', borderTopWidth: 0.5 }}>
                    <Text style={styles.initialFooter}>Entered By (Initials)</Text>
                  </View>
                </View>

                {record.entries.map((entry) => (
                  <View key={entry._id} style={styles.subContainerRight}>
                    <Text style={styles.result}>{formatDateShort(entry.date)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.bedPosition)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.alignmentLight)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.tensionCable)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.horzStability)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.vertStability)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.laserCamera)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.lightBoxes)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.rfDoorContacts)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.rfScreenIntegrity)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.consoleSwitches)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.patientMonitors)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.patientIntercom)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.tempHumidity)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.emergencyCart)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.safetyWarning)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.doorIndicator)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.cryogenIndicator)}</Text>
                    <Text style={styles.result}> {convertNaPassFail(entry.oxygenMonitor)}</Text>
                    <Text style={styles.result}> {entry.initials}</Text>
                  </View>
                ))}
              </View>

              <View style={styles.helperText}>
                <Text style={styles.reviewInitials}>Reviewer's Initial:</Text>
              </View>

              <View style={{ marginLeft: 32, marginRight: 32, textAlign: 'center' }}>
                <Text style={styles.copyRight}>© {getCurrentYear()} Radiology QC Solutions LLC</Text>
              </View>
            </Page>
          ))
        : null}
    </Document>
  );
}

VcPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

VcPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default VcPdfPreview;
