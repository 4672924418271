import { getUserInitials } from 'utils/user.util';

export const visualChecklistFields = [
  'bedPosition',
  'alignmentLight',
  'tensionCable',
  'horzStability',
  'vertStability',
  'laserCamera',
  'lightBoxes',
  'rfDoorContacts',
  'rfScreenIntegrity',
  'consoleSwitches',
  'patientMonitors',
  'patientIntercom',
  'tempHumidity',
  'emergencyCart',
  'safetyWarning',
  'doorIndicator',
  'cryogenIndicator',
  'oxygenMonitor',
];

export const generateEmptyVcEntry = (currUser, scanner) => {
  const newEntry = {
    _id: 'new',
    deviceId: scanner._id,
    date: new Date(),
    outFields: [],
    initials: getUserInitials(currUser),
    enteredById: currUser._id,
  };
  visualChecklistFields.forEach((field) => {
    newEntry[field] = 'pass';
  });

  return newEntry;
};
