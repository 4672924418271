import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MamWeeklyOverviewTable from './MamWeeklyOverviewTable';
import MamLongTermOverviewTable from './longTerm/MamLongTermOverviewTable';

const useStyles = makeStyles((theme) => ({
  qcOverviewContainer: {
    transition: '0.5s ease-in-out',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  typoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  typography: {
    color: theme.palette.primary.darkMain,
    fontWeight: 600,
  },
  tablesContainer: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    '@media (max-width: 649px)': {
      maxHeight: 'initial',
    },
  },
}));

function MamEntryOverview(props) {
  const { device } = props;
  const classes = useStyles();

  return (
    <Box className={classes.qcOverviewContainer}>
      <Box className={classes.tablesContainer}>
        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Weekly</Typography>
        <MamWeeklyOverviewTable device={device} />
        <Typography sx={{ marginTop: '16px', fontSize: 16, fontWeight: 600 }}>Long-Term</Typography>
        <MamLongTermOverviewTable device={device} />
      </Box>
    </Box>
  );
}

MamEntryOverview.propTypes = {
  device: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MamEntryOverview;
