import React from 'react';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
});

function PlaceholderPdfPreview() {
  return (
    <Document>
      <Page style={styles.body} />
    </Document>
  );
}

export default PlaceholderPdfPreview;
