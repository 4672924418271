import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import RwsDailyEntryForm from './RwsDailyEntryForm';
import RwsDailyEntryDisplay from './RwsDailyEntryDisplay';
import { getUserInitials } from 'utils/user.util';

const initializeEntryData = (entryData, currUser) => {
  if (entryData.empty) {
    return {
      day: entryData.day,
      viewingCheckPerformed: false,
      monitorCheckPerformed: false,
      remarks: '',
      enteredById: currUser._id,
      initials: getUserInitials(currUser),
    };
  }
  return entryData;
};

function RwsDailyToggleableEntry(props) {
  const { entryData, mode } = props;
  const { currUser } = useCurrUserCtx();
  const { values, setFieldValue } = useFormikContext();
  const [isToggled, setToggled] = useState(false);

  let EntryComponent = RwsDailyEntryDisplay;

  const handleToggle = () => {
    const initEntryData = initializeEntryData(entryData, currUser);
    const updatedEntries = Object.assign(values.entries);
    if (!isToggled) {
      updatedEntries[initEntryData.day] = initEntryData;
    } else {
      delete updatedEntries[initEntryData.day];
    }
    setFieldValue('entries', updatedEntries);
    setToggled(!isToggled);
  };

  const canToggle = isToggled || mode === 'edit' || (mode === 'enter' && entryData.empty);
  const toggleHandler = canToggle ? handleToggle : null;

  if (isToggled) {
    EntryComponent = RwsDailyEntryForm;
  }

  return <EntryComponent entryData={entryData} click={toggleHandler} />;
}

RwsDailyToggleableEntry.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
};

export default withRouter(RwsDailyToggleableEntry);
