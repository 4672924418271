import React from 'react';
import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  tableCellRoot: {
    padding: 0,
    lineHeight: '1rem',
    fontSize: '0.8rem',
    textAlign: 'center',
    height: '40px',
    borderTop: '0.1px solid #0000004a',
    '@media (max-width: 540px)': {
      fontSize: '0.7rem',
      lineHeight: '0.75rem',
    },
  },
});

function TableHeader(props) {
  const { classes, children } = props;
  return (
    <TableCell {...props} classes={{ root: classes.tableCellRoot }}>
      {children}
    </TableCell>
  );
}

export default withStyles(styles)(TableHeader);
