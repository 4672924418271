/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from '@tanstack/react-table';
import { useSnackbar } from 'notistack';

import TablePaginationActions from 'views/components/TablePaginationActions';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useUserCtx } from 'context/UserContext';
import { Button, Checkbox, Dialog, Typography } from '@mui/material';
import { useDeviceCtx } from 'context/DeviceContext';
import radqcApi from 'dataExchange/radqcApiClient';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserName } from 'utils/user.util';

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <InputBase
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])}
        placeholder="Max"
        className="w-24 border shadow rounded"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  ) : (
    <InputBase
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      sx={{ fontSize: 12, border: '1px solid #f1f0f0', borderRadius: '4px', paddingLeft: '2px ' }}
      className="w-36 border shadow rounded"
      inputProps={{ 'aria-label': 'search' }}
    />
  );
}

function UserDevicesTable({ data, columns }: { data: any[]; columns: ColumnDef<any>[] }) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
  });
  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <Box component="div" width="100%">
      <TableContainer component={Box} sx={{ marginTop: '16px' }}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header) => {
                  return (
                    <TableCell
                      size="small"
                      padding="none"
                      sx={{ fontSize: 14, fontWeight: 600 }}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ArrowDropUpIcon sx={{ fontSize: '16px' }} />,
                            desc: <ArrowDropDownIcon sx={{ fontSize: '16px' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <Box component="div" marginY="4px">
                          <Filter column={header.column} table={table} />
                        </Box>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel()?.rows?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FAFAFA',
                      cursor: 'pointer',
                    },
                  }}
                  // onClick={() => history.push(`/users/${row?.original._id}`)}
                >
                  {row.getVisibleCells()?.map((cell) => {
                    return (
                      <TableCell padding="none" size="small" sx={{ fontSize: 14 }} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={(_, page) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
}

function ModifyUserDevicesModal({ user, openEditDevice, setOpenEditDevice, setOpen }: any) {
  const { updateUsers } = useUserCtx();
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { enqueueSnackbar } = useSnackbar();
  const userDevices = useMemo(
    () => devices.filter((device: any) => user.devices?.includes(device._id)),
    [devices, user.devices],
  );
  const userDeviceIds = useMemo(() => userDevices.map((item: any) => item._id), [userDevices]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  useEffect(() => setSelectedDevices(userDeviceIds), [userDeviceIds]);

  const updateUserDevices = async () => {
    try {
      const updateData = { devices: selectedDevices };
      // This confirmation should come after the put once loading indicator is added
      enqueueSnackbar(`Devices updated for ${getUserName(user)}`);
      const accessToken = await getAccessTokenSilently();
      await radqcApi('put', `/users/${user._id}/devices`, updateData, { accessToken });
      updateUsers();
      updateDevices();
    } catch (error) {
      console.error('Error updating user devices:', error);
      setOpen(true);
      setOpenEditDevice(false);
    }
  };

  const handleSave = () => {
    updateUserDevices();
    setSelectedDevices([]);
    setOpenEditDevice(false);
  };

  const handleCheckboxToggle = (itemId: string) => {
    setSelectedDevices((prevSelectedDevices) => {
      let newSelectedIds = [...prevSelectedDevices];

      if (prevSelectedDevices?.includes(itemId)) {
        newSelectedIds = newSelectedIds.filter((id) => id !== itemId);
      } else {
        newSelectedIds.push(itemId);
      }

      return newSelectedIds;
    });
  };

  const transformedTableData = useMemo(() => {
    return devices.map((device: any) => {
      const isUserDeviceSelected = selectedDevices?.some((item) => item === device._id);
      return {
        ...device,
        checked: isUserDeviceSelected,
      };
    });
  }, [devices, selectedDevices]);

  const [data, setData] = useState(transformedTableData);
  useEffect(() => setData(transformedTableData), [transformedTableData]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'checked',
        header: '',
        cell: (info) => {
          const row = info.row.original;
          const selected = row.checked;
          return (
            <Checkbox
              sx={{ fontSize: '2px' }}
              checked={Boolean(selected)}
              size="small"
              onClick={() => {
                handleCheckboxToggle(row._id);
              }}
            />
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'name',
        id: 'firstName',
        cell: (info: any) => (
          <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>{info.getValue()}</Typography>
        ),
        header: () => <span>Device Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'type',
        header: () => <span>Type</span>,
        cell: (info: any) => (
          <Typography fontSize={14} textTransform="uppercase">
            {info.getValue()}
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  return (
    <Dialog open={openEditDevice} onClose={() => setOpenEditDevice(false)}>
      <Box component="div" sx={{ padding: '32px' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 600 }}> Update User's Device</Typography>
        <UserDevicesTable {...{ data, columns }} />
        <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          <Button sx={{ marginRight: '16px' }} color="secondary" onClick={() => setOpenEditDevice(false)}>
            Cancel
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ModifyUserDevicesModal;
