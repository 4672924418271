/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import { Input, TableRow, FormControl, MenuItem, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EntryComment from 'views/entry/components/EntryComment';
import { useModalCtx } from 'context/ModalContext';
import { checkForOutField } from 'views/entry/qa/components/QaEntry.util';
import { formatDateShort } from 'utils/time.util';
import { RadSelect, RadTextInput } from 'presentational/Inputs';
import NoPadTableCell from './NoPadTableCell';
import QAEntryDatePicker from './QAEntryDatePicker';

const styles = (theme) => ({
  formControl: {
    margin: '0px 0',
    textAlign: 'center',
  },
  select: {
    color: '#2D2D2D',
    fontSize: '12px',
  },
  radTextInput: {
    '&$error': {
      color: 'blue',
    },
    textAlign: 'center',
  },
  outValue: {
    '&$error': {
      color: theme.palette.primary.red,
    },
    '& fieldset': {
      borderColor: theme.palette.primary.red,
      color: theme.palette.primary.red,
    },
    borderColor: theme.palette.primary.red,
    color: theme.palette.primary.red,
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  error: {},
  menuItem: {
    fontSize: '12px',
  },
  icons: {
    fontSize: 16,
  },
  deleteIcon: {
    color: theme.palette.primary.red,
  },
  iconButtonRoot: {
    padding: '0.25rem',
  },
  radSelects: {
    width: '100%',
  },
  inputRoot: {
    padding: '0.2rem 0',
    fontSize: '0.725rem',
    width: '100%',
  },
});

function QaEntryForm(props) {
  const { entryData, classes, click } = props;
  const { values, setFieldValue } = useFormikContext();
  const { showModal, hideModal } = useModalCtx();

  const entryIndex = entryData._id || 'new';

  const handleUpdateNote = (newNotes) => {
    const updatedEntries = Object.assign(values.entries);
    updatedEntries[entryIndex].notes = newNotes;
    setFieldValue('entries', updatedEntries);
    hideModal();
  };

  const handleChange = (e) => {
    const field = e.target.name.substring(e.target.name.lastIndexOf('.') + 1);
    const entry = values.entries[entryIndex];
    entry[field] = e.target.value;
    const isOut = checkForOutField(field, e.target.value, values.entries[entryIndex]);
    if (isOut) {
      if (!entry.outFields.includes(field)) entry.outFields.push(field);
    } else {
      entry.outFields = entry.outFields.filter((currfield) => currfield !== field);
    }
    setFieldValue(`entries.${entryIndex}`, entry);
  };

  const getStyleForField = (field, defaultClass) =>
    values.entries[entryIndex].outFields.includes(field) ? clsx(defaultClass, classes.outValue) : defaultClass;

  let dateUi = (
    <>
      {formatDateShort(entryData.date)}
      <Input type="hidden" name={`entries.${entryIndex}.date`} value={entryData.date} />
    </>
  );
  if (entryIndex !== 'new') {
    dateUi = <QAEntryDatePicker values={values} setFieldValue={setFieldValue} entryIndex={entryIndex} />;
  }

  let cancelButton = null;
  if (click) {
    cancelButton = (
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={click}>
        <ClearIcon classes={{ root: classes.icons }} className={classes.deleteIcon} />
      </IconButton>
    );
  }

  return (
    <TableRow>
      <NoPadTableCell width="6.7%">{dateUi}</NoPadTableCell>
      <NoPadTableCell width="6.85%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.setupAccuracy`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('setupAccuracy', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="6.75%">
        <FormControl className={clsx(classes.formControl, classes.radSelects)}>
          <RadSelect
            name={`entries.${entryIndex}.setupConsole`}
            onChange={(e) => handleChange(e)}
            className={getStyleForField('setupConsole', classes.select)}
          >
            <MenuItem className={classes.menuItem} value>
              Yes
            </MenuItem>
            <MenuItem className={classes.menuItem} value={false}>
              No
            </MenuItem>
          </RadSelect>
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="8%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.centerFreq`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('centerFreq', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.4%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.txGainAtten`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('txGainAtten', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.5%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.geoAccuracyHf`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('geoAccuracyHf', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.65%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.geoAccuracyAp`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('geoAccuracyAp', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.65%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.geoAccuracyRl`}
            type="number"
            onChange={(e) => handleChange(e)}
            InputProps={{
              classes: { root: getStyleForField('geoAccuracyRl', classes.inputRoot) },
            }}
          />
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.8%">
        <FormControl className={clsx(classes.formControl, classes.radSelects)}>
          <RadSelect
            name={`entries.${entryIndex}.spatialResUpper`}
            onChange={(e) => handleChange(e)}
            className={getStyleForField('spatialResUpper', classes.select)}
          >
            <MenuItem className={classes.menuItem} value={0.7}>
              0.7
            </MenuItem>
            <MenuItem className={classes.menuItem} value={0.8}>
              0.8
            </MenuItem>
            <MenuItem className={classes.menuItem} value={0.9}>
              0.9
            </MenuItem>
            <MenuItem className={classes.menuItem} value={1}>
              1.0
            </MenuItem>
            <MenuItem className={classes.menuItem} value={1.1}>
              1.1
            </MenuItem>
          </RadSelect>
        </FormControl>
      </NoPadTableCell>
      <NoPadTableCell width="7.95%">
        <FormControl className={clsx(classes.formControl, classes.radSelects)}>
          <RadSelect
            name={`entries.${entryIndex}.spatialResLower`}
            onChange={(e) => handleChange(e)}
            className={getStyleForField('spatialResLower', classes.select)}
          >
            <MenuItem className={classes.menuItem} value={0.7}>
              0.7
            </MenuItem>
            <MenuItem className={classes.menuItem} value={0.8}>
              0.8
            </MenuItem>
            <MenuItem className={classes.menuItem} value={0.9}>
              0.9
            </MenuItem>
            <MenuItem className={classes.menuItem} value={1}>
              1.0
            </MenuItem>
            <MenuItem className={classes.menuItem} value={1.1}>
              1.1
            </MenuItem>
          </RadSelect>
        </FormControl>
      </NoPadTableCell>

      <NoPadTableCell width="9%">
        <FormControl className={classes.formControl}>
          <RadTextInput
            margin="dense"
            name={`entries.${entryIndex}.spokesNum`}
            type="number"
            InputProps={{
              classes: { root: classes.inputRoot },
            }}
          />
        </FormControl>
      </NoPadTableCell>

      <NoPadTableCell width="8.25%">
        <FormControl className={classes.formControl}>
          <RadSelect
            name={`entries.${entryIndex}.artifactEval`}
            onChange={(e) => handleChange(e)}
            className={getStyleForField('artifactEval', classes.select)}
          >
            <MenuItem className={classes.menuItem} value>
              Yes
            </MenuItem>
            <MenuItem className={classes.menuItem} value={false}>
              No
            </MenuItem>
          </RadSelect>
        </FormControl>
      </NoPadTableCell>

      <NoPadTableCell width="13.5%">{entryData.initials}</NoPadTableCell>

      <NoPadTableCell width="7%">
        <IconButton
          onClick={() =>
            showModal(EntryComment, {
              savedComment: values.entries[entryIndex].notes,
              hideModal,
              updateComment: handleUpdateNote,
              title: 'Notes',
            })
          }
        >
          <CommentIcon classes={{ root: classes.icons }} />
        </IconButton>
      </NoPadTableCell>
      <NoPadTableCell width="13.5%">
        {entryData.reviewedBy ? `${entryData.reviewedBy} on ${formatDateShort(entryData.reviewedDate)}` : null}
      </NoPadTableCell>
      <NoPadTableCell width="4%">{cancelButton}</NoPadTableCell>
    </TableRow>
  );
}

QaEntryForm.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

QaEntryForm.defaultProps = {
  click: null,
};

export default withStyles(styles)(QaEntryForm);
