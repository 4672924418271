import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDeviceCtx } from 'context/DeviceContext';
import { deviceTypes } from 'types/device';
import MetricsOverview from 'views/metrics/MetricsOverview';
import MamEntryOverview from 'views/entry/mam/overview/MamEntryOverview';
import RwsEntryOverview from 'views/entry/rws/overview/RwsEntryOverview';

const styles = (theme) => ({
  main: {
    height: 'auto',
    padding: '16px',
    border: '1px solid #dadada',
  },
  link: {
    textDecoration: 'none',
  },
});

// TODO: had to use regular button tag to remove unaligned hover effect

function DeviceOverview(props) {
  const { match, classes } = props;
  const { devices } = useDeviceCtx();
  const device = devices.find((a) => match.url.includes(a._id));

  let additionalInfo = null;
  switch (device.type) {
    case deviceTypes.CT:
    case deviceTypes.MRI:
      additionalInfo = (
        <Link to={`/devices/${device._id}/metrics`} className={classes.link}>
          <MetricsOverview key={device._id} metricsConfig={device.metricsConfig} />
        </Link>
      );
      break;
    case deviceTypes.MAM:
      additionalInfo = <MamEntryOverview device={device} />;
      break;
    case deviceTypes.RWS:
      additionalInfo = <RwsEntryOverview device={device} />;
      break;
    default:
      throw Error(`Unknown device type: ${device.type}`);
  }

  return <Box className={classes.main}>{additionalInfo}</Box>;
}

export default withRouter(withStyles(styles)(DeviceOverview));
