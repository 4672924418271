import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import radqcApi from '../dataExchange/radqcApiClient';
import InitialLoadingIndicator from '../components/InitialLoadingIndicator';
import UserNotInSystem from 'components/UserNotInSystem';
// import { useModalCtx } from 'context/ModalContext';

const CurrUserContext = createContext();

function CurrUserCtxProvider(props) {
  const { children } = props;
  const [currUser, setCurrUser] = useState(null);
  // const { showModal, hideModal } = useModalCtx();
  const [display, setDisplay] = useState(<InitialLoadingIndicator />);
  const { getAccessTokenSilently } = useAuth0();
  const [openAccountSetting, setOpenAccountSetting] = useState(false);
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);

  const modifyCurrUser = async (newUserFields) => {
    const accessToken = await getAccessTokenSilently();
    const newCurrUser = await radqcApi('put', `/users/${currUser._id}`, newUserFields, { accessToken });
    setCurrUser(newCurrUser);
  };

  const updateCurrUser = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const newCurrUser = await radqcApi('get', '/users/me', { accessToken });

    if (newCurrUser === 'USER_NOT_FOUND') {
      setDisplay(<UserNotInSystem />);
      // showModal(UserNotInSystem, {});
    } else {
      setCurrUser(newCurrUser);
    }
  }, [getAccessTokenSilently]);

  // Update with the list of devices from the DB
  useEffect(() => {
    updateCurrUser();
  }, [updateCurrUser]);

  if (!currUser) return display;

  return (
    <CurrUserContext.Provider
      value={{
        currUser,
        modifyCurrUser,
        setOpenAccountSetting,
        openAccountSetting,
        openDeviceSetting,
        setOpenDeviceSetting,
      }}
    >
      {children}
    </CurrUserContext.Provider>
  );
}

const useCurrUserCtx = () => {
  const context = useContext(CurrUserContext);
  if (context === undefined) {
    throw new Error('useCurrUserCtx must be used within an CurrUserCtxProvider');
  }
  return context;
};

export { CurrUserCtxProvider, useCurrUserCtx };
