import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useDeviceCtx } from 'context/DeviceContext';
import QcDailyEntryForm from './QcDailyEntryForm';
import QcDailyEntryDisplay from './QcDailyEntryDisplay';
import { getUserInitials } from 'utils/user.util';

const initializeEntryData = (entryData, currUser, scanner) => {
  if (entryData.empty) {
    // if no noise baseline is set, entry passes
    let noiseMax = null;
    if (scanner.baseline[`${entryData.mode}Avg`]) {
      const baselineAvg = scanner.baseline[`${entryData.mode}Avg`];
      noiseMax = baselineAvg * (1 + scanner.noiseRange / 100);
    }

    return {
      day: entryData.day,
      warmUp: false,
      airCals: false,
      mode: entryData.mode,
      water: {
        value: '',
        min: -scanner.waterRange,
        max: scanner.waterRange,
      },
      noise: {
        value: '',
        max: noiseMax,
      },
      artifacts: false,
      outFields: [],
      enteredById: currUser._id,
      initials: getUserInitials(currUser),
    };
  }
  return entryData;
};

function QcDailyToggleableEntry(props) {
  const { match, entryData, mode } = props;
  const { currUser } = useCurrUserCtx();
  const { values, setFieldValue } = useFormikContext();
  const { devices } = useDeviceCtx();
  const scanner = devices.find((a) => match.url.includes(a._id));
  const [isToggled, setToggled] = useState(false);

  let EntryComponent = QcDailyEntryDisplay;

  const handleToggle = () => {
    const initEntryData = initializeEntryData(entryData, currUser, scanner);
    const updatedEntries = Object.assign(values.entries);
    if (!isToggled) {
      updatedEntries[initEntryData.day] = initEntryData;
    } else {
      delete updatedEntries[initEntryData.day];
    }
    setFieldValue('entries', updatedEntries);
    setToggled(!isToggled);
  };

  const canToggle = isToggled || mode === 'edit' || (mode === 'enter' && entryData.empty);
  const toggleHandler = canToggle ? handleToggle : null;

  if (isToggled) {
    EntryComponent = QcDailyEntryForm;
  }

  return <EntryComponent entryData={entryData} click={toggleHandler} />;
}

QcDailyToggleableEntry.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
};

export default withRouter(QcDailyToggleableEntry);
