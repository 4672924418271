import React from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, MenuItem, Button, Typography, Box, FormLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@mui/lab';
import { RadSelect, RadTextInput } from 'presentational/Inputs';
import { useModalCtx } from 'context/ModalContext';
import { LocalizationProvider } from '@mui/x-date-pickers';

const styles = (theme) => ({
  centered: {
    textAlign: 'center',
  },
  formControl: {
    margin: 0,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  select: {
    zIndex: 1400,
    'label + &': {
      marginTop: '0rem',
    },
  },
  buttonStyles: {
    fontSize: '0.8rem',
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  buttonRoot: {
    marginLeft: '40px',
  },
  buttonRootCancel: {
    marginLeft: '25px',
  },
  outFieldValues: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    height: '1.85rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtitles: {
    margin: '0.25rem 0',
    opacity: 0.7,
    fontSize: 14,
    textAlign: 'left',
    textDecoration: 'underline',
  },
  inputs: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  windowLevelinputs: {
    margin: '0 1rem 0 0',
  },
  artifactSelect: {
    width: '10rem',
    paddingTop: '0.8rem',
  },
  initial: {
    margin: '1rem 0',
  },
  section: {
    minWidth: '15.5rem',
  },
  rightSection: {
    marginRight: '1rem',
  },
  headingSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  artifactAndDateInputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  monthlyDisplayMonitor: {
    marginTop: '1rem',
  },
});

function QcMonthlyTable(props) {
  const { disableMonthlyQc, classes } = props;
  const { hideModal } = useModalCtx();
  const { values, setFieldValue } = useFormikContext();
  const handleDateChange = (input) => {
    setFieldValue('enteredDate', input);
  };

  const handleChange = (e) => {
    const field = e.target.name;
    let outFields = Object.assign(values.outFields);
    const { value } = e.target;
    if (value === 'fail') {
      if (!outFields.includes(field)) outFields.push(field);
    } else {
      outFields = outFields.filter((currfield) => currfield !== field);
    }
    setFieldValue(field, value);
    setFieldValue('outFields', outFields);
  };

  const getStyleForField = (field, defaultClass) =>
    values.outFields.includes(field) ? clsx(defaultClass, classes.outFieldValues) : defaultClass;

  const createNaPassFailMenu = (name) => (
    <RadSelect name={name} onChange={(e) => handleChange(e)} className={getStyleForField(name, classes.select)}>
      <MenuItem sx={{ fontSize: 14 }} value="n/a">
        N/A
      </MenuItem>
      <MenuItem sx={{ fontSize: 14 }} value="pass">
        P
      </MenuItem>
      <MenuItem sx={{ fontSize: 14 }} value="fail">
        F
      </MenuItem>
    </RadSelect>
  );

  const displayEntryLine = (name, label) => (
    <>
      <div className={classes.inputContainer}>{label}</div>
      <div className={classes.inputContainer}>
        <FormControl className={classes.formControl}>{createNaPassFailMenu(name)}</FormControl>
      </div>
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 16, marginBottom: '16px' }} className={classes.centered}>
          Monthly Visual Checklist
        </Typography>
      </Grid>
      <Box className={classes.headingSection}>
        <Box className={clsx(classes.section, classes.rightSection)}>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.centered, classes.subtitles)}>
              Gantry
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('tableHeight', 'Table Height Indicator')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('tablePosition', 'Table Position Indicator')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('angulation', 'Angulation Indicator')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('laserLocLight', 'Laser Localization Light')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('cables', 'High Voltage Cable/other Cables')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('smoothness', 'Smoothness of table motion')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('gantryXray', 'X-Ray on Indicator')}
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.centered, classes.subtitles)}>
              Control Console
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('exposureSwitch', 'Exposure Switch')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('displayWindow', 'Display Window Width/level')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('panelSwitches', 'Panel Switches/lights/meters')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('consoleXray', 'X-Ray on Indicator')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('doorInterlocks', 'Door Interlocks')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('warningLabels', 'Warning Labels')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('intercom', 'Intercom System')}
          </Grid>
        </Box>
      </Box>

      <Box className={clsx(classes.headingSection, classes.monthlyDisplayMonitor)}>
        <Box>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 600, fontSize: 14, width: '100%', textAlign: 'left' }}>
              Monthly Display Monitor
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.centered, classes.subtitles)}>
              SMPTE Pattern
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('contrast0', '0%-5% Contrast is Discernible')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('contrast95', '95%-100% Contrast is Discernible')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('graySteps', 'Distinct Gray Level Steps')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('alphanumerics', 'Alphanumerics Discernible')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('highPatterns', 'High Contrast Patterns Visible')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('lowPatterns', 'Low Contrast Patterns Visible')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('noArtifacts', 'No Artifacts')}
          </Grid>
          <Grid item xs={12} className={classes.inputs}>
            <div className={clsx(classes.inputContainer, classes.windowLevelinputs)}>
              <FormControl className={classes.formControl}>
                <FormLabel sx={{ margin: 0, padding: 0, fontSize: 12 }}>Window</FormLabel>
                <RadTextInput sx={{ margin: 0 }} name="window" type="number" margin="dense" />
              </FormControl>
            </div>
            <div className={clsx(classes.inputContainer, classes.windowLevelinputs)}>
              <FormControl className={classes.formControl}>
                <FormLabel sx={{ margin: 0, padding: 0, fontSize: 12 }}>Level</FormLabel>
                <RadTextInput sx={{ margin: 0 }} name="level" type="number" margin="dense" />
              </FormControl>
            </div>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Grid item xs={12}>
            <Typography variant="h5" className={clsx(classes.centered, classes.subtitles)}>
              Others
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('postings', 'Postings')}
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {displayEntryLine('records', 'Service Records')}
          </Grid>
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography sx={{ fontSize: 14 }}>If available, scan manufacturer&apos;s large phantom.</Typography>
          <Box className={classes.artifactAndDateInputs}>
            <FormControl className={clsx(classes.formControl, classes.artifactSelect)}>
              {/* <RadTextInput label="Artifacts" name="artifacts" type="number" /> */}
              <FormLabel sx={{ margin: 0, padding: 0, fontSize: 12 }}>Artifacts</FormLabel>
              <RadSelect name="artifacts" className={values.artifacts ? classes.outFieldValues : classes.select}>
                <MenuItem value={false}>None</MenuItem>
                <MenuItem value>Artifact(s) Noted</MenuItem>
              </RadSelect>
            </FormControl>

            <LocalizationProvider utils={DateFnsUtils}>
              <DatePicker
                renderInput={() => <TextField label="Date of Monthly QC:" />}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="dateOfMonthlyQC"
                value={values.enteredDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>

      <Grid item xs={12} className={clsx(classes.inputContainer, classes.initial)}>
        Entered By: {values.initials}
      </Grid>

      <Grid item xs={12} className={classes.inputContainer}>
        <FormControl className={classes.formControl}>
          <Button
            color="secondary"
            onClick={hideModal}
            classes={{ label: classes.buttonStyles, root: classes.buttonRootCancel }}
          >
            Cancel
          </Button>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={disableMonthlyQc}
            classes={{ label: classes.buttonStyles, root: classes.buttonRoot }}
          >
            Submit
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
}

QcMonthlyTable.propTypes = {
  disableMonthlyQc: PropTypes.bool.isRequired,
};

export default withStyles(styles)(QcMonthlyTable);
