import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, FormControl, Select, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { getDeviceIdFromUrl } from 'utils/device.util';
import radqcApi from 'dataExchange/radqcApiClient';
import RecordViewer from 'views/entry/components/recordViewer/RecordViewer';
import { EntryModes } from 'types/entry';
import { formatDate } from 'utils/time.util';
import MamRejectTable from './mamRejectTable/MamRejectTable';

const styles = {
  table: {
    height: '100%',
  },
  mamRejectSelect: {
    width: '10rem',
    margin: '2rem',
  },
};

function MamRejectDashboard(props) {
  const { mode, match, date, classes } = props;
  const [savedMamRejects, setSavedMamRejects] = useState([]);
  const [selEntryIndex, setSelEntryIndex] = useState(0);
  // const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();

  const getRejects = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const deviceId = getDeviceIdFromUrl(match.url);
    let receivedMamRejects;
    if (mode === 'view') {
      const startDate = date.start.toISOString();
      const endDate = date.end.toISOString();

      try {
        receivedMamRejects = await radqcApi(
          'get',
          `/entries/mam_qu/device/${deviceId}?start=${startDate}&end=${endDate}`,
          { accessToken },
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        receivedMamRejects = await radqcApi('get', `/entries/mam_qu/device/${deviceId}`, { accessToken });
      } catch (err) {
        receivedMamRejects = [];
      }
    }
    if (!receivedMamRejects) receivedMamRejects = [];
    setSavedMamRejects(receivedMamRejects);
  }, [match.url, mode, getAccessTokenSilently, date.start, date.end]);

  useEffect(() => {
    getRejects();
  }, [mode, date, getRejects]);

  if (mode === 'view') {
    return <RecordViewer recordType="MAM Reject Analysis" records={savedMamRejects} />;
  }

  return (
    <Grid item xs={12} className={classes.table}>
      {mode === EntryModes.EDIT && (
        <FormControl className={classes.formControl}>
          <Select
            className={classes.mamRejectSelect}
            onChange={(e) => setSelEntryIndex(e.target.value)}
            value={selEntryIndex}
          >
            {savedMamRejects.map((entry, index) => (
              <MenuItem key={entry._id} className={classes.menuItem} value={index}>
                {formatDate(entry.date)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <MamRejectTable
        key={mode + selEntryIndex}
        mamReject={mode === EntryModes.EDIT ? savedMamRejects[selEntryIndex] : null}
        mode={mode}
        updateRejects={getRejects}
      />
    </Grid>
  );
}

MamRejectDashboard.propTypes = {
  mode: PropTypes.string.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(withStyles(styles)(MamRejectDashboard));
