import React from 'react';
import { FormControl, MenuItem, Box, FormLabel, Typography } from '@mui/material';
import { RadSelect, RadTextInput, RadCheckbox } from 'presentational/Inputs';

interface AdditionalSettingsMRIProps {
  heliumSet: boolean;
}

function AdditionalSettingsMRI({ heliumSet }: AdditionalSettingsMRIProps) {
  return (
    <>
      <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '12px' }}>
        <FormControl>
          <FormLabel sx={{ fontSize: 12 }}>Field Strength</FormLabel>
          <RadSelect name="fieldStrength">
            <MenuItem value="" />
            <MenuItem value={0.2}>0.2T</MenuItem>
            <MenuItem value={0.3}>0.3T</MenuItem>
            <MenuItem value={0.35}>0.35T</MenuItem>
            <MenuItem value={0.5}>0.5T</MenuItem>
            <MenuItem value={0.6}>0.6T</MenuItem>
            <MenuItem value={0.7}>0.7T</MenuItem>
            <MenuItem value={1.0}>1.0T</MenuItem>
            <MenuItem value={1.2}>1.2T</MenuItem>
            <MenuItem value={1.5}>1.5T</MenuItem>
            <MenuItem value={3.0}>3.0T</MenuItem>
          </RadSelect>
        </FormControl>
        <FormControl sx={{ marginLeft: '16px' }}>
          <FormLabel sx={{ fontSize: 12 }}>Phantom Size</FormLabel>
          <RadSelect name="phantomSize">
            <MenuItem value="Large">Large</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Small">Small</MenuItem>
          </RadSelect>
        </FormControl>
      </Box>
      <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormControl>
          <RadCheckbox name="heliumSet">
            <Typography sx={{ fontSize: 12 }}>Set Helium Alert</Typography>
          </RadCheckbox>
        </FormControl>
        {heliumSet && (
          <FormControl>
            <FormLabel sx={{ fontSize: 12 }}>Alert Level</FormLabel>
            <RadTextInput name="heAlertLevel" type="number" />
          </FormControl>
        )}
      </Box>
      {heliumSet && (
        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl>
            <RadCheckbox id="coldHeadTrack" name="coldHeadTrack">
              <Typography sx={{ fontSize: 12 }}> Cold Head Tracking</Typography>
            </RadCheckbox>
          </FormControl>
          <FormControl>
            <RadCheckbox id="emailEngineers" name="emailEngineers">
              <Typography sx={{ fontSize: 12 }}> Email Engineers</Typography>
            </RadCheckbox>
          </FormControl>
        </Box>
      )}
    </>
  );
}

export default AdditionalSettingsMRI;
