//@ts-nocheck
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import { useDeviceCtx } from 'context/DeviceContext';
import UsersTableContainer from './UsersTable';
import { isUserCreator } from 'utils/user.util';
import { useCurrUserCtx } from 'context/CurrUserContext';
import ModifyUserModal from './ModifyUserModal';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

function UsersOverview() {
  const { devices } = useDeviceCtx();
  const { currUser } = useCurrUserCtx();
  const [openAddNewModal, setOpenAddNewModal] = useState(false);

  //TODO fix: Expression produces a union type that is too complex to represent.ts(2590) for now use ts-nocheck

  const getDevicesForUser = (userDeviceIds: any[]) =>
    devices.filter((device: { _id: string | number }) => userDeviceIds.includes(device._id));

  return (
    <Box>
      <Box component="div" display="flex" alignItems="center">
        <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Users </Typography>
        <Tooltip title="Add New User" placement="right">
          <IconButton
            disabled={!isUserCreator(currUser)}
            sx={{ marginLeft: '8px' }}
            onClick={() => setOpenAddNewModal(true)}
          >
            <PersonAddAltOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <UsersTableContainer getDevicesForUser={getDevicesForUser} />
      <ModifyUserModal openEditModal={openAddNewModal} setEditModal={setOpenAddNewModal} />
    </Box>
  );
}

export default withRouter(UsersOverview);
