import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isAdmin } from 'utils/user.util';
import { useOrganizationCtx } from './OrganizationContext';
import { useCurrUserCtx } from './CurrUserContext';
import radqcApi from 'dataExchange/radqcApiClient';

const DeviceContext = createContext({
  devices: [],
  unfilteredDevices: [],
  updateDevices: () => {},
});

function DeviceCtxProvider(props) {
  const { children } = props;
  const [devices, setDevices] = useState([]);
  const [unfilteredDevices, setUnfilteredDevices] = useState([]);
  const { selectedOrg } = useOrganizationCtx();
  const { currUser } = useCurrUserCtx();
  const { getAccessTokenSilently } = useAuth0();

  const updateDevices = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    let deviceList = [];
    const unfilteredDeviceList = await radqcApi('get', '/devices', { accessToken });
    if (selectedOrg.type === 'facility') {
      deviceList = await radqcApi('get', `/devices/organization/${selectedOrg.id}`, { accessToken });
    } else if (selectedOrg.type === 'group') {
      deviceList = await radqcApi('get', `/devices/organization/${selectedOrg.id}?group=1`, { accessToken });
    } else {
      deviceList = unfilteredDeviceList;
    }

    if (!isAdmin(currUser)) {
      deviceList = deviceList.filter((device) => currUser.devices.includes(device._id));
    }
    setUnfilteredDevices(unfilteredDeviceList);
    setDevices(deviceList);
  }, [currUser, getAccessTokenSilently, selectedOrg]);

  const getDeviceFromId = (deviceId) => devices.find((a) => a._id === deviceId);

  // Update with the list of devices from the DB
  useEffect(() => {
    updateDevices();
  }, [updateDevices]);

  return (
    <DeviceContext.Provider value={{ devices, unfilteredDevices, getDeviceFromId, updateDevices }}>
      {children}
    </DeviceContext.Provider>
  );
}

const useDeviceCtx = () => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error('useDeviceCtx must be used within an DeviceCtxProvider');
  }
  return context;
};

export { DeviceCtxProvider, useDeviceCtx };
