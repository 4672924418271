import React from 'react';
import { IconButton, TableCell, TableRow, Table, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import PropTypes from 'prop-types';
import { weeklyCommonStyles, getStyleForField } from 'views/entry/mam/components/commonStyles';
import { useModalCtx } from 'context/ModalContext';
import { formatDate } from 'utils/time.util';
import { artifactEvalFields, displayValue } from 'views/entry/mam/components/mamEntry.util';
import EntryComment from 'views/entry/components/EntryComment';

const useStyles = makeStyles(weeklyCommonStyles);

function ArtifactEvalEntryDisplay(props) {
  const { entryData, click } = props;
  const classes = useStyles();
  const { showModal, hideModal } = useModalCtx();
  const fields = artifactEvalFields.filter((field) => !field.includes('Remarks') && !field.includes('outFields'));

  let editButton = null;
  if (click) {
    editButton = (
      <IconButton onClick={click} classes={{ root: classes.iconButtonRoot }}>
        <EditIcon className={classes.icons} />
      </IconButton>
    );
  }

  return (
    <TableCell classes={{ root: classes.noPadding }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{formatDate(entryData.date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{entryData.initials}</TableCell>
          </TableRow>
          {fields.map((field) => (
            <TableRow key={field}>
              <TableCell classes={{ root: getStyleForField(field, entryData, classes) }}>
                {displayValue(entryData[field])}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  showModal(EntryComment, {
                    savedComment: entryData.artEvalRemarks,
                    hideModal,
                    title: 'Remarks',
                  });
                }}
              >
                <CommentIcon className={classes.icons} />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableTitles }}>{editButton}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableCell>
  );
}

ArtifactEvalEntryDisplay.propTypes = {
  entryData: PropTypes.objectOf(PropTypes.any).isRequired,
  click: PropTypes.func,
};

ArtifactEvalEntryDisplay.defaultProps = {
  click: null,
};

export default ArtifactEvalEntryDisplay;
