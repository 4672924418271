import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext({
  component: null,
  props: {},
  showModal: (a, b) => {},
  hideModal: () => {},
});

function ModalCtxProvider(props) {
  const { children } = props;
  const [component, setComponent] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const showModal = (comp, mProps) => {
    setComponent(comp);
    setModalProps(mProps);
  };

  const hideModal = () => {
    setComponent(null);
    setModalProps({});
  };

  return (
    <ModalContext.Provider
      value={{
        component,
        modalProps,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

const useModalCtx = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalCtx must be used within a ModalCtxProvider');
  }
  return context;
};

export { ModalCtxProvider, useModalCtx };
