import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { mapValues, uniqBy } from 'lodash';
import * as Yup from 'yup';
import { Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDeviceCtx } from 'context/DeviceContext';
import { useUserCtx } from 'context/UserContext';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { useModalCtx } from 'context/ModalContext';
import { generateEmptyVcEntry } from 'views/entry/vc/components/VcTable.util';
import EntryConfirmModal from 'views/entry/components/EntryConfirmModal';
import { enumNaPassFailValidation } from 'utils/entry.util';
import { getUserInitials } from 'utils/user.util';
import VcEntryForm from './components/VcEntryForm';
import radqcApi from 'dataExchange/radqcApiClient';
import VcTableHeader from 'views/entry/vc/components/VcTableHeader';
import VcToggleableEntry from './components/VcToggleableEntry';

const styles = (theme) => ({
  headerTableRow: { backgroundColor: 'transparent' },
  formStyle: {
    height: '600px',
  },
  tableContainer: {
    width: '100%',
    height: '80%',
    marginBottom: '20px',
    border: '1px solid #005ed426',
    overflowY: 'auto',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 649px)': {
      margin: '0',
      width: '100%',
    },
  },
  bottomNavigation: {
    height: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: 'none',
    backgroundColor: `${theme.palette.primary.main}2a`,
  },
  initials: {
    textAlign: 'left',
    paddingLeft: '1rem',
  },
  rightTables: {
    height: '675px',
  },
  tableCell__notApplicableSubmit: {
    border: 'none',
  },
});

function VcTable(props) {
  const { match, classes, vcEntries, mode, updateEntries } = props;
  const [entryKey, setEntryKey] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const { devices, updateDevices } = useDeviceCtx();
  const { updateUsers } = useUserCtx();
  const { currUser } = useCurrUserCtx();
  const { showModal } = useModalCtx();
  const { enqueueSnackbar } = useSnackbar();
  const device = devices.find((a) => match.url.includes(a._id));

  const initialValues = { entries: {} };
  let newEntryForm = null;
  if (mode === 'enter') {
    initialValues.entries = { new: generateEmptyVcEntry(currUser, device) };
    newEntryForm = <VcEntryForm entryData={initialValues.entries.new} mode={mode} />;
  }

  const handleReview = async () => {
    const reviewPayload = {
      reviewedBy: getUserInitials(currUser),
      reviewedById: currUser._id,
      reviewedDate: new Date().toISOString(),
    };
    const accessToken = await getAccessTokenSilently();
    const resultMsg = await radqcApi('put', `/entries/vc/device/${device._id}/review`, reviewPayload, { accessToken });
    enqueueSnackbar(resultMsg);
    const numEntriesReviewed = resultMsg.substr(0, resultMsg.indexOf(' '));
    if (numEntriesReviewed > 0) {
      updateEntries();
      updateDevices();
      updateUsers();
    }
  };

  const handleSubmit = (values, submitForm) => {
    const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
    showModal(EntryConfirmModal, {
      entryType: 'vc',
      confirmEntries: newEntries,
      accept: () => submitForm(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        entries: Yup.lazy((obj) =>
          Yup.object(
            mapValues(obj, () =>
              Yup.object({
                deviceId: Yup.string().required('Required'),
                date: Yup.string().required('Required'),
                bedPosition: enumNaPassFailValidation,
                alignmentLight: enumNaPassFailValidation,
                tensionCable: enumNaPassFailValidation,
                horzStability: enumNaPassFailValidation,
                vertStability: enumNaPassFailValidation,
                laserCamera: enumNaPassFailValidation,
                lightBoxes: enumNaPassFailValidation,
                rfDoorContacts: enumNaPassFailValidation,
                rfScreenIntegrity: enumNaPassFailValidation,
                consoleSwitches: enumNaPassFailValidation,
                patientMonitors: enumNaPassFailValidation,
                patientIntercom: enumNaPassFailValidation,
                tempHumidity: enumNaPassFailValidation,
                emergencyCart: enumNaPassFailValidation,
                safetyWarning: enumNaPassFailValidation,
                doorIndicator: enumNaPassFailValidation,
                cryogenIndicator: enumNaPassFailValidation,
                oxygenMonitor: enumNaPassFailValidation,
                outFields: Yup.array(),
                initials: Yup.string().required('Required'),
                enteredById: Yup.string().required('Required'),
              }),
            ),
          ),
        ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const accessToken = await getAccessTokenSilently();
        const newEntries = Object.keys(values.entries).map((entry) => values.entries[entry]);
        if (newEntries[0]._id === 'new') {
          const newEntry = newEntries[0];
          delete newEntry._id;
          await radqcApi('post', '/entries/vc', newEntry, { accessToken });
          enqueueSnackbar(`Added new VC entry`, { variant: 'info' });
        } else {
          // TODO: add new back end endpoint to accept multiple modified entries at once
          await newEntries.forEach((entry) => radqcApi('put', `/entries/vc/${entry._id}`, entry, { accessToken }));
          enqueueSnackbar(`Updated ${newEntries.length} entr${newEntries.length === 1 ? 'y' : 'ies'}`, {
            variant: 'info',
          });
        }
        // The following is used to force React to re-render the form
        setEntryKey(entryKey + 1);
        updateEntries();
        updateDevices();
        updateUsers();
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, submitForm }) => {
        let allVcEntries = vcEntries;
        if (mode === 'edit') {
          const savedVcEntries = vcEntries || [];
          allVcEntries = [...savedVcEntries, ...Object.values(values.entries)].sort((a, b) => a.date - b.date);
          allVcEntries = uniqBy(allVcEntries, '_id');
        }
        return (
          <Form className={classes.formStyle}>
            <div className={classes.tableContainer}>
              <VcTableHeader />
              <div className={classes.rightTables}>
                <Table key={entryKey}>
                  <TableBody>
                    <TableRow>
                      {newEntryForm}
                      {allVcEntries.map((entry) => (
                        <VcToggleableEntry key={entryKey + entry._id} entryData={entry} mode={mode} />
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
            <Table className={classes.bottomNavigation}>
              <TableBody>
                <TableRow className={classes.headerTableRow}>
                  <TableCell classes={{ root: classes.tableCell__notApplicableSubmit }}>N/A = Not Applicable</TableCell>
                  <TableCell classes={{ root: classes.tableCell__notApplicableSubmit }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={Object.keys(values.entries).length < 1 && mode !== 'review'}
                      onClick={() => (mode === 'review' ? handleReview() : handleSubmit(values, submitForm))}
                    >
                      {mode === 'review' ? 'Review' : 'Submit'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Form>
        );
      }}
    </Formik>
  );
}

VcTable.propTypes = {
  vcEntries: PropTypes.arrayOf(PropTypes.any),
  mode: PropTypes.string.isRequired,
  updateEntries: PropTypes.func.isRequired,
};

VcTable.defaultProps = {
  vcEntries: [],
};

export default withRouter(withStyles(styles)(VcTable));
