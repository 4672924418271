/* eslint-disable react/jsx-boolean-value */

// @ts-ignore
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Typography, Box, Button, MenuItem, InputLabel } from '@mui/material';
import { useCurrUserCtx } from 'context/CurrUserContext';
import { pick } from 'lodash';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { RadTextInput, RadSelect } from 'presentational/Inputs';
import { enumDegrees, enumCertifications } from 'types/user';
import { useUserCtx } from 'context/UserContext';

export default function TemporaryDrawer() {
  const { currUser, modifyCurrUser, openAccountSetting, setOpenAccountSetting } = useCurrUserCtx();
  const { updateUsers } = useUserCtx();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = pick(currUser, ['firstName', 'lastName', 'role', 'email', 'degree', 'certification']);

  return (
    <Drawer anchor="right" open={openAccountSetting} onClose={() => setOpenAccountSetting(false)}>
      <Box component="div" width="400px" padding="32px">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography fontWeight={600} fontSize="20px">
            Account Settings
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '6px' }}>
            Here&apos;s some settings you can adjust. Make sure to press &apos;Update&apos; button to save your changes.
          </Typography>

          <Box component="div" sx={{ marginTop: '16px' }}>
            <Typography fontSize="16px" fontWeight={600}>
              User Profile Information
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                firstName: Yup.string().required('Required'),
                lastName: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const newUserData = { ...values };
                await modifyCurrUser(newUserData);
                enqueueSnackbar(`Updated user profile`);
                updateUsers();
                setSubmitting(false);
                openAccountSetting(false);
              }}
            >
              <Form>
                <InputLabel
                  id="role"
                  aria-label="role"
                  aria-labelledby="role"
                  sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                >
                  User role
                </InputLabel>
                <RadSelect
                  disabled
                  displayEmpty={true}
                  value={initialValues.role}
                  name="role"
                  defaultValue={initialValues.role}
                >
                  <MenuItem value={initialValues.role}>{initialValues.role}</MenuItem>
                </RadSelect>

                <Box component="div" sx={{ display: 'flex', marginTop: '16px' }}>
                  <Box component="div" sx={{ marginRight: '8px' }}>
                    <InputLabel
                      id="firstName"
                      aria-label="firstName"
                      aria-labelledby="firstName"
                      sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                    >
                      First Name
                    </InputLabel>
                    <RadTextInput variant="filled" id="firstName" name="firstName" type="text" />
                  </Box>
                  <Box component="div">
                    <InputLabel
                      id="lastName"
                      aria-label="lastName"
                      aria-labelledby="lastName"
                      sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                    >
                      Last Name
                    </InputLabel>
                    <RadTextInput variant="filled" id="lastName" name="lastName" type="text" />
                  </Box>
                </Box>
                <Box component="div" sx={{ display: 'flex', marginTop: '16px' }}>
                  <Box component="div" sx={{ marginRight: '8px' }}>
                    <InputLabel
                      id="email"
                      aria-label="email"
                      aria-labelledby="email"
                      sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                    >
                      Email
                    </InputLabel>
                    <RadTextInput
                      sx={{ width: '400px' }}
                      id="email"
                      variant="filled"
                      name="email"
                      type="text"
                      disabled
                    />
                  </Box>
                </Box>

                <Box component="div" sx={{ display: 'flex', marginTop: '16px' }}>
                  <Box component="div" sx={{ marginRight: '8px' }}>
                    <InputLabel
                      id="degree"
                      aria-label="degree"
                      aria-labelledby="degree"
                      sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                    >
                      Degree
                    </InputLabel>
                    <RadSelect name="degree">
                      {enumDegrees.map((degree) => (
                        <MenuItem key={degree} value={degree}>
                          {degree}
                        </MenuItem>
                      ))}
                    </RadSelect>
                  </Box>
                  <Box component="div">
                    <InputLabel
                      id="certification"
                      aria-label="certification"
                      aria-labelledby="certification"
                      sx={{ fontSize: '12px', color: '#000', display: 'flex' }}
                    >
                      Certification
                    </InputLabel>
                    <RadSelect name="certification">
                      {enumCertifications.map((certification) => (
                        <MenuItem key={certification} value={certification}>
                          {certification}
                        </MenuItem>
                      ))}
                    </RadSelect>
                  </Box>
                </Box>

                <Box component="div" sx={{ marginTop: '32px', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    sx={{ width: '180px' }}
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    onClick={() => setOpenAccountSetting(false)}
                  >
                    Cancel
                  </Button>
                  <Button sx={{ width: '180px' }} variant="outlined" color="primary" type="submit">
                    Update
                  </Button>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
