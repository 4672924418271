import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/time.util';
import palette from 'theme/palette';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    margin: 16,
  },
  subContainer: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.3,
    margin: 8,
  },
  table: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.3,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'table',
    width: '46.5%',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginRight: 0,
  },
  autoMarginRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  dailyHeader: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.2,
    margin: 'auto',
    width: '70%',
    textAlign: 'center',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomColor: '#000',
  },
  dailyHeaderTxt: {
    fontSize: 10,
    fontWeight: 500,
  },
  dataTxt: {
    fontSize: 10,
    fontWeight: 300,
  },
  copyRight: {
    fontSize: 8,
    color: palette.primary.main,
    opacity: 0.5,
    marginLeft: 24,
    marginBottom: 32,
    textAlign: 'center',
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function HePdfPreview(props) {
  const { records, device } = props;

  return (
    <Document>
      {records
        ? records.map((record) => (
            <Page key={record.entries[0]._id} style={styles.body}>
              <View fixed style={styles.footer}>
                <Text
                  style={styles.header}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
                <Text fixed style={styles.header}>
                  Provided By Radiology QC Solutions
                </Text>
              </View>
              <Text style={styles.formTitle}>MRI Helium Level</Text>
              <View key={record.entries[0]._id} style={{ marginBottom: 24, marginTop: 32, marginLeft: 32 }}>
                <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
                <Text style={styles.scannerInfo}>Scanner : {`${device.name} (${device.apNumber})`}</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.subContainer}>
                  <View style={styles.table}>
                    <View style={styles.autoMarginRow}>
                      <View style={styles.dailyHeader}>
                        <Text style={styles.dailyHeaderTxt}>Date</Text>
                      </View>
                      <View style={styles.dailyHeader}>
                        <Text style={styles.dailyHeaderTxt}>Helium Level (%)</Text>
                      </View>
                      <View style={styles.dailyHeader}>
                        <Text style={styles.dailyHeaderTxt}>Pressure (PSI)</Text>
                      </View>
                      <View style={styles.dailyHeader}>
                        <Text style={styles.dailyHeaderTxt}>Cold Head Running</Text>
                      </View>
                      <View style={styles.dailyHeader}>
                        <Text style={styles.dailyHeaderTxt}>Entered By</Text>
                      </View>
                    </View>

                    {record.entries.map((entry) => (
                      <View key={entry._id} style={styles.autoMarginRow}>
                        <View style={styles.dailyHeader}>
                          <Text style={styles.dataTxt}> {formatDate(entry.date)}</Text>
                        </View>
                        <View style={styles.dailyHeader}>
                          <Text style={styles.dataTxt}>{entry.heliumLevel}</Text>
                        </View>
                        <View style={styles.dailyHeader}>
                          <Text style={styles.dataTxt}>{entry.pressure}</Text>
                        </View>
                        <View style={styles.dailyHeader}>
                          <Text style={styles.dataTxt}>{entry.coldHeadRunning ? 'Y' : 'N'}</Text>
                        </View>
                        <View style={styles.dailyHeader}>
                          <Text style={styles.dataTxt}>{entry.enteredBy}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <Text style={styles.copyRight}>© 2020 Radiology QC Solutions LLC</Text>
            </Page>
          ))
        : null}
    </Document>
  );
}

HePdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

HePdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default HePdfPreview;
